import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Cloud({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cloud</title>
      <defs>
        <path
          d="M18.0063746,6.00111106 C21.3200831,6.00111106 24.0063746,8.68740256 24.0063746,12.0011111 C24.0063746,15.3148196 21.3200831,18.0011133 18.0063746,18.0011133 L9.00712041,18.0011133 C4.32921689,18.0045994 0.428547864,14.4238431 0.0326909283,9.76271758 C-0.363166008,5.1015921 2.87778487,0.91431707 7.48927975,0.128881895 C11.8407754,-0.612269907 16.0434586,1.90883414 17.4861391,6.00111106 L18.0063746,6.00111106 Z M18.0063746,8.00111106 L16.7463746,8.00111106 C16.2903765,8.00111106 15.8921285,7.69262964 15.7781289,7.25111134 C14.8685353,3.72827459 11.4118044,1.48959485 7.82508615,2.10048887 C4.2383679,2.7113829 1.71762833,5.96815236 2.02551706,9.59347218 C2.33340579,13.218792 5.36725948,16.0038247 9.00637464,16.001113 L18.0063746,16.001113 C20.2155136,16.001113 22.0063746,14.2102501 22.0063746,12.0011111 C22.0063746,9.79197206 20.2155136,8.00111106 18.0063746,8.00111106 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-915, -196)">
          <g id="cloud" transform="translate(915, 199)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
