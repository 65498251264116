import { Button } from '@components';
import { StepperContext } from '@components/Stepper';
import { useAppSettings, useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatCurrency } from '@utils';
import IncomeHardStopStep from './IncomeHardStopStep';

const IncomeCheckStep: React.FC = () => {
  const theme = useTheme();
  const { formatNumber } = useIntl();
  const isMobile = useIsMobile();
  const { goToNextStep } = useContext(StepperContext);

  const { data: appSettingsData } = useAppSettings();

  const [isWrongIncome, setIsWrongIncome] = useState(false);

  return isWrongIncome ? (
    <IncomeHardStopStep onBack={() => setIsWrongIncome(false)} />
  ) : (
    <Stack alignItems="center" gap={4}>
      <Typography variant="h2" textAlign="center" sx={{ marginTop: theme.spacing(isMobile ? 19 : 37) }}>
        <FormattedMessage
          id="eligibilityCheck.step.3.title"
          values={{ basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0), }}
        />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={() => setIsWrongIncome(true)}>
          <FormattedMessage id="common.no" />
        </Button>
        <Button variant="contained" onClick={goToNextStep}>
          <FormattedMessage id="common.yes" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default IncomeCheckStep;
