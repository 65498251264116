import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function BarChart2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>bar-chart-2</title>
      <defs>
        <path
          d="M10,2 L10,18 L12,18 L12,2 L10,2 Z M13,0 C13.5522847,0 14,0.44771525 14,1 L14,19 C14,19.5522847 13.5522847,20 13,20 L9,20 C8.44771525,20 8,19.5522847 8,19 L8,1 C8,0.44771525 8.44771525,0 9,0 L13,0 Z M21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,6 C16,5.44771525 16.4477153,5 17,5 L21,5 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,19 C6,19.5522847 5.55228475,20 5,20 L1,20 C0.44771525,20 0,19.5522847 0,19 L0,11 C0,10.4477153 0.44771525,10 1,10 L5,10 Z M20,7 L18,7 L18,18 L20,18 L20,7 Z M4,12 L2,12 L2,18 L4,18 L4,12 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1149, -669)">
          <g id="bar-chart-2" transform="translate(1150, 671)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
