import { InfoIcon } from '@icons';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface IFieldErrorMessageProps {
  message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const FieldErrorMessage: React.FC<IFieldErrorMessageProps> = ({ message }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" justifyContent="space-between" mt="4px" alignItems="start">
      <Typography fontSize="14px" lineHeight="23px" color={theme.palette.error.main}>
        {`${message}` || ''}
      </Typography>
      <Stack justifySelf="end">
        <InfoIcon color={theme.palette.error.main} />
      </Stack>
    </Stack>
  );
};

export default FieldErrorMessage;
