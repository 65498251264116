import { ButtonProps } from '@mui/material';
import React, { MouseEvent, ReactNode, forwardRef } from 'react';
import { StyledButton } from './styles';

interface IButtonProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children: ReactNode;
  startIcon?: ReactNode;
}

// @ts-ignore
const Button: React.FC<IButtonProps & ButtonProps> = forwardRef(
  (
    {
      color = 'primary',
      variant = 'text',
      className,
      onClick,
      type = 'button',
      disabled = false,
      children,
      startIcon,
      ...restProps
    },
    ref,
  ) => {
    return (
      <StyledButton
        disableRipple
        className={className}
        color={color}
        variant={variant}
        type={type}
        onClick={onClick}
        disabled={disabled}
        startIcon={startIcon}
        ref={ref}
        {...restProps}
      >
        {children}
      </StyledButton>
    );
  },
);

export default Button;
