import { IInputProps } from '@components/Input';
import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { StyledSendCodeInput } from './styles';

const SendCodeInput: React.FC<IInputProps & TextFieldProps> = ({
  width,
  height,
  disabled,
  value,
  helperText,
  onChange,
  onBlur,
  password,
  email,
  number,
  label,
  placeholder,
  fullWidth,
  error,
  className,
  name,
  multiline,
  rows,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (onChange) {
      if (inputValue.length <= 1) {
        onChange(event);
      } else {
        const modifiedEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> = {
          ...event,
          target: {
            ...event.target,
            value: inputValue.slice(0, 1),
          },
        };
        onChange(modifiedEvent);
      }
    }
  };

  return (
    <StyledSendCodeInput
      inputProps={{ maxLength: 1 }}
      number
      width="48px"
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={handleChange}
      label={label}
      className={className}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      name={name}
      InputLabelProps={{ shrink: true }}
      multiline={multiline}
      rows={rows}
      {...rest}
    />
  );
};

export default SendCodeInput;
