import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Speaker({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>speaker</title>
      <defs>
        <path
          d="M3,2 C2.44771525,2 2,2.44771525 2,3 L2,19 C2,19.5522847 2.44771525,20 3,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,3 C16,2.44771525 15.5522847,2 15,2 L3,2 Z M15,0 C16.6568542,0 18,1.34314575 18,3 L18,19 C18,20.6568542 16.6568542,22 15,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,3 C0,1.34314575 1.34314575,0 3,0 L15,0 Z M9,8 C6.23857625,8 4,10.2385763 4,13 C4,15.7614237 6.23857625,18 9,18 C11.7614237,18 14,15.7614237 14,13 C14,10.2385763 11.7614237,8 9,8 Z M9,10 C10.6568542,10 12,11.3431458 12,13 C12,14.6568542 10.6568542,16 9,16 C7.34314575,16 6,14.6568542 6,13 C6,11.3431458 7.34314575,10 9,10 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-919, -83)">
          <g id="speaker" transform="translate(922, 84)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
