import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function CloudLightning({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cloud-lightning</title>
      <defs>
        <path
          d="M12.1745796,10.4446219 C12.480932,9.98509318 13.1018014,9.86091932 13.5613301,10.1672718 C14.0208588,10.4736242 14.1450326,11.0944936 13.8386802,11.5540223 L10.875147,15.9993221 L15.0066299,15.9993221 C15.8053253,15.9993221 16.2817167,16.8894675 15.8386802,17.5540223 L11.8386802,23.5540223 C11.5323277,24.013551 10.9114584,24.1377248 10.4519297,23.8313724 C9.99240099,23.5250199 9.86822712,22.9041506 10.1745796,22.4446219 L13.1381128,17.9993221 L9.00662988,17.9993221 C8.20793449,17.9993221 7.7315431,17.1091766 8.17457958,16.4446219 Z M9.00083817,0 C12.8411149,0 16.2263238,2.43100892 17.4861484,5.99932237 L18.0056249,5.99932257 C21.0881435,5.99622464 23.6714887,8.32941335 23.981278,11.3963273 C24.2910673,14.4632413 22.2264962,17.2659165 19.2056249,17.8793226 C18.6643855,17.9892245 18.1365313,17.6395564 18.0266294,17.0983171 C17.9167275,16.5570777 18.2663955,16.0292235 18.8076349,15.9193216 C20.8215491,15.5103842 22.1979298,13.641934 21.9914036,11.5973247 C21.7848774,9.55271542 20.0626473,7.99725628 18.0066299,7.99932207 L16.7466299,7.99932207 C16.2906642,7.99932207 15.892436,7.69088372 15.7784059,7.24940681 C14.9801955,4.15906841 12.1925981,2 9.00083819,2 C5.80907828,2 3.02148095,4.15906853 2.22327056,7.24940694 C1.42506017,10.3397454 2.81838648,13.5787139 5.61090593,15.1244068 C6.09410828,15.3918651 6.26900341,16.0003958 6.00154514,16.4835981 C5.73408686,16.9668005 5.12555619,17.1416956 4.64235383,16.8742373 C1.05197168,14.8869179 -0.739447861,10.7225297 0.286822642,6.7492375 C1.31309315,2.77594525 4.89714685,0 9.00083817,0 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1070, -193)">
          <g id="cloud-lightning" transform="translate(1069.9934, 193.0007)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0.0066, -0.0007)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
