import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Pause({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>pause</title>
      <defs>
        <path
          d="M2,2 L2,16 L4,16 L4,2 L2,2 Z M5,0 C5.55228475,0 6,0.44771525 6,1 L6,17 C6,17.5522847 5.55228475,18 5,18 L1,18 C0.44771525,18 0,17.5522847 0,17 L0,1 C0,0.44771525 0.44771525,0 1,0 L5,0 Z M13,0 C13.5522847,0 14,0.44771525 14,1 L14,17 C14,17.5522847 13.5522847,18 13,18 L9,18 C8.44771525,18 8,17.5522847 8,17 L8,1 C8,0.44771525 8.44771525,0 9,0 L13,0 Z M12,2 L10,2 L10,16 L12,16 L12,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1509, -393)">
          <g id="pause" transform="translate(1514, 396)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
