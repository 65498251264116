import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Battery({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>battery</title>
      <defs>
        <path
          d="M3,2 C2.44771525,2 2,2.44771525 2,3 L2,11 C2,11.5522847 2.44771525,12 3,12 L17,12 C17.5522847,12 18,11.5522847 18,11 L18,3 C18,2.44771525 17.5522847,2 17,2 L3,2 Z M3,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,11 C20,12.6568542 18.6568542,14 17,14 L3,14 C1.34314575,14 0,12.6568542 0,11 L0,3 C0,1.34314575 1.34314575,0 3,0 Z M24,8 L24,6 C24,5.44771525 23.5522847,5 23,5 C22.4477153,5 22,5.44771525 22,6 L22,8 C22,8.55228475 22.4477153,9 23,9 C23.5522847,9 24,8.55228475 24,8 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-843, -672)">
          <g id="battery" transform="translate(843, 677)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
