import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { authApi } from '@api';
import { CreateCustomerDto } from '@app-types';

const useSignUp = (options?: UseMutationOptions<unknown, unknown, CreateCustomerDto>) => {
  return useMutation({
    ...options,
    mutationFn: authApi.createCustomer,
  });
};

export default useSignUp;
