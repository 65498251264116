import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Crosshair({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>crosshair</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M12.0006955,2.05500569 L12,5 C12,5.55228475 11.5522847,6 11,6 C10.4477153,6 10,5.55228475 10,5 L10.0003032,2.05489527 C5.82844282,2.51592828 2.51592828,5.82844282 2.05489527,10.0003032 L5,10 C5.55228475,10 6,10.4477153 6,11 C6,11.5522847 5.55228475,12 5,12 L2.05500569,12.0006955 C2.51645693,16.1720858 5.82877572,19.4841085 10.0003032,19.9451047 L10,17 C10,16.4477153 10.4477153,16 11,16 C11.5522847,16 12,16.4477153 12,17 L12.0006955,19.9449943 C16.1717529,19.4835799 19.4835799,16.1717529 19.9449943,12.0006955 L17,12 C16.4477153,12 16,11.5522847 16,11 C16,10.4477153 16.4477153,10 17,10 L19.9451047,10.0003032 C19.4841085,5.82877572 16.1720858,2.51645693 12.0006955,2.05500569 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-93, -391)">
          <g id="crosshair" transform="translate(94, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
