import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function PlusCircle({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>plus-circle</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M11,2 C6.02943725,2 2,6.02943725 2,11 C2,15.9705627 6.02943725,20 11,20 C15.9705627,20 20,15.9705627 20,11 C20,6.02943725 15.9705627,2 11,2 Z M11,6 C11.5522847,6 12,6.44771525 12,7 L12,10 L15,10 C15.5128358,10 15.9355072,10.3860402 15.9932723,10.8833789 L16,11 C16,11.5522847 15.5522847,12 15,12 L12,12 L12,15 C12,15.5128358 11.6139598,15.9355072 11.1166211,15.9932723 L11,16 C10.4477153,16 10,15.5522847 10,15 L10,12 L7,12 C6.48716416,12 6.06449284,11.6139598 6.00672773,11.1166211 L6,11 C6,10.4477153 6.44771525,10 7,10 L10,10 L10,7 C10,6.48716416 10.3860402,6.06449284 10.8833789,6.00672773 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1572, -526)">
          <g id="plus-circle" transform="translate(1573, 527)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
