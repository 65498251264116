import { styled } from '@mui/material/styles';
import { IPageLayoutProps } from './PageLayout';

export const Wrapper = styled('div')<{ isFullScreen: boolean }>(({ isFullScreen }) =>
  isFullScreen
    ? {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }
    : {},
);

export const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2.5)} 13%`,
  boxShadow: `0 0 ${theme.spacing(1)} 0 rgba(0, 0, 0, 0.1)`,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.secondary.main,
    padding: `${theme.spacing(2.5)} 22px`,
  },
}));

export const Navigation = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5),
}));

export const Body = styled('div')<IPageLayoutProps>(({ theme, bodyHeight }) => ({
  width: '100%',
  height: bodyHeight || `calc(100% - ${theme.spacing(14.25)})`,
}));
