import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Wifi({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>wifi</title>
      <defs>
        <path
          d="M5.2300121,9.32013441 C8.93782269,6.23179986 14.3222015,6.23179986 18.0300121,9.32013441 C18.4543738,9.67359665 19.0849248,9.61612125 19.438387,9.1917595 C19.7918492,8.76739776 19.7343738,8.13684684 19.3100121,7.7833846 C14.8606394,4.07738313 8.39938481,4.07738313 3.9500121,7.7833846 C3.52565036,8.13684684 3.46817495,8.76739776 3.82163719,9.1917595 C4.17509943,9.61612125 4.80565036,9.67359665 5.2300121,9.32013441 Z M1.6615746,5.75164953 C7.33163517,0.749450155 15.838389,0.749450155 21.5084496,5.75164953 C21.9226024,6.11702041 22.5545313,6.07747483 22.9199021,5.663322 C23.285273,5.24916918 23.2457274,4.61724035 22.8315746,4.25186947 C16.4055059,-1.41728982 6.76451825,-1.41728982 0.3384496,4.25186947 C-0.0757032283,4.61724035 -0.115248811,5.24916918 0.250122069,5.663322 C0.615492949,6.07747483 1.24742177,6.11702041 1.6615746,5.75164953 Z M8.68917877,12.9269687 C10.4232011,11.6950297 12.7468231,11.6950297 14.4808454,12.9269687 C14.931073,13.2468336 15.5553563,13.1411538 15.8752213,12.6909262 C16.1950862,12.2406986 16.0894064,11.6164153 15.6391788,11.2965503 C13.2115476,9.57183583 9.95847664,9.57183583 7.53084543,11.2965503 C7.08061785,11.6164153 6.97493802,12.2406986 7.29480294,12.6909262 C7.61466786,13.1411538 8.23895118,13.2468336 8.68917877,12.9269687 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1720, -196)">
          <g id="wifi" transform="translate(1720, 201)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
