import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { authApi } from '@api';

const useLogin = (options?: UseMutationOptions<unknown, unknown, { email: string; password: string }>) => {
  return useMutation({
    ...options,
    mutationFn: authApi.login,
  });
};

export default useLogin;
