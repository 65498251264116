const asyncMap = async <T, ReturnType>(arr: T[], callback: (el: T) => Promise<ReturnType>): Promise<ReturnType[]> => {
  const results = [];

  // we use for instead of array methods because they ignore async code and run callback in parallel
  // eslint-disable-next-line no-restricted-syntax
  for (const val of arr) {
    // eslint-disable-next-line no-await-in-loop
    results.push(await callback(val));
  }

  return results;
};

export default asyncMap;
