import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Headphones({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>headphones</title>
      <defs>
        <path
          d="M10,0 C15.5228474,0 20,4.47715241 20,10 L20,16.9999999 C20,18.6568541 18.6568542,19.9999999 17,19.9999999 L16,19.9999999 C14.3431458,19.9999999 13,18.6568541 13,16.9999999 L13,13.9999999 C13,12.3431456 14.3431458,10.9999999 16,10.9999999 L18,10.999 L18,10 C18,5.66508567 14.5521622,2.13545713 10.2491793,2.00380682 L10,2 C5.58172205,2 2,5.58172193 2,10 L2,10.999 L4,10.9999999 C5.65685425,10.9999999 7,12.3431456 7,13.9999999 L7,16.9999999 C7,18.6568541 5.65685425,19.9999999 4,19.9999999 L3,19.9999999 C1.34314575,19.9999999 0,18.6568541 0,16.9999999 L0,10 C0,4.47715241 4.47715256,0 10,0 Z M18,12.9999999 L16,12.9999999 C15.4477153,12.9999999 15,13.4477151 15,13.9999999 L15,16.9999999 C15,17.5522846 15.4477153,17.9999999 16,17.9999999 L17,17.9999999 C17.5522847,17.9999999 18,17.5522846 18,16.9999999 L18,12.9999999 Z M4,12.9999999 L2,12.9999999 L2,16.9999999 C2,17.5522846 2.44771525,17.9999999 3,17.9999999 L4,17.9999999 C4.55228475,17.9999999 5,17.5522846 5,16.9999999 L5,13.9999999 C5,13.4477151 4.55228475,12.9999999 4,12.9999999 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1150, -599)">
          <g id="headphones" transform="translate(1152, 601)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
