import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Play({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>play</title>
      <defs>
        <path
          d="M2,2.83330107 L2,17.1699701 L13.1507426,10.0016356 L2,2.83330107 Z M1.54075759,0.160457093 L15.5407576,9.16045709 C16.1530808,9.55409344 16.1530808,10.4491777 15.5407576,10.842814 L1.54075759,19.842814 C0.875245955,20.270643 0,19.7928014 0,19.0016356 L0,1.00163557 C0,0.210469775 0.875245955,-0.267371816 1.54075759,0.160457093 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1578, -460)">
          <g id="play" transform="translate(1582, 462)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
