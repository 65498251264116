import { PageLayout } from '@components';
import { Container, Grid } from '@mui/material';
import React from 'react';
import SuccessfulSignUp from './SuccessfulSignUp';
import { Wrapper } from './styles';

const SignUpWelcome: React.FC = () => {
  return (
    <PageLayout>
      <Container maxWidth="lg" sx={{ pt: '24px' }}>
        <Wrapper container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuccessfulSignUp />
          </Grid>
        </Wrapper>
      </Container>
    </PageLayout>
  );
};

export default SignUpWelcome;
