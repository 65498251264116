import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import 'swiper/css';
import { SignUpCarouselSlideBox, TipBox, TipBoxTitle } from './styles';

interface SignUpCarouselSlideProps {
  number: number;
  title: string;
  image: ReactNode;
  topDescription?: string;
  bottomDescription?: string;
}

const SignUpCarouselSlide: React.FC<SignUpCarouselSlideProps> = ({
  number,
  title,
  image,
  topDescription,
  bottomDescription,
}) => {
  return (
    <SignUpCarouselSlideBox>
      <TipBox>
        <TipBoxTitle>
          <FormattedMessage id="signup.carousel.tip" values={{ number }} />
        </TipBoxTitle>
      </TipBox>
      <Typography variant="h2" sx={{ mt: '8px' }}>
        {title}
      </Typography>
      <Typography sx={{ mt: '16px' }}>{topDescription}</Typography>
      {image}
      {bottomDescription && <Typography>{bottomDescription}</Typography>}
    </SignUpCarouselSlideBox>
  );
};

export default SignUpCarouselSlide;
