import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Copy({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>copy</title>
      <defs>
        <path
          d="M10,9 C9.44771525,9 9,9.44771525 9,10 L9,19 C9,19.5522847 9.44771525,20 10,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,10 C20,9.44771525 19.5522847,9 19,9 L10,9 Z M19,7 C20.6568542,7 22,8.34314575 22,10 L22,19 C22,20.6568542 20.6568542,22 19,22 L10,22 C8.34314575,22 7,20.6568542 7,19 L7,10 C7,8.34314575 8.34314575,7 10,7 L19,7 Z M12,0 C13.6568542,0 15,1.34314575 15,3 L15,4 C15,4.55228475 14.5522847,5 14,5 C13.4477153,5 13,4.55228475 13,4 L13,3 C13,2.44771525 12.5522847,2 12,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,12 C2,12.5522847 2.44771525,13 3,13 L4,13 C4.55228475,13 5,13.4477153 5,14 C5,14.5522847 4.55228475,15 4,15 L3,15 C1.34314575,15 0,13.6568542 0,12 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-387, -194)">
          <g id="copy" transform="translate(388, 195)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
