import { CreateCustomerDto } from '@app-types';
import { ChangeCustomerPasswordDto } from '@app-types/customer';
import axios from './axios';

const createCustomer = async (data: CreateCustomerDto) => {
  const response = await axios.post('/auth/customer/signup', data);

  if (response.status === 200 || response.status === 201) {
    window.location.href = '/welcome';
  }

  return response;
};

const login = async (data: { email: string; password: string }) => {
  const response = await axios.put('/auth/customer/login', data);

  if (response.status === 200 || response.status === 201) {
    window.location.href = '/submission-creation';
  }

  return response;
};

const forgotPassword = async (data: { email: string }) => {
  const response = await axios.post('/auth/customer/forgot-password', data);

  return response;
};

const resetPassword = async (data: { id: string; password: string }) => {
  const response = await axios.patch(`/auth/customer/reset-password/${data.id}`, { password: data.password });

  if (response.status === 200) {
    window.location.href = '/submission-creation';
  }

  return response;
};

const changePassword = async (data: { id: string; passwords: ChangeCustomerPasswordDto }) => {
  const response = await axios.patch(`/auth/customer/change-password/${data.id}`, {
    oldPassword: data.passwords.oldPassword,
    password: data.passwords.password,
  });

  return response;
};

const getCurrentCustomer = async () => axios.get('/customers/me');

const logout = () => {
  localStorage.removeItem('accessToken');
  window.location.href = '/login';
};

export default { createCustomer, getCurrentCustomer, login, forgotPassword, resetPassword, logout, changePassword };
