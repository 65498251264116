import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface MobileMenuProps {
  menuOptions: {
    label: string;
    icon?: JSX.Element;
    onClick: () => void;
  }[];
}

const MobileMenuList: FC<MobileMenuProps> = ({ menuOptions }) => {
  const theme = useTheme();

  return (
    <>
      <Divider />
      <MenuList
        sx={{
          backgroundColor: theme.palette.grey['600'],
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            sx={{
              textAlign: 'end',
            }}
            onClick={option.onClick}
          >
            <ListItemText>
              <Typography fontSize="16px" fontWeight="500" color={theme.palette.grey['100']}>
                {option.label}
              </Typography>
            </ListItemText>
            {!!option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          </MenuItem>
        ))}
      </MenuList>
      <Divider />
    </>
  );
};

export default MobileMenuList;
