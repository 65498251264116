const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = (reader.result as string)?.replace(/^data:[A-z]*\/?[A-z]*;base64,/, '');
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
  });

export default convertFileToBase64;
