import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Star({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>star</title>
      <defs>
        <path
          d="M8.80779568,7.70262392 C8.66225594,7.99747141 8.38107073,8.20193068 8.05571654,8.24948607 L3.1495,8.9666031 L6.69882113,12.4236419 C6.93469487,12.6533829 7.0423575,12.9845141 6.98669695,13.3090433 L6.14926913,18.1916734 L10.5356371,15.8849265 C10.8270199,15.7316912 11.1751567,15.7316912 11.4665396,15.8849265 L15.8529075,18.1916734 L15.0154797,13.3090433 C14.9598192,12.9845141 15.0674818,12.6533829 15.3033555,12.4236419 L18.8526767,8.9666031 L13.9464601,8.24948607 C13.6211059,8.20193068 13.3399207,7.99747141 13.194381,7.70262392 L11.0010883,3.25925434 L8.80779568,7.70262392 Z M7.24682697,6.3464661 L10.104381,0.557376085 C10.4712164,-0.185792028 11.5309603,-0.185792028 11.8977957,0.557376085 L14.7553497,6.3464661 L21.1457165,7.28051393 C21.9656312,7.40035674 22.2924147,8.40819801 21.6988211,8.98635811 L17.0756101,13.4893656 L18.166697,19.8509567 C18.3068155,20.6679189 17.4492666,21.2908819 16.7156371,20.9050735 L11.0010883,17.8998497 L5.28653961,20.9050735 C4.55291004,21.2908819 3.69536119,20.6679189 3.83547972,19.8509567 L4.92656655,13.4893656 L0.303355535,8.98635811 C-0.290237994,8.40819801 0.0365454473,7.40035674 0.856460124,7.28051393 L7.24682697,6.3464661 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1721, -252)">
          <g id="star" transform="translate(1722, 253)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
