import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Link2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>link-2</title>
      <defs>
        <path
          d="M18,0 C19.5912989,0 21.1174224,0.632141042 22.2426407,1.75735931 C23.367859,2.88257758 24,4.40870106 24,6 C24,9.3137085 21.3137085,12 18,12 L15,12 C14.4477153,12 14,11.5522847 14,11 C14,10.4477153 14.4477153,10 15,10 L18,10 C20.209139,10 22,8.209139 22,6 C22,4.93913404 21.5785726,3.92171839 20.8284271,3.17157288 C20.0782816,2.42142736 19.060866,2 18,2 L15,2 C14.4477153,2 14,1.55228475 14,1 C14,0.44771525 14.4477153,0 15,0 L18,0 Z M9,1.77635684e-15 C9.55228475,1.77635684e-15 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L6,2 C3.790861,2 2,3.790861 2,6 C2,7.06086596 2.42142736,8.07828161 3.17157288,8.82842712 C3.92171839,9.57857264 4.93913404,10 6,10 L9,10 C9.55228475,10 10,10.4477153 10,11 C10,11.5522847 9.55228475,12 9,12 L6,12 C4.40870106,12 2.88257758,11.367859 1.75735931,10.2426407 C0.632141042,9.11742242 0,7.59129894 0,6 C0,2.6862915 2.6862915,1.77635684e-15 6,1.77635684e-15 Z M16,5 C16.5522847,5 17,5.44771525 17,6 C17,6.55228475 16.5522847,7 16,7 L8,7 C7.44771525,7 7,6.55228475 7,6 C7,5.44771525 7.44771525,5 8,5 L16,5 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-464, -199)">
          <g id="link-2" transform="translate(464, 205)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -6)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
