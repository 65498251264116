import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function MoreVertical({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>more-vertical</title>
      <defs>
        <path
          d="M3,16 C4.65685425,16 6,17.3431458 6,19 C6,20.6568542 4.65685425,22 3,22 C1.34314575,22 0,20.6568542 0,19 C0,17.3431458 1.34314575,16 3,16 Z M3,18 C2.44771525,18 2,18.4477153 2,19 C2,19.5522847 2.44771525,20 3,20 C3.55228475,20 4,19.5522847 4,19 C4,18.4477153 3.55228475,18 3,18 Z M3,8 C4.65685425,8 6,9.34314575 6,11 C6,12.6568542 4.65685425,14 3,14 C1.34314575,14 0,12.6568542 0,11 C0,9.34314575 1.34314575,8 3,8 Z M3,10 C2.44771525,10 2,10.4477153 2,11 C2,11.5522847 2.44771525,12 3,12 C3.55228475,12 4,11.5522847 4,11 C4,10.4477153 3.55228475,10 3,10 Z M3,0 C4.65685425,0 6,1.34314575 6,3 C6,4.65685425 4.65685425,6 3,6 C1.34314575,6 0,4.65685425 0,3 C0,1.34314575 1.34314575,0 3,0 Z M3,2 C2.44771525,2 2,2.44771525 2,3 C2,3.55228475 2.44771525,4 3,4 C3.55228475,4 4,3.55228475 4,3 C4,2.44771525 3.55228475,2 3,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1002, -391)">
          <g id="more-vertical" transform="translate(1011, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-9, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
