import { Language } from '@app-types';
import { Currency } from '@constants';
import { Box, Typography, useTheme } from '@mui/material';
import { currencyFormatter } from '@utils';
import { FC } from 'react';

interface ISubmissionFormFooterProps {
  value: string;
  title: string;
}

const Amount: FC<ISubmissionFormFooterProps> = ({ value, title }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h3" fontSize={20} color={theme.palette.secondary.main}>
        {title}
      </Typography>
      <Typography variant="h2" fontSize={32} color={theme.palette.secondary.main} sx={{ mt: '13px' }}>
        {currencyFormatter(parseFloat(value), Language.De, Currency.Euro)}
      </Typography>
    </Box>
  );
};

export default Amount;
