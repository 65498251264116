import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Navigation2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>navigation-2</title>
      <defs>
        <path
          d="M8.00309555,3.89263668 L2.89047652,17.7697455 L7.50695661,15.1317569 C7.81438928,14.956081 8.19180182,14.956081 8.49923449,15.1317569 L13.1157146,17.7697455 L8.00309555,3.89263668 Z M8.00309555,17.1517511 L1.49923449,20.8682431 C0.688861732,21.3313133 -0.257910874,20.5300951 0.0647524358,19.6542946 L7.06475244,0.654294641 C7.38616033,-0.218098214 8.62003078,-0.218098214 8.94143867,0.654294641 L15.9414387,19.6542946 C16.264102,20.5300951 15.3173294,21.3313133 14.5069566,20.8682431 L8.00309555,17.1517511 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-920, -460)">
          <g id="navigation-2" transform="translate(923.9969, 462)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3.9969, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
