import { Button } from '@components';
import { withoutAuth } from '@hoc';
import { useViewportSize } from '@hooks';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Form from './Form';
import { Content, LoginBox, PageWrapper, Subtitle, Title } from './styles';

const Login: React.FC = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const viewportSize = useViewportSize();

  const isMobile = viewportSize <= theme.breakpoints.values.md;

  const handleSignUpClick = () => {
    navigate('/eligibility-check');
  };

  return (
    <PageWrapper>
      <Content>
        <Box>
          <Title variant="h1" color="secondary" sx={{ letterSpacing: '0.5px' }}>
            <FormattedMessage id="common.companyName" />
          </Title>
          <Subtitle variant="h2" color="secondary">
            {/* dangerouslySetInnerHTML is used here in order to highlight with bold font weight some parts of the text due to the design */}
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'login.subtitle' }) }} />
          </Subtitle>
          <Typography variant="h4" color="secondary" sx={{ marginBottom: theme.spacing(1) }}>
            <FormattedMessage id="login.subtitle.2" />
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            sx={{ marginBottom: theme.spacing(4), '& > span': { fontWeight: '600' } }}
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'login.description' }) }}
          />
          <Button variant="contained" color="secondary" onClick={handleSignUpClick} fullWidth={isMobile}>
            <FormattedMessage id="login.button.signUp" />
          </Button>
        </Box>
        <LoginBox>
          <Form />
        </LoginBox>
      </Content>
      <Footer />
    </PageWrapper>
  );
};

export default withoutAuth(Login);
