import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Feather({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>feather</title>
      <defs>
        <path
          d="M19.9471068,2.05122979 C22.6818987,4.78602168 22.6820798,9.21988286 19.9476502,11.9548998 L13.2081536,18.714395 C13.0205327,18.9025726 12.7657299,19.0083366 12.5,19.0083366 L4.414,19.008 L1.70710678,21.7154434 C1.34662282,22.0759273 0.779391764,22.1036569 0.387100557,21.798632 L0.292893219,21.7154434 C-0.0976310729,21.3249191 -0.0976310729,20.6917541 0.292893219,20.3012298 L3,17.593 L3,9.50833657 C3,9.24312008 3.10535684,8.98876617 3.29289322,8.80122979 L10.0428932,2.05122979 C12.7778663,-0.683743264 17.2121337,-0.683743264 19.9471068,2.05122979 Z M14.078,15.008 L8.414,15.008 L6.414,17.008 L12.0849183,17.0083366 L14.078,15.008 Z M11.4571068,3.46544335 L5,9.92255013 L5,15.593 L14.2928932,6.30122979 C14.6533772,5.94074583 15.2206082,5.91301629 15.6128994,6.21804118 L15.7071068,6.30122979 C16.0976311,6.69175408 16.0976311,7.32491906 15.7071068,7.71544335 L10.414,13.008 L16,13.0083366 C16.0231252,13.0083366 16.0460671,13.0091215 16.0688005,13.0106663 L18.5318464,10.5422781 C20.4868177,8.58730532 20.4868177,5.41936782 18.5328932,3.46544335 C16.5789687,1.51151888 13.4110313,1.51151888 11.4571068,3.46544335 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-917, -525)">
          <g id="feather" transform="translate(918, 526)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
