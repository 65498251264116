import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Book({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>book</title>
      <defs>
        <path
          d="M17,0 C17.5522847,0 18,0.44771525 18,1 L18,21 C18,21.5522847 17.5522847,22 17,22 L3.5,22 C1.56700338,22 0,20.4329966 0,18.5 L0,3.5 C0,1.56700338 1.56700338,0 3.5,0 L17,0 Z M16,17 L3.5,17 C2.67157288,17 2,17.6715729 2,18.5 C2,19.3284271 2.67157288,20 3.5,20 L16,20 L16,17 Z M16,2 L3.5,2 C2.67157288,2 2,2.67157288 2,3.5 L1.99996673,15.3368314 C2.45460288,15.1208514 2.96318826,15 3.5,15 L16,15 L16,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-847, -194)">
          <g id="book" transform="translate(850, 195)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
