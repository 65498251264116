import { SubmissionStatus } from '@app-types';
import { Button, InputController } from '@components';
import { LocalStorageKey, ViewportBreakpoint } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangeCustomerInfo, useDeleteCustomer, useValidateIBAN, useViewportSize } from '@hooks';
import { CheckIcon, InfoIcon, Trash2Icon } from '@icons';
import { Alert, Box, Grid, Slide, Snackbar, Typography, useTheme } from '@mui/material';
import { useAppDispatch } from '@store';
import { selectCurrentCustomer, updateFirstName } from '@store/customer/slice';
import { selectCurrentSubmission } from '@store/submission/slice';
import { AxiosError, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import SignUpFormSection from '../../../../../SignUp/components/SignUpFormSection';
import ConfirmModal from '../../../../../SubmissionCreation/ConfirmModal/ConfirmModal';
import { convertSettingsMainInfoFormValuesToDto } from './dto';
import { Form } from './styles';

interface ISettingsMainInfoFormProps {
  className?: string;
}

interface ISettingsMainInfoFormValues {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phone: string;
  bank: string;
  iban: string;
}

const SettingsMainInfoForm: React.FC<ISettingsMainInfoFormProps> = ({ className }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string | null>(null);

  const viewportWidth = useViewportSize();
  const isMobile = viewportWidth <= ViewportBreakpoint.Mobile;

  const currentCustomer = useSelector(selectCurrentCustomer);

  const { email, firstName, lastName, dateOfBirth, street, houseNumber, zipCode, city, phone, bank, iban } =
    currentCustomer ?? {};

  const currentSubmission = useSelector(selectCurrentSubmission);
  const changeCustomerInfo = useChangeCustomerInfo();

  const { mutateAsync: deleteCustomer } = useDeleteCustomer();

  const submissionStatus = currentSubmission?.status;
  const isEnabledEdit =
    !submissionStatus || [SubmissionStatus.Withdrawn, SubmissionStatus.Completed].includes(submissionStatus);

  const initialValues: ISettingsMainInfoFormValues = {
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    dateOfBirth: dateOfBirth || '',
    street: street || '',
    houseNumber: houseNumber || '',
    zipCode: zipCode || localStorage.getItem(LocalStorageKey.ZipCode) || '',
    city: city || '',
    phone: phone || '',
    bank: bank || '',
    iban: iban || '',
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(formatMessage({ id: 'signup.email.required.title' }))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, formatMessage({ id: 'signup.email.valid.title' })),
    firstName: yup.string().required(formatMessage({ id: 'signup.firstName.required.title' })),
    lastName: yup.string().required(formatMessage({ id: 'signup.lastName.required.title' })),
    dateOfBirth: yup.string(),
    street: yup.string().required(formatMessage({ id: 'signup.street.required.title' })),
    houseNumber: yup.string().required(formatMessage({ id: 'signup.houseNumber.required.title' })),
    zipCode: yup.string().required(formatMessage({ id: 'signup.zipCode.required.title' })),
    city: yup.string().required(formatMessage({ id: 'signup.city.required.title' })),
    phone: yup
      .string()
      .required(formatMessage({ id: 'signup.phone.required.title' }))
      .matches(
        /^\+?\d{1,4}?[-\s]?\(?\d{1,3}?\)?[-\s]?\d{1,4}[-\s]?\d{1,4}[-\s]?\d{1,9}$/,
        formatMessage({ id: 'signup.phone.invalid.title' }),
      )
      .test('optional-parentheses', formatMessage({ id: 'signup.phone.invalidParentheses.title' }), (value) => {
        if (!value) return true;
        const match = value.match(/\((\d*)\)/);
        return !match || (match && match[1].length > 0);
      }),
    bank: yup.string().required(formatMessage({ id: 'signup.bank.required.title' })),
    iban: yup
      .string()
      .required(formatMessage({ id: 'signup.iban.required.title' }))
      .matches(/^DE\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}$/, formatMessage({ id: 'signup.iban.invalid.title' })),
  });

  const methods = useForm<ISettingsMainInfoFormValues>({
    resolver: yupResolver(schema) as unknown as Resolver<
      ISettingsMainInfoFormValues,
      ResolverOptions<ISettingsMainInfoFormValues>
    >,
    defaultValues: initialValues,
  });
  const { control, watch, handleSubmit, formState } = methods;
  const { errors } = formState;

  const validateIBAN = useValidateIBAN(watch('iban'));

  useEffect(() => {
    setFormErrors(Object.keys(errors).length > 0 ? formatMessage({ id: 'signup.mandatory' }) : null);
  }, [errors]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^\d\s+()/-]/g, '');
    methods.setValue('phone', inputValue, { shouldValidate: !!formErrors });
  };

  const handleIbanBlur = () => {
    const bankName = validateIBAN.data?.data.bankData.name || '';

    methods.setValue('bank', bankName, { shouldValidate: true });
  };

  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };

  const dispatch = useAppDispatch();

  const handleFormSubmit = async (data: ISettingsMainInfoFormValues) => {
    data.iban = data.iban.replace(/\s/g, '');

    if (currentCustomer && currentCustomer.id) {
      try {
        const res = await changeCustomerInfo.mutateAsync({
          id: currentCustomer.id,
          customer: convertSettingsMainInfoFormValuesToDto(data),
        });

        if (res instanceof AxiosError) {
          setFormErrors(formatMessage({ id: 'settings.changePassword.error.common' }));
        }

        dispatch(updateFirstName(res?.firstName));

        setAlertOpen(true);
        setTimeout(() => setAlertOpen(false), 5000);
      } catch (error) {
        if (error instanceof AxiosError) {
          setFormErrors(formatMessage({ id: 'settings.changePassword.error.common' }));
        } else {
          console.error('Error:', error);
        }
      }
    } else {
      console.error('Customer not found!');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const res = await deleteCustomer(currentCustomer?.id);

      if (res.status === HttpStatusCode.Ok) {
        navigate('/login');
      } else {
        setFormErrors(formatMessage({ id: 'settings.deleteAccount.error.common' }));
      }
        
    } catch (error) {
      if (error instanceof AxiosError) {
        setFormErrors(formatMessage({ id: 'settings.deleteAccount.error.common' }));
      } else {
        console.error('Error:', error);
      }

    } finally {
      setOpenConfirmModal(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form className={className} onSubmit={handleSubmit(handleFormSubmit)}>
        {!isEnabledEdit && (
          <Box mb="32px">
            <Typography color={theme.palette.error.light}>
              <FormattedMessage id="settings.disabledChanging.title" />
            </Typography>
          </Box>
        )}
        <Grid container gap="32px" flexDirection="column" alignItems="center">
          <SignUpFormSection title="signup.yourName.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="firstName"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.firstName.placeholder' })}
                  label={formatMessage({ id: 'signup.firstName.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="lastName"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.lastName.placeholder' })}
                  label={formatMessage({ id: 'signup.lastName.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourContactDetails.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="phone"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.phoneNumber.placeholder' })}
                  label={formatMessage({ id: 'signup.phoneNumber.label' })}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  onChange={handlePhoneChange}
                  disabled={!isEnabledEdit}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="email"
                  email
                  control={control}
                  placeholder={formatMessage({ id: 'signup.email.placeholder' })}
                  label={formatMessage({ id: 'signup.email.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourAdress.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 615, width: '100%' }}>
                <InputController
                  fullWidth
                  name="street"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.street.placeholder' })}
                  label={formatMessage({ id: 'signup.street.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
              <Box sx={{ maxWidth: 135, width: '100%' }}>
                <InputController
                  fullWidth
                  name="houseNumber"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.houseNumber.placeholder' })}
                  label={formatMessage({ id: 'signup.houseNumber.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px', mt: '24px' }}>
              <Box sx={{ maxWidth: 215, width: '100%' }}>
                <InputController
                  fullWidth
                  name="zipCode"
                  control={control}
                  disabled={!isEnabledEdit}
                  placeholder={formatMessage({ id: 'signup.postCode.placeholder' })}
                  label={formatMessage({ id: 'signup.postCode.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 535, width: '100%' }}>
                <InputController
                  fullWidth
                  name="city"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.location.placeholder' })}
                  label={formatMessage({ id: 'signup.location.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourBankDetails.section.title" showBorder={false}>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="iban"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.iban.placeholder' })}
                  label={formatMessage({ id: 'signup.iban.label' })}
                  onBlur={handleIbanBlur}
                  disabled={!isEnabledEdit}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="bank"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.bank.placeholder' })}
                  label={formatMessage({ id: 'signup.bank.label' })}
                  disabled={!isEnabledEdit}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: formErrors ? 'space-between' : 'flex-end',
              borderTop: `1px solid ${theme.palette.grey[500]}`,
              pt: '30px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {formErrors && (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Box sx={{ flexShrink: 0 }}>
                  <InfoIcon color={theme.palette.error.main} />
                </Box>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: theme.palette.grey[200],
                      '& > span': { fontWeight: '600', color: theme.palette.grey[200] },
                    }}
                    dangerouslySetInnerHTML={{ __html: formErrors }}
                  />
                </Box>
              </Box>
            )}
            <Button 
              startIcon={<Trash2Icon color="white" />} 
              sx={{
                flexShrink: 0,
                alignSelf: 'start',
                [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
                  width: '100%',
                },
              }}
              color="error" 
              onClick={() => setOpenConfirmModal(true)}
            >
              <FormattedMessage id="settings.deleteAccount.button.title" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                flexShrink: 0,
                alignSelf: 'start',
                [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
                  width: '100%',
                },
              }}
              disabled={!isEnabledEdit}
            >
              <FormattedMessage id="settings.updateAccount.button.title" />
            </Button>
            {alertOpen && (
              <Snackbar
                open={alertOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                TransitionComponent={Slide}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  <FormattedMessage id="settings.success.common" />
                </Alert>
              </Snackbar>
            )}
          </Box>
        </Grid>
        {isOpenConfirmModal && (
          <ConfirmModal
            open={isOpenConfirmModal}
            titleId={
              !isEnabledEdit 
                ? 'settings.confirmModal.deleteAccount.thereIsSubmission.title' 
                : 'settings.confirmModal.deleteAccount.noSubmission.title' 
            } 
            handleClose={() => setOpenConfirmModal(false)}
            updateClick={handleDeleteAccount}
          />
        )}
      </Form>
    </FormProvider>
  );
};

export default SettingsMainInfoForm;
