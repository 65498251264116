import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { SignUpFormSectionBox } from './styles';

interface ISignUpFormSectionProps {
  title: string;
  children: ReactNode;
  showBorder?: boolean;
}

const SignUpFormSection: React.FC<ISignUpFormSectionProps> = ({ title, children, showBorder = true }) => {
  return (
    <SignUpFormSectionBox showBorder={showBorder}>
      <Typography variant="h3" sx={{ fontSize: '20px', fontWeight: 500 }}>
        <FormattedMessage id={title} />
      </Typography>
      <Box mt="24px" mb={showBorder ? '30px' : 0}>
        {children}
      </Box>
    </SignUpFormSectionBox>
  );
};

export default SignUpFormSection;
