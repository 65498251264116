import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Globe({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>globe</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M6.05279391,12.0010636 L2.05500569,12.0006955 C2.46835632,15.7372697 5.16921492,18.7842959 8.72607502,19.7102675 C7.2052792,17.4233136 6.28166193,14.7770381 6.05279391,12.0010636 Z M19.9449943,12.0006955 L15.9498422,12.0008226 C15.7255335,14.7635472 14.8008579,17.4154858 13.2745676,19.7097575 C16.8312897,18.7837266 19.5316823,15.7369207 19.9449943,12.0006955 Z M13.945194,12.0003647 L8.05765422,12.0006768 C8.30895924,14.713613 9.33046978,17.2982679 11.0000219,19.4492318 C12.6759302,17.2904611 13.6988433,14.6975901 13.945194,12.0003647 Z M8.72543244,2.29024251 L8.6892238,2.29941009 C5.15025532,3.23685972 2.46644779,6.27618877 2.05489527,10.0003032 L6.05007662,10.0001782 C6.27424317,7.2370933 7.19895783,4.58479128 8.72543244,2.29024251 Z M10.9999781,2.5507682 C9.32386253,4.70980581 8.30090373,7.30305111 8.05471464,10.0006358 L13.9424384,10.0003233 C13.6912918,7.28702224 12.6697354,4.70199636 10.9999781,2.5507682 Z M13.273925,2.2897325 L13.4629614,2.58063524 C14.8714653,4.80010201 15.728192,7.34051478 15.9472885,9.99993588 L19.9451047,10.0003032 C19.5321249,6.26327319 16.831102,3.21578659 13.273925,2.2897325 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1072, -598)">
          <g id="globe" transform="translate(1073, 599)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
