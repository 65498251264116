import { Select } from '@components';
import { SelectOption } from '@components/Select';
import { SelectProps } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { StyledBox } from './styles';

interface ISelectControllerProps {
  name: string;
  control: Control<FieldValues> | Control<any>;
  label: string;
  options: SelectOption[];
}

export const SelectController: FC<ISelectControllerProps & SelectProps> = ({ name, control, label, options, ...rest }) => {
  return (
    <StyledBox>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{ width: '100%' }}
            label={value ? '' : label}
            defaultValue=""
            onChange={onChange}
            value={value || ''}
            options={options}
            {...rest}
          />
        )}
        control={control}
        name={name}
      />
    </StyledBox>
  );
};

export default SelectController;
