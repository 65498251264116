import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Award({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>award</title>
      <defs>
        <path
          d="M8,0 C12.418278,0 16,3.581722 16,8 C16,10.5896541 14.7695348,12.8919177 12.8613622,14.3540331 L13.9913131,22.8684771 C14.1014481,23.6985855 13.2035539,24.2883227 12.4855042,23.8574929 L8,21.1661904 L3.51449576,23.8574929 C2.79638903,24.288357 1.89844315,23.6984938 2.0087057,22.8683352 L3.13962861,14.3547921 C1.23089111,12.8927146 0,10.5901023 0,8 C0,3.581722 3.581722,0 8,0 Z M8,16 C6.94482066,16 5.93735484,15.7957141 5.01499538,15.4245352 L4.26444368,21.0751434 L7.48550424,19.1425071 C7.80218663,18.9524976 8.19781337,18.9524976 8.51449576,19.1425071 L11.7358817,21.0753387 L10.9860028,15.4241334 C10.063372,15.7955667 9.05556,16 8,16 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C9.17258832,14 10.2666135,13.6636312 11.1908485,13.0821208 C11.232672,13.0476978 11.2784253,13.0187294 11.3266434,12.9935211 C12.9383509,11.9184124 14,10.0831869 14,8 C14,4.6862915 11.3137085,2 8,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1225, -667)">
          <g id="award" transform="translate(1229, 667)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
