import { AdditionalDemand, CreateSubmissionDto, IChangeSubmissionStatusDto } from '@app-types';
import axios from './axios';

const createSubmission = async (data: CreateSubmissionDto) => {
  const response = await axios.post('/submissions', data);

  return response.data;
};
const getCurrentSubmission = () => axios.get('/submissions/current/my');

// todo: add types
const calculateAllowance = (data: any) => axios.post('/submissions/expected-allowance', data);

const sendRequestedDocuments = async ({ id, data }: { id: string | undefined; data: AdditionalDemand[] }) => {
  const response = await axios.patch(`/submissions/${id}/send-requested-documents`, data);

  return response.data;
};

const changeSubmissionStatusByCustomer = async ({
  id,
  data,
}: {
  id: string | undefined;
  data: IChangeSubmissionStatusDto;
}) => {
  const response = await axios.patch(`/submissions/${id}/submission-status`, data);

  return response.data;
};

export default {
  createSubmission,
  calculateAllowance,
  getCurrentSubmission,
  sendRequestedDocuments,
  changeSubmissionStatusByCustomer,
};
