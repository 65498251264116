import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function AtSign({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>at-sign</title>
      <defs>
        <path
          d="M1.13723313,6.13148182 C3.40018135,1.54785711 8.52250847,-0.872815044 13.500554,0.288913432 C18.4785995,1.45064191 22.0003297,5.88858119 22.0006516,11.0004491 L22.0006516,12.0004491 C22.0006516,14.2095881 20.2097906,16.0004491 18.0006516,16.0004491 C16.6556937,16.0004491 15.4657731,15.3366554 14.7406287,14.3188066 C13.8248924,15.3504246 12.4886827,16.0004491 11.0006516,16.0004491 C8.23922786,16.0004491 6.00065161,13.7618729 6.00065161,11.0004491 C6.00065161,8.23902538 8.23922786,6.00044913 11.0006516,6.00044913 C13.6894063,6.00044913 15.8824697,8.12275584 15.9960321,10.7835595 L16.0006516,11.0004491 L16.0006516,12.0004491 C16.0006516,13.1050186 16.8960821,14.0004491 18.0006516,14.0004491 C19.1052211,14.0004491 20.0006516,13.1050186 20.0006516,12.0004491 L20.0006516,11.0005121 C20.0003882,6.81812622 17.1189726,3.18708499 13.0460263,2.23657987 C8.97307995,1.28607476 4.78208504,3.2666247 2.93058195,7.0168631 C1.07907886,10.7671015 2.05505719,15.2986005 5.28621699,17.9541528 C8.51737679,20.6097051 13.1521006,20.6894027 16.4726516,18.1465121 C16.9111309,17.810723 17.5387995,17.8939698 17.8745886,18.3324491 C18.2103778,18.7709284 18.1271309,19.398597 17.6886516,19.7343862 C13.6302004,22.8423636 7.96553793,22.7449554 4.01634262,19.4992803 C0.0671473137,16.2536053 -1.12571509,10.7151065 1.13723313,6.13148182 Z M11.0006516,8.00044913 C9.34379736,8.00044913 8.00065161,9.34359488 8.00065161,11.0004491 C8.00065161,12.6573034 9.34379736,14.0004491 11.0006516,14.0004491 C12.6575059,14.0004491 14.0006516,12.6573034 14.0006516,11.0004491 C14.0006516,9.34359488 12.6575059,8.00044913 11.0006516,8.00044913 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1646, -668)">
          <g id="at-sign" transform="translate(1647, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
