import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function MessageSquare({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>message-square</title>
      <defs>
        <path
          d="M4.29289322,14.2928932 C4.4804296,14.1053568 4.73478351,14 5,14 L17,14 C17.5522847,14 18,13.5522847 18,13 L18,3 C18,2.44771525 17.5522847,2 17,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,16.5857864 L4.29289322,14.2928932 Z M5.41421356,16 L1.70710678,19.7071068 C1.07714192,20.3370716 0,19.8909049 0,19 L0,3 C0,1.34314575 1.34314575,0 3,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,13 C20,14.6568542 18.6568542,16 17,16 L5.41421356,16 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-542, -321)">
          <g id="message-square" transform="translate(544, 323)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
