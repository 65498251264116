import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Mic({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>mic</title>
      <defs>
        <path
          d="M15,9 C15.5522847,9 16,9.44771525 16,10 L16,12 C16,16.0792885 12.9468042,19.445465 9.00095507,19.9379871 L9,22 L12,22 C12.5128358,22 12.9355072,22.3860402 12.9932723,22.8833789 L13,23 C13,23.5522847 12.5522847,24 12,24 L4,24 C3.44771525,24 3,23.5522847 3,23 C3,22.4477153 3.44771525,22 4,22 L7,22 L7.00004873,19.9381123 C3.13435348,19.456091 0.125172611,16.2160131 0.00380682469,12.2491793 L0,12 L0,10 C0,9.44771525 0.44771525,9 1,9 C1.55228475,9 2,9.44771525 2,10 L2,12 C2,15.3137084 4.68629154,18 8,18 C11.3137085,18 14,15.3137084 14,12 L14,10 C14,9.44771525 14.4477153,9 15,9 Z M8,0 C10.209139,0 12,1.790861 12,4 L12,12 C12,14.209139 10.209139,16 8,16 C5.790861,16 4,14.209139 4,12 L4,4 C4,1.790861 5.790861,0 8,0 Z M8,2 C6.8954305,2 6,2.8954305 6,4 L6,12 C6,13.1045695 6.8954305,14 8,14 C9.1045695,14 10,13.1045695 10,12 L10,4 C10,2.8954305 9.1045695,2 8,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-390, -318)">
          <g id="mic" transform="translate(394, 318)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
