import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Video({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>video</title>
      <defs>
        <path
          d="M14,0 C15.6568542,0 17,1.34314575 17,3 L17,6.056 L22.4187618,2.18626653 C23.0806303,1.71350333 24,2.18662744 24,3 L24,13 C24,13.8133726 23.0806303,14.2864967 22.4187618,13.8137335 L17,9.943 L17,13 C17,14.6568542 15.6568542,16 14,16 L3,16 C1.34314575,16 0,14.6568542 0,13 L0,3 C0,1.34314575 1.34314575,0 3,0 L14,0 Z M14,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,13 C2,13.5522847 2.44771525,14 3,14 L14,14 C14.5522847,14 15,13.5522847 15,13 L15,3 C15,2.44771525 14.5522847,2 14,2 Z M22,4.94318932 L17.7204651,8 L22,11.0568107 L22,4.94318932 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1353, -86)">
          <g id="video" transform="translate(1353, 90)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -4)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
