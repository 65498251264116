import { submissionsInFillingProcessApi } from '@api';
import { ISubmission, SubmissionStatus } from '@app-types';
import { StepperContext } from '@components/Stepper';
import { LocalStorageKey } from '@constants';
import eventBus from '@eventBus';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCalculateAllowance,
  useCreateSubmission,
  useCreateSubmissionInFillingProcess,
  useUpdateSubmissionInFillingProcess,
} from '@hooks';
import { selectCurrentSubmission } from '@store/submission/slice';
import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  FieldArrayWithId,
  FormProvider,
  Resolver,
  ResolverOptions,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  WatchObserver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import * as yup from 'yup';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import {
  convertSubmissionFormValuesToDto,
  convertSubmissionFormValuesToExpectedAllowance,
  convertSubmissionFormValuesToUpdateDto,
  convertSubmissionToFormValues,
} from '../dto';
import { ChildFormValues, SubmissionCreationFormValues, SubmissionRadioValue } from '../types';
import ChildBenefit from './ChildBenefit';
import CounselingRecognizedBody from './CounselingRecognizedBody';
import InfoBox from './InfoBox';
import MaintenanceObligations from './MaintenanceObligations';
import OngoingServices from './OngoingServices';
import { Form, StyledSubmissionFormFooter } from './styles';

interface ISubmissionFormContext {
  getChildrenValue: (index: number, key: keyof ChildFormValues) => any;
  childrenFields: FieldArrayWithId[];
  appendChild: UseFieldArrayAppend<SubmissionCreationFormValues, 'children'>;
  removeChild: UseFieldArrayRemove;
}

export const SubmissionFormContext = createContext<ISubmissionFormContext>({
  getChildrenValue: () => {},
  childrenFields: [],
  appendChild: () => {},
  removeChild: () => {},
});

const useSubmissionFormSchemaAndMethods = (
  childrenArray: ChildFormValues[],
) => {
  const { formatMessage } = useIntl();
  let combinedSchema = yup.object();

  combinedSchema = combinedSchema.shape({
    hasSpouse: yup.string().required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    isThereSpouseHousehold: yup.mixed().when('hasSpouse', {
      // @ts-ignore
      is: 'yes',
      then: () => yup.string().required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    }),
    isThereSpouseMaintenance: yup.mixed().when('isThereSpouseHousehold', {
      // @ts-ignore
      is: 'no',
      then: () => yup.string().required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    }),
    spouseFirstName: yup.string().when('hasSpouse', {
      // @ts-ignore
      is: 'yes',
      then: () => yup.string().required(formatMessage({ id: 'submissionCreation.validation.firstName.title' })),
    }),
    spouseLastName: yup.string().when('hasSpouse', {
      // @ts-ignore
      is: 'yes',
      then: () => yup.string().required(formatMessage({ id: 'submissionCreation.validation.lastName.title' })),
    }),
    spouseBirthDate: yup.date().when('hasSpouse', {
      // @ts-ignore
      is: 'yes',
      then: () => yup.date().required(formatMessage({ id: 'submissionCreation.validation.birthDate.title' })),
    }),
    marriageCertificateFiles: yup.mixed().when('hasSpouse', {
      is: 'yes',
      then: () =>
        yup
          .mixed()
          .test(
            'is-defined',
            formatMessage({ id: 'submissionCreation.validation.documents.title' }),
            function (value, context) {
              const { uploadedMarriageCertificateFiles } = context.parent;
              const { createError } = this;
              if (
                (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                (uploadedMarriageCertificateFiles === undefined ||
                  (Array.isArray(uploadedMarriageCertificateFiles) && uploadedMarriageCertificateFiles.length === 0))
              ) {
                return createError({
                  message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                });
              }
              return true;
            },
          ),
      otherwise: () => yup.mixed().nullable(),
    }),
  });

  if (childrenArray.length > 0) {
    combinedSchema = combinedSchema.shape({
      children: yup.array().of(
        yup.object().shape({
          isThereHousehold: yup.boolean(),
          isThereMaintenance: yup.boolean(),
          isThereCreditedToPAccount: yup.boolean(),
          firstName: yup.string().required(formatMessage({ id: 'submissionCreation.validation.firstName.title' })),
          lastName: yup.string().required(formatMessage({ id: 'submissionCreation.validation.lastName.title' })),
          birthDate: yup.date().required(formatMessage({ id: 'submissionCreation.validation.birthDate.title' })),
          isBenefits: yup.boolean(),
          supplement: yup.string(),
          childDocuments: yup
            .mixed()
            .test(
              'is-defined',
              formatMessage({ id: 'submissionCreation.validation.documents.title' }),
              function (value, context) {
                const children = context.from?.[1]?.value?.children || [];
                const hasDocumentsAtLeastOneChild = children.some(
                  (child: ChildFormValues) => !!child.childDocuments || !!child.uploadedChildDocuments,
                );

                const { uploadedChildDocuments } = context.parent;
                const { createError } = this;
                if (
                  !hasDocumentsAtLeastOneChild &&
                  (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                  (uploadedChildDocuments === undefined ||
                    (Array.isArray(uploadedChildDocuments) && uploadedChildDocuments.length === 0))
                ) {
                  return createError({
                    message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                  });
                }
                return true;
              },
            ),
          supplementDocuments: yup
            .mixed()
            .test(
              'is-defined',
              formatMessage({ id: 'submissionCreation.validation.documents.title' }),
              function (value, context) {
                const children = context.from?.[1]?.value?.children || [];
                const hasDocumentsAtLeastOneChild = children.some(
                  (child: ChildFormValues) => !!child.supplementDocuments || !!child.uploadedSupplementDocuments,
                );

                const { uploadedSupplementDocuments, isBenefits, supplement } = context.parent;
                const { createError } = this;
                if (
                  !hasDocumentsAtLeastOneChild &&
                  (isBenefits || supplement) &&
                  (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                  (uploadedSupplementDocuments === undefined ||
                    (Array.isArray(uploadedSupplementDocuments) && uploadedSupplementDocuments.length === 0))
                ) {
                  return createError({
                    message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                  });
                }
                return true;
              },
            ),
        }),
      ),
    });
  }

  combinedSchema = combinedSchema.shape({
    isUserReceivedOngoingBenefit: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    ongoingBenefit: yup.object().when('isUserReceivedOngoingBenefit', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.object().shape({
          amount: yup.string(),
          furtherPersonsNumber: yup.string(),
          documents: yup
            .mixed()
            .test(
              'is-defined',
              formatMessage({ id: 'submissionCreation.validation.documents.title' }),
              function (value, context) {
                const { uploadedDocuments, amount, furtherPersonsNumber } = context.parent;
                const { createError } = this;
                if (
                  (amount || furtherPersonsNumber) &&
                  (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                  (uploadedDocuments === undefined || (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                ) {
                  return createError({
                    message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                  });
                }
                return true;
              },
            ),
        }),
      otherwise: () =>
        yup.object().shape({
          amount: yup.string(),
          furtherPersonsNumber: yup.string(),
          documents: yup.mixed(),
        }),
    }),

    isUserReceivedHealthDamages: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    healthDamages: yup.array().when('isUserReceivedHealthDamages', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),
      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),
    isUserReceivedOneOffBenefits: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    oneOffBenefits: yup.array().when('isUserReceivedOneOffBenefits', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),

      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),

    isUserReceivedBackPaymentBenefits: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    backPaymentBenefits: yup.array().when('isUserReceivedBackPaymentBenefits', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),

      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),
    isUserReceivedMotherChildFoundations: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    motherChildFoundations: yup.array().when('isUserReceivedMotherChildFoundations', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),

      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),
    isUserReceivedAdditionalBenefits: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    additionalBenefits: yup.array().when('isUserReceivedAdditionalBenefits', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),
      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),
  });

  combinedSchema = combinedSchema.shape({
    isUserReceivedBackPaymentWagePensionBenefits: yup
      .string()
      .required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    backPaymentWagePensionBenefits: yup.array().when('isUserReceivedBackPaymentWagePensionBenefits', {
      // @ts-ignore
      is: 'yes',
      then: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string().required(formatMessage({ id: 'submissionCreation.validation.amount.title' })),
            documents: yup
              .mixed()
              .test(
                'is-defined',
                formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                function (value, context) {
                  const { uploadedDocuments } = context.parent;
                  const { createError } = this;
                  if (
                    (value === undefined || (Array.isArray(value) && value.length === 0)) &&
                    (uploadedDocuments === undefined ||
                      (Array.isArray(uploadedDocuments) && uploadedDocuments.length === 0))
                  ) {
                    return createError({
                      message: formatMessage({ id: 'submissionCreation.validation.documents.title' }),
                    });
                  }
                  return true;
                },
              ),
          }),
        ),
      otherwise: () =>
        yup.array().of(
          yup.object().shape({
            amount: yup.string(),
            documents: yup.mixed(),
          }),
        ),
    }),
  });

  const schema = combinedSchema.shape({
    isUserAlreadyCounseling: yup.string().required(formatMessage({ id: 'submissionCreation.validation.radioButton.title' })),
    confirmAccurancy: yup
      .boolean()
      .oneOf([true], formatMessage({ id: 'submissionCreation.validation.checkbox.title' }))
      .required(formatMessage({ id: 'submissionCreation.validation.checkbox.title' })),
    conseptTheProcessing: yup
      .boolean()
      .oneOf([true], formatMessage({ id: 'submissionCreation.validation.checkbox.title' }))
      .required(formatMessage({ id: 'submissionCreation.validation.checkbox.title' })),
    isUserReadDeclarationOfConsent: yup
      .boolean()
      .oneOf([true], formatMessage({ id: 'submissionCreation.validation.readDeclarationOfConsent' }))
      .required(formatMessage({ id: 'submissionCreation.validation.readDeclarationOfConsent' })),
  });

  const methods = useForm<SubmissionCreationFormValues>({
    resolver: yupResolver(schema) as unknown as Resolver<
      SubmissionCreationFormValues,
      ResolverOptions<SubmissionCreationFormValues>
    >,
  });

  return useMemo(() => ({ combinedSchema, schema, methods }), [combinedSchema, schema, methods]);
};

const SubmissionForm = () => {
  const { mutateAsync: createSubmission } = useCreateSubmission();

  const submissionInFillingProcess = useQuery({
    queryKey: ['submissionInFillingProcess'],
    queryFn: () => submissionsInFillingProcessApi.getCurrentSubmissionInFillingProcess().then(({ data }) => data),
  });
  const { setCurrentStep, goToNextStep } = useContext(StepperContext);

  const [childrenArray, setChildrenArray] = useState<ChildFormValues[]>([]);
  const [isChangesUnsaved, setIsChangesUnsaved] = useState(false);
  const [navigateConfirmModal, setNavigateConfirmModal] = useState(false);

  const { methods } = useSubmissionFormSchemaAndMethods(childrenArray);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting, isDirty },
  } = methods;

  const {
    fields: childrenFields,
    append: appendChild,
    remove: removeChild,
  } = useFieldArray({
    control,
    name: 'children',
  });

  const [prevFormValues, setPrevFormValues] = useState<SubmissionCreationFormValues | null>(null);

  const { mutateAsync: updateSubmissionInFillingProcess } = useUpdateSubmissionInFillingProcess({
    onSuccess: () => {
      setPrevFormValues(getValues());
    },
  });
  const { mutateAsync: createSubmissionInFillingProcess } = useCreateSubmissionInFillingProcess({
    onSuccess: () => {
      submissionInFillingProcess.refetch();
      setPrevFormValues(getValues());
    },
  });

  const currentSubmission = useSelector(selectCurrentSubmission);

  useEffect(() => {
    setChildrenArray(childrenFields);
  }, [childrenFields]);

  useEffect(() => {
    if (submissionInFillingProcess.data) {
      reset(convertSubmissionToFormValues(submissionInFillingProcess.data as unknown as ISubmission), { keepDirty: false });
    }
  }, [submissionInFillingProcess?.data?.id]);

  // Synchronous update of currentStep to avoid step glare on reload page (if currentStep is a two/three)
  useLayoutEffect(() => {
    if (currentSubmission) {
      switch (currentSubmission.status) {
        case SubmissionStatus.New:
        case SubmissionStatus.InProcessing:
        case SubmissionStatus.DocumentRequested:
        case SubmissionStatus.Update:
          setCurrentStep(1);
          break;
        case SubmissionStatus.Completed:
        case SubmissionStatus.Withdrawn:
        case SubmissionStatus.Archived:
          setCurrentStep(2);
          break;
        default:
          setCurrentStep(0);
          break;
      }
    }
  }, [currentSubmission?.status]);

  const getChildrenValue = (index: number, key: keyof ChildFormValues) => {
    return watch(`children[${index}].${key}` as unknown as WatchObserver<SubmissionCreationFormValues>);
  };

  const ongoingBenefitAmount = watch('ongoingBenefit.amount');
  const ongoingBenefitFurtherPersonsNumber = watch('ongoingBenefit.furtherPersonsNumber');
  const isUserReceivedOngoingBenefit = watch('isUserReceivedOngoingBenefit');

  const [isOngoingBenefitsValuesUndefinedOrEmpty, setIsOngoingBenefitsValuesUndefinedOrEmpty] = useState(false);

  const checkOngoingBenefits = () => {
    if (
      isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes &&
      !ongoingBenefitAmount &&
      !ongoingBenefitFurtherPersonsNumber
    ) {
      setIsOngoingBenefitsValuesUndefinedOrEmpty(true);
    } else {
      setIsOngoingBenefitsValuesUndefinedOrEmpty(false);
    }
  }

  useEffect(() => {
    checkOngoingBenefits();
  }, [ongoingBenefitAmount, ongoingBenefitFurtherPersonsNumber]);

  const handleFormSubmit = async (data: SubmissionCreationFormValues) => {
    const isOngoingBenefitsIncomplete =
      isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes &&
      !ongoingBenefitAmount &&
      !ongoingBenefitFurtherPersonsNumber;

    if (isOngoingBenefitsIncomplete) {
      setIsOngoingBenefitsValuesUndefinedOrEmpty(true);

      console.error('Not all fields are required!');
    } else {
      try {
        const dto = await convertSubmissionFormValuesToDto(data);
        const res = await createSubmission(dto);
        setIsChangesUnsaved(false);
        if (res.status) {
          localStorage.removeItem(LocalStorageKey.EligibilityCheck);
          goToNextStep();
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const contextValue = useMemo(() => ({ getChildrenValue, childrenFields, appendChild, removeChild }), [childrenFields]);

  const calculateAllowanceData = convertSubmissionFormValuesToExpectedAllowance(watch());

  useEffect(() => {
    if (isDirty) {
      setIsChangesUnsaved(true);
    }
  }, [calculateAllowanceData, isDirty]);

  const { data } = useCalculateAllowance(calculateAllowanceData);

  useEffect(() => {
    eventBus.changeSavingSubmitionStatus.broadcast('show');
    const unsubscribe = eventBus.saveSubmissionInFillingProcess.subscribe(async () => {
      try {
        eventBus.changeSavingSubmitionStatus.broadcast('pending');
        const formValues = getValues();
        if (submissionInFillingProcess?.data?.id) {
          const dto = await convertSubmissionFormValuesToUpdateDto(formValues);
          await updateSubmissionInFillingProcess({ id: submissionInFillingProcess?.data?.id, data: dto });
          eventBus.changeSavingSubmitionStatus.broadcast('loaded');

          return;
        }

        const dto = await convertSubmissionFormValuesToDto(formValues);
        await createSubmissionInFillingProcess(dto);
        eventBus.changeSavingSubmitionStatus.broadcast('loaded');
      } catch (error) {
        eventBus.changeSavingSubmitionStatus.broadcast('error');
      }
    });

    return () => {
      unsubscribe();
      eventBus.changeSavingSubmitionStatus.broadcast('hide');
    };
  }, [submissionInFillingProcess?.data?.id]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isChangesUnsaved && !isEqual(prevFormValues, getValues())) {
        e.preventDefault();
        e.returnValue = '';
        return true;
      }
      return null;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isChangesUnsaved, prevFormValues]);

  const shouldBlockNavigation = () => {
    if (isChangesUnsaved && !isEqual(prevFormValues, getValues())) {
      setNavigateConfirmModal(true);
      return 'Are you sure you want to leave? You may lose unsaved changes.';
    }
    return null;
  };

  // @ts-ignore
  const blocker = useBlocker(shouldBlockNavigation);

  return (
    <SubmissionFormContext.Provider value={contextValue}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <MaintenanceObligations />
          {!!childrenFields?.length && <ChildBenefit />}
          <OngoingServices isOngoingBenefitsValuesUndefinedOrEmpty={isOngoingBenefitsValuesUndefinedOrEmpty} />

          <CounselingRecognizedBody />

          <InfoBox />
          <StyledSubmissionFormFooter checkOngoingBenefits={checkOngoingBenefits} data={data} isSubmitting={isSubmitting} />
        </Form>
      </FormProvider>

      {navigateConfirmModal && (
        <ConfirmModal
          open={navigateConfirmModal}
          titleId="submissionCreation.confirmModal.loseUnsavedChanges.title"
          // @ts-ignore
          updateClick={() => blocker?.proceed()}
          handleClose={() => setNavigateConfirmModal(false)}
        />
      )}
    </SubmissionFormContext.Provider>
  );
};

export default SubmissionForm;
