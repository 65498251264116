import InfoDrawer from '@components/Stepper/InfoDrawer';
import { useScrollPosition } from '@hooks';
import { isDefined } from '@utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Body, Content, Step, StepTitle, Steps, StepsBar, Wrapper } from './styles';
import { IndexedWizardStep, WizardStep } from './types';

export enum Flow {
  settings,
}

export interface IStepperProps {
  hideSteps?: boolean;
  className?: string;
  steps: WizardStep[];
  makeTabsClickable?: boolean;
  withPaddings?: boolean;
  flow?: Flow;
}

export interface IStyledStepperProps {
  withPaddings?: boolean;
  flow?: Flow;
}

export interface IStepperContext {
  currentStep?: IndexedWizardStep | null;
  setStep: (id: string, event?: React.MouseEvent<HTMLDivElement> | undefined) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  openInfoDrawer: (title: string, description: string) => void;
  setCurrentStep: (id: number) => void;
}

export const StepperContext = React.createContext<IStepperContext>({
  currentStep: null,
  setStep: () => {},
  goToNextStep: () => {},
  goToPrevStep: () => {},
  openInfoDrawer: () => {},
  setCurrentStep: () => {},
});

const Stepper: React.FC<IStepperProps> = ({
  steps,
  className,
  hideSteps = false,
  makeTabsClickable = false,
  withPaddings = true,
  flow,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [drawerTopOffset, setDrawerTopOffset] = useState(0);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerDescription, setDrawerDescription] = useState('');

  const handleOpenInfoDrawer = (title: string, description: string) => {
    setDrawerTitle(title);
    setDrawerDescription(description);

    setOpenInfoDrawer(true);
  };

  const [currentStep, setCurrentStep] = useState<IndexedWizardStep | null>(
    steps.length > 0 ? { ...steps[0], index: 0 } : null,
  );

  const indexedSteps: IndexedWizardStep[] = useMemo(() => steps.map((step, index) => ({ ...step, index })), [steps]);

  const handleNext = () => {
    setCurrentStep((prevStep) => indexedSteps[isDefined(prevStep?.index) ? (prevStep?.index || 0) + 1 : 0]);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => indexedSteps[isDefined(prevStep?.index) ? (prevStep?.index || 0) - 1 : 0]);
  };

  const handleCurrentStep = (id: number) => {
    setCurrentStep(indexedSteps[id]);
  };

  const handleChangeStep = (id: string, event?: React.MouseEvent<HTMLDivElement> | undefined) => {
    if (event && makeTabsClickable) {
      event.stopPropagation();
    }

    const newStep = indexedSteps.find((s) => s.id === id);

    if (newStep) {
      if (newStep.disabled) {
        return;
      }
      setCurrentStep(newStep);
    }
  };

  // update current step if steps data is changed
  useEffect(() => {
    if (currentStep) {
      setCurrentStep(indexedSteps[currentStep.index]);
    }
  }, [indexedSteps]);

  const scrollPosition = useScrollPosition();
  useEffect(() => {
    if (contentRef) {
      const contentTop = contentRef.current?.getBoundingClientRect().top;
      setDrawerTopOffset(contentTop && contentTop > 0 ? contentTop : 0);
    }
  }, [scrollPosition]);

  const contextValue = useMemo(
    () => ({
      goToNextStep: handleNext,
      goToPrevStep: handlePrev,
      setStep: handleChangeStep,
      currentStep,
      openInfoDrawer: handleOpenInfoDrawer,
      setCurrentStep: handleCurrentStep,
    }),
    [currentStep],
  );

  return currentStep ? (
    <StepperContext.Provider value={contextValue}>
      <Wrapper>
        {!hideSteps && (
          <StepsBar>
            <Steps withPaddings={withPaddings} flow={flow}>
              {indexedSteps.map(({ id, index, title, isHidden, disabled }) => {
                const isActive = index === currentStep.index;
                const isFutureStep = index > currentStep.index;
                return isHidden ? null : (
                  <Step
                    key={id}
                    active={isActive}
                    disabled={disabled || isFutureStep}
                    onClick={makeTabsClickable ? (event) => handleChangeStep(id, event) : undefined}
                    flow={flow}
                  >
                    <StepTitle
                      variant="body1"
                      fontSize="14px"
                      letterSpacing="0.25px"
                      flow={flow}
                      isActive={isActive}
                      isFutureStep={isFutureStep}
                    >
                      {title}
                    </StepTitle>
                  </Step>
                );
              })}
            </Steps>
          </StepsBar>
        )}
        <Body className={className}>
          <Content openInfoDrawer={openInfoDrawer} ref={contentRef}>
            {currentStep.content}
          </Content>

          <InfoDrawer
            drawerTopOffset={drawerTopOffset}
            open={openInfoDrawer}
            onClose={() => setOpenInfoDrawer(false)}
            // title={formatMessage({ id: 'submissionCreation.drawer.title' })}
            title={drawerTitle}
            // description={formatMessage({ id: 'submissionCreation.drawer.description' })}
            description={drawerDescription}
          />
        </Body>
      </Wrapper>
    </StepperContext.Provider>
  ) : null;
};

export default Stepper;
