import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Bookmark({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>bookmark</title>
      <defs>
        <path
          d="M14,17.0568107 L14,3 C14,2.44771525 13.5522847,2 13,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17.0568107 L7.41876181,13.1862665 C7.76645933,12.9379112 8.23354067,12.9379112 8.58123819,13.1862665 L14,17.0568107 Z M8,15.2289036 L1.58123819,19.8137335 C0.91936972,20.2864967 0,19.8133726 0,19 L0,3 C0,1.34314575 1.34314575,0 3,0 L13,0 C14.6568542,0 16,1.34314575 16,3 L16,19 C16,19.8133726 15.0806303,20.2864967 14.4187618,19.8137335 L8,15.2289036 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1357, -392)">
          <g id="bookmark" transform="translate(1361, 394)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
