import { CheckIcon, Trash2Icon } from '@icons';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface IUploadedFileProps {
  fileName: string;
  onDeleteFile?: () => void;
}

const UploadedFile: FC<IUploadedFileProps> = ({ fileName, onDeleteFile }) => {
  const theme = useTheme();

  const handleDeleteButtonClick = () => {
    onDeleteFile?.();
  };

  return (
    <Stack direction="row" alignItems="center">
      <CheckIcon color={theme.palette.success.main} style={{ zIndex: 1 }} />
      <Typography fontSize="16px" ml={2} mr={1} sx={{ zIndex: 1 }}>
        {fileName}
      </Typography>
      <IconButton onClick={handleDeleteButtonClick}>
        <Trash2Icon color={theme.palette.grey['300']} width="16px" height="16px" />
      </IconButton>
    </Stack>
  );
};

export default UploadedFile;
