import { certificatesApi } from '@api';
import { MessageToApplicantIn, SubmissionStatus } from '@app-types';
import { Button } from '@components';
import Loader from '@components/Loader';
import { AlertTriangleIcon, CheckCircleIcon, FileMinusIcon } from '@icons';
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import { selectCurrentSubmission } from '@store/submission/slice';
import { useQuery } from '@tanstack/react-query';
import { downloadFile } from '@utils';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../ApplicationReview/styles';
import DateComponent from '../Date/Date';

interface ISubmissionCompletedProps {
  status: string | undefined;
  createdAt: string | undefined;
  submissionId: string | undefined;
  messageToApplicantIn: string | undefined;
  additionalMessageToApplicantIn: string | undefined;
}

enum NegativeCertificateType {
  negativeCertificate1 = 'negativeCertificate1',
  negativeCertificate2 = 'negativeCertificate2',
  negativeCertificate3 = 'negativeCertificate3',
}

const SubmissionCompleted: FC<ISubmissionCompletedProps> = ({
  status,
  createdAt,
  submissionId,
  messageToApplicantIn,
  additionalMessageToApplicantIn,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const currentSubmission = useSelector(selectCurrentSubmission);
  const recognizedBody = currentSubmission?.recognizedBody;
  const recognizedBodyAddress = `${recognizedBody?.street || ''} ${recognizedBody?.houseNumber || ''}, ${
    recognizedBody?.zipCode || ''
  }, ${recognizedBody?.city || ''}`;

  let description = '';
  switch (messageToApplicantIn) {
    case MessageToApplicantIn.ByPost:
      description = formatMessage({
        id: 'submissionDetails.issueCertificateModal.send.dropdown.description.byPost',
      });
      break;
    case MessageToApplicantIn.Pick:
      description = formatMessage(
        { id: 'submissionDetails.issueCertificateModal.send.dropdown.description.pick' },
        { address: recognizedBodyAddress },
      );
      break;
    case MessageToApplicantIn.DigitalTransmissionToTheBankAndByPost:
      description = formatMessage({
        id: 'submissionDetails.issueCertificateModal.send.dropdown.description.digitalTransmissionToTheBankAndByPost',
      });
      break;
    case MessageToApplicantIn.DigitalTransmissionToTheBankAndCollection:
      description = formatMessage(
        {
          id: 'submissionDetails.issueCertificateModal.send.dropdown.description.digitalTransmissionToTheBankAndCollection',
        },
        { address: recognizedBodyAddress },
      );
      break;
    default:
      description = '';
      break;
  }

  const { data: certificateFileData, isPending: isCertificateFileDataPending } = useQuery({
    queryKey: ['certificateFile', submissionId],
    queryFn: () => certificatesApi.getCertificateFile(submissionId),
    refetchOnMount: 'always',
  });

  const { data: certificateData, isPending: isCertificateDataPending } = useQuery({
    queryKey: ['certificate', submissionId],
    queryFn: () => certificatesApi.getCertificateBySubmissionId(submissionId),
    refetchOnMount: 'always',
  });

  const daysLeft = useMemo(() => {
    if (!currentSubmission?.updatedAt) {
      return 30;
    }

    const oneDay = 1000 * 60 * 60 * 24;
    const thirtyDays = oneDay * 30;

    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const timeDiff = Date.now() + timezoneOffset - Date.parse(currentSubmission.updatedAt);

    const result = (thirtyDays - timeDiff) / oneDay;

    return result;
  }, [currentSubmission?.updatedAt]);

  const handleDownloadButtonClick = () => {
    const blob = new Blob([certificateFileData?.data], { type: 'application/pdf' });
    const filename = certificateFileData?.headers['content-disposition'].split('filename=')[1].split('.')[0];
    downloadFile(blob, filename);
  };

  const isSubmissionSuccessful = status === SubmissionStatus.Completed && !!certificateData?.data;

  const isNegativeCertificate =
    !!certificateData?.data &&
    (certificateData?.data?.certificateType === NegativeCertificateType.negativeCertificate1 ||
      certificateData?.data?.certificateType === NegativeCertificateType.negativeCertificate2 ||
      certificateData?.data?.certificateType === NegativeCertificateType.negativeCertificate3);

  const negativeCertificateType =
    NegativeCertificateType[certificateData?.data?.certificateType as NegativeCertificateType] ||
    NegativeCertificateType.negativeCertificate1;

  if (isCertificateDataPending || isCertificateFileDataPending) return <Loader />;

  return (
    <Box>
      <Container sx={{ pt: '24px' }}>
        <Wrapper container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              margin: '0 auto',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isSubmissionSuccessful && !isNegativeCertificate ? (
              <CheckCircleIcon width="80px" height="80px" color={theme.palette.primary.main} />
            ) : (
              <AlertTriangleIcon width="80px" height="80px" color={theme.palette.primary.dark} />
            )}
            <Box sx={{ mt: '11px' }}>
              {isSubmissionSuccessful && !isNegativeCertificate ? (
                <DateComponent createdAt={createdAt} />
              ) : (
                <DateComponent color={theme.palette.primary.dark} createdAt={createdAt} />
              )}
            </Box>
            {isSubmissionSuccessful ? (
              <>
                <Typography variant="h2" sx={{ fontSize: '32px', mt: '8px' }}>
                  {isNegativeCertificate ? (
                    <FormattedMessage id={`submissionCreation.submissionCompleted.${negativeCertificateType}.title`} />
                  ) : (
                    <FormattedMessage id="submissionCreation.submissionCompleted.positive.title" />
                  )}
                </Typography>
                <Box sx={{ maxWidth: '490px', mt: '16px' }}>
                  {!isNegativeCertificate && <Typography>{description}</Typography>}
                  {additionalMessageToApplicantIn && (
                    <Typography mt={`${!isNegativeCertificate ? '16px' : 0}`}>
                      <FormattedMessage id={additionalMessageToApplicantIn} />
                    </Typography>
                  )}
                </Box>
                {!!certificateData?.data?.password && (
                  <Stack sx={{ maxWidth: '490px', mt: '16px' }} direction="column">
                    <Typography>
                      <FormattedMessage id="submissionDetails.issueCertificateModal.password.description" />
                    </Typography>
                    <Typography fontWeight="700">{certificateData?.data?.password}</Typography>
                  </Stack>
                )}
                {daysLeft > 0 ? (
                  <Box>
                    {isNegativeCertificate ? (
                      <Button sx={{ mt: '32px' }} onClick={handleDownloadButtonClick}>
                        <Stack direction="row" justifyContent="space-between" gap="8px" alignItems="center">
                          <FileMinusIcon color={theme.palette.secondary.main} />
                          <FormattedMessage id="submissionCreation.submissionCompleted.negativeCertificate.button" />
                        </Stack>
                      </Button>
                    ) : (
                      <Button sx={{ mt: '32px' }} onClick={handleDownloadButtonClick}>
                        <FormattedMessage id="submissionCreation.submissionCompleted.positive.button" />
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Button sx={{ mt: '32px' }} onClick={() => navigate('/tips')}>
                    <FormattedMessage id="submissionCreation.submissionCompleted.newRequest.button" />
                  </Button>
                )}
              </>
            ) : (
              <Typography variant="h2" sx={{ fontSize: '32px', mt: '8px' }}>
                <FormattedMessage id="submissionCreation.submissionCompleted.negative.title" />
              </Typography>
            )}
          </Grid>
        </Wrapper>
      </Container>
    </Box>
  );
};

export default SubmissionCompleted;
