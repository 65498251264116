import { Button } from '@components';
import { CheckIcon } from '@icons';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useIsMobile from '../../../shared/hooks/useIsMobile';

interface IConfirmationStepProps {
  onSubmit: () => void;
}

const ConfirmationStep: React.FC<IConfirmationStepProps> = ({ onSubmit }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Stack
      gap={2}
      alignItems="center"
      sx={{
        width: theme.spacing(isMobile ? 38 : 61.25),
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: isMobile ? 'translate(-50%, -65%)' : 'translate(-50%, -100%)',
      }}
    >
      <CheckIcon width={theme.spacing(10)} height={theme.spacing(10)} color={theme.palette.primary.main} />
      <Typography variant="h2" align="center">
        <FormattedMessage id="eligibilityCheck.confirmation.title" />
      </Typography>
      <Typography variant="body1" align="center" sx={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id="eligibilityCheck.confirmation.description" />
      </Typography>
      <Button onClick={onSubmit} sx={{ marginTop: theme.spacing(2) }}>
        <FormattedMessage id="eligibilityCheck.confirmation.submit" />
      </Button>
    </Stack>
  );
};

export default ConfirmationStep;
