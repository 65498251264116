import { CalendarIcon } from '@icons';
import { styled } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

export const StyledCalendarIcon = styled(CalendarIcon)(({ theme }) => ({
  ':hover': {
    use: {
      fill: theme.palette.primary.main,
    },
    g: {
      fill: theme.palette.primary.main,
    },
  },
}));

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<Dayjs> & { backgroundMode: 'white' | 'gray' }>(
  ({ theme, backgroundMode }) => ({
    '.MuiOutlinedInput-input': {
      padding: '12px 16px',
    },

    '& .MuiOutlinedInput-root': {
      paddingRight: '20px',
      backgroundColor: backgroundMode === 'gray' ? theme.palette.grey['600'] : theme.palette.secondary.main,
      '&:hover': {
        fieldset: {
          borderWidth: 2,
          borderColor: `${theme.palette.primary.main}`,
        },
        '&.Mui-disabled fieldset': {
          borderWidth: 1,
          borderColor: `${theme.palette.grey['400']}`,
        },
      },
      '&.Mui-error fieldset': {
        borderWidth: 2,
        borderColor: `${theme.palette.error.main}`,
      },

      '& .MuiIconButton-root': {
        '&:hover': {
          color: theme.palette.primary.main,
          use: {
            fill: theme.palette.primary.main,
          },
          g: {
            fill: theme.palette.primary.main,
          },
        },
      },
    },

    '.MuiInputLabel-root': {
      position: 'static',
      transform: 'none',
      transition: 'none',
      marginBottom: '8px',
      fontSize: '16px',
      color: theme.palette.secondary.dark,
    },

    legend: { width: 0 },

    '& ::placeholder': {
      fontSize: '16px',
    },
    svg: {
      width: 20,
      height: 20,
    },
  }),
);
