import io, { Socket } from 'socket.io-client';

class SocketApi {
  static socket: null | Socket = null;

  static createConnection() {
    this.socket = io(`${process.env.REACT_APP_API_FULL_URL}`);

    this.socket.on('connect', () => {
      console.log('connected with server');
    });

    this.socket.on('disconnect', () => {
      console.log('disconnected');
    });
  }
}

export default SocketApi;
