import { InputBaseComponentProps } from '@mui/material';
import React, { FC, FunctionComponent } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { Input } from '@components';
import { IInputProps } from '@components/Input';
import { CurrencyInputAdornment } from '@components/formControllers/CurrencyInputController/styles';
import NumericFormat from './NumericFormat';

interface IInputControllerProps {
  name: string;
  control: Control<any>;
  label: React.ReactNode;
  currencySymbol?: string;
}

const CurrencyInputController: FC<IInputControllerProps & IInputProps> = ({
  currencySymbol,
  name,
  control,
  label,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          helperText={error ? error.message : ''}
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          label={label}
          InputProps={{
            inputComponent: NumericFormat as unknown as FunctionComponent<InputBaseComponentProps>,
            endAdornment: <CurrencyInputAdornment position="start">{currencySymbol || '€'}</CurrencyInputAdornment>,
          }}
          {...rest}
        />
      )}
    />
  );
};

export default CurrencyInputController;
