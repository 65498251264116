import { Button, CheckboxController } from '@components';
import { ViewportBreakpoint } from '@constants';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubmissionFormBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '16px',
  padding: '48px 92px 44px 70px',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    position: 'relative',
    margin: 0,
    padding: '64px 0',
    borderRadius: 0,

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      left: -22,
      top: 0,
      width: '100vw',
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export const AmountBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 2,

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    gridTemplateColumns: '1fr',
    gap: 32,
  },
}));

export const StyledCheckboxController = styled(CheckboxController)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  marginRight: 0,
  alignItems: 'start',

  '& .MuiFormControlLabel-label': {
    color: theme.palette.secondary.main,
    lineHeight: 1.64,
  },

  '& .MuiCheckbox-root': {
    padding: '3px 9px',
  },
}));

export const NoteBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  marginTop: '33px',
}));

export const CheckboxesBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '28px',
}));

export const StyledButton = styled(Button)(() => ({
  width: 'fit-content',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    width: '100%',
  },
}));
