import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Monitor({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>monitor</title>
      <defs>
        <path
          d="M7,20 C6.44771525,20 6,19.5522847 6,19 C6,18.4477153 6.44771525,18 7,18 L10,18 L10,16 L3,16 C1.34314575,16 0,14.6568542 0,13 L0,3 C0,1.34314575 1.34314575,0 3,0 L19,0 C20.6568542,0 22,1.34314575 22,3 L22,13 C22,14.6568542 20.6568542,16 19,16 L12,16 L12,18 L15,18 C15.5128358,18 15.9355072,18.3860402 15.9932723,18.8833789 L16,19 C16,19.5522847 15.5522847,20 15,20 Z M19,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,13 C2,13.5522847 2.44771525,14 3,14 L19,14 C19.5522847,14 20,13.5522847 20,13 L20,3 C20,2.44771525 19.5522847,2 19,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-768, -392)">
          <g id="monitor" transform="translate(769, 394)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
