import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function FastForward({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>fast-forward</title>
      <defs>
        <path
          d="M12.6139406,0.212499318 L21.6139406,7.21249932 C22.1286865,7.6128572 22.1286865,8.39084587 21.6139406,8.79120375 L12.6139406,15.7912038 C11.9570826,16.3020933 11,15.8339997 11,15.0018515 L11,1.00185154 C11,0.16970336 11.9570826,-0.298390243 12.6139406,0.212499318 Z M1.61394061,0.212499318 L10.6139406,7.21249932 C11.1286865,7.6128572 11.1286865,8.39084587 10.6139406,8.79120375 L1.61394061,15.7912038 C0.957082606,16.3020933 0,15.8339997 0,15.0018515 L0,1.00185154 C0,0.16970336 0.957082606,-0.298390243 1.61394061,0.212499318 Z M13,3.0464909 L13,12.9572122 L19.371178,8.00185154 L13,3.0464909 Z M2,3.0464909 L2,12.9572122 L8.37117796,8.00185154 L2,3.0464909 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1149, -529)">
          <g id="fast-forward" transform="translate(1150, 533)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -4)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
