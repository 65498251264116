import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Layers({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>layers</title>
      <defs>
        <path
          d="M20.5530055,15.1055728 C21.046984,14.8585836 21.647657,15.0588079 21.8946462,15.5527864 C22.1416355,16.0467649 21.9414111,16.6474379 21.4474327,16.8944272 L11.4474327,21.8944272 C11.1659052,22.0351909 10.834533,22.0351909 10.5530055,21.8944272 L0.553005461,16.8944272 C0.0590269636,16.6474379 -0.141197383,16.0467649 0.105791866,15.5527864 C0.352781114,15.0588079 0.953454155,14.8585836 1.44743265,15.1055728 L11.0002191,19.881966 Z M20.5530055,10.1055728 C21.046984,9.85858356 21.647657,10.0588079 21.8946462,10.5527864 C22.1416355,11.0467649 21.9414111,11.6474379 21.4474327,11.8944272 L11.4474327,16.8944272 C11.1659052,17.0351909 10.834533,17.0351909 10.5530055,16.8944272 L0.553005461,11.8944272 C0.0590269636,11.6474379 -0.141197383,11.0467649 0.105791866,10.5527864 C0.352781114,10.0588079 0.953454155,9.85858356 1.44743265,10.1055728 L11.0002191,14.881966 Z M11.4474327,0.105572809 L21.4474327,5.10557281 C22.1844812,5.47409708 22.1844812,6.52590292 21.4474327,6.89442719 L11.4474327,11.8944272 C11.1659052,12.0351909 10.834533,12.0351909 10.5530055,11.8944272 L0.553005461,6.89442719 C-0.184043078,6.52590292 -0.184043078,5.47409708 0.553005461,5.10557281 L10.5530055,0.105572809 C10.834533,-0.0351909363 11.1659052,-0.0351909363 11.4474327,0.105572809 Z M11.0002191,2.11803399 L3.23628703,6 L11.0002191,9.88196601 L18.7641511,6 L11.0002191,2.11803399 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-994, -194)">
          <g id="layers" transform="translate(995, 195)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
