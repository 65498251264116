import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function SkipBack({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>skip-back</title>
      <defs>
        <path
          d="M14,3.08250215 L6.60078106,9.0018773 L14,14.9212525 L14,3.08250215 Z M14.375305,17.7827461 L4.37530495,9.78274611 C3.87489835,9.38242083 3.87489835,8.62133377 4.37530495,8.22100849 L14.375305,0.221008492 C15.0300683,-0.302802184 16,0.163371093 16,1.0018773 L16,17.0018773 C16,17.8403835 15.0300683,18.3065568 14.375305,17.7827461 Z M2,16.0018773 L2,2.0018773 C2,1.44959255 1.55228475,1.0018773 1,1.0018773 C0.44771525,1.0018773 0,1.44959255 0,2.0018773 L0,16.0018773 C0,16.5541621 0.44771525,17.0018773 1,17.0018773 C1.55228475,17.0018773 2,16.5541621 2,16.0018773 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-390, -142)">
          <g id="skip-back" transform="translate(394, 145)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
