import { Radio, RadioProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  '&.MuiRadio-root': {
    '&:hover': {
      backgroundColor: 'transparent ',
    },
  },
}));

export const DefaultIcon = styled('div')<{ isHovering?: boolean }>(({ theme, isHovering }) => ({
  width: '16px',
  height: '16px',

  border: `1px solid ${isHovering ? theme.palette.primary.dark : theme.palette.grey['400']}`,
  borderRadius: '8px',
  backgroundColor: theme.palette.grey['600'],

  boxSizing: 'border-box',
}));

export const CheckedIcon = styled('div')(({ theme }) => ({
  width: '16px',
  height: '16px',

  borderRadius: '8px',
  border: `4px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey['600'],

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  boxSizing: 'border-box',
}));
