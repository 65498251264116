import { Button, FileInputController } from '@components';
import { useIsMobile } from '@hooks';
import { FileIcon } from '@icons';
import { useTheme } from '@mui/material';
import { selectCustomer } from '@store/customer/selectors';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFiles from '../../ApplicationReview/UploadFiles/UploadFiles';

interface UploadFileButtonProps {
  fieldName: string;
}

const UploadFileButton: FC<UploadFileButtonProps> = ({ fieldName }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { control } = useFormContext();
  const customer = useSelector(selectCustomer);

  const [openModal, setOpenModal] = useState(false);

  if (isMobile) {
    return <FileInputController name={fieldName} control={control} />;
  }

  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={<FileIcon color={theme.palette.secondary.main} />}
        onClick={() => setOpenModal(true)}
      >
        <FormattedMessage id="formController.fileInput.uploadFile" />
      </Button>
      <UploadFiles
        open={openModal}
        formFieldName={fieldName}
        handleClose={() => setOpenModal(false)}
        customerId={customer?.id || ''}
      />
    </>
  );
};

export default UploadFileButton;
