import { StepperLayout } from '@components';
import Loader from '@components/Loader';
import { withAuth } from '@hoc';
import { selectSubmissionLoadingStatus } from '@store/submission/selectors';
import { selectCurrentSubmission } from '@store/submission/slice';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ApplicationReview from './ApplicationReview';
import SubmissionCompleted from './SubmissionCompleted';
import SubmissionForm from './SubmissionForm';

interface ISubmissionCreationProps {}

const SubmissionCreation: React.FC<ISubmissionCreationProps> = ({}) => {
  const { formatMessage } = useIntl();
  const currentSubmission = useSelector(selectCurrentSubmission);
  const submissionLoadingStatus = useSelector(selectSubmissionLoadingStatus);

  if (submissionLoadingStatus === 'LOADING') return <Loader />;

  return (
    <StepperLayout
      steps={[
        {
          id: '1',
          title: formatMessage({ id: 'submissionCreation.firstStep' }),
          content: <SubmissionForm />,
        },
        {
          id: '2',
          title: formatMessage({ id: 'submissionCreation.secondStep' }),
          content: (
            <ApplicationReview
              status={currentSubmission?.status}
              createdAt={currentSubmission?.createdAt}
              additionalDemands={currentSubmission?.additionalDemands}
              submissionId={currentSubmission?.id}
              messageToRequestedDocuments={currentSubmission?.messageToRequestedDocuments}
            />
          ),
        },
        {
          id: '3',
          title: formatMessage({ id: 'submissionCreation.thirdStep' }),
          content: (
            <SubmissionCompleted
              status={currentSubmission?.status}
              createdAt={currentSubmission?.createdAt}
              submissionId={currentSubmission?.id}
              messageToApplicantIn={currentSubmission?.messageToApplicantIn || ''}
              additionalMessageToApplicantIn={currentSubmission?.additionalMessageToApplicantIn || ''}
            />
          ),
        },
      ]}
    />
  );
};

export default withAuth(SubmissionCreation);
