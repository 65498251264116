import { Button } from '@components';
import { useIsMobile } from '@hooks';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitlesBox = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();

  return {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    paddingBottom: '32px',
    display: isMobile ? 'flex' : 'grid',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'center' : 'none',
    gridTemplateColumns: '56px 1fr',
    gap: '24px',
  };
});

export const SelectFileBox = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();

  return {
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    marginTop: '32px',
    paddingTop: '32px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '160px',
    gap: isMobile ? 10 : 0,
  };
});

export const StyledButton = styled(Button)(({ theme }) => {
  const isMobile = useIsMobile();

  return {
    width: isMobile ? '100%' : 'auto',
  };
});
