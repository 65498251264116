import { Button } from '@components';
import { MailIcon } from '@icons';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Description } from './styles';

const SuccessfulSignUp: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box textAlign="center">
      <MailIcon width="80px" height="80px" color={theme.palette.primary.main} />
      <Typography variant="h1" sx={{ fontSize: '32px', mt: '16px' }}>
        <FormattedMessage id="signup.success.title" />
      </Typography>
      <Description>
        <FormattedMessage id="signup.success.description" />
      </Description>
      <Button color="primary" variant="contained" onClick={() => navigate('/send-code')}>
        <FormattedMessage id="signup.success.button.title" />
      </Button>
    </Box>
  );
};

export default SuccessfulSignUp;
