import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledTypography } from './styles';

interface IDateProps {
  color?: string;
  createdAt: string | undefined;
}

const Date: FC<IDateProps> = ({ color, createdAt }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <StyledTypography color={color}>
        <FormattedMessage id="submissionCreation.applicationReview.submissionAudit.date" />
      </StyledTypography>
      <StyledTypography color={color}>{dayjs(createdAt).format('DD.MM.YYYY')}</StyledTypography>
    </Box>
  );
};

export default Date;
