import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Film({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>film</title>
      <defs>
        <path
          d="M18.82,0 C20.5762655,0 22,1.4237345 22,3.18 L22,18.82 C22,20.5762655 20.5762655,22 18.82,22 L3.18,22 C1.4237345,22 0,20.5762655 0,18.82 L0,3.18 C0,1.4237345 1.4237345,0 3.18,0 L18.82,0 Z M5,17 L2,17 L2,18.82 C2,19.471696 2.528304,20 3.18,20 L5,20 L5,17 Z M15,12 L7,12 L7,20 L15,20 L15,12 Z M20,17 L17,17 L17,20 L18.82,20 C19.471696,20 20,19.471696 20,18.82 L20,17 Z M20,12 L17,12 L17,15 L20,15 L20,12 Z M5,12 L2,12 L2,15 L5,15 L5,12 Z M5,7 L2,7 L2,10 L5,10 L5,7 Z M15,2 L7,2 L7,10 L15,10 L15,2 Z M20,7 L17,7 L17,10 L20,10 L20,7 Z M18.82,2 L17,2 L17,5 L20,5 L20,3.18 C20,2.528304 19.471696,2 18.82,2 Z M5,2 L3.18,2 C2.528304,2 2,2.528304 2,3.18 L2,5 L5,5 L5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-614, -598)">
          <g id="film" transform="translate(615, 599)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
