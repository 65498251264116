import Input from '@components/Input';
import { styled } from '@mui/material/styles';

export const StyledSendCodeInput = styled(Input)(({ theme }) => ({
  '.MuiInputBase-root': {
    borderRadius: '8px',
  },

  '.MuiInputBase-input': {
    width: '48px',
    height: '80px',
  },

  '.MuiOutlinedInput-input': {
    padding: '0',
    backgroundColor: theme.palette.secondary.main,
  },

  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    fontSize: '32px',
    fontWeight: 500,
    textAlign: 'center',
  },

  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& .MuiOutlinedInput-root': {
    paddingRight: 0,
  },
}));
