import { IFile, ServerBase64 } from '@app-types';
import { IRecognizedBody } from './recognizedBody';

export enum SubmissionBenefitListType {
  AdditionalDocuments = 'additionalDocuments',
  AdditionalBenefits = 'additionalBenefits',
  HealthDamage = 'healthDamage',
  OneOffBenefits = 'oneOffBenefits',
  BackPaymentBenefits = 'backPaymentBenefits',
}

export interface BenefitListItem {
  id: string;
  label: string;
  value: number;
  order: number;
  type: SubmissionBenefitListType;
}

export enum SubmissionStatus {
  New = 'New',
  InProcessing = 'In processing',
  DocumentRequested = 'Document requested',
  Update = 'Update',
  Withdrawn = 'Withdrawn',
  Completed = 'Completed',
  Archived = 'Archived',
}

export enum BenefitType {
  InitialEquipmentApartment = 'initialEquipmentApartment',
  InitialEquipmentClothing = 'initialEquipmentClothing',
  InitialEquipmentPregnancy = 'initialEquipmentPregnancy',
  RentTherapeuticEquipment = 'rentTherapeuticEquipment',
  PurchaseOrthopedicShoes = 'purchaseOrthopedicShoes',
}

export const MessageToApplicantIn = {
  ByPost: 'byPost',
  Pick: 'pick',
  DigitalTransmissionToTheBankAndByPost: 'digitalTransmissionToTheBankAndByPost',
  DigitalTransmissionToTheBankAndCollection: 'digitalTransmissionToTheBankAndCollection',
};

export interface SubmissionChild {
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  isThereHousehold?: boolean;
  isThereMaintenance?: boolean;
  isThereCreditedToPAccount?: boolean;
  childDocuments?: FileList;
  isBenefits?: boolean;
  supplement?: string;
  supplementDocuments?: FileList;
}

export interface CreateSubmissionChildDto {
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  isThereHousehold?: boolean;
  isThereMaintenance?: boolean;
  isThereCreditedToPAccount?: boolean;
  childDocuments?: IFile[];
  uploadedChildDocuments?: ServerBase64[];
  isBenefits?: boolean;
  supplement?: string;
  supplementDocuments?: IFile[];
  uploadedSupplementDocuments?: ServerBase64[];
}

export interface CreateBenefitDto {
  type?: BenefitType;
  amount?: number;
  explanation?: string;
  documents?: IFile[];
  uploadedDocuments?: ServerBase64[];
}

export interface CreateSubmissionDto {
  hasSpouse?: boolean;
  isThereSpouseHousehold?: boolean;
  isThereSpouseMaintenance?: boolean;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseBirthDate?: Date;
  children?: CreateSubmissionChildDto[];
  marriageCertificateFiles?: IFile[];
  uploadedMarriageCertificateFiles?: ServerBase64[];

  ongoingBenefit?: {
    amount?: number;
    furtherPersonsNumber?: number;
    documents?: IFile[];
    uploadedDocuments?: ServerBase64[];
  };
  motherChildFoundation?: CreateBenefitDto;
  backPaymentWagePensionBenefit?: CreateBenefitDto;
  oneOffBenefits?: CreateBenefitDto[];
  healthDamages?: CreateBenefitDto[];
  backPaymentBenefits?: CreateBenefitDto[];
  additionalBenefits?: CreateBenefitDto[];
  recognizedBodyId?: string;

  isUserReceivedOngoingBenefit?: boolean;
  isUserReceivedOneOffBenefits?: boolean;
  isUserReceivedHealthDamages?: boolean;
  isUserReceivedBackPaymentBenefits?: boolean;
  isUserReceivedMotherChildFoundations?: boolean;
  isUserReceivedAdditionalBenefits?: boolean;
  isUserReceivedBackPaymentWagePensionBenefits?: boolean;
  isUserAlreadyCounseling?: boolean;
}

export interface UpdateBenefitDto {
  type?: BenefitType;
  amount?: number;
  explanation?: string;
  documents?: IFile[];
  uploadedDocuments?: ServerBase64[];
}

export interface UpdateSubmissionChildDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  isThereHousehold?: boolean;
  isThereMaintenance?: boolean;
  isThereCreditedToPAccount?: boolean;
  childDocuments?: IFile[];
  uploadedChildDocuments?: ServerBase64[];
  isBenefits?: boolean;
  supplement?: string;
  supplementDocuments?: IFile[];
  uploadedSupplementDocuments?: ServerBase64[];
}

export interface UpdateSubmissionDto {
  hasSpouse?: boolean;
  isThereSpouseHousehold?: boolean;
  isThereSpouseMaintenance?: boolean;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseBirthDate?: Date;
  children?: UpdateSubmissionChildDto[];
  marriageCertificateFiles?: IFile[];
  uploadedMarriageCertificateFiles?: ServerBase64[];

  ongoingBenefit?: {
    amount?: number;
    furtherPersonsNumber?: number;
    documents?: IFile[];
    uploadedDocuments?: ServerBase64[];
  };
  motherChildFoundation?: UpdateBenefitDto;
  backPaymentWagePensionBenefit?: UpdateBenefitDto;
  oneOffBenefits?: UpdateBenefitDto[];
  healthDamages?: UpdateBenefitDto[];
  backPaymentBenefits?: UpdateBenefitDto[];
  additionalBenefits?: UpdateBenefitDto[];
  recognizedBodyId?: string;

  isUserReceivedOngoingBenefit?: boolean;
  isUserReceivedOneOffBenefits?: boolean;
  isUserReceivedHealthDamages?: boolean;
  isUserReceivedBackPaymentBenefits?: boolean;
  isUserReceivedMotherChildFoundations?: boolean;
  isUserReceivedAdditionalBenefits?: boolean;
  isUserReceivedBackPaymentWagePensionBenefits?: boolean;
  isUserAlreadyCounseling?: boolean;
}

export interface CalculateAllowanceDto {
  hasSpouse: boolean;
  children: string;
  // isUserReceivedBenefits: boolean;
  monthlyBenefits: string;
  benefits: string;
}

export interface CalculateAllowance {
  basicMonthlyAllowance: number;
  oneOffAllowance: number;
}

export interface CalculateAllowanceResponse {
  data: CalculateAllowance;
}

export interface AdditionalDemandDocument {
  documentName: string;
  type: string;
}

export interface AdditionalDemand {
  id: string;
  section: string;
  additionalMessage?: string;
  documents: AdditionalDemandDocument[] | any;
  comment?: string;
}

export interface RequestedDocumentResponse {
  id: string;
  section: string;
  additionalMessage?: string;
  documents: ServerBase64[];
  comment?: string;
}

export interface RequestedDocumentDto {
  section: string;
  additionalMessage?: string;
  customerFiles: FileList[];
}

export interface ISubmissionChild {
  id?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  isThereHousehold?: boolean;
  isThereMaintenance?: boolean;
  isThereCreditedToPAccount?: boolean;
  childDocuments?: IFile[];
  isBenefits?: boolean;
  supplement?: string;
  supplementDocuments?: IFile[];
}

export interface IBenefit {
  type?: BenefitType;
  amount?: number;
  explanation?: string;
  documents?: IFile[];
}

export interface ISubmission {
  id: string;
  hasSpouse?: boolean;
  publicId: string;
  status: SubmissionStatus;
  personalSituation: string;
  isThereSpouseHousehold?: boolean;
  isThereSpouseMaintenance?: boolean;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseBirthDate?: Date;
  marriageCertificateFiles?: IFile[];
  children?: ISubmissionChild[];
  createdAt: string;
  updatedAt: string;
  additionalDemands?: AdditionalDemand[];
  messageToApplicantIn?: string;
  additionalMessageToApplicantIn?: string;
  messageToRequestedDocuments?: string;
  recognizedBody?: IRecognizedBody;

  isUserReceivedOngoingBenefit?: boolean;
  isUserReceivedOneOffBenefits?: boolean;
  isUserReceivedHealthDamages?: boolean;
  isUserReceivedBackPaymentBenefits?: boolean;
  isUserReceivedMotherChildFoundations?: boolean;
  isUserReceivedBackPaymentWagePensionBenefits?: boolean;
  isUserReceivedAdditionalBenefits?: boolean;
  isUserAlreadyCounseling?: boolean;

  ongoingBenefit?: {
    amount: string;
    furtherPersonsNumber: string;
    documents: IFile[];
  };
  oneOffBenefits?: IBenefit[];
  healthDamages?: IBenefit[];
  backPaymentBenefits?: IBenefit[];
  motherChildFoundation?: IBenefit;
  backPaymentWagePensionBenefit?: IBenefit;
  additionalBenefits?: IBenefit[];
  recognizedBodyId?: string;
}

export interface IChangeSubmissionStatusDto {
  status: SubmissionStatus;
}
