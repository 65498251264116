import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Package({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>package</title>
      <defs>
        <path
          d="M12.3351439,0.313408293 L20.3351437,4.31340822 C21.3546303,4.82000324 21.9995397,5.85999226 22.0001439,6.99893886 L22.0001439,16.5294695 C21.9995397,17.6678855 21.3546303,18.7078745 20.3373575,19.2133661 L12.3376439,23.2132228 C11.492592,23.6360871 10.4976958,23.6360871 9.65293029,23.2133661 L1.6501681,19.2119796 C0.631656712,18.698783 -0.00776890066,17.6524502 7.12844884e-05,16.5189389 L7.12844884e-05,6.99840822 C0.000748116486,5.85999226 0.645657511,4.82000324 1.66293029,4.31451167 L5.47230626,2.40942821 C5.52113436,2.3791535 5.57237626,2.35320661 5.62539103,2.33180119 L9.66514389,0.313408293 C10.5060928,-0.104469431 11.494195,-0.104469431 12.3351439,0.313408293 Z M2.00007128,7.377 L2.00009532,16.5258981 C1.997474,16.906055 2.21061587,17.2548326 2.54735748,17.4245117 L10.0000713,21.151 L10.0000713,11.377 L2.00007128,7.377 Z M20.0000713,7.377 L12.0000713,11.377 L12.0000713,21.145 L19.445144,17.4234082 C19.7849729,17.2545432 19.9999427,16.9078802 20.000144,16.5289389 L20.0000713,7.377 Z M6.00507128,4.379 L3.24107128,5.761 L11.0001439,9.64090487 L13.7630713,8.259 L6.00507128,4.379 Z M10.5573575,2.10336605 L8.24107128,3.261 L16.0000713,7.14 L18.7580713,5.761 L11.4442258,2.10401382 C11.1641086,1.96517702 10.8351567,1.96532769 10.5573575,2.10336605 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-994, -458)">
          <g id="package" transform="translate(994.9999, 458)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-0.9999, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
