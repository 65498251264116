import {
  CreateSubmissionDto,
  IBenefit,
  ISubmission,
  ISubmissionChild,
  RequestedDocumentResponse,
  UpdateSubmissionDto,
} from '@app-types';
import { asyncMap, convertFileListToArray, convertFileToServerBase64, deleteFalsyValuesExceptFalse } from '@utils';
import dayjs from 'dayjs';
import { Document } from './ApplicationReview/DocumentsRequested/DocumentsRequested';
import { BenefitFormValues, ChildFormValues, SubmissionCreationFormValues, SubmissionRadioValue } from './types';

export const convertSubmissionFormValuesToDto = async (
  formValues: SubmissionCreationFormValues,
): Promise<CreateSubmissionDto> => {
  const result: CreateSubmissionDto = {};

  if (formValues.hasSpouse !== undefined) {
    result.hasSpouse = formValues.hasSpouse === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedOngoingBenefit !== undefined) {
    result.isUserReceivedOngoingBenefit = formValues.isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedOneOffBenefits !== undefined) {
    result.isUserReceivedOneOffBenefits = formValues.isUserReceivedOneOffBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedHealthDamages !== undefined) {
    result.isUserReceivedHealthDamages = formValues.isUserReceivedHealthDamages === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedBackPaymentBenefits !== undefined) {
    result.isUserReceivedBackPaymentBenefits = formValues.isUserReceivedBackPaymentBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedMotherChildFoundations !== undefined) {
    result.isUserReceivedMotherChildFoundations =
      formValues.isUserReceivedMotherChildFoundations === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedAdditionalBenefits !== undefined) {
    result.isUserReceivedAdditionalBenefits = formValues.isUserReceivedAdditionalBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedBackPaymentWagePensionBenefits !== undefined) {
    result.isUserReceivedBackPaymentWagePensionBenefits =
      formValues.isUserReceivedBackPaymentWagePensionBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserAlreadyCounseling !== undefined) {
    result.isUserAlreadyCounseling = formValues.isUserAlreadyCounseling === SubmissionRadioValue.Yes;
  }

  if (result.hasSpouse) {
    const uploadedMarriageCertificateFiles = await asyncMap(
      convertFileListToArray(formValues.uploadedMarriageCertificateFiles),
      convertFileToServerBase64,
    );

    result.isThereSpouseHousehold = formValues.isThereSpouseHousehold === SubmissionRadioValue.Yes;
    if (!result.isThereSpouseHousehold) {
      result.isThereSpouseMaintenance = formValues.isThereSpouseMaintenance === SubmissionRadioValue.Yes;
    }
    result.spouseFirstName = formValues.spouseFirstName;
    result.spouseLastName = formValues.spouseLastName;
    result.spouseBirthDate = formValues.spouseBirthDate as unknown as Date;
    result.marriageCertificateFiles = formValues.marriageCertificateFiles;
    result.uploadedMarriageCertificateFiles = uploadedMarriageCertificateFiles;
  }

  if (result.isUserReceivedOngoingBenefit) {
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(formValues.ongoingBenefit?.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.ongoingBenefit = {
      amount: Number(formValues.ongoingBenefit?.amount),
      documents: formValues.ongoingBenefit?.documents,
      uploadedDocuments,
    };

    let personCount = 0;

    formValues.children.forEach((child) => {
      if (child.isThereHousehold || child.isThereMaintenance) {
        personCount += 1;
      }
    });

    if (result.hasSpouse) {
      personCount += 1;
    }

    if (personCount < 5) {
      result.ongoingBenefit.furtherPersonsNumber = Number(formValues.ongoingBenefit?.furtherPersonsNumber);
    }
  }

  if (result.isUserReceivedOneOffBenefits) {
    result.oneOffBenefits = await asyncMap(formValues.oneOffBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedHealthDamages) {
    result.healthDamages = await asyncMap(formValues.healthDamages || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedBackPaymentBenefits) {
    result.backPaymentBenefits = await asyncMap(formValues.backPaymentBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedAdditionalBenefits) {
    result.additionalBenefits = await asyncMap(formValues.additionalBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedMotherChildFoundations && !!formValues.motherChildFoundations?.length) {
    const motherChildFoundation = formValues.motherChildFoundations[0];
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(motherChildFoundation.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.motherChildFoundation = {
      ...motherChildFoundation,
      amount: Number(motherChildFoundation.amount),
      uploadedDocuments,
    };
  }

  if (result.isUserReceivedBackPaymentWagePensionBenefits && !!formValues.backPaymentWagePensionBenefits?.length) {
    const backPaymentWagePensionBenefit = formValues.backPaymentWagePensionBenefits[0];
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(backPaymentWagePensionBenefit.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.backPaymentWagePensionBenefit = {
      ...backPaymentWagePensionBenefit,
      amount: Number(backPaymentWagePensionBenefit.amount),
      uploadedDocuments,
    };
  }

  if (result.isUserAlreadyCounseling && !!formValues.recognizedBodyId) {
    result.recognizedBodyId = formValues.recognizedBodyId;
  }

  result.children = await asyncMap(formValues.children, async (child) => {
    const uploadedChildDocuments = await asyncMap(
      convertFileListToArray(child.uploadedChildDocuments),
      convertFileToServerBase64,
    );
    const uploadedSupplementDocuments = await asyncMap(
      convertFileListToArray(child.uploadedSupplementDocuments),
      convertFileToServerBase64,
    );
    const birthDate = child.birthDate as unknown as Date;

    return { ...child, uploadedChildDocuments, uploadedSupplementDocuments, birthDate };
  });

  return result;
};

export const convertSubmissionFormValuesToUpdateDto = async (
  formValues: SubmissionCreationFormValues,
): Promise<UpdateSubmissionDto> => {
  const result: UpdateSubmissionDto = {};

  if (formValues.hasSpouse !== undefined) {
    result.hasSpouse = formValues.hasSpouse === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedOngoingBenefit !== undefined) {
    result.isUserReceivedOngoingBenefit = formValues.isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedOneOffBenefits !== undefined) {
    result.isUserReceivedOneOffBenefits = formValues.isUserReceivedOneOffBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedHealthDamages !== undefined) {
    result.isUserReceivedHealthDamages = formValues.isUserReceivedHealthDamages === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedBackPaymentBenefits !== undefined) {
    result.isUserReceivedBackPaymentBenefits = formValues.isUserReceivedBackPaymentBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedMotherChildFoundations !== undefined) {
    result.isUserReceivedMotherChildFoundations =
      formValues.isUserReceivedMotherChildFoundations === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedAdditionalBenefits !== undefined) {
    result.isUserReceivedAdditionalBenefits = formValues.isUserReceivedAdditionalBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserReceivedBackPaymentWagePensionBenefits !== undefined) {
    result.isUserReceivedBackPaymentWagePensionBenefits =
      formValues.isUserReceivedBackPaymentWagePensionBenefits === SubmissionRadioValue.Yes;
  }
  if (formValues.isUserAlreadyCounseling !== undefined) {
    result.isUserAlreadyCounseling = formValues.isUserAlreadyCounseling === SubmissionRadioValue.Yes;
  }

  if (result.hasSpouse) {
    const uploadedMarriageCertificateFiles = await asyncMap(
      convertFileListToArray(formValues.uploadedMarriageCertificateFiles),
      convertFileToServerBase64,
    );

    result.isThereSpouseHousehold = formValues.isThereSpouseHousehold === SubmissionRadioValue.Yes;
    if (!result.isThereSpouseHousehold) {
      result.isThereSpouseMaintenance = formValues.isThereSpouseMaintenance === SubmissionRadioValue.Yes;
    }
    result.spouseFirstName = formValues.spouseFirstName;
    result.spouseLastName = formValues.spouseLastName;
    result.spouseBirthDate = formValues.spouseBirthDate as unknown as Date;
    result.marriageCertificateFiles = formValues.marriageCertificateFiles;
    result.uploadedMarriageCertificateFiles = uploadedMarriageCertificateFiles;
  }

  if (result.isUserReceivedOngoingBenefit) {
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(formValues.ongoingBenefit?.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.ongoingBenefit = {
      amount: Number(formValues.ongoingBenefit?.amount),
      uploadedDocuments,
      documents: formValues.ongoingBenefit?.documents,
    };

    let personCount = 0;

    formValues.children.forEach((child) => {
      if (child.isThereHousehold || child.isThereMaintenance) {
        personCount += 1;
      }
    });

    if (result.hasSpouse) {
      personCount += 1;
    }

    if (personCount < 5) {
      result.ongoingBenefit.furtherPersonsNumber = Number(formValues.ongoingBenefit?.furtherPersonsNumber);
    }
  }

  if (result.isUserReceivedOneOffBenefits) {
    result.oneOffBenefits = await asyncMap(formValues.oneOffBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedHealthDamages) {
    result.healthDamages = await asyncMap(formValues.healthDamages || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedBackPaymentBenefits) {
    result.backPaymentBenefits = await asyncMap(formValues.backPaymentBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedAdditionalBenefits) {
    result.additionalBenefits = await asyncMap(formValues.additionalBenefits || [], async (benefit) => {
      const uploadedDocuments = await asyncMap(convertFileListToArray(benefit.uploadedDocuments), convertFileToServerBase64);

      return { ...benefit, amount: Number(benefit.amount), uploadedDocuments };
    });
  }

  if (result.isUserReceivedMotherChildFoundations && !!formValues.motherChildFoundations?.length) {
    const motherChildFoundation = formValues.motherChildFoundations[0];
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(motherChildFoundation.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.motherChildFoundation = {
      ...motherChildFoundation,
      amount: Number(motherChildFoundation.amount),
      uploadedDocuments,
    };
  }

  if (result.isUserReceivedBackPaymentWagePensionBenefits && !!formValues.backPaymentWagePensionBenefits?.length) {
    const backPaymentWagePensionBenefit = formValues.backPaymentWagePensionBenefits[0];
    const uploadedDocuments = await asyncMap(
      convertFileListToArray(backPaymentWagePensionBenefit.uploadedDocuments),
      convertFileToServerBase64,
    );

    result.backPaymentWagePensionBenefit = {
      ...backPaymentWagePensionBenefit,
      amount: Number(backPaymentWagePensionBenefit.amount),
      uploadedDocuments,
    };
  }

  if (result.isUserAlreadyCounseling && !!formValues.recognizedBodyId) {
    result.recognizedBodyId = formValues.recognizedBodyId;
  }

  result.children = await asyncMap(formValues.children, async (child) => {
    const uploadedChildDocuments = await asyncMap(
      convertFileListToArray(child.uploadedChildDocuments),
      convertFileToServerBase64,
    );
    const uploadedSupplementDocuments = await asyncMap(
      convertFileListToArray(child.uploadedSupplementDocuments),
      convertFileToServerBase64,
    );
    const birthDate = child.birthDate as unknown as Date;

    return { ...child, uploadedChildDocuments, uploadedSupplementDocuments, birthDate };
  });

  return result;
};

export const convertRequestedDocumentsFormValuesToDto = async (
  formValues: Document[],
): Promise<RequestedDocumentResponse[]> => {
  const result: RequestedDocumentResponse[] = await asyncMap(formValues, async (formValue) => {
    const convertedDocuments = await asyncMap(
      convertFileListToArray(formValue.customerFiles as FileList),
      convertFileToServerBase64,
    );

    return {
      id: formValue.id,
      section: formValue.section,
      additionalMessage: formValue.comment,
      documents: convertedDocuments,
    };
  });

  return result;
};

export const convertSubmissionToFormValues = (submission: ISubmission): SubmissionCreationFormValues => {
  const data = deleteFalsyValuesExceptFalse(submission) as ISubmission;
  const convertBooleanToSubmissionRadioValue = (value?: boolean): SubmissionRadioValue | undefined => {
    switch (value) {
      case true:
        return SubmissionRadioValue.Yes;
      case false:
        return SubmissionRadioValue.No;
      default:
        return value;
    }
  };

  const getBenefitsFormValues = (benefits?: IBenefit[]): BenefitFormValues[] => {
    if (!benefits) {
      return [];
    }
    const result = benefits.map((benefit) => ({
      type: benefit.type,
      amount: benefit?.amount?.toString(),
      explanation: benefit.explanation,
      documents: benefit.documents,
    }));

    return result;
  };

  const getChildrenFormValues = (children?: ISubmissionChild[]): ChildFormValues[] => {
    if (!children) {
      return [];
    }
    return children.map((child) => ({
      id: child?.id,
      firstName: child.firstName,
      lastName: child.lastName,
      birthDate: child.birthDate ? dayjs(child.birthDate) : undefined,
      isBenefits: child.isBenefits,
      isThereCreditedToPAccount: child.isThereCreditedToPAccount,
      isThereHousehold: child.isThereHousehold,
      supplement: child.supplement,
      childDocuments: child.childDocuments,
      supplementDocuments: child.supplementDocuments,
    }));
  };

  return {
    hasSpouse: convertBooleanToSubmissionRadioValue(data.hasSpouse),
    isThereSpouseHousehold: convertBooleanToSubmissionRadioValue(data.isThereSpouseHousehold),
    isThereSpouseMaintenance: convertBooleanToSubmissionRadioValue(data.isThereSpouseMaintenance),
    spouseFirstName: data.spouseFirstName || '',
    spouseLastName: data.spouseLastName || '',
    spouseBirthDate: data.spouseBirthDate ? dayjs(data.spouseBirthDate) : undefined,
    marriageCertificateFiles: data.marriageCertificateFiles,
    children: getChildrenFormValues(data.children),

    isUserReceivedOngoingBenefit: convertBooleanToSubmissionRadioValue(data.isUserReceivedOngoingBenefit),
    isUserReceivedOneOffBenefits: convertBooleanToSubmissionRadioValue(data.isUserReceivedOneOffBenefits),
    isUserReceivedHealthDamages: convertBooleanToSubmissionRadioValue(data.isUserReceivedHealthDamages),
    isUserReceivedBackPaymentBenefits: convertBooleanToSubmissionRadioValue(data.isUserReceivedBackPaymentBenefits),
    isUserReceivedMotherChildFoundations: convertBooleanToSubmissionRadioValue(data.isUserReceivedMotherChildFoundations),
    isUserReceivedBackPaymentWagePensionBenefits: convertBooleanToSubmissionRadioValue(
      data.isUserReceivedBackPaymentWagePensionBenefits,
    ),
    isUserReceivedAdditionalBenefits: convertBooleanToSubmissionRadioValue(data.isUserReceivedAdditionalBenefits),
    isUserAlreadyCounseling: convertBooleanToSubmissionRadioValue(data.isUserAlreadyCounseling),

    ongoingBenefit: {
      amount: data.ongoingBenefit?.amount,
      furtherPersonsNumber: data.ongoingBenefit?.furtherPersonsNumber,
      documents: data.ongoingBenefit?.documents,
    },
    oneOffBenefits: getBenefitsFormValues(data.oneOffBenefits),
    healthDamages: getBenefitsFormValues(data.healthDamages),
    backPaymentBenefits: getBenefitsFormValues(data.backPaymentBenefits),
    motherChildFoundations: getBenefitsFormValues(data.motherChildFoundation ? [data.motherChildFoundation] : []),
    backPaymentWagePensionBenefits: getBenefitsFormValues(
      data.backPaymentWagePensionBenefit ? [data.backPaymentWagePensionBenefit] : [],
    ),
    additionalBenefits: getBenefitsFormValues(data.additionalBenefits),
    recognizedBodyId: data.recognizedBody?.id,
  };
};

// todo: add types
export const convertSubmissionFormValuesToExpectedAllowance = (formValues: SubmissionCreationFormValues): any => {
  const result: any = {
    hasSpouse: formValues.hasSpouse === SubmissionRadioValue.Yes,
    isUserReceivedOngoingBenefit: formValues.isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes,
    isUserReceivedOneOffBenefits: formValues.isUserReceivedOneOffBenefits === SubmissionRadioValue.Yes,
    isUserReceivedHealthDamages: formValues.isUserReceivedHealthDamages === SubmissionRadioValue.Yes,
    isUserReceivedBackPaymentBenefits: formValues.isUserReceivedBackPaymentBenefits === SubmissionRadioValue.Yes,
    isUserReceivedMotherChildFoundations: formValues.isUserReceivedMotherChildFoundations === SubmissionRadioValue.Yes,
    isUserReceivedAdditionalBenefits: formValues.isUserReceivedAdditionalBenefits === SubmissionRadioValue.Yes,
    isUserReceivedBackPaymentWagePensionBenefits:
      formValues.isUserReceivedBackPaymentWagePensionBenefits === SubmissionRadioValue.Yes,
  };

  if (result.hasSpouse) {
    result.isThereSpouseHousehold = formValues.isThereSpouseHousehold === SubmissionRadioValue.Yes;
    if (!result.isThereSpouseHousehold) {
      result.isThereSpouseMaintenance = formValues.isThereSpouseMaintenance === SubmissionRadioValue.Yes;
    }
  }

  if (result.isUserReceivedOngoingBenefit) {
    result.ongoingBenefit = {
      amount: Number(formValues.ongoingBenefit?.amount),
    };

    let personCount = 0;

    formValues.children.forEach((child) => {
      if (child.isThereHousehold || child.isThereMaintenance) {
        personCount += 1;
      }
    });

    if (result.hasSpouse) {
      personCount += 1;
    }

    if (personCount < 5) {
      result.ongoingBenefit.furtherPersonsNumber = Number(formValues.ongoingBenefit?.furtherPersonsNumber);
    }
  }

  const getBenefitData = (benefit: any): any => {
    const benefitResult: any = {
      amount: Number(benefit.amount),
    };

    if (benefit.type) {
      benefitResult.type = benefit.type;
    }

    return benefitResult;
  };

  if (result.isUserReceivedOneOffBenefits) {
    result.oneOffBenefits = (formValues.oneOffBenefits || []).map(getBenefitData);
  }

  if (result.isUserReceivedHealthDamages) {
    result.healthDamages = (formValues.healthDamages || []).map(getBenefitData);
  }

  if (result.isUserReceivedBackPaymentBenefits) {
    result.backPaymentBenefits = (formValues.backPaymentBenefits || []).map(getBenefitData);
  }

  if (result.isUserReceivedAdditionalBenefits) {
    result.additionalBenefits = (formValues.additionalBenefits || []).map(getBenefitData);
  }

  if (result.isUserReceivedMotherChildFoundations && !!formValues.motherChildFoundations?.length) {
    const motherChildFoundation = formValues.motherChildFoundations[0];

    result.motherChildFoundation = {
      amount: Number(motherChildFoundation.amount),
    };
  }

  if (result.isUserReceivedBackPaymentWagePensionBenefits && !!formValues.backPaymentWagePensionBenefits?.length) {
    const backPaymentWagePensionBenefit = formValues.backPaymentWagePensionBenefits[0];

    result.backPaymentWagePensionBenefit = {
      amount: Number(backPaymentWagePensionBenefit.amount),
    };
  }

  result.children = (formValues.children || []).map((child) => {
    return { ...child };
  });

  return result;
};
