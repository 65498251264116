import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(() => ({
  width: '100%',

  '& .MuiFormControl-root': {
    width: '100%',
  },
}));
