import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Mail({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>mail</title>
      <defs>
        <path
          d="M19,0 C20.6522847,0 22,1.34771525 22,3 L22,15 C22,16.6522847 20.6522847,18 19,18 L3,18 C1.34771525,18 0,16.6522847 0,15 L0,3 C0,1.34771525 1.34771525,0 3,0 L19,0 Z M19.9999141,4.92 L11.5734623,10.8192319 C11.2291422,11.060256 10.7708578,11.060256 10.4265377,10.8192319 L1.99991414,4.921 L2,15 C2,15.5477153 2.45228475,16 3,16 L19,16 C19.5477153,16 20,15.5477153 20,15 L19.9999141,4.92 Z M19,2 L3,2 C2.61200142,2 2.27189207,2.22696691 2.10652962,2.55404305 L11,8.77934444 L19.8934704,2.55404305 C19.7281079,2.22696691 19.3879986,2 19,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-691, -254)">
          <g id="mail" transform="translate(692.0001, 257)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1.0001, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
