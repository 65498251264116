import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(37),
  paddingTop: theme.spacing(4),
  margin: '0 auto',
  boxSizing: 'border-box',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const Wrapper = styled(Grid)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 80px)',
}));
