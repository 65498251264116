import { ChevronDownIcon, ChevronUpIcon, UserIcon } from '@icons';
import { Button, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface MobileMenuButtonProps {
  open: boolean;
  onClick: () => void;
}

const MobileMenuButton: FC<MobileMenuButtonProps> = ({ open, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      startIcon={<UserIcon style={{ width: '20px', height: '20px' }} color={theme.palette.grey['200']} />}
      endIcon={
        open ? (
          <ChevronUpIcon style={{ width: '20px', height: '20px', marginLeft: '-10px' }} color={theme.palette.grey['200']} />
        ) : (
          <ChevronDownIcon
            style={{ width: '20px', height: '20px', marginLeft: '-10px' }}
            color={theme.palette.grey['200']}
          />
        )
      }
      onClick={onClick}
    />
  );
};

export default MobileMenuButton;
