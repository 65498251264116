import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { submissionsApi } from '@api';
import { CreateSubmissionDto } from '@app-types';

const useCreateSubmission = (options?: UseMutationOptions<unknown, unknown, CreateSubmissionDto>) => {
  return useMutation({
    ...options,
    mutationFn: submissionsApi.createSubmission,
  });
};

export default useCreateSubmission;
