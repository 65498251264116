import { Button, Checkbox } from '@components';
import { StepperContext } from '@components/Stepper';
import { LocalStorageKey } from '@constants';
import { useIsMobile } from '@hooks';
import { AlertTriangleIcon } from '@icons';
import { Divider, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

enum CheckboxIndex {
  MaintenanceObligations = 1,
  ChildBenefit = 2,
  SocialBenefits = 3,
  AdditionalPayments = 4,
}

const ConditionsCheckStep: React.FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { goToNextStep } = useContext(StepperContext);
  const { formatMessage } = useIntl();
  const [checkedItems, setCheckedItems] = useState({
    [CheckboxIndex.MaintenanceObligations]: false,
    [CheckboxIndex.ChildBenefit]: false,
    [CheckboxIndex.SocialBenefits]: false,
    [CheckboxIndex.AdditionalPayments]: false,
  });

  const handleCheckboxChange = (index: CheckboxIndex) => () => {
    setCheckedItems({ ...checkedItems, [index]: !checkedItems[index] });
  };

  const isAtLeastOneChecked = Object.values(checkedItems).some((item) => item);

  const handleSubmit = () => {
    localStorage.setItem(
      LocalStorageKey.EligibilityCheck,
      JSON.stringify(
        Object.fromEntries(
          Object.entries(checkedItems).map(([key, value]) => [`is${CheckboxIndex[parseFloat(key)]}`, value]),
        ),
      ),
    );

    goToNextStep();
  };

  return (
    <Stack alignItems="center">
      <Typography
        variant="h2"
        textAlign="center"
        sx={{ marginTop: theme.spacing(isMobile ? 10 : 18), marginBottom: theme.spacing(1.5) }}
      >
        <FormattedMessage id="eligibilityCheck.step.6.title" />
      </Typography>
      <Stack direction="row" alignItems="center" textAlign="center" gap={1} mb={3}>
        <AlertTriangleIcon color={theme.palette.primary.dark} style={{ flexShrink: 0 }} />
        <Typography variant="h4">
          <FormattedMessage id="eligibilityCheck.step.6.subtitle" />
        </Typography>
      </Stack>
      <Divider variant="fullWidth" sx={{ width: '100%', marginBottom: theme.spacing(3.5) }} />
      <Stack gap={1.5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedItems[CheckboxIndex.MaintenanceObligations]}
              onChange={handleCheckboxChange(CheckboxIndex.MaintenanceObligations)}
            />
          }
          label={formatMessage({ id: 'eligibilityCheck.step.6.checkbox.1' })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedItems[CheckboxIndex.ChildBenefit]}
              onChange={handleCheckboxChange(CheckboxIndex.ChildBenefit)}
            />
          }
          label={formatMessage({ id: 'eligibilityCheck.step.6.checkbox.2' })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedItems[CheckboxIndex.SocialBenefits]}
              onChange={handleCheckboxChange(CheckboxIndex.SocialBenefits)}
            />
          }
          label={formatMessage({ id: 'eligibilityCheck.step.6.checkbox.3' })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedItems[CheckboxIndex.AdditionalPayments]}
              onChange={handleCheckboxChange(CheckboxIndex.AdditionalPayments)}
            />
          }
          label={formatMessage({ id: 'eligibilityCheck.step.6.checkbox.4' })}
        />
      </Stack>
      <Divider variant="fullWidth" sx={{ width: '100%', marginTop: theme.spacing(3.5), marginBottom: theme.spacing(4) }} />
      <Button variant="contained" onClick={handleSubmit} disabled={!isAtLeastOneChecked}>
        <FormattedMessage id="common.next" />
      </Button>
    </Stack>
  );
};

export default ConditionsCheckStep;
