import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Briefcase({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>briefcase</title>
      <defs>
        <path
          d="M3,20 C1.34314575,20 0,18.6568542 0,17 L0,7 C0,5.34314575 1.34314575,4 3,4 L6,4 L6,3 C6,1.40231912 7.24891996,0.0963391206 8.82372721,0.00509269341 L9,0 L13,0 C14.6568542,0 16,1.34314575 16,3 L16,4 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,17 C22,18.6568542 20.6568542,20 19,20 L3,20 Z M6,6 L3,6 C2.44771525,6 2,6.44771525 2,7 L2,17 C2,17.5522847 2.44771525,18 3,18 L6,18 L6,6 Z M14,6 L8,6 L8,18 L14,18 L14,6 Z M19,6 L16,6 L16,18 L19,18 C19.5522847,18 20,17.5522847 20,17 L20,7 C20,6.44771525 19.5522847,6 19,6 Z M13,2 L9,2 C8.44771525,2 8,2.44771525 8,3 L8,4 L14,4 L14,3 C14,2.48716416 13.6139598,2.06449284 13.1166211,2.00672773 L13,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-845, -253)">
          <g id="briefcase" transform="translate(846, 255)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
