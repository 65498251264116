import { Button } from '@components';
import { useAppSettings, useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '@utils';
import Warning from '../Warning';

interface IIncomeHardStopStepProps {
  onBack: () => void;
}

const IncomeHardStopStep: React.FC<IIncomeHardStopStepProps> = ({ onBack }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { formatNumber } = useIntl();
  const { data: appSettingsData } = useAppSettings();

  return (
    <Warning>
      <Typography variant="overline" color="error.main" sx={{ marginBottom: theme.spacing(0.5) }}>
        <FormattedMessage id="eligibilityCheck.step.8.overline" />
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: theme.spacing(2), whiteSpace: 'pre-wrap' }}>
        <FormattedMessage
          id="eligibilityCheck.step.8.description"
          values={{ basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0) }}
        />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={onBack}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button variant="contained" onClick={() => navigate('/login')} color="error">
          <FormattedMessage id="common.leave" />
        </Button>
      </Stack>
    </Warning>
  );
};

export default IncomeHardStopStep;
