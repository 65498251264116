import { submissionsApi } from '@api';
import { IChangeSubmissionStatusDto } from '@app-types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

const useChangeSubmissionStatusByCustomer = (
  id: string | undefined,
  options?: UseMutationOptions<unknown, unknown, IChangeSubmissionStatusDto>,
) => {
  return useMutation({
    ...options,
    mutationFn: (data) => {
      return submissionsApi.changeSubmissionStatusByCustomer({ id, data });
    },
  });
};

export default useChangeSubmissionStatusByCustomer;
