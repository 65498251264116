import { IRecognizedBodySelectOption, RecognizedBodyStatus } from '@app-types/recognizedBody';
import { RadioGroupController, SelectController } from '@components';
import { useRecognizedBodies } from '@hooks';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SubmissionCreationFormValues, SubmissionRadioValue } from '../../types';
import { BorderedBlock } from '../styles';

const CounselingRecognizedBody = () => {
  const { formatMessage } = useIntl();

  const { data } = useRecognizedBodies({
    status: RecognizedBodyStatus.Active,
  });

  const { control, watch } = useFormContext<SubmissionCreationFormValues>();
  const isUserAlreadyCounseling = watch('isUserAlreadyCounseling');

  const recognizedBodyOptions: IRecognizedBodySelectOption[] =
    data?.map((recognizedBody) => ({ value: recognizedBody.id, label: recognizedBody.name })) || [];

  return (
    <BorderedBlock mt="32px" pb="27px">
      {/* TODO: add heading in the future */}
      <RadioGroupController
        row
        name="isUserAlreadyCounseling"
        control={control}
        label={formatMessage({ id: 'submissionCreation.ongoingService.isUserAlreadyCounseling' })}
        options={[
          { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
          { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
        ]}
      />
      {isUserAlreadyCounseling === SubmissionRadioValue.Yes && (
        <Stack pl="2%" pr="2%" mt="32px">
          <SelectController
            name="recognizedBodyId"
            control={control}
            label={formatMessage({ id: 'submissionCreation.ongoingService.chooseRecognizedBody' })}
            options={recognizedBodyOptions}
          />
        </Stack>
      )}
    </BorderedBlock>
  );
};

export default CounselingRecognizedBody;
