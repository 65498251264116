import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function File({
  width = '24px',
  height = '24px',
  color = '#000000',
  ...props
}: Record<string, unknown> & { color?: string; width?: string; height?: string }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>file</title>
      <defs>
        <path
          d="M10,0 C10.0425909,0 10.0845598,0.00266261633 10.1257495,0.00783047292 L10,0 C10.0528361,0 10.1052411,0.00418141391 10.1567725,0.0123609877 C10.1883933,0.0172035954 10.2193064,0.0236158178 10.249662,0.0314174347 C10.2598053,0.0342797004 10.2698902,0.0370498784 10.2799252,0.0399762024 C10.3109399,0.0487322365 10.3413507,0.059226165 10.3710585,0.0711039637 C10.3800191,0.0749695695 10.3890567,0.0787341995 10.3980377,0.0826308894 C10.4262995,0.0946381533 10.4536613,0.108067907 10.4802859,0.122674361 C10.4906553,0.128558233 10.5012587,0.134613306 10.5117542,0.140864684 C10.5399066,0.15749759 10.5670269,0.175549461 10.5931738,0.194844517 C10.5995817,0.199634907 10.6064603,0.204834375 10.6132762,0.210126665 C10.6177282,0.213538884 10.6216003,0.216599882 10.625449,0.219688767 L10.7071068,0.292893219 L17.7071068,7.29289322 C17.7364445,7.32223095 17.7639678,7.3533831 17.7894939,7.38616693 L17.7071068,7.29289322 C17.7429509,7.32873733 17.7757929,7.36702236 17.8054709,7.40735764 C17.8244505,7.43297305 17.8425024,7.46009338 17.8592238,7.48809993 C17.8653867,7.49874131 17.8714418,7.50934473 17.8772982,7.52005033 C17.8919321,7.54633874 17.9053618,7.57370048 17.9175449,7.60172936 C17.9212658,7.61094326 17.9250304,7.61998091 17.9286618,7.62907226 C17.9407738,7.65864932 17.9512678,7.68906007 17.9602981,7.72009403 C17.9629501,7.73010978 17.9657203,7.7401947 17.9683328,7.75032594 C17.9763842,7.78069364 17.9827964,7.81160666 17.9877474,7.84300527 C17.9892866,7.85360724 17.990772,7.86402246 17.9920936,7.8744695 C17.9973374,7.91544017 18,7.95740914 18,8 L18,19 C18,20.6568542 16.6568542,22 15,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,3 C0,1.34314575 1.34314575,0 3,0 Z M9,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,19 C2,19.5522847 2.44771525,20 3,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,9 L10,9 C9.48716416,9 9.06449284,8.61395981 9.00672773,8.11662113 L9,8 L9,2 Z M14.586,7 L11,3.415 L11,7 L14.586,7 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-543, -526)">
          <g id="file" transform="translate(546, 527)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
