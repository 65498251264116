import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Hash({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>hash</title>
      <defs>
        <path
          d="M12.9937807,1.23917971e-05 L13.1104315,0.00620445791 C13.6593384,0.0671941057 14.0548734,0.561612889 13.9938837,1.11051972 L13.45,6 L17,6.00008819 C17.5128358,6.00008819 17.9355072,6.38612838 17.9932723,6.88346707 L18,7.00008819 C18,7.55237294 17.5522847,8.00008819 17,8.00008819 L13.227,8 L12.783,12 L17,12.0000882 C17.5128358,12.0000882 17.9355072,12.3861284 17.9932723,12.8834671 L18,13.0000882 C18,13.5523729 17.5522847,14.0000882 17,14.0000882 L12.561,14 L11.9938837,19.1105197 C11.9372505,19.6202189 11.5068952,19.9976741 11.0062193,20.000164 L10.8895685,19.9939719 C10.3406616,19.9329823 9.94512662,19.4385635 10.0061163,18.8896567 L10.549,14 L6.561,14 L5.99388373,19.1105197 C5.93725049,19.6202189 5.50689519,19.9976741 5.00621927,20.000164 L4.88956847,19.9939719 C4.34066164,19.9329823 3.94512662,19.4385635 4.00611627,18.8896567 L4.549,14 L1,14.0000882 C0.487164161,14.0000882 0.0644928393,13.614048 0.00672773133,13.1167093 L0,13.0000882 C0,12.4478034 0.44771525,12.0000882 1,12.0000882 L4.772,12 L5.216,8 L1,8.00008819 C0.487164161,8.00008819 0.0644928393,7.614048 0.00672773133,7.11670932 L0,7.00008819 C0,6.44780344 0.44771525,6.00008819 1,6.00008819 L5.438,6 L6.00611627,0.889656667 C6.06274951,0.379957467 6.49310481,0.00250232316 6.99378073,1.23917971e-05 L7.11043153,0.00620445791 C7.65933836,0.0671941057 8.05487338,0.561612889 7.99388373,1.11051972 L7.45,6 L11.438,6 L12.0061163,0.889656667 C12.0627495,0.379957467 12.4931048,0.00250232316 12.9937807,1.23917971e-05 Z M11.216,8 L7.227,8 L6.783,12 L10.772,12 L11.216,8 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1356, -669)">
          <g id="hash" transform="translate(1359, 671)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
