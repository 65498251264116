import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IStyledProps } from './Select';

export const StyledMenu = styled(Menu)<IStyledProps>(({ theme, width }) => ({
  '.MuiMenu-paper': {
    width,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
    border: `solid 1px ${theme.palette.background.default}`,
    borderTop: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    '.MuiMenu-list': {
      padding: 0,
    },

    '& .MuiPaper-root': {
      width,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '15px 0 17px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'normal',
  margin: '4px 5px',
  color: theme.palette.grey['100'],
  borderRadius: '4px',

  '&:hover': {
    color: theme.palette.secondary.dark,
    background: theme.palette.grey['500'],
  },

  '&.Mui-selected': {
    color: theme.palette.secondary.dark,
    background: theme.palette.grey['600'],

    '&:hover': {
      color: theme.palette.secondary.dark,
      background: theme.palette.grey['500'],
    },
  },
}));
