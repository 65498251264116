import { withAuth } from '@hoc';
import { Container, Grid } from '@mui/material';
import React from 'react';
import { StyledSettingsBox, StyledSettingsMainInfoForm } from './styles';

const SettingsMainInfo: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ pt: '24px' }}>
      <Grid direction="column" xs={12}>
        <StyledSettingsBox>
          <StyledSettingsMainInfoForm />
        </StyledSettingsBox>
      </Grid>
    </Container>
  );
};

export default withAuth(SettingsMainInfo);
