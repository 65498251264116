import { authApi } from '@api';
import { Logo } from '@components';
import MenuButton from '@components/layouts/PageLayout/MenuButton';
import MobileMenuButton from '@components/layouts/PageLayout/MobileMenuButton';
import MobileMenuList from '@components/layouts/PageLayout/MobileMenuList';
import eventBus from '@eventBus';
import { LogOutIcon } from '@icons';
import { Collapse, Stack, useMediaQuery, useTheme } from '@mui/material';
import { selectCustomer } from '@store/customer/selectors';
import React, { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import policyPdfFile from '@assets/privacyPolicy.pdf';
import { downloadLocalFile } from '@utils';
import SaveSubmissionButton from './SaveSubmissionButton';
import { Body, Header, Navigation, Wrapper } from './styles';

export interface IPageLayoutProps {
  navigationItems?: React.ReactElement[];
  isFullScreen?: boolean;
  disableLogout?: boolean;
  bodyHeight?: string;
}

const PageLayout: React.FC<PropsWithChildren<IPageLayoutProps>> = ({
  children,
  navigationItems,
  isFullScreen = false,
  disableLogout = false,
  bodyHeight,
}) => {
  const account = useSelector(selectCustomer);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const menuOptions = [
    {
      label: formatMessage({ id: 'common.settings' }),
      onClick: () => navigate('/settings'),
    },
    {
      label: formatMessage({ id: 'header.menu.privacyPolicy' }),
      onClick: () => {
        window.open(policyPdfFile, "_blank");
        downloadLocalFile(policyPdfFile, 'privacy-policy.pdf')
      },
    },
    {
      label: formatMessage({ id: 'common.logout' }),
      icon: <LogOutIcon color={theme.palette.grey['200']} style={{ marginLeft: '8px', width: '16px', height: '16px' }} />,
      onClick: authApi.logout,
    },
  ];

  const handleSaveButtonClick = () => {
    eventBus.saveSubmissionInFillingProcess.broadcast();
  };

  useLayoutEffect(() => {
    const unsubscribe = eventBus.changeSavingSubmitionStatus.subscribe((status) => {
      switch (status) {
        case 'pending':
          setIsLoading(true);
          break;
        case 'show':
          setIsVisible(true);
          break;
        case 'hide':
          setIsVisible(false);
          break;
        default:
          setIsLoading(false);
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Wrapper isFullScreen={isFullScreen}>
      <Header>
        <Logo />
        <Navigation>{navigationItems?.map((item, i) => React.cloneElement(item, { key: i }))}</Navigation>
        <Stack direction="row" alignItems="center" gap={isMobile ? '10px' : '20px'}>
          {!!account && isVisible && (
            <SaveSubmissionButton onClick={handleSaveButtonClick} loading={isLoading}>
              <FormattedMessage id={isMobile ? 'header.mobile.saveSubmission' : 'header.saveSubmission'} />
            </SaveSubmissionButton>
          )}
          {!!account && !disableLogout && !isMobile && <MenuButton menuOptions={menuOptions} />}
          {!!account && !disableLogout && isMobile && (
            <MobileMenuButton open={open} onClick={() => setOpen((prev) => !prev)} />
          )}
        </Stack>
      </Header>
      <TransitionGroup>
        {isMobile && open && (
          <Collapse>
            <MobileMenuList menuOptions={menuOptions} />
          </Collapse>
        )}
      </TransitionGroup>
      <Body bodyHeight={bodyHeight}>{children}</Body>
    </Wrapper>
  );
};

export default PageLayout;
