import { BenefitType, IFile } from '@app-types';
import { Dayjs } from 'dayjs';

export enum SubmissionRadioValue {
  Yes = 'yes',
  No = 'no',
}

export interface BenefitFormValues {
  type?: BenefitType;
  amount?: string;
  explanation?: string;
  documents?: IFile[];
  uploadedDocuments?: FileList;
}

export interface ChildFormValues {
  id?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Dayjs;
  isThereHousehold?: boolean;
  isThereMaintenance?: boolean;
  isThereCreditedToPAccount?: boolean;
  childDocuments?: IFile[];
  uploadedChildDocuments?: FileList;
  isBenefits?: boolean;
  supplement?: string;
  supplementDocuments?: IFile[];
  uploadedSupplementDocuments?: FileList;
}

export interface SubmissionCreationFormValues {
  hasSpouse?: SubmissionRadioValue;
  isThereSpouseHousehold?: SubmissionRadioValue;
  isThereSpouseMaintenance?: SubmissionRadioValue;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseBirthDate?: Dayjs;
  marriageCertificateFiles?: IFile[];
  uploadedMarriageCertificateFiles?: FileList;
  children: ChildFormValues[];

  isUserReceivedOngoingBenefit?: SubmissionRadioValue;
  isUserReceivedOneOffBenefits?: SubmissionRadioValue;
  isUserReceivedHealthDamages?: SubmissionRadioValue;
  isUserReceivedBackPaymentBenefits?: SubmissionRadioValue;
  isUserReceivedMotherChildFoundations?: SubmissionRadioValue;
  isUserReceivedBackPaymentWagePensionBenefits?: SubmissionRadioValue;
  isUserReceivedAdditionalBenefits?: SubmissionRadioValue;
  isUserAlreadyCounseling?: SubmissionRadioValue;

  ongoingBenefit?: {
    amount?: string;
    furtherPersonsNumber?: string;
    documents?: IFile[];
    uploadedDocuments?: FileList;
  };
  oneOffBenefits?: BenefitFormValues[];
  healthDamages?: BenefitFormValues[];
  backPaymentBenefits?: BenefitFormValues[];
  motherChildFoundations?: BenefitFormValues[];
  backPaymentWagePensionBenefits?: BenefitFormValues[];
  additionalBenefits?: BenefitFormValues[];
  recognizedBodyId?: string;
}
