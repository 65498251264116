import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SignUpCarouselSlideBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
}));

export const TipBox = styled(Box)(({ theme }) => ({
  maxWidth: '80px',
  background: `${theme.palette.primary.light}45`,
  padding: '4px 14px 3px',
  borderRadius: '20px',
}));

export const TipBoxTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  color: theme.palette.primary.main,
}));

