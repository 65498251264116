import { AlertTriangleIcon } from '@icons';
import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SubmissionCreationFormValues, SubmissionRadioValue } from '../../types';
import { Content, Wrapper } from './styles';

const InfoBox: React.FC = () => {
  const theme = useTheme();

  const { watch } = useFormContext<SubmissionCreationFormValues>();

  const hasSpouse = watch('hasSpouse');
  const children = watch('children');
  const ongoingBenefit = watch('ongoingBenefit');
  const isUserReceivedOngoingBenefit = watch('isUserReceivedOngoingBenefit');

  let personCount = 0;

  children?.forEach((child) => {
    if (child.isThereHousehold || child.isThereMaintenance) {
      personCount += 1;
    }
  });

  if (hasSpouse === SubmissionRadioValue.Yes) {
    personCount += 1;
  }

  if (personCount < 5 && isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes) {
    personCount += Number(ongoingBenefit?.furtherPersonsNumber || 0);
  }

  if (personCount <= 5) {
    return null;
  }

  return (
    <Wrapper>
      <Content>
        <AlertTriangleIcon color={theme.palette.primary.dark} style={{ flexShrink: 0 }} />
        <Typography>
          <FormattedMessage id="submissionCreation.overFivePersons.infoBox" />
        </Typography>
      </Content>
    </Wrapper>
  );
};

export default InfoBox;
