export enum RecognizedBodyStatus {
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
}

export interface IRecognizedBody {
  id: string;
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  status: RecognizedBodyStatus;
  responsibleForZIPs: string;
  approvedURLs: string[];
  recognizingAuthority: string;
  dateOfRecognitionDecision: Date;
  recognitionFileNumber: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IRecognizedBodyParams {
  status: RecognizedBodyStatus;
}

export interface IRecognizedBodySelectOption {
  value: string;
  label: string;
}
