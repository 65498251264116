import Button from '@components/Button';
import { styled } from '@mui/material/styles';
import { IStyledProps } from './SaveSubmissionButton';

export const StyledIconButton = styled(Button)<IStyledProps>(({ theme, backgroundColor, textColor, border }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: textColor || theme.palette.primary.main,
  border: border || `1px solid ${theme.palette.primary.main}`,
  background: backgroundColor || `${theme.palette.primary.main}10`,
  paddingLeft: '16px',
  paddingRight: '24px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '8px',
    paddingBottom: '8px',
    height: 'auto',
  },
}));
