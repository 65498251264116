import { IconButton } from '@components';
import { useIsMobile } from '@hooks';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Grid)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 80px)',
}));

export const StyledIconButton = styled(IconButton)(() => {
  const isMobile = useIsMobile();

  return {
    maxWidth: isMobile ? '100%' : '265px',
    padding: 0,
  };
});
