import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components';
import { ViewportBreakpoint } from '@constants';
import { FileIcon } from '@icons';
import { useTheme } from '@mui/material';
import { VisuallyHiddenInput } from './styles';

interface IInputControllerProps {
  name: string;
  control: Control<any>;
  accept?: string;
  title?: string;
  showIcon?: boolean;
  additionalOnChange?: (files: FileList) => void;
}

const FileInputController: FC<IInputControllerProps> = ({
  name,
  control,
  accept,
  title = 'formController.fileInput.uploadFile',
  showIcon = true,
  additionalOnChange,
}) => {
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Button
          startIcon={showIcon && <FileIcon color={theme.palette.secondary.main} />}
          component="label"
          variant="contained"
          sx={{
            [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
              width: '100%',
            },
          }}
        >
          <FormattedMessage id={title} />
          <VisuallyHiddenInput
            type="file"
            accept={accept || 'image/jpeg,image/png,application/pdf'}
            onChange={(e) => {
              const mergedFiles: FileList = Array.from(value || []).concat(
                Array.from(e.target.files || []),
              ) as unknown as FileList;
              onChange(mergedFiles);
              additionalOnChange?.(mergedFiles || []);
            }}
            multiple
          />
        </Button>
      )}
    />
  );
};

export default FileInputController;
