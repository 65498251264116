import { Language } from '@app-types';
import locales from '@locales';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { persistor, store } from '@store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import defaultTheme from '@themes';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useRouter } from './hooks';

import './global.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const router = useRouter();
  const [loggedOut, setLoggedOut] = useState(false);

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem('expireTime');

    if (expireTime !== null) {
      const expireTimeNumber = parseInt(expireTime, 10);

      if (!Number.isNaN(expireTimeNumber) && expireTimeNumber < Date.now() && !loggedOut) {
        localStorage.removeItem('accessToken');
        setLoggedOut(true);
        window.location.href = '/login';
      }
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 3600000;

    localStorage.setItem('expireTime', expireTime.toString());
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const interval = setInterval(() => {
        checkForInactivity();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [loggedOut]);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <IntlProvider messages={locales[Language.De]} locale={Language.De} defaultLocale={Language.De}>
          <RouterProvider router={router} />
          <GlobalStyles styles={{ body: { margin: 0 } }} />
        </IntlProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
