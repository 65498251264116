import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Flag({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>flag</title>
      <defs>
        <path
          d="M0,2 C0,1.73478351 0.10535684,1.4804296 0.292893219,1.29289322 C0.512470125,1.07331631 0.90748509,0.809973002 1.50360932,0.571523309 C2.40642491,0.210397075 3.56360882,0 5,0 C6.41656341,0 7.38262989,0.276018993 9.37139068,1.07152331 C11.1326299,1.77601899 11.9165634,2 13,2 C14.1886088,2 15.0939249,1.83539707 15.7536093,1.57152331 C16.0949851,1.434973 16.2624701,1.32331631 16.2928932,1.29289322 C16.9228581,0.662928358 18,1.10909515 18,2 L18,14 C18,14.2652165 17.8946432,14.5195704 17.7071068,14.7071068 C17.4875299,14.9266837 17.0925149,15.190027 16.4963907,15.4284767 C15.5935751,15.7896029 14.4363912,16 13,16 C11.5834366,16 10.6173701,15.723981 8.62860932,14.9284767 C6.86737011,14.223981 6.08343659,14 5,14 C3.81139118,14 2.90607509,14.1646029 2.24639068,14.4284767 C2.15049861,14.4668335 2.06832724,14.5032261 1.99906031,14.5364058 L2,21 C2,21.5522847 1.55228475,22 1,22 C0.44771525,22 0,21.5522847 0,21 L0,2 Z M5,2 C3.81139118,2 2.90607509,2.16460293 2.24639068,2.42847669 C2.15093187,2.46666021 2.06907007,2.50289723 2,2.53595588 L2,12.396437 C2.81910645,12.1433287 3.8144511,12 5,12 C6.41656341,12 7.38262989,12.276019 9.37139068,13.0715233 C11.1326299,13.776019 11.9165634,14 13,14 C14.1886088,14 15.0939249,13.8353971 15.7536093,13.5715233 C15.8490681,13.5333398 15.9309299,13.4971028 16,13.4640441 L16,3.603563 C15.1808935,3.85667129 14.1855489,4 13,4 C11.5834366,4 10.6173701,3.72398101 8.62860932,2.92847669 C6.86737011,2.22398101 6.08343659,2 5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-770, -598)">
          <g id="flag" transform="translate(773, 599)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
