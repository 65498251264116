import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Power({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>power</title>
      <defs>
        <path
          d="M2.92777778,4.93300868 C3.3182383,4.54242063 3.95140327,4.54231725 4.34199132,4.93277778 C4.73257937,5.3232383 4.73268275,5.95640327 4.34222222,6.34699132 C1.21877844,9.47145521 1.21932974,14.5363001 4.34345363,17.660084 C7.46757752,20.7838679 12.5324225,20.7838679 15.6565464,17.660084 C18.7806703,14.5363001 18.7812216,9.47145521 15.6577778,6.34699132 C15.2673173,5.95640327 15.2674206,5.3232383 15.6580087,4.93277778 C16.0485967,4.54231725 16.6817617,4.54242063 17.0722222,4.93300868 C20.976527,8.83858854 20.9758378,15.1696447 17.070683,19.0743745 C13.1655281,22.9791043 6.8344719,22.9791043 2.92931703,19.0743745 C-0.975837829,15.1696447 -0.97652695,8.83858854 2.92777778,4.93300868 Z M10.005,1.77635684e-15 C10.5572848,1.77635684e-15 11.005,0.44771525 11.005,1 L11.005,11 C11.005,11.5522847 10.5572848,12 10.005,12 C9.45271525,12 9.005,11.5522847 9.005,11 L9.005,1 C9.005,0.44771525 9.45271525,1.77635684e-15 10.005,1.77635684e-15 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-768, -668)">
          <g id="power" transform="translate(770, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
