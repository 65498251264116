import { submissionsApi } from '@api';
import { CalculateAllowanceDto, CalculateAllowanceResponse } from '@app-types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

const useCalculateAllowance = (
  data: CalculateAllowanceDto,
  options?: UseQueryOptions<unknown, unknown, CalculateAllowanceResponse>,
) => {
  return useQuery({
    ...options,
    queryKey: ['calculateAllowance', data],
    queryFn: () => submissionsApi.calculateAllowance(data),
    refetchOnMount: 'always',
  });
};

export default useCalculateAllowance;
