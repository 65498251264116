import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function CheckSquare({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>check-square</title>
      <defs>
        <path
          d="M14,0 C14.5522847,0 15,0.44771525 15,1 C15,1.55228475 14.5522847,2 14,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.5522847 2.44771525,18 3,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,10 C18,9.44771525 18.4477153,9 19,9 C19.5522847,9 20,9.44771525 20,10 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 Z M20.2928932,0.292893219 C20.6834175,-0.0976310729 21.3165825,-0.0976310729 21.7071068,0.292893219 C22.0976311,0.683417511 22.0976311,1.31658249 21.7071068,1.70710678 L10.7071068,12.7071068 C10.3165825,13.0976311 9.68341751,13.0976311 9.29289322,12.7071068 L6.29289322,9.70710678 C5.90236893,9.31658249 5.90236893,8.68341751 6.29289322,8.29289322 C6.68341751,7.90236893 7.31658249,7.90236893 7.70710678,8.29289322 L10,10.5857864 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1222, -253)">
          <g id="check-square" transform="translate(1223, 255)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
