import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignUpAlert from '../SignUpAlert';

export const StyledSignUpAlert = styled(SignUpAlert)(() => ({
  margin: '0 auto',
}));

export const StyledGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 80px)',
}));

export const Title = styled('h2')(() => ({
  margin: 0,
  fontSize: '32px',
  fontWeight: 500,
  letterSpacing: '0.25px',
}));

export const Subtitle = styled(Typography)(() => ({
  margin: '0 auto',
  paddingTop: '16px',
  maxWidth: '455px',
}));

export const CodeInputsWithCheckMarkWrapper = styled('div')(() => ({
  margin: '40px 0 15px 56px',
  display: 'grid',
  gridTemplateColumns: 'minmax(50px, 250px) 56px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CodeInputsBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
}));

export const SendEmailText = styled('span')(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));
