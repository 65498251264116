import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Cpu({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cpu</title>
      <defs>
        <path
          d="M15,0 C15.5522847,0 16,0.44771525 16,1 L16,3 L18,3 C19.6568542,3 21,4.34314575 21,6 L21,8 L23,8 C23.5128358,8 23.9355072,8.38604019 23.9932723,8.88337887 L24,9 C24,9.55228475 23.5522847,10 23,10 L21,10 L21,13 L23,13 C23.5128358,13 23.9355072,13.3860402 23.9932723,13.8833789 L24,14 C24,14.5522847 23.5522847,15 23,15 L21,15 L21,18 C21,19.6568542 19.6568542,21 18,21 L16,21 L16,23 C16,23.5128358 15.6139598,23.9355072 15.1166211,23.9932723 L15,24 C14.4477153,24 14,23.5522847 14,23 L14,21 L10,21 L10,23 C10,23.5128358 9.61395981,23.9355072 9.11662113,23.9932723 L9,24 C8.44771525,24 8,23.5522847 8,23 L8,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,15 L1,15 C0.487164161,15 0.0644928393,14.6139598 0.00672773133,14.1166211 L0,14 C0,13.4477153 0.44771525,13 1,13 L3,13 L3,10 L1,10 C0.487164161,10 0.0644928393,9.61395981 0.00672773133,9.11662113 L0,9 C0,8.44771525 0.44771525,8 1,8 L3,8 L3,6 C3,4.34314575 4.34314575,3 6,3 L8,3 L8,1 C8,0.487164161 8.38604019,0.0644928393 8.88337887,0.00672773133 L9,0 C9.55228475,0 10,0.44771525 10,1 L10,3 L14,3 L14,1 C14,0.487164161 14.3860402,0.0644928393 14.8833789,0.00672773133 Z M18,5 L6,5 C5.44771525,5 5,5.44771525 5,6 L5,18 C5,18.5522847 5.44771525,19 6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 L19,6 C19,5.44771525 18.5522847,5 18,5 Z M15,8 C15.5522847,8 16,8.44771525 16,9 L16,15 C16,15.5522847 15.5522847,16 15,16 L9,16 C8.44771525,16 8,15.5522847 8,15 L8,9 C8,8.44771525 8.44771525,8 9,8 L15,8 Z M14,10 L10,10 L10,14 L14,14 L14,10 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-540, -458)">
          <g id="cpu" transform="translate(540, 458)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <rect id="Rectangle" x={0} y={0} width={24} height={24} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
