import { ViewportBreakpoint } from '@constants';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SettingsMainInfoForm from './components/SettingsMainInfoForm';

export const TitleBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
}));

export const StyledSettingsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    margin: 0,
  },
}));

export const StyledSettingsMainInfoForm = styled(SettingsMainInfoForm)(() => ({
  marginTop: '35px',
  maxWidth: '780px',
  width: '100%',
}));
