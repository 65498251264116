import IconButton from '@components/IconButton';
import { useIsMobile } from '@hooks';
import { FormControl, MenuItemProps, SelectChangeEvent, SelectProps } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { StyledMenu, StyledMenuItem } from './styles';

export type SelectOption = {
  label: string;
  value: string;
} & MenuItemProps;

export interface ISelectProps {
  label?: string;
  value?: string | null;
  options: SelectOption[];
  onChange?: (event: SelectChangeEvent, child?: ReactNode) => void;
  className?: string;
}

export interface IStyledProps {
  width?: string | number;
}

const Select: FC<ISelectProps & SelectProps> = ({ options, label, onChange, value, className }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);

  const isMobile = useIsMobile();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    const scrollSize = isMobile ? 12 : 9;

    let listSize = 0;

    if (buttonRef?.current?.offsetWidth !== null && buttonRef?.current?.offsetWidth !== undefined) {
      listSize = buttonRef.current.offsetWidth + scrollSize;
    } else {
      listSize = 200;
    }

    setButtonWidth(listSize);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (selectedValue: string | null) => {
    setAnchorEl(null);

    if (onChange) {
      const selectedOption = options.find((option) => option.value === selectedValue);
      onChange({ target: { value: selectedValue } } as SelectChangeEvent, selectedOption?.label);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl className={className}>
      <div>
        <IconButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          open={open}
          showRightIcon
          ref={buttonRef}
        >
          {value ? options.find((option) => option.value === value)?.label : label}
        </IconButton>
        <StyledMenu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
          width={buttonWidth}
        >
          {options.map(({ value: optionValue, label: optionLabel, ...optionProps }) => {
            return (
              <StyledMenuItem
                key={optionValue}
                value={optionValue}
                selected={optionValue === value}
                onClick={() => handleMenuItemClick(optionValue)}
                {...optionProps}
              >
                {optionLabel}
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      </div>
    </FormControl>
  );
};

export default Select;
