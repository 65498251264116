import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function AlertOctagon({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>alert-octagon</title>
      <defs>
        <path
          d="M15.14,0 C15.4052165,0 15.6595704,0.10535684 15.8471068,0.292893219 L21.7071068,6.15289322 C21.8946432,6.3404296 22,6.59478351 22,6.86 L22,15.14 C22,15.4052165 21.8946432,15.6595704 21.7071068,15.8471068 L15.8471068,21.7071068 C15.6595704,21.8946432 15.4052165,22 15.14,22 L6.86,22 C6.59478351,22 6.3404296,21.8946432 6.15289322,21.7071068 L0.292893219,15.8471068 C0.10535684,15.6595704 0,15.4052165 0,15.14 L0,6.86 C0,6.59478351 0.10535684,6.3404296 0.292893219,6.15289322 L6.15289322,0.292893219 C6.3404296,0.10535684 6.59478351,0 6.86,0 L15.14,0 Z M14.7257864,2 L7.27421356,2 L2,7.27421356 L2,14.7257864 L7.27421356,20 L14.7257864,20 L20,14.7257864 L20,7.27421356 L14.7257864,2 Z M10.2928932,13.2928932 C10.6834175,12.9023689 11.3165825,12.9023689 11.7071068,13.2928932 C12.0976311,13.6834175 12.0976311,14.3165825 11.7071068,14.7071068 C11.3165825,15.0976311 10.6834175,15.0976311 10.2928932,14.7071068 C9.90236893,14.3165825 9.90236893,13.6834175 10.2928932,13.2928932 Z M11,6 C11.5522847,6 12,6.44771525 12,7 L12,11 C12,11.5522847 11.5522847,12 11,12 C10.4477153,12 10,11.5522847 10,11 L10,7 C10,6.44771525 10.4477153,6 11,6 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-614, -526)">
          <g id="alert-octagon" transform="translate(615, 527)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
