import { CheckedIcon, DefaultIcon, StyledRadio } from '@components/Radio/styles';
import { RadioProps } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

export interface IRadioProps {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  name?: string;
}

const Radio: React.FC<IRadioProps & RadioProps> = ({ name, checked, onChange, disabled, ...rest }) => {
  const [isHoveringRadio, setIsHoveringRadio] = useState(false);

  const handleRadioMouseEnter = () => {
    setIsHoveringRadio(true);
  };

  const handleRadioMouseLeave = () => {
    setIsHoveringRadio(false);
  };

  return (
    <StyledRadio
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      icon={<DefaultIcon isHovering={isHoveringRadio} />}
      checkedIcon={<CheckedIcon />}
      onMouseEnter={handleRadioMouseEnter}
      onMouseLeave={handleRadioMouseLeave}
      {...rest}
    />
  );
};

export default Radio;
