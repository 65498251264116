import { customersApi } from '@api';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

const useDeleteCustomer = (
  options?: UseMutationOptions<unknown, unknown, string | undefined>,
) => {
  return useMutation({
    ...options,
    mutationFn: customersApi.deleteCustomer,
  });
};

export default useDeleteCustomer;
