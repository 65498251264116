import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ChevronsLeft({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chevrons-left</title>
      <defs>
        <path
          d="M5.29289322,0.292893219 C5.68341751,-0.0976310729 6.31658249,-0.0976310729 6.70710678,0.292893219 C7.09763107,0.683417511 7.09763107,1.31658249 6.70710678,1.70710678 L2.41421356,6 L6.70710678,10.2928932 C7.09763107,10.6834175 7.09763107,11.3165825 6.70710678,11.7071068 C6.31658249,12.0976311 5.68341751,12.0976311 5.29289322,11.7071068 L0.292893219,6.70710678 C-0.0976310729,6.31658249 -0.0976310729,5.68341751 0.292893219,5.29289322 Z M12.2928932,0.292893219 C12.6834175,-0.0976310729 13.3165825,-0.0976310729 13.7071068,0.292893219 C14.0976311,0.683417511 14.0976311,1.31658249 13.7071068,1.70710678 L9.41421356,6 L13.7071068,10.2928932 C14.0976311,10.6834175 14.0976311,11.3165825 13.7071068,11.7071068 C13.3165825,12.0976311 12.6834175,12.0976311 12.2928932,11.7071068 L7.29289322,6.70710678 C6.90236893,6.31658249 6.90236893,5.68341751 7.29289322,5.29289322 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-849, -396)">
          <g id="chevrons-left" transform="translate(854, 402)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -6)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
