import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function SkipForward({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>skip-forward</title>
      <defs>
        <path
          d="M2,3.08250215 L2,14.9212525 L9.39921894,9.0018773 L2,3.08250215 Z M1.62469505,0.221008492 L11.624695,8.22100849 C12.1251017,8.62133377 12.1251017,9.38242083 11.624695,9.78274611 L1.62469505,17.7827461 C0.969931703,18.3065568 0,17.8403835 0,17.0018773 L0,1.0018773 C0,0.163371093 0.969931703,-0.302802184 1.62469505,0.221008492 Z M14,2.0018773 L14,16.0018773 C14,16.5541621 14.4477153,17.0018773 15,17.0018773 C15.5522847,17.0018773 16,16.5541621 16,16.0018773 L16,2.0018773 C16,1.44959255 15.5522847,1.0018773 15,1.0018773 C14.4477153,1.0018773 14,1.44959255 14,2.0018773 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1649, -393)">
          <g id="skip-forward" transform="translate(1653, 396)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
