import { FormatNumberOptions } from "react-intl";

const formatCurrency = (
  formatNumber: (value: number | bigint, opts?: FormatNumberOptions | undefined) => string,
  value: number | bigint,
) => {
  return formatNumber(value, { style: "currency", currency: 'EUR', currencyDisplay: 'name', trailingZeroDisplay: "stripIfInteger" });
};
  
export default formatCurrency;
  