import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function LifeBuoy({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>life-buoy</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M8.24255219,15.1715651 L5.38282434,18.0323074 C6.92241642,19.2636898 8.87522098,20 11,20 C13.124779,20 15.0775836,19.2636898 16.6171757,18.0323074 L13.7574478,15.1715651 C12.9670032,15.6951083 12.0191072,16 11,16 C9.98089278,16 9.03299682,15.6951083 8.24255219,15.1715651 Z M18.0323074,5.38282434 L15.1715651,8.24255219 C15.6951083,9.03299682 16,9.98089278 16,11 C16,12.0191072 15.6951083,12.9670032 15.1715651,13.7574478 L18.0323074,16.6171757 C19.2636898,15.0775836 20,13.124779 20,11 C20,8.87522098 19.2636898,6.92241642 18.0323074,5.38282434 Z M2,11 C2,13.124779 2.7363102,15.0775836 3.96769257,16.6171757 L6.8284349,13.7574478 C6.30489166,12.9670032 6,12.0191072 6,11 C6,9.98089278 6.30489166,9.03299682 6.8284349,8.24255219 L3.96769257,5.38282434 C2.7363102,6.92241642 2,8.87522098 2,11 Z M11,8 C9.34314575,8 8,9.34314575 8,11 C8,12.6568542 9.34314575,14 11,14 C12.6568542,14 14,12.6568542 14,11 C14,9.34314575 12.6568542,8 11,8 Z M11,2 C8.87522098,2 6.92241642,2.7363102 5.38282434,3.96769257 L8.24255219,6.8284349 C9.03299682,6.30489166 9.98089278,6 11,6 C12.0191072,6 12.9670032,6.30489166 13.7574478,6.8284349 L16.6171757,3.96769257 C15.0775836,2.7363102 13.124779,2 11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-614, -320)">
          <g id="life-buoy" transform="translate(615, 321)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
