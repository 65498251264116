import { submissionsApi } from '@api';
import { AdditionalDemand } from '@app-types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useSendRequestedDocuments = (
  id: string | undefined,
  options?: UseMutationOptions<unknown, unknown, AdditionalDemand[]>,
) => {
  return useMutation({
    ...options,
    mutationFn: (data) => {
      return submissionsApi.sendRequestedDocuments({ id, data });
    },
  });
};

export default useSendRequestedDocuments;
