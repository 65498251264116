import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Camera({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>camera</title>
      <defs>
        <path
          d="M15,0 C15.3343532,0 15.6465845,0.167101135 15.8320503,0.445299804 L17.5351838,3 L21,3 C22.6568542,3 24,4.34314575 24,6 L24,17 C24,18.6568542 22.6568542,20 21,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,6 C0,4.34314575 1.34314575,3 3,3 L6.46481624,3 L8.16794971,0.445299804 C8.35341548,0.167101135 8.66564681,0 9,0 L15,0 Z M14.4648162,2 L9.53518376,2 L7.83205029,4.5547002 C7.64658452,4.83289886 7.33435319,5 7,5 L3,5 C2.44771525,5 2,5.44771525 2,6 L2,17 C2,17.5522847 2.44771525,18 3,18 L21,18 C21.5522847,18 22,17.5522847 22,17 L22,6 C22,5.44771525 21.5522847,5 21,5 L17,5 C16.6656468,5 16.3534155,4.83289886 16.1679497,4.5547002 L14.4648162,2 Z M12,6 C14.7614237,6 17,8.23857625 17,11 C17,13.7614237 14.7614237,16 12,16 C9.23857625,16 7,13.7614237 7,11 C7,8.23857625 9.23857625,6 12,6 Z M12,8 C10.3431458,8 9,9.34314575 9,11 C9,12.6568542 10.3431458,14 12,14 C13.6568542,14 15,12.6568542 15,11 C15,9.34314575 13.6568542,8 12,8 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1353, -527)">
          <g id="camera" transform="translate(1353, 529)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
