import ministryLogo from '@assets/ministry-logo.jpg';
import { Button } from '@components';
import { useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Warning from '../Warning';

interface IProps {
  onBack: () => void;
}

const WrongRegionStep: React.FC<IProps> = ({ onBack }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  return (
    <Warning>
      <Typography variant="overline" color="error.main" sx={{ marginBottom: theme.spacing(0.5) }}>
        <FormattedMessage id="eligibilityCheck.step.5.overline" />
      </Typography>
      <Typography variant="h2" sx={{ marginBottom: theme.spacing(2) }}>
        <FormattedMessage id="eligibilityCheck.step.5.wrongRegion.title" />
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
        <FormattedMessage id="eligibilityCheck.step.5.wrongRegion.description.1" />
      </Typography>
      <img
        src={ministryLogo}
        alt={formatMessage({ id: 'common.ministryName' })}
        width={isMobile ? theme.spacing(36) : theme.spacing(49)}
      />
      <Typography
        variant="body1"
        sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2), '& strong': { fontWeight: '600' } }}
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: 'eligibilityCheck.step.5.wrongRegion.description.2' }),
        }}
      />
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={onBack}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button variant="contained" onClick={() => navigate('/login')} color="error">
          <FormattedMessage id="common.leave" />
        </Button>
      </Stack>
    </Warning>
  );
};

export default WrongRegionStep;
