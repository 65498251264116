import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Bluetooth({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>bluetooth</title>
      <defs>
        <path
          d="M7.5,20.5877912 L10.5857864,17.5020048 L7.5,14.4162183 L7.5,20.5877912 Z M10.5857864,6.50200475 L7.5,3.41621832 L7.5,9.58779119 L10.5857864,6.50200475 Z M7.5,9.58779119 L7.5,14.4162183 L5.08578644,12.0020048 L7.5,9.58779119 Z M1.70710678,18.2091115 C1.31658249,18.5996358 0.683417511,18.5996358 0.292893219,18.2091115 C-0.0976310729,17.8185872 -0.0976310729,17.1854223 0.292893219,16.794898 L5.08578644,12.0020048 L0.292893219,7.20911154 C-0.0976310729,6.81858724 -0.0976310729,6.18542227 0.292893219,5.79489797 C0.683417511,5.40437368 1.31658249,5.40437368 1.70710678,5.79489797 L12.7071068,16.794898 C13.0976311,17.1854223 13.0976311,17.8185872 12.7071068,18.2091115 L7.20710678,23.7091115 C6.57714192,24.3390764 5.5,23.8929096 5.5,23.0020048 L5.5,1.00200475 C5.5,0.111099904 6.57714192,-0.335066888 7.20710678,0.294897973 L12.7071068,5.79489797 C13.0976311,6.18542227 13.0976311,6.81858724 12.7071068,7.20911154 L1.70710678,18.2091115 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1509, -319)">
          <g id="bluetooth" transform="translate(1515, 319)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-6, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
