import { PageLayout } from '@components';
import { Flow } from '@components/Stepper/Stepper';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SettingsMainInfo from './components/SettingsMainInfo';
import SettingsPassword from './components/SettingsPassword';
import { StyledStepper } from './styles';

interface ISettingsProps {
  className?: string;
}

const Settings: React.FC<ISettingsProps> = ({ className }) => {
  const { formatMessage } = useIntl();

  return (
    <PageLayout>
      <Box maxWidth="777px" margin="48px auto 0">
        <Box px="20px">
          <Typography variant="h1" sx={{ fontSize: '32px' }}>
            <FormattedMessage id="common.settings" />
          </Typography>
          <StyledStepper
            steps={[
              {
                id: '1',
                title: formatMessage({ id: 'settings.mainInfo.step' }),
                content: <SettingsMainInfo />,
              },
              {
                id: '2',
                title: formatMessage({ id: 'settings.password.step' }),
                content: <SettingsPassword />,
              },
            ]}
            hideSteps={false}
            makeTabsClickable
            className={className}
            withPaddings={false}
            flow={Flow.settings}
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Settings;
