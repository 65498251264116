import { Button } from '@components';
import { StepperContext } from '@components/Stepper';
import { useIsMobile } from '@hooks';
import { AlertOctagonIcon } from '@icons';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const NoAccountStep: React.FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();
  const { goToNextStep } = useContext(StepperContext);

  const [hasAccount, setHasAccount] = useState(true);

  return hasAccount ? (
    <Stack alignItems="center" gap={4}>
      <Typography variant="h2" textAlign="center" sx={{ marginTop: theme.spacing(isMobile ? 19 : 37) }}>
        <FormattedMessage id="eligibilityCheck.step.2.1.title" />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={() => setHasAccount(false)}>
          <FormattedMessage id="common.no" />
        </Button>
        <Button variant="contained" onClick={goToNextStep}>
          <FormattedMessage id="common.yes" />
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Paper
      sx={{
        marginTop: theme.spacing(isMobile ? 12 : 26),
        paddingLeft: theme.spacing(isMobile ? 4 : 7),
        paddingTop: theme.spacing(isMobile ? 5 : 7),
        paddingRight: theme.spacing(isMobile ? 4 : 10),
        paddingBottom: theme.spacing(5),
        width: isMobile ? '90%' : '61%',
      }}
    >
      <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" gap={isMobile ? 4 : 6}>
        <AlertOctagonIcon
          width={theme.spacing(7)}
          height={theme.spacing(7)}
          color={theme.palette.primary.main}
          style={{ flexShrink: 0 }}
        />
        <Box>
          <Typography variant="overline" color="primary" sx={{ marginBottom: theme.spacing(0.5) }}>
            <FormattedMessage id="eligibilityCheck.step.2.overline" />
          </Typography>
          <Typography variant="h2" sx={{ marginBottom: theme.spacing(2) }}>
            <FormattedMessage id="eligibilityCheck.step.2.2.title" />
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: theme.spacing(4), '& strong': { fontWeight: '600' } }}
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'eligibilityCheck.step.2.description' }) }}
          />
          <Button variant="contained" onClick={goToNextStep} sx={isMobile ? { width: '100%' } : {}}>
            <FormattedMessage id="common.next" />
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
};

export default NoAccountStep;
