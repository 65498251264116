import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { submissionsInFillingProcessApi } from '@api';
import { UpdateSubmissionDto } from '@app-types';

interface Props {
  id: string;
  data: UpdateSubmissionDto;
}

const useUpdateSubmissionInFillingProcess = (options?: UseMutationOptions<unknown, unknown, Props>) => {
  return useMutation({
    ...options,
    mutationFn: (data: Props) => submissionsInFillingProcessApi.updateSubmissionInFillingProcess(data.id, data.data),
  });
};

export default useUpdateSubmissionInFillingProcess;
