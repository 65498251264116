import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function UserX({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>user-x</title>
      <defs>
        <path
          d="M12,12 C14.7614237,12 17,14.2385763 17,17 L17,19 C17,19.5522847 16.5522847,20 16,20 C15.4477153,20 15,19.5522847 15,19 L15,17 C15,15.3431458 13.6568542,14 12,14 L5,14 C3.34314575,14 2,15.3431458 2,17 L2,19 C2,19.5522847 1.55228475,20 1,20 C0.44771525,20 0,19.5522847 0,19 L0,17 C0,14.2385763 2.23857625,12 5,12 Z M17.2928932,5.29289322 C17.6834175,4.90236893 18.3165825,4.90236893 18.7071068,5.29289322 L20.5,7.085 L22.2928932,5.29289322 C22.6533772,4.93240926 23.2206082,4.90467972 23.6128994,5.20970461 L23.7071068,5.29289322 C24.0976311,5.68341751 24.0976311,6.31658249 23.7071068,6.70710678 L21.915,8.5 L23.7071068,10.2928932 C24.0675907,10.6533772 24.0953203,11.2206082 23.7902954,11.6128994 L23.7071068,11.7071068 C23.3165825,12.0976311 22.6834175,12.0976311 22.2928932,11.7071068 L20.5,9.915 L18.7071068,11.7071068 C18.3466228,12.0675907 17.7793918,12.0953203 17.3871006,11.7902954 L17.2928932,11.7071068 C16.9023689,11.3165825 16.9023689,10.6834175 17.2928932,10.2928932 L19.085,8.5 L17.2928932,6.70710678 C16.9324093,6.34662282 16.9046797,5.77939176 17.2097046,5.38710056 Z M8.5,0 C11.2614237,0 13.5,2.23857625 13.5,5 C13.5,7.76142375 11.2614237,10 8.5,10 C5.73857625,10 3.5,7.76142375 3.5,5 C3.5,2.23857625 5.73857625,0 8.5,0 Z M8.5,2 C6.84314575,2 5.5,3.34314575 5.5,5 C5.5,6.65685425 6.84314575,8 8.5,8 C10.1568542,8 11.5,6.65685425 11.5,5 C11.5,3.34314575 10.1568542,2 8.5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-993, -141)">
          <g id="user-x" transform="translate(993, 143)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
