import { PageLayout } from '@components';
import { Container, Grid } from '@mui/material';
import React from 'react';
import SignUpCarouselTips from './components/SignUpCarouselTips';
import { SignUpCarouselTipsWrapper } from './styles';

const SignUpTips: React.FC = () => {
  return (
    <PageLayout>
      <Container maxWidth="lg">
        <SignUpCarouselTipsWrapper container>
          <Grid item xs={12}>
            <SignUpCarouselTips />
          </Grid>
        </SignUpCarouselTipsWrapper>
      </Container>
    </PageLayout>
  );
};

export default SignUpTips;
