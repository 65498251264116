import { XIcon } from '@icons';
import { IconButton, useTheme } from '@mui/material';
import React, { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledSignUpAlert, StyledSignUpAlertCollapse } from './styles';

interface ISignUpAlertProps {
  email: string;
  openAlert: boolean;
  setOpenAlert: Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const SignUpAlert: React.FC<ISignUpAlertProps> = ({ email, openAlert, setOpenAlert, className, ...restProps }) => {
  const theme = useTheme();

  return (
    <StyledSignUpAlertCollapse in={openAlert} {...restProps}>
      <StyledSignUpAlert
        className={className}
        action={
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAlert(false);
            }}
          >
            <XIcon color={theme.palette.secondary.main} />
          </IconButton>
        }
      >
        <FormattedMessage id="signup.sendCode.sentEmail" values={{ email }} />
      </StyledSignUpAlert>
    </StyledSignUpAlertCollapse>
  );
};

export default SignUpAlert;
