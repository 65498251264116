import { Checkbox, FieldErrorMessage } from '@components';
import { ICheckboxProps } from '@components/Checkbox';
import { StyledCheckboxFormControlLabel } from '@components/formControllers/CheckboxController/styles';
import { ChangeEvent, FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

export interface IInputControllerProps {
  name: string;
  control: Control<FieldValues>;
  label: React.ReactNode;
  className?: string;
  additionalOnChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const CheckboxController: FC<IInputControllerProps & ICheckboxProps> = ({
  name,
  control,
  label,
  className,
  additionalOnChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <StyledCheckboxFormControlLabel
            className={className}
            control={
              <Checkbox
                checked={value || false}
                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  additionalOnChange?.(event, checked);
                  onChange?.(event, checked);
                }}
                {...rest}
              />
            }
            label={label}
          />
          {error && <FieldErrorMessage message={error.message} />}
        </>
      )}
    />
  );
};

export default CheckboxController;
