import { CreateCustomerDto } from '@app-types';
import dayjs from 'dayjs';

export const convertSignUpFormValuesToDto = (formValues: CreateCustomerDto): CreateCustomerDto => {
  return {
    email: formValues.email,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    dateOfBirth: dayjs(formValues.dateOfBirth).format('YYYY-MM-DD'),
    street: formValues.street,
    houseNumber: formValues.houseNumber,
    zipCode: formValues.zipCode,
    city: formValues.city,
    phone: formValues.phone,
    bank: formValues.bank,
    iban: formValues.iban,
    password: formValues.password,
  };
};
