import { submissionsApi } from '@api';
import { ISubmission } from '@app-types/submissions';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';
import isEqual from 'react-fast-compare';

export type SubmissionSliceState =
  | {
      status: 'INIT';
    }
  | { status: 'LOADING' }
  | { status: 'ERROR' }
  | { status: 'LOADED'; data: ISubmission };

export const getCurrentSubmission = createAsyncThunk('@submission/getCurrentSubmission', async () => {
  const res = await submissionsApi.getCurrentSubmission();
  return res.data;
});

const initialState: SubmissionSliceState = {
  status: 'INIT',
};

const submissionSlice = createSlice({
  name: 'submission',
  initialState: initialState as SubmissionSliceState,
  reducers: {
    clearSubmission: (state) => {
      state.status = 'INIT';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete state.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentSubmission.pending, (state) => {
      state.status = 'LOADING';
    });

    builder.addCase(getCurrentSubmission.fulfilled, (state, action) => {
      state.status = 'LOADED';
      if (state.status === 'LOADED' && !isEqual(state.data, action.payload)) {
        state.data = action.payload;
      }
    });

    builder.addCase(getCurrentSubmission.rejected, (state) => {
      state.status = 'ERROR';
    });
  },
});

export const { clearSubmission } = submissionSlice.actions;

export const selectCurrentSubmission = (state: RootState): ISubmission | null => {
  if (state.submission.status === 'LOADED') {
    return state.submission.data;
  }
  return null;
};

export default submissionSlice.reducer;
