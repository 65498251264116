import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function BellOff({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>bell-off</title>
      <defs>
        <path
          d="M1.70710678,0.292893219 L23.7071068,22.2928932 C24.0976311,22.6834175 24.0976311,23.3165825 23.7071068,23.7071068 C23.3165825,24.0976311 22.6834175,24.0976311 22.2928932,23.7071068 L16.585,18 L2,18 C0.712643678,18 0.668252081,16.1355529 1.86682521,16.0069704 L2,16 C3.1045695,16 4,15.1045695 4,14 L4,9.00126017 C3.99870048,7.96952709 4.19696435,6.94968623 4.58135074,5.99722603 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 Z M12.865,20.4982281 C13.1421209,20.0205018 13.7540456,19.8578791 14.2317719,20.135 C14.7094982,20.4121209 14.8721209,21.0240456 14.595,21.5017719 C14.0583034,22.4269784 13.0696029,22.9964563 12,22.9964563 C10.9303971,22.9964563 9.94169664,22.4269784 9.405,21.5017719 C9.12787905,21.0240456 9.29050183,20.4121209 9.76822814,20.135 C10.2459544,19.8578791 10.8578791,20.0205018 11.135,20.4982281 L11.2000436,20.5965394 C11.3874689,20.8464703 11.6830806,20.9964563 12,20.9964563 C12.3565343,20.9964563 12.6861011,20.8066303 12.865,20.4982281 Z M6.16731352,7.5842731 C6.05596137,8.04601757 5.99939696,8.52114918 6,9 L6,14 C6,14.7285743 5.80521143,15.4116533 5.46487122,16 L14.585,16 L6.16731352,7.5842731 Z M8.0685713,2.02908219 C10.546119,0.631087966 13.5796899,0.653930228 16.0359046,2.08907483 C18.4921192,3.52421943 20.0014529,6.1557562 20.000001,9 L20.000001,13 C20.000001,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18.0000001,8.99948924 C18.0010899,6.86592332 16.8690897,4.89227074 15.0269286,3.81591229 C13.1847676,2.73955384 10.9095895,2.72242214 9.0514287,3.77091781 C8.57043408,4.04232639 7.96049077,3.87242333 7.68908219,3.3914287 C7.41767361,2.91043408 7.58757667,2.30049077 8.0685713,2.02908219 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1646, -597)">
          <g id="bell-off" transform="translate(1646, 597)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <rect id="Rectangle" x={0} y={0} width={24} height={24} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
