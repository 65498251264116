import { AxiosResponse } from 'axios';
import axios from './axios';

interface IEligibilityCheckResponse {
  isEligibleByLocalities: boolean;
  isEligibleByResponsibleForZIPs: boolean;
}

const checkZipCodeEligibility = (zipCode: number | string): Promise<AxiosResponse<IEligibilityCheckResponse>> =>
  axios.get(`/eligibility-check/zip/${zipCode.toString()}`);

export default { checkZipCodeEligibility };
