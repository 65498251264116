import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Activity({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>activity</title>
      <defs>
        <path
          d="M14,15.8377223 L8.9486833,0.683772234 C8.64478453,-0.227924078 7.35521547,-0.227924078 7.0513167,0.683772234 L4.27924078,9 L1,9 C0.44771525,9 0,9.44771525 0,10 C0,10.5522847 0.44771525,11 1,11 L5,11 C5.43043041,11 5.81256925,10.7245699 5.9486833,10.3162278 L8,4.16227766 L13.0513167,19.3162278 C13.3552155,20.2279241 14.6447845,20.2279241 14.9486833,19.3162278 L17.7207592,11 L21,11 C21.5522847,11 22,10.5522847 22,10 C22,9.44771525 21.5522847,9 21,9 L17,9 C16.5695696,9 16.1874307,9.27543009 16.0513167,9.68377223 L14,15.8377223 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-93, -527)">
          <g id="activity" transform="translate(94, 529)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
