import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Edit({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>edit</title>
      <defs>
        <path
          d="M8.34,2 C8.89228475,2 9.34,2.44771525 9.34,3 C9.34,3.55228475 8.89228475,4 8.34,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,19 C2,19.5522847 2.44771525,20 3,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,13.66 C18,13.1077153 18.4477153,12.66 19,12.66 C19.5522847,12.66 20,13.1077153 20,13.66 L20,19 C20,20.6568542 18.6568542,22 17,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,5 C0,3.34314575 1.34314575,2 3,2 Z M17.7071068,0.292893219 L21.7071068,4.29289322 C22.0976311,4.68341751 22.0976311,5.31658249 21.7071068,5.70710678 L11.7071068,15.7071068 C11.5195704,15.8946432 11.2652165,16 11,16 L7,16 C6.44771525,16 6,15.5522847 6,15 L6,11 C6,10.7347835 6.10535684,10.4804296 6.29289322,10.2928932 L16.2928932,0.292893219 C16.6834175,-0.0976310729 17.3165825,-0.0976310729 17.7071068,0.292893219 Z M17,2.41421356 L8,11.4142136 L8,14 L10.5857864,14 L19.5857864,5 L17,2.41421356 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-239, -391)">
          <g id="edit" transform="translate(240, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
