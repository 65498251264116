import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function CheckCircle({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>check-circle</title>
      <defs>
        <path
          d="M2.63816372,3.8536377 C5.80331554,0.150414182 11.027246,-1.02986385 15.4770621,0.95285834 C15.9815346,1.17763814 16.2082703,1.76881393 15.9834905,2.27328641 C15.7587107,2.77775889 15.1675349,3.0044946 14.6630624,2.7797148 C11.0223038,1.15748756 6.74817888,2.12316958 4.15850921,5.15307973 C1.56883953,8.18298988 1.28052739,12.5553533 3.44992201,15.8990745 C5.61931663,19.2427957 9.72963221,20.7613449 13.5518796,19.6312257 C17.374127,18.5011065 19.9977835,14.9915321 20.0000623,11.0062866 L20.0000623,10.0762866 C20.0000623,9.52400182 20.4477775,9.07628657 21.0000623,9.07628657 C21.552347,9.07628657 22.0000623,9.52400182 22.0000623,10.0762866 L22.0000623,11.0068583 C21.9972767,15.8784128 18.7905855,20.1678926 14.1189498,21.5491494 C9.44731406,22.9304062 4.42359501,21.0744017 1.7721127,16.9876313 C-0.879369607,12.9008609 -0.526988104,7.55686121 2.63816372,3.8536377 Z M21.2929555,1.29917979 C21.6834798,0.908655498 22.3166448,0.908655498 22.7071691,1.29917979 C23.0976933,1.68970408 23.0976933,2.32286906 22.7071691,2.71339335 L11.7071691,13.7133934 C11.3166448,14.1039176 10.6834798,14.1039176 10.2929555,13.7133934 L7.29295549,10.7133934 C6.9024312,10.3228691 6.9024312,9.68970408 7.29295549,9.29917979 C7.68347978,8.9086555 8.31664476,8.9086555 8.70716905,9.29917979 L11.0000623,11.592073 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1148, -319)">
          <g id="check-circle" transform="translate(1148, 320)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
