import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Lock({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>lock</title>
      <defs>
        <path
          d="M10,0 C13.3137085,0 16,2.6862915 16,6 L16,9 L17,9 C18.6568542,9 20,10.3431458 20,12 L20,19 C20,20.6568542 18.6568542,22 17,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,12 C0,10.3431458 1.34314575,9 3,9 L4,9 L4,6 C4,2.76160306 6.56557489,0.122428238 9.77506174,0.00413847206 Z M17,11 L3,11 C2.44771525,11 2,11.4477153 2,12 L2,19 C2,19.5522847 2.44771525,20 3,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,12 C18,11.4477153 17.5522847,11 17,11 Z M10,2 C7.790861,2 6,3.790861 6,6 L6,9 L14,9 L14,6 C14,3.85780461 12.3160315,2.10892112 10.1996403,2.00489531 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-615, -252)">
          <g id="lock" transform="translate(617, 253)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
