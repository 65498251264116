import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function PieChart({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>pie-chart</title>
      <defs>
        <path
          d="M6.60102304,0.913467271 C7.10721008,0.692575631 7.69662382,0.923853257 7.91751546,1.43004031 C8.1384071,1.93622735 7.90712947,2.52564109 7.40094242,2.74653273 C3.69417789,4.36410334 1.52163066,8.25558941 2.08985078,12.2598077 C2.65807091,16.2640259 5.82749183,19.397458 9.83793779,19.9199149 C13.8483837,20.4423718 17.7148067,18.2255274 19.2899424,14.5005327 C19.5050393,13.9918562 20.0917735,13.7538629 20.60045,13.9689597 C21.1091265,14.1840565 21.3471199,14.7707908 21.132023,15.2794673 C19.2068571,19.8322385 14.4812291,22.541715 9.57957292,21.9031566 C4.67791675,21.2645981 0.804180071,17.4348479 0.109688808,12.5408033 C-0.584802454,7.64675878 2.07053305,2.89049802 6.60102304,0.913467271 Z M11.0009827,-1.77635684e-15 C13.9183641,-1.77635684e-15 16.7162572,1.15892524 18.7791573,3.22182541 C20.8420575,5.28472557 22.0009827,8.08261861 22.0009827,11 C22.0009827,11.5522847 21.5532675,12 21.0009827,12 L11.0009827,12 C10.448698,12 10.0009827,11.5522847 10.0009827,11 L10.0009827,1 C10.0009827,0.44771525 10.448698,-1.77635684e-15 11.0009827,-1.77635684e-15 Z M12.0009827,2.05569676 L12.0009827,10 L19.945286,10 C19.7196104,7.98098115 18.815371,6.0864662 17.3649438,4.63603897 C15.9145165,3.18561174 14.0200016,2.28137235 12.0009827,2.05569676 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1504, -526)">
          <g id="pie-chart" transform="translate(1505, 527)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
