import { FieldErrorMessage, Radio } from '@components';
import { IRadioProps } from '@components/Radio';
import {
  StyledFormLabel,
  StyledRadioFormControlLabel,
  StyledTypography,
} from '@components/formControllers/RadioGroupController/styles';
import { InfoIcon } from '@icons';
import { FormControl, IconButton, RadioGroup, RadioGroupProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type Option = {
  label: string;
  value: string | boolean;
} & IRadioProps;

interface IRadioControllerProps {
  name: string;
  control: Control<any, any>;
  label: string;
  row?: boolean;
  options: Option[];
  showIcon?: boolean;
  onIconClick?: () => void;
}

export const RadioGroupController: FC<IRadioControllerProps & RadioGroupProps> = ({
  row,
  options,
  name,
  control,
  label,
  showIcon,
  onIconClick,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <FormControl component="fieldset">
      <StyledFormLabel component="legend">
        <StyledTypography>{label}</StyledTypography>
        {showIcon && (
          <IconButton size="small" onClick={onIconClick}>
            <InfoIcon color={theme.palette.primary.dark} />
          </IconButton>
        )}
      </StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <RadioGroup row={row} value={value || ''} onChange={onChange} {...rest}>
              {options.map((singleOption, index) => {
                const { value: radioValue, label: radioLabel, ...radioProps } = singleOption;
                return (
                  <StyledRadioFormControlLabel
                    key={index}
                    value={radioValue}
                    label={radioLabel}
                    error={!!error}
                    control={<Radio {...radioProps} />}
                  />
                );
              })}
            </RadioGroup>
            {error && <FieldErrorMessage message={error.message} />}
          </>
        )}
      />
    </FormControl>
  );
};

export default RadioGroupController;
