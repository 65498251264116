import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Eye({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>eye</title>
      <defs>
        <path
          d="M12,0 C15.3798024,0 18.3386923,1.63249094 20.8545372,4.31605887 C21.7188737,5.23801779 22.4694995,6.22244509 23.1056644,7.20747457 C23.4900327,7.80262558 23.7538591,8.27165021 23.8944272,8.5527864 C24.0351909,8.8343139 24.0351909,9.1656861 23.8944272,9.4472136 C23.7538591,9.72834979 23.4900327,10.1973744 23.1056644,10.7925254 C22.4694995,11.7775549 21.7188737,12.7619822 20.8545372,13.6839411 C18.3386923,16.3675091 15.3798024,18 12,18 C8.62019756,18 5.66130774,16.3675091 3.1454628,13.6839411 C2.28112631,12.7619822 1.5305005,11.7775549 0.894335622,10.7925254 C0.50996726,10.1973744 0.246140906,9.72834979 0.105572809,9.4472136 C-0.0351909363,9.1656861 -0.0351909363,8.8343139 0.105572809,8.5527864 C0.246140906,8.27165021 0.50996726,7.80262558 0.894335622,7.20747457 C1.5305005,6.22244509 2.28112631,5.23801779 3.1454628,4.31605887 C5.66130774,1.63249094 8.62019756,0 12,0 Z M12,2 C9.25480244,2 6.77619226,3.36750906 4.6045372,5.68394113 C3.82824869,6.51198221 3.149187,7.40255491 2.57441438,8.29252543 C2.41127724,8.54512487 2.26658862,8.78236969 2.14071218,9 C2.26658862,9.21763031 2.41127724,9.45487513 2.57441438,9.70747457 C3.149187,10.5974451 3.82824869,11.4880178 4.6045372,12.3160589 C6.77619226,14.6324909 9.25480244,16 12,16 C14.7451976,16 17.2238077,14.6324909 19.3954628,12.3160589 C20.1717513,11.4880178 20.850813,10.5974451 21.4255856,9.70747457 C21.5887228,9.45487513 21.7334114,9.21763031 21.8592878,9 C21.7334114,8.78236969 21.5887228,8.54512487 21.4255856,8.29252543 C20.850813,7.40255491 20.1717513,6.51198221 19.3954628,5.68394113 C17.2238077,3.36750906 14.7451976,2 12,2 Z M12,5 C14.209139,5 16,6.790861 16,9 C16,11.209139 14.209139,13 12,13 C9.790861,13 8,11.209139 8,9 C8,6.790861 9.790861,5 12,5 Z M12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1221, -528)">
          <g id="eye" transform="translate(1221, 531)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
