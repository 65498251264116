import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Slash({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>slash</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M2,11 C2,15.9705627 6.02943725,20 11,20 C13.124779,20 15.0775836,19.2636898 16.6171757,18.0323074 L3.96769257,5.38282434 C2.7363102,6.92241642 2,8.87522098 2,11 Z M11,2 C8.87522098,2 6.92241642,2.7363102 5.38282434,3.96769257 L18.0323074,16.6171757 C19.2636898,15.0775836 20,13.124779 20,11 C20,6.02943725 15.9705627,2 11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-541, -83)">
          <g id="slash" transform="translate(542, 84)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
