import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ChevronDown({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chevron-down</title>
      <defs>
        <path
          d="M7,5.58578644 L1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L6.29289322,7.70710678 C6.68341751,8.09763107 7.31658249,8.09763107 7.70710678,7.70710678 L13.7071068,1.70710678 C14.0976311,1.31658249 14.0976311,0.683417511 13.7071068,0.292893219 C13.3165825,-0.0976310729 12.6834175,-0.0976310729 12.2928932,0.292893219 L7,5.58578644 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1651, -201)">
          <g id="chevron-down" transform="translate(1656, 209)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -8)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
