import { Button, InputController } from '@components';
import { StepperContext } from '@components/Stepper';
import { SessionStorageKey } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForgotPassword } from '@hooks';
import { Box, Typography, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import { FormProvider, Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { RestorePasswordContext } from '../RestorePassword';

interface IForgotPasswordForm {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { setEmail } = useContext(RestorePasswordContext);
  const { goToNextStep } = useContext(StepperContext);

  const { formatMessage } = useIntl();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const forgotPassword = useForgotPassword();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(formatMessage({ id: 'signup.email.valid.title' }))
      .required(formatMessage({ id: 'signup.email.required.title' })),
  });

  const methods = useForm<IForgotPasswordForm>({
    resolver: yupResolver(schema) as unknown as Resolver<IForgotPasswordForm, ResolverOptions<IForgotPasswordForm>>,
    defaultValues: {
      email: sessionStorage.getItem(SessionStorageKey.Email) || '',
    },
  });
  const { control, handleSubmit } = methods;

  const handleFormSubmit = async (data: IForgotPasswordForm) => {
    setErrorMessage(null);

    const res = await forgotPassword.mutateAsync(data);

    if (res instanceof AxiosError) {
      if (res.response?.status === 404) {
        return setErrorMessage(formatMessage({ id: `forgotPassword.serverError[${res.response.data.message}]` }));
      }
      return setErrorMessage(formatMessage({ id: 'forgotPassword.error.common' }));
    }

    setEmail(data.email);
    goToNextStep();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box sx={{ marginBottom: theme.spacing(4) }}>
          <InputController
            fullWidth
            name="email"
            control={control}
            placeholder={formatMessage({ id: 'forgotPassword.email.placeholder' })}
            label={formatMessage({ id: 'forgotPassword.email.label' })}
          />
        </Box>
        {!!errorMessage && (
          <Typography variant="body2" color="error" sx={{ paddingBottom: theme.spacing(1.5) }}>
            {errorMessage}
          </Typography>
        )}
        <Button fullWidth variant="contained" type="submit" sx={{ marginBottom: theme.spacing(3) }}>
          <FormattedMessage id="forgotPassword.submit" />
        </Button>
        <Button fullWidth variant="outlined" onClick={() => navigate(-1)}>
          <FormattedMessage id="common.back" />
        </Button>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
