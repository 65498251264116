import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Plus({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>plus</title>
      <defs>
        <path
          d="M8,0 C8.55228475,0 9,0.44771525 9,1 L9,7 L15,7 C15.5128358,7 15.9355072,7.38604019 15.9932723,7.88337887 L16,8 C16,8.55228475 15.5522847,9 15,9 L9,9 L9,15 C9,15.5128358 8.61395981,15.9355072 8.11662113,15.9932723 L8,16 C7.44771525,16 7,15.5522847 7,15 L7,9 L1,9 C0.487164161,9 0.0644928393,8.61395981 0.00672773133,8.11662113 L0,8 C0,7.44771525 0.44771525,7 1,7 L7,7 L7,1 C7,0.487164161 7.38604019,0.0644928393 7.88337887,0.00672773133 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1429, -323)">
          <g id="plus" transform="translate(1433, 327)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-4, -4)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
