import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Zap({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>zap</title>
      <defs>
        <path
          d="M8.86912893,14.0032382 L1.00191115,14.0032382 C0.154073504,14.0032382 -0.309082563,13.0143807 0.233689873,12.3630538 L10.2336899,0.363053794 C10.8729428,-0.404049724 12.1180433,0.136438815 11.994189,1.12727293 L11.1346934,8.00323819 L19.0019112,8.00323819 C19.8497488,8.00323819 20.3129049,8.99209567 19.7701324,9.64342259 L9.77013243,21.6434226 C9.1308795,22.4105261 7.88577901,21.8700376 8.00963328,20.8792035 L8.86912893,14.0032382 Z M3.13695277,12.0032382 L10.0019112,12.0032382 C10.6034091,12.0032382 11.0687957,12.5304198 10.994189,13.1272729 L10.4172505,17.742781 L16.8668695,10.0032382 L10.0019112,10.0032382 C9.40041316,10.0032382 8.93502663,9.47605661 9.00963328,8.87920346 L9.58657179,4.26369537 L3.13695277,12.0032382 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-846, -140)">
          <g id="zap" transform="translate(848, 141)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
