import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Airplay({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>airplay</title>
      <defs>
        <path
          d="M11.7682213,12.3598156 L16.7682213,18.3598156 C17.3109937,19.0111425 16.8478376,20 16,20 L6,20 C5.15216235,20 4.68900628,19.0111425 5.23177872,18.3598156 L10.2317787,12.3598156 C10.6315738,11.8800615 11.3684262,11.8800615 11.7682213,12.3598156 Z M11,14.5620499 L8.13504161,18 L13.8649584,18 L11,14.5620499 Z M19,0 C20.6568542,0 22,1.34314575 22,3 L22,13 C22,14.6568542 20.6568542,16 19,16 L18,16 C17.4477153,16 17,15.5522847 17,15 C17,14.4477153 17.4477153,14 18,14 L19,14 C19.5522847,14 20,13.5522847 20,13 L20,3 C20,2.44771525 19.5522847,2 19,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,13 C2,13.5522847 2.44771525,14 3,14 L4,14 C4.55228475,14 5,14.4477153 5,15 C5,15.5522847 4.55228475,16 4,16 L3,16 C1.34314575,16 0,14.6568542 0,13 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-93, -460)">
          <g id="airplay" transform="translate(94, 462)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
