import { createTheme } from '@mui/material/styles';

export enum Color {
  Blue = '#2c83ff',
  VeryDarkBlue = '#0c346d',
  DarkBlue = '#0b5ed4',
  LightBlue = '#5caffe',
  VeryLightBlue = '#5caffeb3',
  Black = '#000000',
  DarkGrey = '#444444',
  BrownishGrey = '#707070',
  Grey = '#9c9c9c',
  LightGrey = '#bbbbbb',
  PaleGrey = '#e4e4e4',
  WhiteGrey = '#f7f7f7',
  White = '#ffffff',
  Red = '#dc0000',
  DarkRed = '#bf0000',
  VeryDarkRed = '#9f0000',
  Green = '#07af00',
}

const defaultTheme = createTheme({
  palette: {
    primary: { main: Color.Blue, dark: Color.DarkBlue, light: Color.LightBlue },
    secondary: { main: Color.White, dark: Color.Black },
    grey: {
      '100': Color.DarkGrey,
      '200': Color.BrownishGrey,
      '300': Color.Grey,
      '400': Color.LightGrey,
      '500': Color.PaleGrey,
      '600': Color.WhiteGrey,
    },
    success: { main: Color.Green },
    error: { main: Color.Red, dark: Color.DarkRed },
    warning: { main: Color.Red },
    background: { default: Color.VeryDarkBlue },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 0 24px 0 rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter, sans-serif',
      color: Color.Black,
      letterSpacing: 'inherit',
    },
    h1: {
      fontSize: 40,
      fontWeight: '500',
      letterSpacing: '0.25px',
    },
    h2: {
      fontSize: 32,
      fontWeight: '500',
      letterSpacing: '0.25px',
    },
    h3: {
      fontSize: 24,
      fontWeight: '500',
      letterSpacing: '0.25px',
    },
    h4: {
      fontSize: 20,
      fontWeight: '500',
      letterSpacing: '0.25px',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: '400',
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.43,
      fontWeight: '400',
    },
    button: {
      fontSize: 16,
      fontWeight: '600',
      letterSpacing: '0.25px',
      textAlign: 'center',
      fontFamily: 'Inter, sans-serif',
    },
    overline: {
      fontSize: 16,
      fontWeight: '600',
      letterSpacing: '0.25px',
      textTransform: 'none',
      lineHeight: 'normal',
    },
  },
});

export default defaultTheme;
