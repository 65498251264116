import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Calendar({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>calendar</title>
      <defs>
        <path
          d="M14,0 C14.5522847,0 15,0.44771525 15,1 L15,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,5 C0,3.34314575 1.34314575,2 3,2 L5,2 L5,1 C5,0.487164161 5.38604019,0.0644928393 5.88337887,0.00672773133 L6,0 C6.55228475,0 7,0.44771525 7,1 L7,2 L13,2 L13,1 C13,0.487164161 13.3860402,0.0644928393 13.8833789,0.00672773133 Z M18,10 L2,10 L2,19 C2,19.5522847 2.44771525,20 3,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,10 Z M5,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,8 L18,8 L18,5 C18,4.44771525 17.5522847,4 17,4 L15,4 L15,5 C15,5.51283584 14.6139598,5.93550716 14.1166211,5.99327227 L14,6 C13.4477153,6 13,5.55228475 13,5 L13,4 L7,4 L7,5 C7,5.51283584 6.61395981,5.93550716 6.11662113,5.99327227 L6,6 C5.44771525,6 5,5.55228475 5,5 L5,4 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1290, -320)">
          <g id="calendar" transform="translate(1292, 321)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
