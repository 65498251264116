import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Voicemail({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>voicemail</title>
      <defs>
        <path
          d="M18.5,0 C21.5375661,0 24,2.46243388 24,5.5 C24,8.53756612 21.5375661,11 18.5,11 L5.5,11 C2.46243388,11 0,8.53756612 0,5.5 C0,2.46243388 2.46243388,0 5.5,0 C8.53756612,0 11,2.46243388 11,5.5 C11,6.82988427 10.5280013,8.04952735 9.74238585,9.00054728 L14.2576141,9.00054728 C13.4719987,8.04952735 13,6.82988427 13,5.5 C13,2.46243388 15.4624339,0 18.5,0 Z M5.5,2 C3.56700338,2 2,3.56700338 2,5.5 C2,7.43299662 3.56700338,9 5.5,9 C7.43299662,9 9,7.43299662 9,5.5 C9,3.56700338 7.43299662,2 5.5,2 Z M18.5,2 C16.5670034,2 15,3.56700338 15,5.5 C15,7.43299662 16.5670034,9 18.5,9 C20.4329966,9 22,7.43299662 22,5.5 C22,3.56700338 20.4329966,2 18.5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1570, -88)">
          <g id="voicemail" transform="translate(1570, 94)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -6)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
