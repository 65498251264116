import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Pocket({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>pocket</title>
      <defs>
        <path
          d="M19,0 C20.6568542,0 22,1.34314575 22,3 L22,9 C22,15.0751322 17.0751322,20 11,20 C8.08261861,20 5.28472557,18.8410748 3.22182541,16.7781746 C1.15892524,14.7152744 0,11.9173814 0,9 L0,3 C0,1.34314575 1.34314575,0 3,0 L19,0 Z M19,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,9 C2,11.3869484 2.94821156,13.6761336 4.63603897,15.363961 C6.32386638,17.0517884 8.61305159,18 11,18 C15.9705627,18 20,13.9705627 20,9 L20,3 C20,2.44771525 19.5522847,2 19,2 Z M6.29289322,7.29289322 C6.68341751,6.90236893 7.31658249,6.90236893 7.70710678,7.29289322 L11,10.5857864 L14.2928932,7.29289322 C14.6834175,6.90236893 15.3165825,6.90236893 15.7071068,7.29289322 C16.0976311,7.68341751 16.0976311,8.31658249 15.7071068,8.70710678 L11.7071068,12.7071068 C11.3165825,13.0976311 10.6834175,13.0976311 10.2928932,12.7071068 L6.29289322,8.70710678 C5.90236893,8.31658249 5.90236893,7.68341751 6.29289322,7.29289322 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1072, -527)">
          <g id="pocket" transform="translate(1073, 529)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
