import { PageLayout, Stepper } from '@components';
import { IStepperProps } from '@components/Stepper';
import React from 'react';
import { IPageLayoutProps } from '../PageLayout';

interface IStepperLayoutProps extends IStepperProps, IPageLayoutProps {}

const StepperLayout: React.FC<IStepperLayoutProps> = ({ navigationItems, steps, hideSteps, className, ...rest }) => {
  return (
    <PageLayout navigationItems={navigationItems} isFullScreen {...rest}>
      <Stepper steps={steps} hideSteps={hideSteps} className={className} />
    </PageLayout>
  );
};

export default StepperLayout;
