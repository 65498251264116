import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function AlertTriangle({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>alert-triangle</title>
      <defs>
        <path
          d="M11.4470257,0 C12.495655,0 13.4681772,0.547537988 14.0148931,1.44887293 L22.4930162,15.6026836 C23.0260538,16.5257831 23.0292363,17.662404 22.5013765,18.5884739 C21.9735166,19.5145439 20.9938982,20.0909683 19.9170257,20.102744 L2.96603518,20.102744 C1.9001532,20.0909683 0.920534838,19.5145439 0.392674958,18.5884739 C-0.135184922,17.662404 -0.132002381,16.5257831 0.409158286,15.5888729 L8.87915829,1.44887293 C9.4258742,0.547537988 10.3983964,0 11.4470257,0 Z M11.4470257,2 C11.098249,2 10.7747302,2.1817132 10.5932202,2.47940787 L2.13301624,16.6028044 C1.95533705,16.9105042 1.95427621,17.2893779 2.1302295,17.5980678 C2.30618279,17.9067578 2.63272225,18.0988993 2.97702571,18.1028044 L19.9060352,18.1028044 C20.2613292,18.0988993 20.5878686,17.9067578 20.7638219,17.5980678 C20.9397752,17.2893779 20.9387144,16.9105042 20.7691583,16.6166151 L12.3020257,2.481372 C12.1207429,2.18251266 11.7965688,2 11.4470257,2 Z M10.7399189,13.3956372 C11.1304432,13.0051129 11.7636082,13.0051129 12.1541325,13.3956372 C12.5446568,13.7861615 12.5446568,14.4193265 12.1541325,14.8098508 C11.7636082,15.2003751 11.1304432,15.2003751 10.7399189,14.8098508 C10.3493946,14.4193265 10.3493946,13.7861615 10.7399189,13.3956372 Z M11.4470257,6.102744 C11.9993105,6.102744 12.4470257,6.55045925 12.4470257,7.102744 L12.4470257,11.102744 C12.4470257,11.6550288 11.9993105,12.102744 11.4470257,12.102744 C10.894741,12.102744 10.4470257,11.6550288 10.4470257,11.102744 L10.4470257,7.102744 C10.4470257,6.55045925 10.894741,6.102744 11.4470257,6.102744 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-306, -526)">
          <g id="alert-triangle" transform="translate(307, 528)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
