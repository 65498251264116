import { listsApi } from '@api';
import { SubmissionBenefitListType } from '@app-types';
import { useQuery } from '@tanstack/react-query';

const useBenefitListItems = (type: SubmissionBenefitListType, enabled: boolean) => {
  return useQuery({
    queryKey: [type],
    queryFn: () => listsApi.getListItems(type).then(({ data }) => data),
    enabled,
    refetchOnMount: 'always',
  });
};

export default useBenefitListItems;
