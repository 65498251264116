import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Loader({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>loader</title>
      <defs>
        <path
          d="M10,1 L10,5 C10,5.55228475 10.4477153,6 11,6 C11.5522847,6 12,5.55228475 12,5 L12,1 C12,0.44771525 11.5522847,0 11,0 C10.4477153,0 10,0.44771525 10,1 Z M10,17 L10,21 C10,21.5522847 10.4477153,22 11,22 C11.5522847,22 12,21.5522847 12,21 L12,17 C12,16.4477153 11.5522847,16 11,16 C10.4477153,16 10,16.4477153 10,17 Z M3.22289322,4.63710678 L6.05289322,7.46710678 C6.44341751,7.85763107 7.07658249,7.85763107 7.46710678,7.46710678 C7.85763107,7.07658249 7.85763107,6.44341751 7.46710678,6.05289322 L4.63710678,3.22289322 C4.24658249,2.83236893 3.61341751,2.83236893 3.22289322,3.22289322 C2.83236893,3.61341751 2.83236893,4.24658249 3.22289322,4.63710678 Z M14.5328932,15.9471068 L17.3628932,18.7771068 C17.7534175,19.1676311 18.3865825,19.1676311 18.7771068,18.7771068 C19.1676311,18.3865825 19.1676311,17.7534175 18.7771068,17.3628932 L15.9471068,14.5328932 C15.5565825,14.1423689 14.9234175,14.1423689 14.5328932,14.5328932 C14.1423689,14.9234175 14.1423689,15.5565825 14.5328932,15.9471068 Z M1,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L1,10 C0.44771525,10 0,10.4477153 0,11 C0,11.5522847 0.44771525,12 1,12 Z M17,12 L21,12 C21.5522847,12 22,11.5522847 22,11 C22,10.4477153 21.5522847,10 21,10 L17,10 C16.4477153,10 16,10.4477153 16,11 C16,11.5522847 16.4477153,12 17,12 Z M4.63710678,18.7771068 L7.46710678,15.9471068 C7.85763107,15.5565825 7.85763107,14.9234175 7.46710678,14.5328932 C7.07658249,14.1423689 6.44341751,14.1423689 6.05289322,14.5328932 L3.22289322,17.3628932 C2.83236893,17.7534175 2.83236893,18.3865825 3.22289322,18.7771068 C3.61341751,19.1676311 4.24658249,19.1676311 4.63710678,18.7771068 Z M15.9471068,7.46710678 L18.7771068,4.63710678 C19.1676311,4.24658249 19.1676311,3.61341751 18.7771068,3.22289322 C18.3865825,2.83236893 17.7534175,2.83236893 17.3628932,3.22289322 L14.5328932,6.05289322 C14.1423689,6.44341751 14.1423689,7.07658249 14.5328932,7.46710678 C14.9234175,7.85763107 15.5565825,7.85763107 15.9471068,7.46710678 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-465, -391)">
          <g id="loader" transform="translate(466, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
