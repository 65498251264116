import { ViewportBreakpoint } from '@constants';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignUpForm from './components/SignUpForm/SignUpForm';

export const TitleBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
}));

export const StyledSignUpBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '0 80px',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    margin: 0,
  },
}));

export const StyledSignUpForm = styled(SignUpForm)(() => ({
  marginTop: '35px',
  maxWidth: '780px',
  width: '100%',
}));
