import { ViewportBreakpoint } from '@constants';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: '16px',
  padding: '48px 92px 44px 70px',
  margin: '40px 0',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    padding: '64px 16px',
    margin: '30px 0',
  },
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
}));
