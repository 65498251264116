import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Facebook({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>facebook</title>
      <defs>
        <path
          d="M3,6 C3,2.6862915 5.6862915,0 9,0 L12,0 C12.5522847,0 13,0.44771525 13,1 L13,5 C13,5.55228475 12.5522847,6 12,6 L9,6 L9,8 L12,8 C12.6505712,8 13.1279292,8.6113889 12.9701425,9.24253563 L11.9701425,13.2425356 C11.8588507,13.6877026 11.4588676,14 11,14 L9,14 L9,21 C9,21.5522847 8.55228475,22 8,22 L4,22 C3.44771525,22 3,21.5522847 3,21 L3,14 L1,14 C0.44771525,14 0,13.5522847 0,13 L0,9 C0,8.44771525 0.44771525,8 1,8 L3,8 L3,6 Z M11,2 L9,2 C6.790861,2 5,3.790861 5,6 L5,9 C5,9.55228475 4.55228475,10 4,10 L2,10 L2,12 L4,12 C4.55228475,12 5,12.4477153 5,13 L5,20 L7,20 L7,13 C7,12.4477153 7.44771525,12 8,12 L10.2192236,12 L10.7192236,10 L8,10 C7.44771525,10 7,9.55228475 7,9 L7,6 C7,4.8954305 7.8954305,4 9,4 L11,4 L11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-312, -459)">
          <g id="facebook" transform="translate(318, 460)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-6, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
