import { ActionType } from '@app-types/verificationCode';
import { PageLayout } from '@components';
import { useAppDispatch } from '@store';
import { selectCustomer } from '@store/customer/selectors';
import { getCurrentCustomer } from '@store/customer/slice';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SendCodeContent from './components/SendCodeContent';

const SignUpSendCode: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customer = useSelector(selectCustomer);

  const onSubmit = () => {
    dispatch(getCurrentCustomer());
    navigate('/tips');
  };

  if (!customer?.email) {
    return null;
  }

  return (
    <PageLayout>
      <SendCodeContent
        titleId="signup.sendCode.title"
        email={customer.email}
        actionType={ActionType.SignUp}
        onSubmit={onSubmit}
      />
    </PageLayout>
  );
};

export default SignUpSendCode;
