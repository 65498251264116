import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Percent({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>percent</title>
      <defs>
        <path
          d="M14.5,11 C16.4329966,11 18,12.5670034 18,14.5 C18,16.4329966 16.4329966,18 14.5,18 C12.5670034,18 11,16.4329966 11,14.5 C11,12.5670034 12.5670034,11 14.5,11 Z M15.2928932,1.29289322 C15.6834175,0.902368927 16.3165825,0.902368927 16.7071068,1.29289322 C17.0976311,1.68341751 17.0976311,2.31658249 16.7071068,2.70710678 L2.70710678,16.7071068 C2.31658249,17.0976311 1.68341751,17.0976311 1.29289322,16.7071068 C0.902368927,16.3165825 0.902368927,15.6834175 1.29289322,15.2928932 Z M14.5,13 C13.6715729,13 13,13.6715729 13,14.5 C13,15.3284271 13.6715729,16 14.5,16 C15.3284271,16 16,15.3284271 16,14.5 C16,13.6715729 15.3284271,13 14.5,13 Z M3.5,0 C5.43299662,0 7,1.56700338 7,3.5 C7,5.43299662 5.43299662,7 3.5,7 C1.56700338,7 0,5.43299662 0,3.5 C0,1.56700338 1.56700338,0 3.5,0 Z M3.5,2 C2.67157288,2 2,2.67157288 2,3.5 C2,4.32842712 2.67157288,5 3.5,5 C4.32842712,5 5,4.32842712 5,3.5 C5,2.67157288 4.32842712,2 3.5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1576, -254)">
          <g id="percent" transform="translate(1579, 257)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
