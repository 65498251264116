import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Shield({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>shield</title>
      <defs>
        <path
          d="M9.4472136,21.8944272 C9.1656861,22.0351909 8.8343139,22.0351909 8.5527864,21.8944272 C8.2759078,21.7559879 7.81107801,21.4986714 7.22000106,21.1292483 C6.2398841,20.5166752 5.25994003,19.8062158 4.34149539,19.0025767 C1.64575967,16.6438079 0,13.9694485 0,11 L0,3 C0,2.54113236 0.312297374,2.14114925 0.757464375,2.0298575 L8.75746437,0.0298574999 C8.91670437,-0.00995249995 9.08329563,-0.00995249995 9.24253563,0.0298574999 L17.2425356,2.0298575 C17.6877026,2.14114925 18,2.54113236 18,3 L18,11 C18,13.9694485 16.3542403,16.6438079 13.6585046,19.0025767 C12.74006,19.8062158 11.7601159,20.5166752 10.7799989,21.1292483 C10.188922,21.4986714 9.7240922,21.7559879 9.4472136,21.8944272 Z M9.72000106,19.4332517 C10.6148841,18.8739498 11.50994,18.2250342 12.3414954,17.4974233 C14.6457597,15.4811921 16,13.2805515 16,11 L16,3.78077641 L9,2.03077641 L2,3.78077641 L2,11 C2,13.2805515 3.35424033,15.4811921 5.65850461,17.4974233 C6.49005997,18.2250342 7.3851159,18.8739498 8.27999894,19.4332517 C8.53712914,19.5939581 8.77859909,19.7373457 9,19.8627787 C9.22140091,19.7373457 9.46287086,19.5939581 9.72000106,19.4332517 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1291, -140)">
          <g id="shield" transform="translate(1294, 141)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
