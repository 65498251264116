import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({}) => ({}));

export const ColorBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

export const PrimaryColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const PrimaryDarkColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

export const PrimaryLightColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

export const SecondaryColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export const SuccessColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

export const ErrorColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}));

export const BackgroundColorBox = styled(ColorBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
