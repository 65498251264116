import { Button } from '@components';
import { StepperContext } from '@components/Stepper';
import { useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BusinessAccountWarningStep from './BusinessAccountWarningStep';

const PrivateCheckStep: React.FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { goToNextStep } = useContext(StepperContext);

  const [isBusinessAccount, setIsBusinessAccount] = useState(false);

  return isBusinessAccount ? (
    <BusinessAccountWarningStep onBack={() => setIsBusinessAccount(false)} />
  ) : (
    <Stack alignItems="center" gap={4}>
      <Typography variant="h2" textAlign="center" sx={{ marginTop: theme.spacing(isMobile ? 19 : 37) }}>
        <FormattedMessage id="eligibilityCheck.step.1.title" />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={() => setIsBusinessAccount(true)}>
          <FormattedMessage id="common.no" />
        </Button>
        <Button variant="contained" onClick={goToNextStep}>
          <FormattedMessage id="common.yes" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default PrivateCheckStep;
