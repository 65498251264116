import { CheckboxProps, useTheme } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { CheckIcon } from '@icons';
import { CheckedIconContainer, DefaultIcon, StyledCheckbox } from './styles';

export interface ICheckboxProps {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  name?: string;
  label?: React.ReactNode;
  backgroundMode?: 'white' | 'gray';
}

const Checkbox: React.FC<ICheckboxProps & CheckboxProps> = ({
  name,
  checked,
  onChange,
  disabled,
  backgroundMode = 'gray',
  ...rest
}) => {
  const theme = useTheme();

  const [isHoveringCheckbox, setIsHoveringCheckbox] = useState(false);

  const handleCheckboxMouseEnter = () => {
    setIsHoveringCheckbox(true);
  };

  const handleCheckboxMouseLeave = () => {
    setIsHoveringCheckbox(false);
  };

  return (
    <StyledCheckbox
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      icon={<DefaultIcon backgroundMode={backgroundMode} isHovering={isHoveringCheckbox} />}
      checkedIcon={
        <CheckedIconContainer>
          <CheckIcon color={theme.palette.grey['600']} />
        </CheckedIconContainer>
      }
      onMouseEnter={handleCheckboxMouseEnter}
      onMouseLeave={handleCheckboxMouseLeave}
      {...rest}
    />
  );
};

export default Checkbox;
