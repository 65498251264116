import Loader from '@components/Loader';
import { useAppDispatch } from '@store';
import { selectCustomer, selectCustomerLoadingStatus } from '@store/customer/selectors';
import { getCurrentCustomer } from '@store/customer/slice';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const withoutAuth =
  (Component: React.FC) =>
  ({ ...props }) => {
    const dispatch = useAppDispatch();
    const account = useSelector(selectCustomer);
    const accountLoadingStatus = useSelector(selectCustomerLoadingStatus);

    useEffect(() => {
      dispatch(getCurrentCustomer());
    }, []);

    if (account) {
      return <Navigate to="/submission-creation" />;
    }

    if (accountLoadingStatus === 'LOADING') return <Loader />;

    return <Component {...props} />;
  };

export default withoutAuth;
