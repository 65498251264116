import FieldErrorMessage from '@components/FieldErrorMessage';
import { EyeIcon, EyeOffIcon } from '@icons';
import { FormControl, IconButton, InputAdornment, TextFieldProps, useTheme } from '@mui/material';
import React, { FocusEventHandler, ReactNode, useState } from 'react';
import { StyledTextField } from './styles';

export interface IInputProps {
  value?: string;
  label?: ReactNode;
  placeholder?: string;
  helperText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  password?: boolean;
  width?: string | number;
  backgroundMode?: 'white' | 'gray';
  height?: string | number;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  email?: boolean;
  number?: boolean;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const Input: React.FC<IInputProps & TextFieldProps> = ({
  width,
  height,
  disabled,
  value,
  helperText,
  onChange,
  onBlur,
  password,
  email,
  number,
  label,
  placeholder,
  fullWidth,
  error,
  className,
  name,
  multiline,
  rows,
  backgroundMode = 'gray',
  ...rest
}) => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isHoveringIcon, setIsHoveringIcon] = useState(false);

  const handleIconMouseEnter = () => {
    setIsHoveringIcon(true);
  };

  const handleIconMouseLeave = () => {
    setIsHoveringIcon(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.startsWith(' ')) {
      return e.preventDefault();
    }

    onChange?.(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
    if (e.target.value === '' && e.code === 'Space') {
      return e.preventDefault();
    }
  };

  const getInputType = () => {
    if (password) {
      return showPassword ? 'text' : 'password';
    }

    if (email) {
      return 'email';
    }

    if (number) {
      return 'number';
    }

    return 'text';
  };

  return (
    <FormControl sx={{ width }} fullWidth={fullWidth}>
      <StyledTextField
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
        label={label}
        className={className}
        error={error}
        disabled={disabled}
        fullWidth={fullWidth}
        name={name}
        InputLabelProps={{ shrink: true }}
        multiline={multiline}
        rows={rows}
        backgroundMode={backgroundMode}
        InputProps={{
          type: getInputType(),
          value,
          onKeyDown: handleKeyDown,
          endAdornment: password ? (
            <InputAdornment position="start" onMouseEnter={handleIconMouseEnter} onMouseLeave={handleIconMouseLeave}>
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword && isHoveringIcon && (
                  <EyeOffIcon style={{ height: 20, width: 20 }} color={theme.palette.grey['200']} />
                )}
                {!showPassword && isHoveringIcon && (
                  <EyeIcon style={{ height: 20, width: 20 }} color={theme.palette.primary.main} />
                )}
                {showPassword && !isHoveringIcon && (
                  <EyeIcon style={{ height: 20, width: 20 }} color={theme.palette.primary.main} />
                )}
                {!showPassword && !isHoveringIcon && (
                  <EyeOffIcon style={{ height: 20, width: 20 }} color={theme.palette.grey['200']} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        {...rest}
      />
      {error && <FieldErrorMessage message={helperText} />}
    </FormControl>
  );
};

export default Input;
