import { CalculateAllowanceResponse } from '@app-types';
import policyPdfFile from '@assets/privacyPolicy.pdf';
import { AlertTriangleIcon, InfoIcon } from '@icons';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Amount from './Amount';
import { AmountBox, CheckboxesBox, NoteBox, StyledButton, StyledCheckboxController, SubmissionFormBox } from './styles';

interface ISubmissionFormFooterProps {
  className?: string;
  data: CalculateAllowanceResponse | undefined;
  isSubmitting: boolean;
  checkOngoingBenefits: () => void;
}

const SubmissionFormFooter: FC<ISubmissionFormFooterProps> = ({ className, data, isSubmitting, checkOngoingBenefits }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [formErrors, setFormErrors] = useState<string | null>(null);

  const { control, watch, formState, setValue } = useFormContext();
  const { errors } = formState;

  const confirmAccurancy = watch('confirmAccurancy');
  const conseptTheProcessing = watch('conseptTheProcessing');

  useEffect(() => {
    setFormErrors(
      Object.keys(errors)
        .filter((key) => key !== 'isUserReadDeclarationOfConsent')
        .length > 0 ? formatMessage({ id: 'submissionCreation.validation.common' }) : null
      );
  }, [errors]);

  return (
    <SubmissionFormBox className={className}>
      <AmountBox>
        <Amount
          value={`${data?.data?.basicMonthlyAllowance.toFixed(2) || 0}`}
          title={formatMessage({ id: 'submissionCreation.footer.monthlyAllowance.title' })}
        />
        <Amount
          value={`${data?.data?.oneOffAllowance.toFixed(2) || 0}`}
          title={formatMessage({ id: 'submissionCreation.footer.oneOffAllowance.title' })}
        />
      </AmountBox>
      <NoteBox>
        <AlertTriangleIcon color={theme.palette.secondary.main} />
        <Typography fontSize={16} color={theme.palette.secondary.main} sx={{ lineHeight: 1.64 }}>
          <FormattedMessage id="submissionCreation.footer.note" />
        </Typography>
      </NoteBox>
      <CheckboxesBox>
        <StyledCheckboxController
          name="confirmAccurancy"
          control={control}
          label={formatMessage({ id: 'submissionCreation.footer.firstCheckbox' })}
        />
        <StyledCheckboxController
          name="conseptTheProcessing"
          control={control}
          label={
            <div>
              {formatMessage({ id: 'submissionCreation.footer.secondCheckbox' })}
              &nbsp;
              <Link href={policyPdfFile} target="_blank" onClick={() => setValue('isUserReadDeclarationOfConsent', true)}>
                {formatMessage({ id: 'submissionCreation.footer.readDeclarationOfConsent' })}
              </Link>
            </div>
          }
        />
        {!!errors?.isUserReadDeclarationOfConsent?.message && (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ flexShrink: 0 }}>
              <InfoIcon color={theme.palette.error.main} />
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                color: theme.palette.error.main,
                '& > span': { fontWeight: '600', color: theme.palette.error.main },
              }}
            >
              {errors?.isUserReadDeclarationOfConsent?.message as string}
            </Typography>
          </Box>
        )}
      </CheckboxesBox>
      <Stack sx={{ gap: 2, pt: '30px' }}>
        {formErrors && (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ flexShrink: 0 }}>
              <InfoIcon color={theme.palette.error.main} />
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                color: theme.palette.error.main,
                '& > span': { fontWeight: '600', color: theme.palette.error.main },
              }}
              dangerouslySetInnerHTML={{ __html: formErrors }}
            />
          </Box>
        )}
        <StyledButton
          onClick={checkOngoingBenefits}
          type="submit"
          color="secondary"
          variant="contained"
          disabled={isSubmitting || !confirmAccurancy || !conseptTheProcessing}
        >
          <FormattedMessage id="submissionCreation.footer.button.submit" />
        </StyledButton>
      </Stack>
    </SubmissionFormBox>
  );
};

export default SubmissionFormFooter;
