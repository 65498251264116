import { ViewportBreakpoint } from '@constants';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Flow, IStyledStepperProps } from './Stepper';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const StepsBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  userSelect: 'none',
  paddingTop: theme.spacing(5),
}));

export const Steps = styled('div')<IStyledStepperProps>(({ theme, withPaddings, flow }) => {
  let borderBottom;

  switch (flow) {
    case Flow.settings:
      borderBottom = `1px solid ${theme.palette.grey['500']}`;
      break;
    default:
      break;
  }

  return {
    width: '100%',
    padding: withPaddings ? '0 13%' : 0,
    display: 'flex',
    gap: theme.spacing(1.7),
    borderBottom,

    [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
      gap: theme.spacing(0.5),
      overflowX: 'auto',
      padding: '0 22px',
    },
  };
});

export const Step = styled('div')<{ active?: boolean; disabled?: boolean; flow?: Flow }>(({
  theme,
  active,
  disabled,
  flow,
}) => {
  let borderBottomWidth = 0;
  let borderBottomColor = theme.palette.primary.main;

  switch (flow) {
    case Flow.settings:
      borderBottomWidth = 0;

      if (disabled) {
        borderBottomWidth = 0;
      }

      if (active) {
        borderBottomWidth = 3;
        borderBottomColor = theme.palette.primary.main;
      }

      break;
    default:
      borderBottomWidth = 1;

      if (disabled) {
        borderBottomWidth = 1;
        borderBottomColor = theme.palette.grey['500'];
      }

      if (active) {
        borderBottomWidth = 3;
        borderBottomColor = theme.palette.primary.main;
      }

      break;
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
    borderBottomStyle: 'solid',
    borderBottomWidth,
    borderBottomColor,
    paddingBottom: theme.spacing(1),
  };
});

export const StepTitle = styled(Typography)<{
  flow?: Flow;
  isFutureStep?: boolean;
  isActive?: boolean;
}>(({ flow, theme, isFutureStep, isActive }) => {
  let color;
  let fontWeight;
  let fontSize = '14px';

  switch (flow) {
    case Flow.settings:
      color = theme.palette.grey['200'];
      fontWeight = 500;
      fontSize = '16px';

      if (isFutureStep) {
        fontWeight = 500;
        color = theme.palette.grey['200'];
      }

      if (isActive) {
        fontWeight = 600;
        color = theme.palette.secondary.dark;
      }

      break;
    default:
      color = isFutureStep ? theme.palette.grey['500'] : theme.palette.primary.main;
      fontWeight = isActive ? '600' : 'normal';

      break;
  }

  return {
    width: '100%',
    lineHeight: 'normal',
    color,
    fontWeight,
    fontSize,

    [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
      whiteSpace: 'nowrap',
    },
  };
});

export const Body = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  padding: '0 13%',
  width: '100%',
  boxSizing: 'border-box',

  '&.Mui-default-padding': {
    padding: theme.spacing(0, 3),
  },

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    padding: '0 22px',
  },
}));

export const Content = styled('div')<{ openInfoDrawer?: boolean }>(({ openInfoDrawer }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: openInfoDrawer ? 'calc(100% - 320px)' : '100%',
  transition: 'all 0.3s ease-out',
}));
