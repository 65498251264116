import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import React from 'react';

import 'dayjs/locale/de';

import { StyledCalendarIcon, StyledDatePicker } from './styles';

export interface IDatePickerProps {
  value?: Dayjs | null;
  label?: string;
  error?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  width?: string | number;
  className?: string;
  backgroundMode?: 'white' | 'gray';
  onChange?: ((value: unknown, context: PickerChangeHandlerContext<DateValidationError>) => void) &
    ((value: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => void);
}

const DatePicker: React.FC<IDatePickerProps> = ({
  className,
  width,
  disabled,
  error,
  value,
  onChange,
  label,
  fullWidth,
  backgroundMode = 'gray',
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <StyledDatePicker
        sx={{ width }}
        label={label}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={className}
        format="DD.MM.YYYY"
        backgroundMode={backgroundMode}
        slots={{
          openPickerIcon: StyledCalendarIcon,
        }}
        slotProps={{
          textField: {
            error,
            fullWidth,
            InputLabelProps: { shrink: true },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
