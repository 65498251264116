import { useIsMobile } from '@hooks';
import { AlertTriangleIcon } from '@icons';
import { Box, Paper, Stack, useTheme } from '@mui/material';
import React from 'react';

const Warning: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Paper
      sx={{
        marginTop: theme.spacing(isMobile ? 12 : 27),
        paddingLeft: theme.spacing(isMobile ? 4 : 7),
        paddingTop: theme.spacing(isMobile ? 5 : 7),
        paddingRight: theme.spacing(isMobile ? 4 : 10),
        paddingBottom: theme.spacing(5),
        width: isMobile ? '90%' : '61%',
      }}
    >
      <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" gap={isMobile ? 4 : 6}>
        <AlertTriangleIcon
          width={theme.spacing(7)}
          height={theme.spacing(7)}
          color={theme.palette.error.main}
          style={{ flexShrink: 0 }}
        />
        <Box>{children}</Box>
      </Stack>
    </Paper>
  );
};

export default Warning;
