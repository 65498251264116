import { IFile } from '@app-types';
import { CheckboxController, FieldErrorMessage, UploadedFile } from '@components';
import { StepperContext } from '@components/Stepper';
import CurrencyInputController from '@components/formControllers/CurrencyInputController';
import { useIsMobile } from '@hooks';
import { InfoIcon } from '@icons';
import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { convertFileListToArray } from '@utils';
import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmissionFormContext } from '../SubmissionForm';
import UploadFileButton from '../UploadFileButton';
import { BorderedBlock } from '../styles';

interface IChildBenefitProps {}

const ChildBenefit: FC<IChildBenefitProps> = () => {
  const theme = useTheme();

  const { openInfoDrawer } = useContext(StepperContext);

  const { getChildrenValue, childrenFields } = useContext(SubmissionFormContext);

  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext();

  const isMobile = useIsMobile();

  const getChildFullName = (index: number) => {
    const firstName = getChildrenValue(index, 'firstName') || '';
    const lastName = getChildrenValue(index, 'lastName') || '';

    return `${firstName} ${lastName}`;
  };

  const getUploadedSupplementDocuments = (fieldIndex: number) => {
    const supplementDocuments = getChildrenValue(fieldIndex, 'uploadedSupplementDocuments') as FileList;

    return convertFileListToArray(supplementDocuments);
  };

  const handleUploadedSupplementDocumentFileDelete = (childIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getUploadedSupplementDocuments(childIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);
      setValue(`children[${childIndex}].uploadedSupplementDocuments`, fileList);
    };
  };

  const getSupplementDocuments = (fieldIndex: number) => {
    const supplementDocuments = getChildrenValue(fieldIndex, 'supplementDocuments') as IFile[];

    return supplementDocuments;
  };

  const handleSupplementDocumentFileDelete = (childIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getSupplementDocuments(childIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);
      setValue(`children[${childIndex}].supplementDocuments`, fileList);
    };
  };

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <BorderedBlock direction="column" mt="60px">
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb="19px" gap={1}>
          <Typography variant="h2">
            <FormattedMessage id="submissionCreation.childBenefits.title" />
          </Typography>
          <IconButton
            size="small"
            onClick={() =>
              openInfoDrawer(
                formatMessage({ id: 'submissionCreation.drawer.title' }),
                formatMessage({ id: 'submissionCreation.childBenefits.drawer.description' }),
              )
            }
          >
            <InfoIcon color={theme.palette.primary.dark} />
          </IconButton>
        </Stack>
      </BorderedBlock>

      <Stack>
        <Stack direction="column" gap={4}>
          {childrenFields.map(({ id }, fieldIndex) => (
            <BorderedBlock key={id} mt="30px" pb="30px">
              <Stack direction="row" mb={2}>
                <Typography fontSize="16px" fontWeight={600} mb={2}>
                  <FormattedMessage
                    id="submissionCreation.childBenefits.childBlockTitle"
                    values={{ number: fieldIndex + 1, childFullName: getChildFullName(fieldIndex) }}
                  />
                </Typography>
              </Stack>

              <Grid container spacing={isMobile ? 1 : 4}>
                <Grid item xs={12} md={4}>
                  <Typography fontSize="16px" lineHeight="34px" fontWeight={600} mb={2}>
                    <FormattedMessage id="submissionCreation.childBenefits.childBenefits" />
                  </Typography>
                  <CheckboxController
                    name={`children[${fieldIndex}].isBenefits`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.childBenefits.isBenefits' })}
                    disabled={getChildrenValue(fieldIndex, 'isThereCreditedToPAccount')}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CurrencyInputController
                    name={`children[${fieldIndex}].supplement`}
                    control={control}
                    label={
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography fontSize="16px" fontWeight={600}>
                          <FormattedMessage id="submissionCreation.childBenefits.supplement.surcharge" />
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() =>
                            openInfoDrawer(
                              formatMessage({ id: 'submissionCreation.childBenefits.supplement.surcharge' }),
                              formatMessage({ id: 'submissionCreation.childBenefits.supplement.drawer.description' }),
                            )
                          }
                        >
                          <InfoIcon color={theme.palette.primary.dark} />
                        </IconButton>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item container xs={12} spacing={isMobile ? 1 : 4}>
                    <Grid item xs={12} md={4} order={{ xs: 1, md: 0 }}>
                      <Stack direction="column" mt={1}>
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography fontSize="16px">
                            <FormattedMessage id="submissionCreation.childBenefits.uploadFollowingDocuments" />
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              openInfoDrawer(
                                formatMessage({ id: 'submissionCreation.drawer.title' }),
                                formatMessage({
                                  id: 'submissionCreation.childBenefits.uploadFollowingDocuments.drawer.description',
                                }),
                              )
                            }
                          >
                            <InfoIcon color={theme.palette.primary.dark} />
                          </IconButton>
                        </Stack>
                        <Typography fontSize="16px" component="span" fontWeight={600}>
                          <FormattedMessage id="submissionCreation.childBenefits.benefitPaymentProof" />
                        </Typography>
                        {getChildrenValue(fieldIndex, 'supplement') && (
                          <Typography fontSize="16px" component="span" fontWeight={600}>
                            <FormattedMessage id="submissionCreation.childBenefits.childSurchargeProof" />
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadFileButton fieldName={`children[${fieldIndex}].uploadedSupplementDocuments`} />
                    <Box mt={1}>
                      {/* @ts-ignore */}
                      {errors && errors.children?.[fieldIndex]?.supplementDocuments && (
                        // @ts-ignore
                        <FieldErrorMessage message={errors.children?.[fieldIndex]?.supplementDocuments?.message} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack direction="column" gap={1} mt={1}>
                      {getSupplementDocuments(fieldIndex)?.map((file, fileIndex) => (
                        <UploadedFile
                          key={file.name}
                          onDeleteFile={handleSupplementDocumentFileDelete(fieldIndex, fileIndex)}
                          fileName={file.name || ''}
                        />
                      ))}
                      {getUploadedSupplementDocuments(fieldIndex)?.map((file, fileIndex) => (
                        <UploadedFile
                          key={file.name}
                          onDeleteFile={handleUploadedSupplementDocumentFileDelete(fieldIndex, fileIndex)}
                          fileName={file.name}
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </BorderedBlock>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default ChildBenefit;
