import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Printer({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>printer</title>
      <defs>
        <path
          d="M17,0 C17.5522847,0 18,0.44771525 18,1 L18,7 L19,7 C20.5976809,7 21.9036609,8.24891996 21.9949073,9.82372721 L22,10 L22,15 C22,16.6568542 20.6568542,18 19,18 L18,18 L18,21 C18,21.5522847 17.5522847,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,18 L3,18 C1.40231912,18 0.0963391206,16.75108 0.00509269341,15.1762728 L0,15 L0,10 C0,8.34314575 1.34314575,7 3,7 L4,7 L4,1 C4,0.487164161 4.38604019,0.0644928393 4.88337887,0.00672773133 L5,0 Z M16,14 L6,14 L6,20 L16,20 L16,14 Z M19,9 L3,9 C2.44771525,9 2,9.44771525 2,10 L2,15 C2,15.5522847 2.44771525,16 3,16 L4,16 L4,13 C4,12.4477153 4.44771525,12 5,12 L17,12 C17.5522847,12 18,12.4477153 18,13 L18,16 L19,16 C19.5128358,16 19.9355072,15.6139598 19.9932723,15.1166211 L20,15 L20,10 C20,9.44771525 19.5522847,9 19,9 Z M16,2 L6,2 L6,7 L16,7 L16,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-614, -668)">
          <g id="printer" transform="translate(615, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
