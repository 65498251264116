import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Cast({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cast</title>
      <defs>
        <path
          d="M0.800366355,14.0798305 C2.37179734,14.4003143 3.59988254,15.6283995 3.92036636,17.1998305 C4.03072968,17.7409759 4.55888189,18.0901938 5.10002732,17.9798305 C5.64117276,17.8694672 5.99039065,17.3413149 5.88002732,16.8001695 C5.3993016,14.443023 3.5571738,12.6008952 1.20002732,12.1201695 C0.658881889,12.0098062 0.130729684,12.3590241 0.020366355,12.9001695 C-0.0899969739,13.4413149 0.259220921,13.9694672 0.800366355,14.0798305 Z M0.889658331,10.0438718 C4.60775269,10.4573986 7.54279824,13.3924442 7.956325,17.1105385 C8.01737373,17.6594388 8.51183509,18.0549206 9.06073535,17.9938718 C9.60963561,17.9328231 10.0051174,17.4383618 9.94406868,16.8894615 C9.42716023,12.2418435 5.75835329,8.57303661 1.11073535,8.05612816 C0.561835086,7.99507943 0.0673737298,8.39056123 0.00632499725,8.93946149 C-0.0547237353,9.48836175 0.340758069,9.98282311 0.889658331,10.0438718 Z M2.00019684,5 L2.00019684,3 C2.00019684,2.44771525 2.44791209,2 3.00019684,2 L19.0001968,2 C19.5524816,2 20.0001968,2.44771525 20.0001968,3 L20.0001968,15 C20.0001968,15.5522847 19.5524816,16 19.0001968,16 L13.0001968,16 C12.4479121,16 12.0001968,16.4477153 12.0001968,17 C12.0001968,17.5522847 12.4479121,18 13.0001968,18 L19.0001968,18 C20.6570511,18 22.0001968,16.6568542 22.0001968,15 L22.0001968,3 C22.0001968,1.34314575 20.6570511,0 19.0001968,0 L3.00019684,0 C1.34334259,0 0.000196839148,1.34314575 0.000196839148,3 L0.000196839148,5 C0.000196839148,5.55228475 0.447912089,6 1.00019684,6 C1.55248159,6 2.00019684,5.55228475 2.00019684,5 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1289, -393)">
          <g id="cast" transform="translate(1290, 396)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
