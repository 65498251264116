import { useAppDispatch } from '@store';
import { selectCustomer } from '@store/customer/selectors';
import { getCurrentCustomer } from '@store/customer/slice';
import { getCurrentSubmission } from '@store/submission/slice';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
  EligibilityCheck,
  Login,
  MobileUpload,
  RestorePassword,
  Settings,
  SignUp,
  SignUpSendCode,
  SignUpTips,
  SignUpWelcome,
  SubmissionCreation,
  ToolkitPage,
} from '../../pages';

const COMMON_ROUTES = [
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: '/submission-creation',
    element: <SubmissionCreation />,
  },
  {
    path: '/eligibility-check',
    element: <EligibilityCheck />,
  },
];

const USER_ROUTES = [...COMMON_ROUTES];

const getRoutes = (/* roles?: UserRole[] */) => {
  return USER_ROUTES;
};

const useRouter = () => {
  const dispatch = useAppDispatch();
  const customer = useSelector(selectCustomer);

  useEffect(() => {
    dispatch(getCurrentCustomer());
    dispatch(getCurrentSubmission());
  }, []);

  const routes = useMemo(() => getRoutes(), []);

  const router = createBrowserRouter(
    !customer || customer?.isEmailVerified
      ? [
          ...routes,
          {
            path: '*',
            element: <Navigate to="/" />,
          },
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/toolkit',
            element: <ToolkitPage />,
          },
          {
            path: '/signup',
            element: <SignUp />,
          },
          {
            path: '/tips',
            element: <SignUpTips />,
          },
          {
            path: '/restore-password',
            element: <RestorePassword />,
          },
          {
            path: '/settings',
            element: <Settings />,
          },
          {
            path: '/mobile-upload',
            element: <MobileUpload />,
          },
        ]
      : [
          {
            path: '*',
            element: <Navigate to="/welcome" />,
          },
          {
            path: '/welcome',
            element: <SignUpWelcome />,
          },
          {
            path: '/send-code',
            element: <SignUpSendCode />,
          },
        ],
  );

  return router;
};

export default useRouter;
