import { appSettingsApi } from '@api';
import { AppSettings } from '@app-types';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

const useAppSettings = (options?: UseQueryOptions): UseQueryResult<AppSettings, any> => {
  return useQuery({
    queryKey: ['app-settings'],
    queryFn: () => appSettingsApi.fetchAppSettings().then(({ data }) => data),
  });
};

export default useAppSettings;
