import { verificationCodesApi } from '@api';
import { IVerificationCodeDto } from '@app-types/verificationCode';
import { useQuery } from '@tanstack/react-query';

const useVerificationCodes = (data: IVerificationCodeDto) => {
  return useQuery({
    queryKey: ['verificationCodes', data.codeValue],
    queryFn: () => verificationCodesApi.check(data),
    enabled: data.codeValue.toString().length === 4,
    refetchOnMount: 'always',
  });
};

export default useVerificationCodes;
