import { DrawerProps, IconButton, Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { ArrowRightIcon } from '@icons';
import { StyledDrawer } from './styles';

interface IDrawerProps {
  onClose?: () => void;
  width?: number | string;
  top?: number | string;
}

const Drawer: FC<IDrawerProps & DrawerProps> = ({ children, top, onClose, width, ...rest }) => {
  const theme = useTheme();
  return (
    <StyledDrawer variant="persistent" width={width} top={top} {...rest}>
      <Stack ml="auto" mb="39px">
        <IconButton onClick={onClose}>
          <ArrowRightIcon color={theme.palette.grey['100']} />
        </IconButton>
      </Stack>

      <Stack>{children}</Stack>
    </StyledDrawer>
  );
};

export default Drawer;
