import { recognizedBodiesApi } from '@api';
import { IRecognizedBodyParams } from '@app-types/recognizedBody';
import { useQuery } from '@tanstack/react-query';

const useRecognizedBodies = (params: IRecognizedBodyParams) => {
  return useQuery({
    queryKey: ['recognizedBodies', params],
    queryFn: () => recognizedBodiesApi.getAll(params).then(({ data }) => data),
    refetchOnMount: 'always',
  });
};

export default useRecognizedBodies;
