import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Folder({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>folder</title>
      <defs>
        <path
          d="M7.46481624,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.5522847 2.44771525,18 3,18 L19,18 C19.5522847,18 20,17.5522847 20,17 L20,6 C20,5.44771525 19.5522847,5 19,5 L10,5 C9.66564681,5 9.35341548,4.83289886 9.16794971,4.5547002 L7.46481624,2 Z M10.5351838,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,17 C22,18.6568542 20.6568542,20 19,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 L8,0 C8.33435319,0 8.64658452,0.167101135 8.83205029,0.445299804 L10.5351838,3 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-917, -599)">
          <g id="folder" transform="translate(918, 601)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
