import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Octagon({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>octagon</title>
      <defs>
        <path
          d="M7.27421356,2 L2,7.27421356 L2,14.7257864 L7.27421356,20 L14.7257864,20 L20,14.7257864 L20,7.27421356 L14.7257864,2 L7.27421356,2 Z M6.86,0 L15.14,0 C15.4052165,0 15.6595704,0.10535684 15.8471068,0.292893219 L21.7071068,6.15289322 C21.8946432,6.3404296 22,6.59478351 22,6.86 L22,15.14 C22,15.4052165 21.8946432,15.6595704 21.7071068,15.8471068 L15.8471068,21.7071068 C15.6595704,21.8946432 15.4052165,22 15.14,22 L6.86,22 C6.59478351,22 6.3404296,21.8946432 6.15289322,21.7071068 L0.292893219,15.8471068 C0.10535684,15.6595704 0,15.4052165 0,15.14 L0,6.86 C0,6.59478351 0.10535684,6.3404296 0.292893219,6.15289322 L6.15289322,0.292893219 C6.3404296,0.10535684 6.59478351,0 6.86,0 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-768, -459)">
          <g id="octagon" transform="translate(769, 460)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
