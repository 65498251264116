import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Command({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>command</title>
      <defs>
        <path
          d="M16,12 C18.209139,12 20,13.790861 20,16 C20,18.209139 18.209139,20 16,20 C13.790861,20 12,18.209139 12,16 L12,12 L16,12 Z M8,16 C8,18.209139 6.209139,20 4,20 C1.790861,20 0,18.209139 0,16 C0,13.790861 1.790861,12 4,12 L8,12 L8,16 Z M4,8 C1.790861,8 0,6.209139 0,4 C0,1.790861 1.790861,0 4,0 C6.209139,0 8,1.790861 8,4 L8,8 L4,8 Z M12,4 C12,1.790861 13.790861,0 16,0 C18.209139,0 20,1.790861 20,4 C20,6.209139 18.209139,8 16,8 L12,8 L12,4 Z M8,8 L8,12 L12,12 L12,8 L8,8 Z M6,6 L6,4 C6,2.8954305 5.1045695,2 4,2 C2.8954305,2 2,2.8954305 2,4 C2,5.1045695 2.8954305,6 4,6 L6,6 Z M6,14 L4,14 C2.8954305,14 2,14.8954305 2,16 C2,17.1045695 2.8954305,18 4,18 C5.1045695,18 6,17.1045695 6,16 L6,14 Z M14,16 C14,17.1045695 14.8954305,18 16,18 C17.1045695,18 18,17.1045695 18,16 C18,14.8954305 17.1045695,14 16,14 L14,14 L14,16 Z M16,6 C17.1045695,6 18,5.1045695 18,4 C18,2.8954305 17.1045695,2 16,2 C14.8954305,2 14,2.8954305 14,4 L14,6 L16,6 Z M14,14 L6,14 L6,6 L14,6 L14,14 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-240, -195)">
          <g id="command" transform="translate(242, 197)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
