import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ZoomIn({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>zoom-in</title>
      <defs>
        <path
          d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,11.124779 17.2636898,13.0775836 16.0323074,14.6171757 L19.7071068,18.2928932 C20.0976311,18.6834175 20.0976311,19.3165825 19.7071068,19.7071068 C19.3165825,20.0976311 18.6834175,20.0976311 18.2928932,19.7071068 L14.6171757,16.0323074 C13.0775836,17.2636898 11.124779,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,2 C5.13400675,2 2,5.13400675 2,9 C2,12.8659932 5.13400675,16 9,16 C10.8904747,16 12.6059153,15.25059 13.8654514,14.0326404 L13.9428932,13.9428932 C13.9713525,13.914434 14.0011003,13.8880487 14.031949,13.8637373 C15.25059,12.6059153 16,10.8904747 16,9 C16,5.13400675 12.8659932,2 9,2 Z M9,5 C9.55228475,5 10,5.44771525 10,6 L10,8 L12,8 C12.5128358,8 12.9355072,8.38604019 12.9932723,8.88337887 L13,9 C13,9.55228475 12.5522847,10 12,10 L10,10 L10,12 C10,12.5128358 9.61395981,12.9355072 9.11662113,12.9932723 L9,13 C8.44771525,13 8,12.5522847 8,12 L8,10 L6,10 C5.48716416,10 5.06449284,9.61395981 5.00672773,9.11662113 L5,9 C5,8.44771525 5.44771525,8 6,8 L8,8 L8,6 C8,5.48716416 8.38604019,5.06449284 8.88337887,5.00672773 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1223, -141)">
          <g id="zoom-in" transform="translate(1225, 143)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
