import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function MoreHorizontal({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>more-horizontal</title>
      <defs>
        <path
          d="M11,0 C12.6568542,0 14,1.34314575 14,3 C14,4.65685425 12.6568542,6 11,6 C9.34314575,6 8,4.65685425 8,3 C8,1.34314575 9.34314575,0 11,0 Z M19,0 C20.6568542,0 22,1.34314575 22,3 C22,4.65685425 20.6568542,6 19,6 C17.3431458,6 16,4.65685425 16,3 C16,1.34314575 17.3431458,0 19,0 Z M3,0 C4.65685425,0 6,1.34314575 6,3 C6,4.65685425 4.65685425,6 3,6 C1.34314575,6 0,4.65685425 0,3 C0,1.34314575 1.34314575,0 3,0 Z M3,2 C2.44771525,2 2,2.44771525 2,3 C2,3.55228475 2.44771525,4 3,4 C3.55228475,4 4,3.55228475 4,3 C4,2.44771525 3.55228475,2 3,2 Z M19,2 C18.4477153,2 18,2.44771525 18,3 C18,3.55228475 18.4477153,4 19,4 C19.5522847,4 20,3.55228475 20,3 C20,2.44771525 19.5522847,2 19,2 Z M11,2 C10.4477153,2 10,2.44771525 10,3 C10,3.55228475 10.4477153,4 11,4 C11.5522847,4 12,3.55228475 12,3 C12,2.44771525 11.5522847,2 11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1072, -399)">
          <g id="more-horizontal" transform="translate(1073, 408)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -9)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
