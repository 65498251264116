import { StepperLayout } from '@components';
import { withoutAuth } from '@hoc';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '@hooks';
import ConditionsCheckStep from './steps/ConditionsCheckStep';
import ConfirmationStep from './steps/ConfirmationStep';
import IncomeCheckStep from './steps/IncomeCheckStep';
import NoAccountStep from './steps/NoAccountStep';
import PrivateCheckStep from './steps/PrivateCheckStep';
import ZipCheckStep from './steps/ZipCheckStep';

const EligibilityCheck: React.FC = () => {
  const [displaySteps, setDisplaySteps] = useState(true);

  const { data } = useAppSettings();

  const navigate = useNavigate();

  const handleLastStepSubmit = () => {
    setDisplaySteps(false);
  };

  const handleSubmit = () => {
    navigate('/signup');
  };

  return (
    <StepperLayout
      hideSteps={!displaySteps}
      disableLogout
      steps={[
        {
          id: '1',
          title: '1.',
          content: <PrivateCheckStep />,
        },
        { id: '2', title: '2.', content: <NoAccountStep /> },
        { id: '3', title: '3.', content: <IncomeCheckStep /> },
        { id: '4', title: '4.', content: <ConditionsCheckStep /> },
        { id: '5', title: '5.', content: <ZipCheckStep onSubmit={handleLastStepSubmit} /> },
        { id: '6', title: '6.', content: <ConfirmationStep onSubmit={handleSubmit} />, isHidden: true },
      ]}
    />
  );
};

export default withoutAuth(EligibilityCheck);
