import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function VideoOff({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>video-off</title>
      <defs>
        <path
          d="M0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L23.7071068,22.2928932 C24.0976311,22.6834175 24.0976311,23.3165825 23.7071068,23.7071068 C23.3165825,24.0976311 22.6834175,24.0976311 22.2928932,23.7071068 L16.7608094,18.1758252 C16.303475,19.2482628 15.2395263,20 14,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,7 C0,5.47498816 1.13789178,4.21574519 2.61095068,4.02499575 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 Z M4.585,6 L3,6 C2.44771525,6 2,6.44771525 2,7 L2,17 C2,17.5522847 2.44771525,18 3,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,16.415 L4.585,6 Z M14,4 C15.6568542,4 17,5.34314575 17,7 L17,9.92578644 L17.1044657,10.0302521 L22.4139179,6.1897483 C23.0751672,5.71144469 24,6.18389651 24,7 L24,17 C24,17.5522847 23.5522847,18 23,18 C22.4477153,18 22,17.5522847 22,17 L22,8.95751772 L17.5860821,12.1502517 C17.1881706,12.4380743 16.6401506,12.3943642 16.2928932,12.0471068 L15.2928932,11.0471068 C15.1053568,10.8595704 15,10.6052165 15,10.34 L15,7 C15,6.44771525 14.5522847,6 14,6 L10.66,6 C10.1077153,6 9.66,5.55228475 9.66,5 C9.66,4.44771525 10.1077153,4 10.66,4 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1646, -251)">
          <g id="video-off" transform="translate(1646, 251)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <rect id="Rectangle" x={0} y={0} width={24} height={24} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
