import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function X({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>x</title>
      <defs>
        <path
          d="M0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L7,5.585 L12.2928932,0.292893219 C12.6533772,-0.0675907428 13.2206082,-0.0953202783 13.6128994,0.209704612 L13.7071068,0.292893219 C14.0976311,0.683417511 14.0976311,1.31658249 13.7071068,1.70710678 L8.415,7 L13.7071068,12.2928932 C14.0675907,12.6533772 14.0953203,13.2206082 13.7902954,13.6128994 L13.7071068,13.7071068 C13.3165825,14.0976311 12.6834175,14.0976311 12.2928932,13.7071068 L7,8.415 L1.70710678,13.7071068 C1.34662282,14.0675907 0.779391764,14.0953203 0.387100557,13.7902954 L0.292893219,13.7071068 C-0.0976310729,13.3165825 -0.0976310729,12.6834175 0.292893219,12.2928932 L5.585,7 L0.292893219,1.70710678 C-0.0675907428,1.34662282 -0.0953202783,0.779391764 0.209704612,0.387100557 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1725, -395)">
          <g id="x" transform="translate(1730, 400)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
