import { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { Input } from '@components';
import { IInputProps } from '@components/Input';

interface IInputControllerProps {
  name: string;
  control: Control<FieldValues> | Control<any>;
  label: string;
}

interface IExtendedInputProps extends IInputProps {
  required?: boolean;
}

const InputController: FC<IInputControllerProps & IExtendedInputProps> = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          helperText={error ? error.message : ''}
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          label={label}
          {...rest}
        />
      )}
    />
  );
};

export default InputController;
