import { Stepper } from '@components';
import { styled } from '@mui/material/styles';

export const StyledStepper = styled(Stepper)(() => ({
  width: '100%',
  padding: 0,

  '& .MuiContainer-root': {
    padding: 0,
  },
}));
