import Button from '@components/Button';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PaginationButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '100px',
  gap: '20px',

  position: 'static',

  '.swiper-button-next': {
    width: '24px !important',
    height: '24px !important',
  },

  '.swiper-pagination-bullet': {
    width: '4px',
    height: '4px',
    background: theme.palette.background.default,
    margin: '0 !important',
    opacity: 1,
  },

  '.swiper-pagination-bullet-active': {
    width: '12px',
    height: '12px',
    background: theme.palette.background.default,
  },
}));

export const ProceedAppButton = styled(Button)(() => ({
  marginTop: '32px',
}));

export const ProceedAppButtonBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[500]}`,
  margin: '30px 0 30px 0',
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledImg = styled('img')(() => ({
  maxWidth: '400px',
  width: '100%',
  margin: '30px 0 50px 0',
}));