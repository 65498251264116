import { BenefitType, IFile, SubmissionBenefitListType } from '@app-types';
import { FieldErrorMessage, InputController, RadioGroupController, Select, UploadedFile } from '@components';
import { StepperContext } from '@components/Stepper';
import CurrencyInputController from '@components/formControllers/CurrencyInputController';
import { useBenefitListItems } from '@hooks';
import { InfoIcon, Trash2Icon } from '@icons';
import { Box, Grid, IconButton as MuiIconButton, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { convertFileListToArray } from '@utils';
import { FC, useContext, useEffect } from 'react';
import { WatchObserver, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { BenefitFormValues, SubmissionCreationFormValues, SubmissionRadioValue } from '../../../types';
import UploadFileButton from '../../UploadFileButton';
import { BorderedBlock, FormInfoBlock } from '../../styles';

interface IBenefitFormBlock {
  radioFieldName:
    | 'isUserReceivedOneOffBenefits'
    | 'isUserReceivedHealthDamages'
    | 'isUserReceivedBackPaymentBenefits'
    | 'isUserReceivedMotherChildFoundations'
    | 'isUserReceivedBackPaymentWagePensionBenefits'
    | 'isUserReceivedAdditionalBenefits';
  benefitType:
    | 'oneOffBenefits'
    | 'healthDamages'
    | 'backPaymentBenefits'
    | 'motherChildFoundations'
    | 'backPaymentWagePensionBenefits'
    | 'additionalBenefits';
  multiple?: boolean;
  benefitListType?: SubmissionBenefitListType;
}

const BenefitFormBlock: FC<IBenefitFormBlock> = ({ radioFieldName, benefitType, benefitListType, multiple = true }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { data: listItemsData } = useBenefitListItems(benefitListType!, multiple && !!benefitListType);
  const { control, watch, setValue } = useFormContext<SubmissionCreationFormValues>();
  const { openInfoDrawer } = useContext(StepperContext);

  const radioValue = watch(radioFieldName);

  const {
    fields: benefitsFields,
    append: appendBenefit,
    remove: removeBenefit,
  } = useFieldArray<SubmissionCreationFormValues, IBenefitFormBlock['benefitType']>({
    control,
    name: benefitType,
  });

  const getBenefitsValue = (index: number, key: keyof BenefitFormValues) => {
    return watch(`${benefitType}[${index}].${key}` as unknown as WatchObserver<SubmissionCreationFormValues>);
  };

  const getBenefitUploadedDocuments = (fieldIndex: number) => {
    const documents = getBenefitsValue(fieldIndex, 'uploadedDocuments') as unknown as FileList;

    return convertFileListToArray(documents);
  };

  const handleBenefitUploadedDocumentFileDelete = (benefitIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getBenefitUploadedDocuments(benefitIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);

      setValue(
        `${benefitType}[${benefitIndex}].uploadedDocuments` as `${IBenefitFormBlock['benefitType']}.${typeof benefitIndex}.uploadedDocuments`,
        fileList as unknown as FileList,
      );
    };
  };

  const getBenefitDocuments = (fieldIndex: number) => {
    const documents = getBenefitsValue(fieldIndex, 'documents') as unknown as IFile[];

    return documents;
  };

  const handleBenefitDocumentFileDelete = (benefitIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getBenefitDocuments(benefitIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);

      setValue(
        `${benefitType}[${benefitIndex}].documents` as `${IBenefitFormBlock['benefitType']}.${typeof benefitIndex}.documents`,
        fileList as unknown as IFile[],
      );
    };
  };

  const handleDeleteBenefitButtonClick = (index: number) => {
    return () => removeBenefit(index);
  };

  const handleOpenInfoDrawer = () => {
    openInfoDrawer(
      formatMessage({ id: 'submissionCreation.drawer.title' }),
      formatMessage({ id: 'submissionCreation.drawer.description' }),
    );
  };

  const handleBenefitTypeChange = (event: SelectChangeEvent<unknown>) => {
    appendBenefit({
      type: event.target.value as BenefitType,
    });
  };

  const benefitsOptions = listItemsData?.items.map((item) => ({ value: item.id, label: item.label })) || [];

  useEffect(() => {
    if (!multiple && !benefitsFields.length) {
      appendBenefit({});
    }
  }, [appendBenefit]);

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <BorderedBlock mt="32px" pb="27px">
      <RadioGroupController
        row
        name={radioFieldName}
        control={control}
        label={formatMessage({ id: `submissionCreation.ongoingService.${radioFieldName}` })}
        options={[
          { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
          { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
        ]}
        onIconClick={() =>
          openInfoDrawer(
            formatMessage({ id: 'submissionCreation.drawer.title' }),
            formatMessage({ id: `submissionCreation.ongoingService.${radioFieldName}.drawer.description` }),
          )
        }
        showIcon
      />
      {radioValue === SubmissionRadioValue.Yes && (
        <Stack mt="32px">
          {benefitsFields.map((benefitField, benefitIndex) => (
            <FormInfoBlock key={benefitField.id} mt="24px" mb="32px">
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight={600} mb={2} position="relative">
                  {multiple ? benefitsOptions.find(({ value }) => value === benefitField.type)?.label : ''}
                </Typography>

                {multiple && (
                  <MuiIconButton onClick={handleDeleteBenefitButtonClick(benefitIndex)}>
                    <Trash2Icon color={theme.palette.error.main} />
                  </MuiIconButton>
                )}
              </Stack>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputController
                    name={`${benefitType}[${benefitIndex}].explanation`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.ongoingService.benefitExplanation' })}
                    placeholder={formatMessage({
                      id: 'submissionCreation.ongoingService.benefitExplanation.placeholder',
                    })}
                    multiline
                    rows={4}
                    backgroundMode="white"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CurrencyInputController
                    name={`${benefitType}[${benefitIndex}].amount`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.ongoingService.benefitAmount' })}
                    placeholder={formatMessage({ id: 'submissionCreation.ongoingService.benefitAmount.placeholder' })}
                    backgroundMode="white"
                  />
                </Grid>
              </Grid>
              <Stack direction="row" mb={3} mt={3} gap={1} alignItems="center">
                <Typography fontSize="16px" sx={{ zIndex: 1 }}>
                  <FormattedMessage id="submissionCreation.ongoingService.uploadSupportingDocuments" />
                </Typography>
                <MuiIconButton size="small" onClick={handleOpenInfoDrawer}>
                  <InfoIcon color={theme.palette.primary.dark} />
                </MuiIconButton>
              </Stack>
              <Grid item container xs={12}>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" gap={2}>
                    <UploadFileButton fieldName={`${benefitType}[${benefitIndex}].uploadedDocuments`} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack direction="column" gap={1} mt={1}>
                    {getBenefitDocuments(benefitIndex)?.map((file, fileIndex) => (
                      <UploadedFile
                        key={file.name}
                        fileName={file.name || ''}
                        onDeleteFile={handleBenefitDocumentFileDelete(benefitIndex, fileIndex)}
                      />
                    ))}
                    {getBenefitUploadedDocuments(benefitIndex)?.map((file, fileIndex) => (
                      <UploadedFile
                        key={file.name}
                        fileName={file.name}
                        onDeleteFile={handleBenefitUploadedDocumentFileDelete(benefitIndex, fileIndex)}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
              <Box mt={1}>
                {/* @ts-ignore */}
                {errors && errors[benefitType]?.[benefitIndex]?.documents && (
                  // @ts-ignore
                  <FieldErrorMessage message={errors[benefitType]?.[benefitIndex]?.documents?.message} />
                )}
              </Box>
            </FormInfoBlock>
          ))}

          {multiple && (
            <Stack pl="2%" pr="2%">
              <Select
                label={formatMessage({ id: 'submissionCreation.ongoingService.chooseSocialBenefit' })}
                options={benefitsOptions}
                onChange={handleBenefitTypeChange}
              />
            </Stack>
          )}
        </Stack>
      )}
    </BorderedBlock>
  );
};

export default BenefitFormBlock;
