import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Maximize({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>maximize</title>
      <defs>
        <path
          d="M6,0 L3,0 C1.34314575,0 0,1.34314575 0,3 L0,6 C0,6.55228475 0.44771525,7 1,7 C1.55228475,7 2,6.55228475 2,6 L2,3 C2,2.44771525 2.44771525,2 3,2 L6,2 C6.55228475,2 7,1.55228475 7,1 C7,0.44771525 6.55228475,0 6,0 Z M20,6 L20,3 C20,1.34314575 18.6568542,0 17,0 L14,0 C13.4477153,0 13,0.44771525 13,1 C13,1.55228475 13.4477153,2 14,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,6 C18,6.55228475 18.4477153,7 19,7 C19.5522847,7 20,6.55228475 20,6 Z M14,20 L17,20 C18.6568542,20 20,18.6568542 20,17 L20,14 C20,13.4477153 19.5522847,13 19,13 C18.4477153,13 18,13.4477153 18,14 L18,17 C18,17.5522847 17.5522847,18 17,18 L14,18 C13.4477153,18 13,18.4477153 13,19 C13,19.5522847 13.4477153,20 14,20 Z M0,14 L0,17 C0,18.6568542 1.34314575,20 3,20 L6,20 C6.55228475,20 7,19.5522847 7,19 C7,18.4477153 6.55228475,18 6,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,14 C2,13.4477153 1.55228475,13 1,13 C0.44771525,13 0,13.4477153 0,14 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-542, -392)">
          <g id="maximize" transform="translate(544, 394)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
