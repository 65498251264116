import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Delete({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>delete</title>
      <defs>
        <path
          d="M21,0 C22.6568542,0 24,1.34314575 24,3 L24,15 C24,16.6568542 22.6568542,18 21,18 L8,18 C7.71163742,18 7.4373114,17.8755196 7.24742331,17.6585046 L0.247423305,9.65850461 C-0.0824744351,9.28147862 -0.0824744351,8.71852138 0.247423305,8.34149539 L7.24742331,0.341495392 C7.4373114,0.124480432 7.71163742,0 8,0 L21,0 Z M21,2 L8.45376823,2 L2.32876823,9 L8.45376823,16 L21,16 C21.5522847,16 22,15.5522847 22,15 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M11.2928932,5.29289322 C11.6834175,4.90236893 12.3165825,4.90236893 12.7071068,5.29289322 L15,7.585 L17.2928932,5.29289322 C17.6533772,4.93240926 18.2206082,4.90467972 18.6128994,5.20970461 L18.7071068,5.29289322 C19.0976311,5.68341751 19.0976311,6.31658249 18.7071068,6.70710678 L16.415,9 L18.7071068,11.2928932 C19.0675907,11.6533772 19.0953203,12.2206082 18.7902954,12.6128994 L18.7071068,12.7071068 C18.3165825,13.0976311 17.6834175,13.0976311 17.2928932,12.7071068 L15,10.415 L12.7071068,12.7071068 C12.3466228,13.0675907 11.7793918,13.0953203 11.3871006,12.7902954 L11.2928932,12.7071068 C10.9023689,12.3165825 10.9023689,11.6834175 11.2928932,11.2928932 L13.585,9 L11.2928932,6.70710678 C10.9324093,6.34662282 10.9046797,5.77939176 11.2097046,5.38710056 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-238, -322)">
          <g id="delete" transform="translate(238, 325)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
