import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledGrid } from './styles';

interface ILoaderProps {
  className?: string;
}

const Loader: React.FC<ILoaderProps> = ({ className }) => {
  return (
    <StyledGrid xs={12} container className={className}>
      <CircularProgress />
    </StyledGrid>
  );
};

export default Loader;
