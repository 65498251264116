import { SaveIcon } from '@icons';
import { ButtonProps, CircularProgress, useTheme } from '@mui/material';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { StyledIconButton } from './styles';

interface ISaveSubmissionButtonProps {
  id?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
}

export interface IStyledProps {
  textColor?: string;
  backgroundColor?: string;
  border?: string;
}

const SaveSubmissionButton: React.FC<ISaveSubmissionButtonProps & ButtonProps> = ({
  id,
  onClick,
  className,
  children,
  loading = false,
  type,
  ...restProps
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseDown = () => {
    setIsActive(true);
  };

  const handleMouseUp = () => {
    setIsActive(false);
  };

  let iconColor;
  let backgroundColor;
  let textColor;
  let border;

  if (isActive) {
    iconColor = theme.palette.secondary.main;
    backgroundColor = theme.palette.background.default;
    textColor = theme.palette.secondary.main;
    border = 'none';
  } else if (isHovered) {
    iconColor = theme.palette.secondary.main;
  } else {
    iconColor = theme.palette.primary.main;
  }

  return (
    <StyledIconButton
      className={className}
      id={id}
      onClick={onClick}
      color="primary"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      backgroundColor={backgroundColor}
      textColor={textColor}
      border={border}
      type={type}
      {...restProps}
    >
      {loading && <CircularProgress size={20} color="inherit" />}
      {!loading && <SaveIcon width="20px" height="20px" color={iconColor} />}
      {children}
    </StyledIconButton>
  );
};

export default SaveSubmissionButton;
