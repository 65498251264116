import { authApi } from '@api';
import { ICustomer } from '@app-types/customer';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';
import isEqual from 'react-fast-compare';

export type CustomerSliceState =
  | {
      status: 'INIT';
    }
  | { status: 'LOADING' }
  | { status: 'ERROR' }
  | { status: 'LOADED'; data: ICustomer };

export const getCurrentCustomer = createAsyncThunk('@customer/getCurrentCustomer', async () => {
  const res = await authApi.getCurrentCustomer();
  return res.data;
});

const initialState: CustomerSliceState = {
  status: 'INIT',
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState as CustomerSliceState,
  reducers: {
    clearCustomer: (state) => {
      state.status = 'INIT';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete state.data;
    },
    updateFirstName: (state, action: PayloadAction<string>) => {
      if (state.status === 'LOADED' && state.data) {
        state.data.firstName = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentCustomer.pending, (state) => {
      state.status = 'LOADING';
    });

    builder.addCase(getCurrentCustomer.fulfilled, (state, action) => {
      state.status = 'LOADED';
      if (state.status === 'LOADED' && !isEqual(state.data, action.payload)) {
        state.data = action.payload;
      }
    });

    builder.addCase(getCurrentCustomer.rejected, (state) => {
      state.status = 'ERROR';
    });
  },
});

export const { clearCustomer, updateFirstName } = customerSlice.actions;

export const selectCurrentCustomer = (state: RootState): ICustomer | null => {
  if (state.customer.status === 'LOADED') {
    return state.customer.data;
  }
  return null;
};

export default customerSlice.reducer;
