import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Heart({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>heart</title>
      <defs>
        <path
          d="M11.804018,1.90488483 C13.023092,0.685238378 14.6768548,0 16.4012907,0 C18.1257266,0 19.7794894,0.685238378 20.9985634,1.90488483 C22.2178779,3.12362695 22.9031163,4.77738977 22.9031163,6.50182562 C22.9031163,8.22626147 22.2178779,9.8800243 20.9983975,11.0989324 L12.1583975,19.9389324 C11.7678732,20.3294567 11.1347082,20.3294567 10.7441839,19.9389324 L1.90418391,11.0989324 C-0.634727996,8.56002041 -0.634727965,4.44363084 1.90418398,1.9047189 C4.44309592,-0.634193044 8.55948549,-0.634193075 11.0983975,1.90471884 L11.4512907,2.25761207 L11.804018,1.90488483 Z M11.4512907,17.8176121 L18.5241839,10.7447188 L19.5843499,9.68455289 C20.4287205,8.84057855 20.9031163,7.69566583 20.9031163,6.50182562 C20.9031163,5.30798542 20.4287205,4.16307269 19.5843499,3.31909835 C18.7400436,2.4743958 17.5951309,2 16.4012907,2 C15.2074505,2 14.0625378,2.4743958 13.2183975,3.3189324 L12.1583975,4.3789324 C11.7678732,4.76945669 11.1347082,4.76945669 10.7441839,4.3789324 L9.68418393,3.31893241 C7.92632054,1.56106907 5.07626091,1.56106909 3.31839754,3.31893246 C1.56053417,5.07679583 1.56053415,7.92685546 3.31839748,9.68471884 L11.4512907,17.8176121 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1425, -526)">
          <g id="heart" transform="translate(1426, 528)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
