import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Menu({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>menu</title>
      <defs>
        <path
          d="M1,8 L19,8 C19.5522847,8 20,7.55228475 20,7 C20,6.44771525 19.5522847,6 19,6 L1,6 C0.44771525,6 0,6.44771525 0,7 C0,7.55228475 0.44771525,8 1,8 Z M1,2 L19,2 C19.5522847,2 20,1.55228475 20,1 C20,0.44771525 19.5522847,0 19,0 L1,0 C0.44771525,0 0,0.44771525 0,1 C0,1.55228475 0.44771525,2 1,2 Z M1,14 L19,14 C19.5522847,14 20,13.5522847 20,13 C20,12.4477153 19.5522847,12 19,12 L1,12 C0.44771525,12 0,12.4477153 0,13 C0,13.5522847 0.44771525,14 1,14 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-466, -463)">
          <g id="menu" transform="translate(468, 468)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
