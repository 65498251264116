export * from './signup';
export * from './submissions';
export * from './appSettings';

export enum Language {
  De = 'de-DE',
}

export interface PaginatedResponse<T> {
  items: T[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface ServerBase64 {
  base64: string;
  name: string;
}

export interface IFile {
  extension?: string;
  id?: string;
  name?: string;
  path?: string;
}
