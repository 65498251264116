import { FileInputController } from '@components';
import { useConnectSocket, useIsMobile } from '@hooks';
import { CameraIcon, UploadIcon } from '@icons';
import { Box, Typography, useTheme } from '@mui/material';
import { convertFileListToArray } from '@utils';
import QRCode from 'qrcode';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SocketApi from '../../../../shared/api/socket';

interface IUploadFilesProps {
  open?: boolean;
  method: { scan?: boolean; load?: boolean };
  formFieldName: string;
  onFileUpload?: () => void;
  customerId?: string;
  handleClose: () => void;
}

const SelectUploadMethod: FC<IUploadFilesProps> = ({
  open,
  method,
  formFieldName,
  onFileUpload,
  customerId,
  handleClose,
}) => {
  const theme = useTheme();
  const { control, setValue, watch } = useFormContext();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();
  useConnectSocket();

  const [qrSrc, setQrSrc] = useState('');

  useEffect(() => {
    if (open && method.scan) {
      const token = localStorage.getItem('accessToken');

      const redirectUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_FULL_URL}/mobile-upload`;
      const timestamp = new Date().getTime();
      const data = `${redirectUrl}?token=${token}&customerId=${customerId}&formFieldName=${formFieldName}&timestamp=${timestamp}`;

      QRCode.toDataURL(data).then(setQrSrc);
    }
  }, [open]);

  useEffect(() => {
    const handleClientPath = (data: any) => {
      const filesArray = data.dto.files.map((fileData: { name: string; data: string }) => {
        const byteCharacters = atob(fileData.data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const fileTypeFromBase64 = fileData.data.split(':')[1].split(';')[0];

        const file = new File([byteArray], fileData.name, { type: fileTypeFromBase64 });
        return file;
      });

      const fileList = new DataTransfer();
      filesArray.forEach((file: any) => {
        fileList.items.add(file);
      });

      if (fileList.files.length > 0) {
        const fileListArray = convertFileListToArray(fileList.files);
        const prevListArray = watch(data.dto.formFieldName);

        const isFileArrayEqual = (file1: any, file2: any) => {
          return (
            file1.name === file2.name &&
            file1.size === file2.size &&
            file1.type === file2.type &&
            Array.from(file1).every((byte, index) => byte === file2[index])
          );
        };

        const arraysAreDifferent = prevListArray
          ? prevListArray.some((prevFile: any) => {
              return !fileListArray.some((file) => isFileArrayEqual(prevFile, file));
            })
          : true;

        if (!arraysAreDifferent || !prevListArray) {
          setValue(data.dto.formFieldName, fileListArray);
        } else {
          const isFileEqual = (file1: any, file2: any) => {
            return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type;
          };

          const newFiles = fileListArray.filter((file) => {
            return !prevListArray.some((prevFile: any) => isFileEqual(prevFile, file));
          });

          setValue(data.dto.formFieldName, [...prevListArray, ...newFiles]);
        }

        handleClose();
      }
    };

    if (!SocketApi.socket?.hasListeners('client-path')) {
      SocketApi.socket?.on('client-path', handleClientPath);
    }

    return () => {
      SocketApi.socket?.off('client-path', handleClientPath);
      SocketApi.socket?.disconnect();
    };
  }, []);

  return (
    <Box sx={{ px: '15px' }}>
      {method.scan ? (
        <CameraIcon width="32px" height="32px" color={theme.palette.primary.main} />
      ) : (
        method.load && <UploadIcon width="32px" height="32px" color={theme.palette.primary.main} />
      )}
      <Typography variant="h3" sx={{ mt: '8px' }}>
        {method.scan ? (
          <FormattedMessage id="submissionCreation.uploadDocuments.byPhone.title" />
        ) : (
          method.load && <FormattedMessage id="submissionCreation.uploadDocuments.uploadFile.title" />
        )}
      </Typography>
      <Typography sx={{ mt: '16px', maxWidth: '347px' }}>
        {method.scan ? (
          <FormattedMessage id="submissionCreation.uploadDocuments.byPhone.description" />
        ) : (
          method.load && <FormattedMessage id="submissionCreation.uploadDocuments.uploadFile.description" />
        )}
      </Typography>
      {method.scan ? (
        <Box sx={{ mt: '22px' }}>
          <img src={qrSrc} alt="Scan" width={220} height="auto" />
        </Box>
      ) : (
        method.load && (
          <Box sx={{ height: isMobile ? '90px' : '220px' }}>
            <Box sx={{ pt: isMobile ? '25px' : '107px' }}>
              <FileInputController
                showIcon={false}
                name={formFieldName}
                control={control}
                title={formatMessage({ id: 'submissionCreation.uploadDocuments.uploadFile.button' })}
                additionalOnChange={onFileUpload}
              />
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default SelectUploadMethod;
