import { Button, Checkbox, DatePicker, IconButton, Input, Radio, StepperLayout } from '@components';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Color } from '@themes';
import React from 'react';
import {
  BackgroundColorBox,
  ErrorColorBox,
  PrimaryColorBox,
  PrimaryDarkColorBox,
  PrimaryLightColorBox,
  SecondaryColorBox,
  SuccessColorBox,
} from './styles';

const ToolkitPage: React.FC = () => {
  return (
    <StepperLayout
      steps={[
        {
          id: '1',
          title: 'First step',
          content: (
            <Grid container gap={2}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Headline 1</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">Headline 2</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Headline 3</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Headline 4</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Copy Standard Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                    Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
                    quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Copy Standard Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                    Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
                    quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
                  </Typography>
                </Grid>
                <Grid item container direction="row" xs={12} gap={2}>
                  <PrimaryColorBox />
                  <PrimaryDarkColorBox />
                  <PrimaryLightColorBox />
                </Grid>
                <Grid item container direction="row" xs={12} gap={2}>
                  <SecondaryColorBox />
                  <SuccessColorBox />
                  <ErrorColorBox />
                  <BackgroundColorBox />
                </Grid>

                <Grid item container direction="column" xs={12} mt={2}>
                  <Stack gap={2} direction="column">
                    <Typography>Input component</Typography>
                    <Input label="Default" placeholder="Vorname eingeben …" width={400} />
                    <Input label="Disabled" placeholder="Vorname eingeben …" width={400} disabled />
                    <Input label="Password" placeholder="Vorname passwort …" password width={400} />
                    <Input label="Error" placeholder="Vorname passwort …" width={400} error helperText="Error example" />
                    <DatePicker label="Date picker" width={400} />
                  </Stack>
                </Grid>

                <Typography mt={2}>Checkbox</Typography>
                <Grid item container direction="row" xs={12} gap={2}>
                  <Stack alignItems="center" direction="row">
                    <Checkbox />
                    <Typography fontWeight="400">Default</Typography>
                  </Stack>
                  <Stack alignItems="center" direction="row">
                    <Checkbox checked />
                    <Typography fontWeight="400">Checked</Typography>
                  </Stack>
                  <Stack alignItems="center" direction="row">
                    <Checkbox disabled />
                    <Typography fontWeight="400">Disabled</Typography>
                  </Stack>
                </Grid>

                <Typography>Radio</Typography>
                <Grid item container direction="row" xs={12} gap={2} alignItems="center">
                  <Stack alignItems="center" direction="row">
                    <Radio />
                    <Typography fontWeight="400">Default</Typography>
                  </Stack>
                  <Stack alignItems="center" direction="row">
                    <Radio checked />
                    <Typography fontWeight="400">Checked</Typography>
                  </Stack>
                  <Stack alignItems="center" direction="row">
                    <Radio disabled />
                    <Typography fontWeight="400">Disabled</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <IconButton showLeftIcon>Kind hinzufügen</IconButton>
              <Grid item container direction="row" xs={12} gap={2}>
                <Button color="primary" variant="contained">
                  Yes
                </Button>
                <Button color="primary" variant="outlined">
                  No
                </Button>
                <Box
                  sx={{
                    backgroundColor: Color.Blue,
                    width: '50%',
                    height: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button color="secondary" variant="contained">
                    Yes
                  </Button>
                </Box>
                <Button color="error" variant="contained">
                  Leave
                </Button>
              </Grid>
            </Grid>
          ),
        },
        { id: '2', title: 'Second step', content: <div /> },
        { id: '3', title: 'Third step', content: <div />, disabled: true },
      ]}
    />
  );
};

export default ToolkitPage;
