import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<TextFieldProps & { backgroundMode: 'white' | 'gray' }>(
  ({ theme, backgroundMode }) => ({
    '.MuiOutlinedInput-input': {
      padding: '12px 16px',
    },

    '.MuiInputBase-multiline': {
      padding: 0,
    },

    '& .MuiInputAdornment-root': {
      paddingRight: '12px',
      marginRight: 0,
    },

    '& .MuiOutlinedInput-root': {
      paddingRight: '8px',
      backgroundColor: backgroundMode === 'gray' ? theme.palette.grey['600'] : theme.palette.secondary.main,
      '&.Mui-focused': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:hover': {
        fieldset: {
          borderWidth: 2,
          borderColor: `${theme.palette.primary.main}`,
        },
        '&.Mui-disabled fieldset': {
          borderWidth: 1,
          borderColor: `${theme.palette.grey['400']}`,
        },
      },
      '&.Mui-error fieldset': {
        borderWidth: 2,
        borderColor: `${theme.palette.error.main}`,
      },
    },

    '.MuiInputLabel-root': {
      position: 'static',
      transform: 'none',
      transition: 'none',
      marginBottom: '8px',
      fontSize: '16px',
      fontWeight: '400',
      color: theme.palette.secondary.dark,

      '&.Mui-focused': {
        color: theme.palette.secondary.dark,
      },
      '&.Mui-error': {
        color: theme.palette.secondary.dark,
      },
    },

    legend: { width: 0 },

    '& ::placeholder': {
      fontSize: '16px',
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  }),
);
