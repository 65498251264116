import Loader from '@components/Loader';
import { useIsMobile } from '@hooks';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectMethodsBox = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();

  return {
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    marginTop: '48px',
    padding: '24px 44px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: isMobile ? 20 : 0,
  };
});

export const StyledLoader = styled(Loader)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: '10',
}));
