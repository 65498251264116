import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Users({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>users</title>
      <defs>
        <path
          d="M13,12 C15.7614237,12 18,14.2385763 18,17 L18,19 C18,19.5522847 17.5522847,20 17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,15.3431458 14.6568542,14 13,14 L5,14 C3.34314575,14 2,15.3431458 2,17 L2,19 C2,19.5522847 1.55228475,20 1,20 C0.44771525,20 0,19.5522847 0,19 L0,17 C0,14.2385763 2.23857625,12 5,12 Z M19.0317541,12.8800003 C19.1698251,12.3452528 19.7152523,12.0236831 20.2499997,12.1617541 C22.4562482,12.7314043 23.9983001,14.7206513 24,17 L24,19 C24,19.5522847 23.5522847,20 23,20 C22.4477153,20 22,19.5522847 22,19 L22.0000003,17.0007459 C21.9989805,15.6335842 21.0737494,14.440036 19.7500003,14.0982459 C19.2152528,13.9601749 18.8936831,13.4147477 19.0317541,12.8800003 Z M9,0 C11.7614237,0 14,2.23857625 14,5 C14,7.76142375 11.7614237,10 9,10 C6.23857625,10 4,7.76142375 4,5 C4,2.23857625 6.23857625,0 9,0 Z M15.03125,0.881960815 C15.1682383,0.346934963 15.7130133,0.0242617405 16.2480392,0.16125 C18.4604327,0.727712235 20.0078433,2.72123893 20.0078433,5.005 C20.0078433,7.28876107 18.4604327,9.28228777 16.2480392,9.84875 C15.7130133,9.98573826 15.1682383,9.66306504 15.03125,9.12803919 C14.8942617,8.59301333 15.216935,8.04823826 15.7519608,7.91125 C17.0793969,7.57137266 18.0078433,6.37525664 18.0078433,5.005 C18.0078433,3.63474336 17.0793969,2.43862734 15.7519608,2.09875 C15.216935,1.96176174 14.8942617,1.41698667 15.03125,0.881960815 Z M9,2 C7.34314575,2 6,3.34314575 6,5 C6,6.65685425 7.34314575,8 9,8 C10.6568542,8 12,6.65685425 12,5 C12,3.34314575 10.6568542,2 9,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1071, -84)">
          <g id="users" transform="translate(1071, 86)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
