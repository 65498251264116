import { ibanApi } from '@api';
import { useQuery } from '@tanstack/react-query';

const useValidateIBAN = (iban: string) => {
  return useQuery({
    queryKey: ['iban', iban],
    queryFn: () => ibanApi.validate(iban),
    enabled: iban?.replace(/ /g, '')?.length === 22,
    refetchOnMount: 'always',
  });
};

export default useValidateIBAN;
