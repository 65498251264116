import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function XCircle({
  color = '#000000',
  width = '24px',
  height = '24px',
  ...props
}: Record<string, unknown> & { color?: string; width?: string; height?: string }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>x-circle</title>
      <defs>
        <path
          d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 0,17.0751322 0,11 C0,4.92486775 4.92486775,0 11,0 Z M11,2 C6.02943725,2 2,6.02943725 2,11 C2,15.9705627 6.02943725,20 11,20 C15.9705627,20 20,15.9705627 20,11 C20,6.02943725 15.9705627,2 11,2 Z M7.29289322,7.29289322 C7.68341751,6.90236893 8.31658249,6.90236893 8.70710678,7.29289322 L11,9.585 L13.2928932,7.29289322 C13.6533772,6.93240926 14.2206082,6.90467972 14.6128994,7.20970461 L14.7071068,7.29289322 C15.0976311,7.68341751 15.0976311,8.31658249 14.7071068,8.70710678 L12.415,11 L14.7071068,13.2928932 C15.0675907,13.6533772 15.0953203,14.2206082 14.7902954,14.6128994 L14.7071068,14.7071068 C14.3165825,15.0976311 13.6834175,15.0976311 13.2928932,14.7071068 L11,12.415 L8.70710678,14.7071068 C8.34662282,15.0675907 7.77939176,15.0953203 7.38710056,14.7902954 L7.29289322,14.7071068 C6.90236893,14.3165825 6.90236893,13.6834175 7.29289322,13.2928932 L9.585,11 L7.29289322,8.70710678 C6.93240926,8.34662282 6.90467972,7.77939176 7.20970461,7.38710056 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-307, -83)">
          <g id="x-circle" transform="translate(308, 84)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
