import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Music({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>music</title>
      <defs>
        <path
          d="M7,14.0002475 C7.55228475,14.0002475 8,14.4479627 8,15.0002475 L8,17.0002475 C8,18.6571017 6.65685425,20.0002475 5,20.0002475 L3,20.0002475 C1.34314575,20.0002475 0,18.6571017 0,17.0002475 C0,15.3433932 1.34314575,14.0002475 3,14.0002475 L7,14.0002475 Z M6,16.0002475 L3,16.0002475 C2.44771525,16.0002475 2,16.4479627 2,17.0002475 C2,17.5525322 2.44771525,18.0002475 3,18.0002475 L5,18.0002475 C5.55228475,18.0002475 6,17.5525322 6,17.0002475 L6,16.0002475 Z M20,15.0002475 C20,16.6571017 18.6568542,18.0002475 17,18.0002475 L15,18.0002475 C13.3431458,18.0002475 12,16.6571017 12,15.0002475 C12,13.3433932 13.3431458,12.0002475 15,12.0002475 L18,12 L18,2.18070789 L8,3.84737456 L8,15.0002475 C8,15.5525322 7.55228475,16.0002475 7,16.0002475 C6.44771525,16.0002475 6,15.5525322 6,15.0002475 L6,3.00024747 C6,2.51140831 6.35341304,2.09421821 6.83560101,2.01385354 L18.835601,0.0138535435 C19.4451327,-0.0877350742 20,0.38230803 20,1.00024747 L20,15.0002475 Z M18,14.0002475 L15,14.0002475 C14.4477153,14.0002475 14,14.4479627 14,15.0002475 C14,15.5525322 14.4477153,16.0002475 15,16.0002475 L17,16.0002475 C17.5522847,16.0002475 18,15.5525322 18,15.0002475 L18,14.0002475 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1150, -460)">
          <g id="music" transform="translate(1152, 462)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
