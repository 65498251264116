import ministryLogo from '@assets/ministry-logo.jpg';
import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FooterContent, FooterWrapper } from './styles';

const Footer: React.FC = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <FooterWrapper>
      <FooterContent>
        <Typography variant="body1" sx={{ fontSize: 12, color: theme.palette.grey['200'] }}>
          <FormattedMessage id="login.footer.subtitle" />
        </Typography>
        <img src={ministryLogo} alt={formatMessage({ id: 'common.ministryName' })} width={theme.spacing(22)} />
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;
