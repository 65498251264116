import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.default,

  [theme.breakpoints.down('md')]: {
    position: 'static',
    paddingTop: theme.spacing(8),
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  boxSizing: 'content-box',
  maxWidth: theme.spacing(117),
  padding: `${theme.spacing(0, 3)}`,
  display: 'flex',
  gap: theme.spacing(13),
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: 56,
  fontWeight: 'bold',
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6.5),
  fontWeight: 'normal',
  '& span': { fontWeight: '600' },

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}));

export const LoginBox = styled(Paper)(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(47),
  padding: theme.spacing(4),
  paddingTop: theme.spacing(5),
  boxSizing: 'border-box',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const FooterWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  boxSizing: 'border-box',
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.secondary.main,

  [theme.breakpoints.down('md')]: {
    position: 'static',
  },
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  maxWidth: theme.spacing(117),
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    maxWidth: 'fit-content',
  },
}));
