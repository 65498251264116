import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ThumbsUp({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>thumbs-up</title>
      <defs>
        <path
          d="M18.6486763,7.00006412 C19.5288038,6.99009715 20.368906,7.36714393 20.946464,8.03133562 C21.524022,8.69552732 21.7807603,9.5798471 21.6484478,10.451562 L20.2686957,19.4499361 C20.0443874,20.9290503 18.7646258,22.0168476 17.28,22.000193 L6,22.000193 C5.44771525,22.000193 5,21.5522847 5,21 L5,10 C5,9.86008166 5.02936223,9.72172053 5.08618845,9.59386153 L9.08618845,0.593861534 C9.24668979,0.232733521 9.60481128,0 10,0 C12.209139,0 14,1.790861 14,4 L14,7.00001135 L18.6486763,7.00006412 Z M7,20.0001287 L17.2913043,20.0001287 C17.7899477,20.0057011 18.2165349,19.643102 18.2915522,19.148438 L19.6713237,10.1499359 C19.7153517,9.85986354 19.6297723,9.56509029 19.437253,9.34369305 C19.2447337,9.12229582 18.9646996,8.99661356 18.66,9 L13,9 C12.4477153,9 12,8.55228475 12,8 L12,4 C12,3.107435 11.4153102,2.35143161 10.6080478,2.09410694 L7,10.2122145 L7,20.0001287 Z M5,11 L3,11 C2.44771525,11 2,11.4477153 2,12 L2,19 C2,19.5522847 2.44771525,20 3,20 L5,20 L5,11 Z M6,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,12 C0,10.3431458 1.34314575,9 3,9 L6,9 C6.55228475,9 7,9.44771525 7,10 L7,21 C7,21.5522847 6.55228475,22 6,22 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-93, -83)">
          <g id="thumbs-up" transform="translate(94, 84)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
