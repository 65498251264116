import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Umbrella({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>umbrella</title>
      <defs>
        <path
          d="M23.9955644,10.9049774 C24.0516112,11.492135 23.5899158,12 23.0000893,12 L1.00008927,12 C0.410262754,12 -0.0514327077,11.492135 0.00461415571,10.9049774 C0.594845647,4.72159985 5.78860548,0 12.0000893,0 C18.2115731,0 23.4053329,4.72159985 23.9955644,10.9049774 Z M12.0000893,2 C7.1929261,2 3.11662932,5.390761 2.16071025,10 L21.8394683,10 C20.8835492,5.390761 16.8072524,2 12.0000893,2 Z M13.0000893,13.7032574 L13.0000893,18 C13.0000893,19.1045695 13.8955198,20 15.0000893,20 C15.9898245,20 16.8116456,19.2810746 16.9718262,18.3369505 L13.0000893,13.7032574 Z M19.0000893,18 C19.0000893,20.209139 17.2092283,22 15.0000893,22 C12.7909503,22 11.0000893,20.209139 11.0000893,18 L11.0000893,11 C11.0000893,10.0715937 12.1551471,9.64431003 12.7593459,10.3492086 L18.7593459,17.3492086 C18.9146971,17.5304517 19.0000893,17.7612887 19.0000893,18 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1720, -140)">
          <g id="umbrella" transform="translate(1720, 141)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
