import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Instagram({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>instagram</title>
      <defs>
        <path
          d="M16,0 C19.3137085,0 22,2.6862915 22,6 L22,16 C22,19.3137085 19.3137085,22 16,22 L6,22 C2.6862915,22 0,19.3137085 0,16 L0,6 C0,2.6862915 2.6862915,0 6,0 L16,0 Z M16,2 L6,2 C3.790861,2 2,3.790861 2,6 L2,16 C2,18.209139 3.790861,20 6,20 L16,20 C18.209139,20 20,18.209139 20,16 L20,6 C20,3.790861 18.209139,2 16,2 Z M11.7766835,6.01081652 C13.9554044,6.33389346 15.6661065,8.04459556 15.9891835,10.2233165 C16.3055865,12.357031 15.2179658,14.4531222 13.2912166,15.4229088 C11.3644675,16.3926955 9.03299419,16.0175298 7.50773217,14.4922678 C5.98247016,12.9670058 5.60730452,10.6355325 6.57709117,8.70878336 C7.54687783,6.78203422 9.64296899,5.69441347 11.7766835,6.01081652 Z M8.36356114,9.60796358 C7.78168914,10.7640131 8.00678853,12.1628971 8.92194574,13.0780543 C9.83710295,13.9932115 11.2359869,14.2183109 12.3920364,13.6364389 C13.5480859,13.0545669 14.2006583,11.7969122 14.0108165,10.5166835 C13.8169704,9.2094509 12.7905491,8.18302964 11.4833165,7.98918348 C10.2030878,7.79934165 8.94543313,8.4519141 8.36356114,9.60796358 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-768, -320)">
          <g id="instagram" transform="translate(769, 321)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
