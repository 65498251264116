import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Moon({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>moon</title>
      <defs>
        <path
          d="M9.14418767,10.8180303 C6.90278993,8.57663254 6.24397847,5.29260898 7.27752593,2.437982 C4.09633644,3.5873034 1.89540402,6.67837285 2.00385273,10.2078365 C2.13368986,14.4333868 5.52883112,17.8285281 9.7543814,17.9583652 C13.2838451,18.0668139 16.3749145,15.8658815 17.5242359,12.684692 C14.669609,13.7182395 11.3855854,13.059428 9.14418767,10.8180303 Z M19.9576498,10.8823459 C19.4713729,16.1443552 14.9748949,20.1197182 9.69295703,19.9574217 C4.41101918,19.7951253 0.167092606,15.5511988 0.00479618902,10.2692609 C-0.157500228,4.98732307 3.81786273,0.490845006 9.07987207,0.00456809253 C9.94001953,-0.074920526 10.4895134,0.900085362 9.97605691,1.59473245 C8.21065285,3.98311963 8.45827685,7.30369233 10.5584012,9.40381671 C12.6585256,11.5039411 15.9790983,11.7515651 18.3674855,9.98616102 C19.0621326,9.47270458 20.0371385,10.0221984 19.9576498,10.8823459 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1150, -392)">
          <g id="moon" transform="translate(1152, 394)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
