import { Button, DatePickerController, InputController } from '@components';
import { LocalStorageKey, ViewportBreakpoint } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidateIBAN, useViewportSize } from '@hooks';
import { InfoIcon } from '@icons';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import useSignUp from '../../../../shared/hooks/useSignUp';
import { convertSignUpFormValuesToDto } from '../../dto';
import SignUpFormSection from '../SignUpFormSection';
import { Form } from './styles';

interface ISignUpFormProps {
  className?: string;
}

interface ISignUpValues {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phone: string;
  bank: string;
  iban: string;
  password: string;
  repeatPassword: string;
}

const SignUpForm: React.FC<ISignUpFormProps> = ({ className }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState<string | null>(null);

  const viewportWidth = useViewportSize();
  const isMobile = viewportWidth <= ViewportBreakpoint.Mobile;

  const createCustomer = useSignUp();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(formatMessage({ id: 'signup.email.required.title' }))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, formatMessage({ id: 'signup.email.valid.title' })),
    firstName: yup.string().required(formatMessage({ id: 'signup.firstName.required.title' })),
    lastName: yup.string().required(formatMessage({ id: 'signup.lastName.required.title' })),
    dateOfBirth: yup
      .string()
      .test('incomplete-date-of-birth', formatMessage({ id: 'signup.dateOfBirth.required.title' }), (value) => {
        if (!value || value === 'Invalid Date') return false;

        return true;
      }),
    street: yup.string().required(formatMessage({ id: 'signup.street.required.title' })),
    houseNumber: yup.string().required(formatMessage({ id: 'signup.houseNumber.required.title' })),
    zipCode: yup.string().required(formatMessage({ id: 'signup.zipCode.required.title' })),
    city: yup.string().required(formatMessage({ id: 'signup.city.required.title' })),
    phone: yup
      .string()
      .required(formatMessage({ id: 'signup.phone.required.title' }))
      .matches(
        /^\+?\d{1,4}?[-\s]?\(?\d{1,3}?\)?[-\s]?\d{1,4}[-\s]?\d{1,4}[-\s]?\d{1,9}$/,
        formatMessage({ id: 'signup.phone.invalid.title' }),
      )
      .test('optional-parentheses', formatMessage({ id: 'signup.phone.invalidParentheses.title' }), (value) => {
        if (!value) return true;
        const match = value.match(/\((\d*)\)/);
        return !match || (match && match[1].length > 0);
      }),
    bank: yup.string().required(formatMessage({ id: 'signup.bank.required.title' })),
    iban: yup
      .string()
      .required(formatMessage({ id: 'signup.iban.required.title' }))
      .matches(/^DE\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}$/, formatMessage({ id: 'signup.iban.invalid.title' })),
    password: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .matches(
        /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{8,12})|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,})$/,
        formatMessage({ id: 'signup.password.requirements.title' }),
      ),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .oneOf([yup.ref('password'), ''], formatMessage({ id: 'signup.password.match.title' })),
  });

  const methods = useForm<ISignUpValues>({
    resolver: yupResolver(schema) as unknown as Resolver<ISignUpValues, ResolverOptions<ISignUpValues>>,
    defaultValues: localStorage.getItem(LocalStorageKey.ZipCode)
      ? { zipCode: localStorage.getItem(LocalStorageKey.ZipCode) || '' }
      : {},
  });
  const { control, watch, handleSubmit, formState } = methods;
  const { errors } = formState;

  const validateIBAN = useValidateIBAN(watch('iban'));

  useEffect(() => {
    setFormErrors(Object.keys(errors).length > 0 ? formatMessage({ id: 'signup.mandatory' }) : null);
  }, [errors]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^\d\s+()/-]/g, '');
    methods.setValue('phone', inputValue, { shouldValidate: !!formErrors });
  };

  const handleIbanBlur = () => {
    const bankName = validateIBAN.data?.data.bankData.name || '';

    methods.setValue('bank', bankName, { shouldValidate: true });
  };

  const handleFormSubmit = async (data: ISignUpValues) => {
    data.iban = data.iban.replace(/\s/g, '');

    const res = await createCustomer.mutateAsync(convertSignUpFormValuesToDto(data));

    if (res instanceof AxiosError) {
      if (res.response?.data.message === 'Customer with this email already exists') {
        setFormErrors(formatMessage({ id: `signup.serverError[${res.response.data.message}]` }));
      } else if (res.response?.status === 409) {
        const days = res.response?.data.message.replace(/[^\d]/g, '');
        setFormErrors(formatMessage({ id: 'signup.error.iban.limit.30days' }, { days }));
      } else {
        setFormErrors(formatMessage({ id: 'signup.error.common' }));
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form className={className} onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container gap="32px" flexDirection="column" alignItems="center">
          <SignUpFormSection title="signup.yourName.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="firstName"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.firstName.placeholder' })}
                  label={formatMessage({ id: 'signup.firstName.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="lastName"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.lastName.placeholder' })}
                  label={formatMessage({ id: 'signup.lastName.label' })}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <DatePickerController
                  fullWidth
                  name="dateOfBirth"
                  control={control}
                  label={formatMessage({ id: 'common.user.birthDate' })}
                  error={!!errors.dateOfBirth}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourContactDetails.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="phone"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.phoneNumber.placeholder' })}
                  label={formatMessage({ id: 'signup.phoneNumber.label' })}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  onChange={handlePhoneChange}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="email"
                  email
                  control={control}
                  placeholder={formatMessage({ id: 'signup.email.placeholder' })}
                  label={formatMessage({ id: 'signup.email.label' })}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px', mt: '24px' }}>
              <Box sx={{ maxWidth: 215, width: '100%' }}>
                <InputController
                  fullWidth
                  name="zipCode"
                  control={control}
                  disabled={!!localStorage.getItem(LocalStorageKey.ZipCode)}
                  placeholder={formatMessage({ id: 'signup.postCode.placeholder' })}
                  label={formatMessage({ id: 'signup.postCode.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 535, width: '100%' }}>
                <InputController
                  fullWidth
                  name="city"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.location.placeholder' })}
                  label={formatMessage({ id: 'signup.location.label' })}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourAdress.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 615, width: '100%' }}>
                <InputController
                  fullWidth
                  name="street"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.street.placeholder' })}
                  label={formatMessage({ id: 'signup.street.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 135, width: '100%' }}>
                <InputController
                  fullWidth
                  name="houseNumber"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.houseNumber.placeholder' })}
                  label={formatMessage({ id: 'signup.houseNumber.label' })}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.yourBankDetails.section.title">
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 295, width: '100%' }}>
                <InputController
                  fullWidth
                  name="iban"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.iban.placeholder' })}
                  label={formatMessage({ id: 'signup.iban.label' })}
                  onBlur={handleIbanBlur}
                />
              </Box>
              <Box sx={{ maxWidth: 455, width: '100%' }}>
                <InputController
                  fullWidth
                  name="bank"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.bank.placeholder' })}
                  label={formatMessage({ id: 'signup.bank.label' })}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <SignUpFormSection title="signup.setPassword.section.title" showBorder={false}>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 375, width: '100%' }}>
                <InputController
                  fullWidth
                  name="password"
                  password
                  control={control}
                  placeholder={formatMessage({ id: 'signup.password.placeholder' })}
                  label={formatMessage({ id: 'signup.password.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 375, width: '100%' }}>
                <InputController
                  fullWidth
                  password
                  name="repeatPassword"
                  control={control}
                  placeholder={formatMessage({ id: 'signup.repeatPassword.placeholder' })}
                  label={formatMessage({ id: 'signup.repeatPassword.label' })}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: formErrors ? 'space-between' : 'flex-end',
              borderTop: `1px solid ${theme.palette.grey[500]}`,
              pt: '30px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {formErrors && (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Box sx={{ flexShrink: 0 }}>
                  <InfoIcon color={theme.palette.error.main} />
                </Box>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: theme.palette.grey[200],
                      '& > span': { fontWeight: '600', color: theme.palette.grey[200] },
                    }}
                    dangerouslySetInnerHTML={{ __html: formErrors }}
                  />
                </Box>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{
                flexShrink: 0,
                alignSelf: 'start',
                [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
                  width: '100%',
                },
              }}
            >
              <FormattedMessage id="signup.completeRegistration.button.title" />
            </Button>
          </Box>
        </Grid>
      </Form>
    </FormProvider>
  );
};

export default SignUpForm;
