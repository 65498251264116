import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function RefreshCcw({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>refresh-ccw</title>
      <defs>
        <path
          d="M22.9324368,13.6379297 C22.9778547,13.7550094 23.0003061,13.8787317 23.000006,14.002277 L23.000006,20 C23.000006,20.5522847 22.5522877,21 22.000003,21 C21.4477182,21 21.000003,20.5522847 21.000003,20 L21.000003,16.311618 L18.0672869,19.0669306 C15.639999,21.4954345 12.1365161,22.5088202 8.78760549,21.7510871 C5.43869488,20.9933541 2.71271096,18.5704733 1.56728692,15.3335985 C1.3830468,14.812951 1.65575903,14.2415267 2.17640673,14.0572866 C2.69705443,13.8730466 3.26847891,14.1457587 3.45271902,14.6664062 C4.36905825,17.2559061 6.54984539,19.1942107 9.22897388,19.8003972 C11.9081024,20.4065836 14.7108887,19.595875 16.6752264,17.6312484 L19.475455,15 L16.000003,15 C15.4477182,15 15.000003,14.5522847 15.000003,14 C15.000003,13.4477153 15.4477182,13 16.000003,13 L22.000003,13 C22.4198098,13 22.779197,13.2586871 22.9274561,13.6253528 L22.9324368,13.6379297 Z M0.0675721698,8.3598938 C0.0221542499,8.24281406 -0.000297087395,8.1190918 2.96788312e-06,7.99554651 L2.96788312e-06,1.9978235 C2.96788312e-06,1.44553875 0.447721246,0.997823502 1.00000297,0.997823502 C1.55229075,0.997823502 2.00000297,1.44553875 2.00000297,1.9978235 L2.00000297,5.68620553 L4.93272205,2.93089295 C7.36001,0.502389023 10.8634929,-0.510996689 14.2124035,0.246736367 C17.5613141,1.00446942 20.287298,3.42735017 21.432722,6.66422502 C21.6169622,7.18487255 21.3442499,7.75629683 20.8236022,7.94053688 C20.3029545,8.12477693 19.7315301,7.85206479 19.5472899,7.33141727 C18.6309507,4.74191739 16.4501636,2.80361279 13.7710351,2.19742634 C11.0919066,1.5912399 8.28912031,2.40194847 6.32478258,4.3665751 L3.52455398,6.9978235 L7.00000297,6.9978235 C7.55229075,6.9978235 8.00000297,7.44553875 8.00000297,7.9978235 C8.00000297,8.55010825 7.55229075,8.9978235 7.00000297,8.9978235 L1.00000297,8.9978235 C0.580199155,8.9978235 0.220811929,8.73913638 0.0725529062,8.37247073 L0.0675721698,8.3598938 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-306, -669)">
          <g id="refresh-ccw" transform="translate(306, 670)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
