import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Image({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>image</title>
      <defs>
        <path
          d="M17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 L2.94195983,19.9983247 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 L17,0 Z M14,9.41421356 L5.414,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,13.415 L14,9.41421356 Z M17,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.4289102 2.27002785,17.794752 2.64935664,17.9367986 L13.2928932,7.29289322 C13.6834175,6.90236893 14.3165825,6.90236893 14.7071068,7.29289322 L18,10.585 L18,3 C18,2.44771525 17.5522847,2 17,2 Z M6.5,4 C7.88071187,4 9,5.11928813 9,6.5 C9,7.88071187 7.88071187,9 6.5,9 C5.11928813,9 4,7.88071187 4,6.5 C4,5.11928813 5.11928813,4 6.5,4 Z M6.5,6 C6.22385763,6 6,6.22385763 6,6.5 C6,6.77614237 6.22385763,7 6.5,7 C6.77614237,7 7,6.77614237 7,6.5 C7,6.22385763 6.77614237,6 6.5,6 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-769, -527)">
          <g id="image" transform="translate(771, 529)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
