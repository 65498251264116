import axiosOriginal, { HttpStatusCode } from 'axios';
import appConfig from '../../config';

const baseURL = `${appConfig.api.protocol}://${appConfig.api.hostname}:${appConfig.api.port}/${appConfig.api.prefix}`;

const axios = axiosOriginal.create({ baseURL });

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers.set({ 'Cache-Control': 'no-store, no-cache' });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  async (response) => {
    if (response.data?.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken);
    }

    return response;
  },
  async (error) => {
    // eslint-disable-next-line no-console
    console.error(error);

    if (error.response?.status === HttpStatusCode.Unauthorized) {
      localStorage.removeItem('accessToken');
    }

    return error;
  },
);

export default axios;
