import { Drawer, DrawerProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDrawer = styled(Drawer)<DrawerProps & { width?: number | string; top?: number | string }>(
  ({ width, top = 0 }) => ({
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: width || '420px',
      padding: '13px 27px 208px 46px',
      backgroundColor: '#f3f9ff',
      // backgroundColor: theme.palette.primary.light,
      border: 'none',
      top,
    },
  }),
);
