import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Thermometer({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>thermometer</title>
      <defs>
        <path
          d="M10.7636124,20.0981767 C10.0603822,22.4164895 7.92305049,24.0016574 5.50042645,24.0016574 C3.0778024,24.0016574 0.940470686,22.4164895 0.237240508,20.0981767 C-0.411939037,17.9580505 0.302148639,15.6584563 2.00042645,14.2587994 L2.00042645,3.5 C2.00042645,1.56700338 3.56742982,0 5.50042645,0 C7.43342307,0 9.00042645,1.56700338 9.00042645,3.5 L9.00042645,14.2587994 C10.6987043,15.6584563 11.4127919,17.9580505 10.7636124,20.0981767 Z M7.00042645,14.76 L7.00042645,3.5 C7.00042645,2.67157288 6.32885357,2 5.50042645,2 C4.67199932,2 4.00042645,2.67157288 4.00042645,3.5 L4.00042645,14.76 C4.00042645,15.0939889 3.83368687,15.40593 3.555982,15.5914794 C2.27411525,16.4479627 1.70361619,18.0423342 2.1511263,19.5176242 C2.59863642,20.9929142 3.9587566,22.0016574 5.50042645,22.0016574 C7.04209629,22.0016574 8.40221647,20.9929142 8.84972659,19.5176242 C9.2972367,18.0423342 8.72673764,16.4479627 7.44487089,15.5914794 C7.16716602,15.40593 7.00042645,15.0939889 7.00042645,14.76 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1359, -139)">
          <g id="thermometer" transform="translate(1365, 139)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-6, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
