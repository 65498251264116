import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Tag({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>tag</title>
      <defs>
        <path
          d="M18.8828932,10.2971068 L10.5857864,2 L2,2 L2,10.5854453 L10.2975,18.8732867 C10.4850683,19.0610637 10.7395908,19.1665733 11.005,19.1665733 C11.2704092,19.1665733 11.5249317,19.0610637 11.7128932,18.8728932 L18.8816636,11.7041228 C19.2685161,11.3140186 19.2682273,10.6847468 18.8828932,10.2971068 Z M20.2992073,13.115 L13.1275,20.2867133 C12.5647952,20.8500445 11.8012277,21.1665733 11.005,21.1665733 C10.2087723,21.1665733 9.44520477,20.8500445 8.88330517,20.2875185 L0.293305166,11.7075185 C0.105516801,11.5199487 0,11.2654183 0,11 L0,1 C0,0.44771525 0.44771525,0 1,0 L11,0 C11.2652165,0 11.5195704,0.10535684 11.7071068,0.292893219 L20.2992073,8.885 C21.4623781,10.0551124 21.4623781,11.9448876 20.2992073,13.115 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-917, -140)">
          <g id="tag" transform="translate(918, 141)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
