import { DatePicker, FieldErrorMessage } from '@components';
import { IDatePickerProps } from '@components/DatePicker';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface IDatePickerControllerProps {
  name: string;
  control: Control<FieldValues> | Control<any>;
  label: string;
  fullWidth?: boolean;
}

const DatePickerController: FC<IDatePickerControllerProps & IDatePickerProps> = ({
  fullWidth,
  name,
  control,
  label,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DatePicker label={label} value={value || null} onChange={onChange} fullWidth={fullWidth} {...rest} />
          {error && <FieldErrorMessage message={error.message} />}
        </>
      )}
    />
  );
};

export default DatePickerController;
