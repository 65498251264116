import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function MapPin({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>map-pin</title>
      <defs>
        <path
          d="M10,0 C15.5228474,0 20,4.47715241 20,10 C20,13.3716045 18.1975984,16.6760073 15.2259527,19.8127445 C14.2070021,20.8883035 13.118253,21.8681776 12.0291768,22.735405 C11.6473986,23.0394136 11.2921737,23.3081093 10.9722093,23.5391947 C10.777224,23.6800174 10.6351454,23.77842 10.5547002,23.8320502 C10.2188008,24.0559831 9.78119922,24.0559831 9.4452998,23.8320502 C9.36485462,23.77842 9.22277597,23.6800174 9.0277907,23.5391947 C8.70782632,23.3081093 8.35260143,23.0394136 7.97082322,22.735405 C6.88174698,21.8681776 5.7929979,20.8883035 4.77404732,19.8127445 C1.80240157,16.6760073 0,13.3716045 0,10 C0,4.47715241 4.47715256,0 10,0 Z M10,2 C5.58172205,2 2,5.58172193 2,10 C2,12.7533953 3.57259843,15.6364924 6.22595268,18.4372552 C7.1757521,19.4398213 8.19637802,20.3583846 9.21667678,21.1708447 C9.49517108,21.3926087 9.75768846,21.5936102 10,21.7726167 C10.2423115,21.5936102 10.5048289,21.3926087 10.7833232,21.1708447 C11.803622,20.3583846 12.8242479,19.4398213 13.7740473,18.4372552 C16.4274016,15.6364924 18,12.7533953 18,10 C18,5.58172193 14.418278,2 10,2 Z M10,5.99999987 C12.209139,5.99999987 14,7.79086087 14,9.99999987 C14,12.2091389 12.209139,13.9999999 10,13.9999999 C7.790861,13.9999999 6,12.2091389 6,9.99999987 C6,7.79086087 7.790861,5.99999987 10,5.99999987 Z M10,7.99999987 C8.8954305,7.99999987 8,8.89543037 8,9.99999987 C8,11.1045694 8.8954305,11.9999999 10,11.9999999 C11.1045695,11.9999999 12,11.1045694 12,9.99999987 C12,8.89543037 11.1045695,7.99999987 10,7.99999987 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-692, -390)">
          <g id="map-pin" transform="translate(694, 390)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, 0)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
