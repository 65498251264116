import eventBus, { EventBusCallback } from '../eventBus';

const eventName = 'createSubmissionInFillingProcess';

const subscribe = (callback: EventBusCallback) => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (data?: any) => {
  return eventBus.broadcast(eventName, data);
};

export default {
  eventName,
  subscribe,
  broadcast,
};
