import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Edit2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>edit-2</title>
      <defs>
        <path
          d="M2,14.4142136 L2,18 L5.58578644,18 L17.5857864,6 L14,2.41421356 L2,14.4142136 Z M14.7071068,0.292893219 L19.7071068,5.29289322 C20.0976311,5.68341751 20.0976311,6.31658249 19.7071068,6.70710678 L6.70710678,19.7071068 C6.5195704,19.8946432 6.26521649,20 6,20 L1,20 C0.44771525,20 0,19.5522847 0,19 L0,14 C0,13.7347835 0.10535684,13.4804296 0.292893219,13.2928932 L13.2928932,0.292893219 C13.6834175,-0.0976310729 14.3165825,-0.0976310729 14.7071068,0.292893219 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-308, -253)">
          <g id="edit-2" transform="translate(310, 255)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
