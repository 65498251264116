import Button from '@components/Button';
import { styled } from '@mui/material/styles';
import { IStyledProps } from './IconButton';

export const StyledIconButton = styled(Button)<IStyledProps>(
  ({ theme, backgroundColor, textColor, border, borderBottomLeftRadius, borderBottomRightRadius }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    color: textColor || theme.palette.primary.main,
    border: border || `1px solid ${theme.palette.primary.main}`,
    background: backgroundColor || `${theme.palette.primary.main}10`,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  }),
);
