import { Button, PageLayout } from '@components';
import { styled } from '@mui/material/styles';

export const StyledPageLayout = styled(PageLayout)(() => ({
  height: 'calc(100% - 88px)',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.dark,
  borderTop: `1px solid ${theme.palette.grey[500]}`,
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
  width: '100vw',
  maxWidth: '100vw',
  borderRadius: 0,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(3, 1fr)',
  padding: '16px 30px',
  height: '64px',

  '&:hover': {
    background: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
}));

export const StyledInput = styled('input')(() => ({
  display: 'none',
}));
