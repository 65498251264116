import { PageLayout } from '@components';
import { withEligibilityCheck, withoutAuth } from '@hoc';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledSignUpBox, StyledSignUpForm, TitleBox } from './styles';

const SignUp: React.FC = () => {
  return (
    <PageLayout disableLogout>
      <Container maxWidth="lg" sx={{ pt: '24px' }}>
        <Grid direction="column" xs={12}>
          <TitleBox>
            <Box maxWidth="777px" margin="0 auto">
              <Typography variant="h1" sx={{ fontSize: '32px' }}>
                <FormattedMessage id="signup.title" />
              </Typography>
              <Typography sx={{ fontSize: '16px', mt: '8px' }}>
                <FormattedMessage id="signup.pleaseRegister.subtitle" />
              </Typography>
              <Typography sx={{ fontSize: '16px', mb: '31px' }}>
                <FormattedMessage id="signup.collectData.subtitle" />
              </Typography>
            </Box>
          </TitleBox>
          <StyledSignUpBox>
            <StyledSignUpForm />
          </StyledSignUpBox>
        </Grid>
      </Container>
    </PageLayout>
  );
};

export default withEligibilityCheck(withoutAuth(SignUp));
