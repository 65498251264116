import { Button, InputController } from '@components';
import { ViewportBreakpoint } from '@constants';
import { withAuth } from '@hoc';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePassword, useViewportSize } from '@hooks';
import { CheckIcon, InfoIcon } from '@icons';
import { Alert, Box, Grid, Slide, Snackbar, Typography, useTheme } from '@mui/material';
import { selectCurrentCustomer } from '@store/customer/slice';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import SignUpFormSection from '../../../SignUp/components/SignUpFormSection';
import { Form } from '../SettingsMainInfo/components/SettingsMainInfoForm/styles';

interface ISettingsPasswordFormProps {
  className?: string;
}

interface ISettingsPasswordValues {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

const SettingsPassword: React.FC<ISettingsPasswordFormProps> = ({ className }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState<string | null>(null);

  const currentCustomer = useSelector(selectCurrentCustomer);

  const viewportWidth = useViewportSize();
  const isMobile = viewportWidth <= ViewportBreakpoint.Mobile;

  const changePassword = useChangePassword();

  const schema = yup.object().shape({
    oldPassword: yup.string().required(formatMessage({ id: 'signup.password.required.title' })),
    password: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .matches(
        /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{8,12})|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,})$/,
        formatMessage({ id: 'signup.password.requirements.title' }),
      ),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .oneOf([yup.ref('password'), ''], formatMessage({ id: 'signup.password.match.title' })),
  });

  const methods = useForm<ISettingsPasswordValues>({
    resolver: yupResolver(schema) as unknown as Resolver<ISettingsPasswordValues, ResolverOptions<ISettingsPasswordValues>>,
  });
  const { control, handleSubmit, formState, reset } = methods;
  const { errors } = formState;

  useEffect(() => {
    setFormErrors(Object.keys(errors).length > 0 ? formatMessage({ id: 'signup.mandatory' }) : null);
  }, [errors]);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };

  const handleFormSubmit = async (data: ISettingsPasswordValues) => {
    if (currentCustomer && currentCustomer.id) {
      try {
        const res = await changePassword.mutateAsync({
          id: currentCustomer.id,
          passwords: { oldPassword: data.oldPassword, password: data.password },
        });
        if (res instanceof AxiosError) {
          if (res.response?.data.message === 'Old password is incorrect!') {
            setFormErrors(formatMessage({ id: `settings.serverError[${res.response.data.message}]` }));
          } else {
            setFormErrors(formatMessage({ id: 'settings.changePassword.error.common' }));
          }
        } else {
          reset();
          setAlertOpen(true);
          setTimeout(() => setAlertOpen(false), 5000);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          setFormErrors(formatMessage({ id: 'settings.changePassword.error.common' }));
        } else {
          console.error('Error:', error);
        }
      }
    } else {
      console.error('Customer not found!');
    }
  };

  return (
    <FormProvider {...methods}>
      <Form className={className} onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container gap="32px" flexDirection="column" alignItems="center">
          <SignUpFormSection title="settings.changePassword.title" showBorder={false}>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 357, width: '100%', mb: '24px' }}>
                <InputController
                  fullWidth
                  name="oldPassword"
                  password
                  control={control}
                  placeholder={formatMessage({ id: 'settings.changePassword.oldPassword.placeholder' })}
                  label={formatMessage({ id: 'settings.changePassword.oldPassword.label' })}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <Box sx={{ maxWidth: 375, width: '100%' }}>
                <InputController
                  fullWidth
                  name="password"
                  password
                  control={control}
                  placeholder={formatMessage({ id: 'settings.changePassword.newPassword.placeholder' })}
                  label={formatMessage({ id: 'settings.changePassword.newPassword.label' })}
                />
              </Box>
              <Box sx={{ maxWidth: 375, width: '100%' }}>
                <InputController
                  fullWidth
                  password
                  name="repeatPassword"
                  control={control}
                  placeholder={formatMessage({ id: 'settings.changePassword.repeatPassword.placeholder' })}
                  label={formatMessage({ id: 'settings.changePassword.repeatPassword.label' })}
                />
              </Box>
            </Box>
          </SignUpFormSection>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: formErrors ? 'space-between' : 'flex-end',
              borderTop: `1px solid ${theme.palette.grey[500]}`,
              pt: '30px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {formErrors && (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Box sx={{ flexShrink: 0 }}>
                  <InfoIcon color={theme.palette.error.main} />
                </Box>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: theme.palette.grey[200],
                      '& > span': { fontWeight: '600', color: theme.palette.grey[200] },
                    }}
                    dangerouslySetInnerHTML={{ __html: formErrors }}
                  />
                </Box>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{
                flexShrink: 0,
                alignSelf: 'start',
                [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
                  width: '100%',
                },
              }}
            >
              <FormattedMessage id="settings.updateAccount.button.title" />
            </Button>
            {alertOpen && (
              <Snackbar
                open={alertOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                TransitionComponent={Slide}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  <FormattedMessage id="settings.success.common" />
                </Alert>
              </Snackbar>
            )}
          </Box>
        </Grid>
      </Form>
    </FormProvider>
  );
};

export default withAuth(SettingsPassword);
