import { AdditionalDemand, SubmissionStatus } from '@app-types';
import { FC } from 'react';
import DocumentsRequested from './DocumentsRequested/DocumentsRequested';
import SubmissionAudit from './SubmissionAudit/SubmissionAudit';

interface IApplicationReviewProps {
  status: string | undefined;
  createdAt: string | undefined;
  additionalDemands: AdditionalDemand[] | undefined;
  submissionId: string | undefined;
  messageToRequestedDocuments: string | undefined;
}

const ApplicationReview: FC<IApplicationReviewProps> = ({
  status,
  createdAt,
  additionalDemands,
  submissionId,
  messageToRequestedDocuments,
}) => {
  return status && status === SubmissionStatus.DocumentRequested ? (
    <DocumentsRequested
      status={status}
      createdAt={createdAt}
      additionalDemands={additionalDemands}
      submissionId={submissionId}
      messageToRequestedDocuments={messageToRequestedDocuments}
    />
  ) : (
    <SubmissionAudit status={status} createdAt={createdAt} submissionId={submissionId} />
  );
};

export default ApplicationReview;
