import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Server({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>server</title>
      <defs>
        <path
          d="M3,2 C2.44771525,2 2,2.44771525 2,3 L2,7 C2,7.55228475 2.44771525,8 3,8 L19,8 C19.5522847,8 20,7.55228475 20,7 L20,3 C20,2.44771525 19.5522847,2 19,2 L3,2 Z M19,12 C20.6568542,12 22,13.3431458 22,15 L22,19 C22,20.6568542 20.6568542,22 19,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,15 C0,13.3431458 1.34314575,12 3,12 L19,12 Z M19,14 L3,14 C2.44771525,14 2,14.4477153 2,15 L2,19 C2,19.5522847 2.44771525,20 3,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,15 C20,14.4477153 19.5522847,14 19,14 Z M19,0 C20.6568542,0 22,1.34314575 22,3 L22,7 C22,8.65685425 20.6568542,10 19,10 L3,10 C1.34314575,10 0,8.65685425 0,7 L0,3 C0,1.34314575 1.34314575,0 3,0 L19,0 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-541, -668)">
          <g id="server" transform="translate(542, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
