import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Unlock({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>unlock</title>
      <defs>
        <path
          d="M9.39700524,0.0307199641 C12.4639192,-0.279069324 15.2665944,1.78550177 15.8800005,4.8063731 C15.9899024,5.34761243 15.6402343,5.8754667 15.098995,5.9853686 C14.5577557,6.09527051 14.0299014,5.74560243 13.9199995,5.2043631 C13.5110621,3.19044888 11.642612,1.81406815 9.59800265,2.02059434 C7.55339335,2.22712053 5.99793421,3.94935069 5.99999742,6.0053681 L5.999,9.005 L17,9.0053681 C18.6568542,9.0053681 20,10.3485138 20,12.0053681 L20,19.0053681 C20,20.6622223 18.6568542,22.0053681 17,22.0053681 L3,22.0053681 C1.34314575,22.0053681 0,20.6622223 0,19.0053681 L0,12.0053681 C0,10.3485138 1.34314575,9.0053681 3,9.0053681 L3.999,9.005 L3.99999742,6.0063731 C3.99697994,3.00091744 6.21489202,0.469988497 9.16838747,0.0581893534 Z M17,11.0053681 L3,11.0053681 C2.44771525,11.0053681 2,11.4530833 2,12.0053681 L2,19.0053681 C2,19.5576528 2.44771525,20.0053681 3,20.0053681 L17,20.0053681 C17.5522847,20.0053681 18,19.5576528 18,19.0053681 L18,12.0053681 C18,11.4530833 17.5522847,11.0053681 17,11.0053681 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-240, -140)">
          <g id="unlock" transform="translate(242, 141.9946)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1.9946)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
