import { IFile, SubmissionBenefitListType } from '@app-types';
import { FieldErrorMessage, InputController, RadioGroupController, UploadedFile } from '@components';
import { StepperContext } from '@components/Stepper';
import CurrencyInputController from '@components/formControllers/CurrencyInputController';
import { InfoIcon } from '@icons';
import { Box, Grid, IconButton as MuiIconButton, Stack, Typography, useTheme } from '@mui/material';
import { convertFileListToArray, formatCurrency } from '@utils';
import { ChangeEvent, FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSettings } from '@hooks';
import { SubmissionCreationFormValues, SubmissionRadioValue } from '../../types';
import UploadFileButton from '../UploadFileButton';
import { BorderedBlock, FormInfoBlock } from '../styles';
import BenefitFormBlock from './BenefitFormBlock';

interface IOngoingServicesProps {
  isOngoingBenefitsValuesUndefinedOrEmpty: boolean;
}

const OngoingServices: FC<IOngoingServicesProps> = ({ isOngoingBenefitsValuesUndefinedOrEmpty }) => {
  const theme = useTheme();

  const { openInfoDrawer } = useContext(StepperContext);

  const { formatMessage, formatNumber } = useIntl();
  const { data: appSettingsData } = useAppSettings();

  const { control, watch, setValue } = useFormContext<SubmissionCreationFormValues>();

  const isUserReceivedOngoingBenefit = watch('isUserReceivedOngoingBenefit');
  const hasSpouse = watch('hasSpouse');
  const children = watch('children');

  let personCount = 0;

  children?.forEach((child) => {
    if (child.isThereHousehold || child.isThereMaintenance) {
      personCount += 1;
    }
  }); 

  if (hasSpouse === SubmissionRadioValue.Yes) {
    personCount += 1;
  }

  const showFurtherPersonsNumberField = personCount < 5;

  const getBenefitUploadedDocuments = () => {
    const documents = watch('ongoingBenefit.uploadedDocuments') as unknown as FileList;

    return convertFileListToArray(documents);
  };

  const handleBenefitUploadedDocumentFileDelete = (fileIndex: number) => {
    return () => {
      const fileList = getBenefitUploadedDocuments();

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);

      setValue('ongoingBenefit.uploadedDocuments', fileList as unknown as FileList);
    };
  };

  const getBenefitDocuments = () => {
    const documents = watch('ongoingBenefit.documents') as unknown as IFile[];

    return documents;
  };

  const handleBenefitDocumentFileDelete = (fileIndex: number) => {
    return () => {
      const fileList = getBenefitDocuments();

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);

      setValue('ongoingBenefit.documents', fileList as unknown as IFile[]);
    };
  };

  const {
    formState: { errors },
  } = useFormContext();

  const handleFurtherPersonsNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (/^\d*$/.test(value)) {
      setValue('ongoingBenefit.furtherPersonsNumber', value);
    }
  };

  return (
    <Stack>
      <BorderedBlock direction="column" mt="60px">
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb="19px" gap={1}>
          <Typography variant="h2">
            <FormattedMessage id="submissionCreation.ongoingService.title" />
          </Typography>
          <MuiIconButton
            size="small"
            onClick={() =>
              openInfoDrawer(
                formatMessage({ id: 'submissionCreation.drawer.title' }),
                formatMessage({ id: 'submissionCreation.ongoingService.drawer.description' }),
              )
            }
          >
            <InfoIcon color={theme.palette.primary.dark} />
          </MuiIconButton>
        </Stack>
      </BorderedBlock>
      <BorderedBlock mt="32px" pb="27px">
        <RadioGroupController
          row
          name="isUserReceivedOngoingBenefit"
          control={control}
          label={formatMessage({
            id: showFurtherPersonsNumberField
              ? 'submissionCreation.ongoingService.underFivePersons.isUserReceivedOngoingBenefits'
              : 'submissionCreation.ongoingService.overFivePersons.isUserReceivedOngoingBenefits',
          }, {
            basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0),
          })}
          options={[
            { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
            { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
          ]}
          onIconClick={() =>
            openInfoDrawer(
              formatMessage({ id: 'submissionCreation.drawer.title' }),
              formatMessage({
                id: 'submissionCreation.ongoingService.isUserReceivedOngoingBenefits.drawer.description',
              }, {
                basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0),
              }),
            )
          }
          showIcon={!showFurtherPersonsNumberField}
        />
        {isUserReceivedOngoingBenefit === SubmissionRadioValue.Yes && (
          <Stack mt="32px">
            <FormInfoBlock mt="24px" mb="32px">

              {showFurtherPersonsNumberField && (
                <>
                <Stack direction="row" justifyContent="space-between" mt={showFurtherPersonsNumberField ? 4 : 0}>
                  <Typography fontSize="14px" fontWeight={600} mb={2} position="relative">
                    <FormattedMessage
                      id='submissionCreation.ongoingService.furtherPersonsNumber'
                      values={{ basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0) }}
                    />
                  </Typography>
                </Stack>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <InputController
                        name="ongoingBenefit.furtherPersonsNumber"
                        control={control}
                        label={formatMessage({ id: 'submissionCreation.ongoingService.furtherPersonsNumber.label' })}
                        placeholder={formatMessage({
                          id: 'submissionCreation.ongoingService.furtherPersonsNumber.placeholder',
                        })}
                        backgroundMode="white"
                        onChange={handleFurtherPersonsNumberChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Stack direction="row" justifyContent="space-between" mt={showFurtherPersonsNumberField ? 4 : 0}>
                <Typography fontSize="14px" fontWeight={600} mb={2} position="relative">
                  <FormattedMessage
                    id={
                      !showFurtherPersonsNumberField 
                        ? "submissionCreation.ongoingService.ongoingSocialBenefit.title"
                        : "submissionCreation.ongoingService.overFivePersons.isUserReceivedOngoingBenefits"
                    }
                    values={{ basicAllowance: formatCurrency(formatNumber, appSettingsData?.basicAllowance || 0) }}
                  />
                </Typography>
              </Stack>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <CurrencyInputController
                    name="ongoingBenefit.amount"
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.ongoingService.benefitAmount' })}
                    placeholder={formatMessage({ id: 'submissionCreation.ongoingService.benefitAmount.placeholder' })}
                    backgroundMode="white"
                  />
                </Grid>
              </Grid>

              <Box mt={1}>
                {isOngoingBenefitsValuesUndefinedOrEmpty && (
                  <FieldErrorMessage
                    message={formatMessage({
                      id: 'submissionCreation.ongoingService.amountAndFurtherPersonsNumber.validation.text',
                    })}
                  />
                )}
              </Box>
              <Stack direction="row" mb={3} mt={3} gap={1} alignItems="center" sx={{ zIndex: 1 }}>
                <Typography fontSize="16px">
                  <FormattedMessage id="submissionCreation.ongoingService.uploadSupportingDocuments" />
                </Typography>
                <MuiIconButton
                  size="small"
                  onClick={() =>
                    openInfoDrawer(
                      formatMessage({ id: 'submissionCreation.drawer.title' }),
                      formatMessage({
                        id: 'submissionCreation.ongoingService.uploadSupportingDocuments.drawer.description',
                      }),
                    )
                  }
                >
                  <InfoIcon color={theme.palette.primary.dark} />
                </MuiIconButton>
              </Stack>
              <Grid item container xs={12}>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" gap={2}>
                    <UploadFileButton fieldName="ongoingBenefit.uploadedDocuments" />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Stack direction="column" gap={1} mt={1}>
                    {getBenefitDocuments()?.map((file, fileIndex) => (
                      <UploadedFile
                        key={file.name}
                        fileName={file.name || ''}
                        onDeleteFile={handleBenefitDocumentFileDelete(fileIndex)}
                      />
                    ))}
                    {getBenefitUploadedDocuments()?.map((file, fileIndex) => (
                      <UploadedFile
                        key={file.name}
                        fileName={file.name || ''}
                        onDeleteFile={handleBenefitUploadedDocumentFileDelete(fileIndex)}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
              <Box mt={1}>
                {/* @ts-ignore */}
                {errors && errors.ongoingBenefit?.documents && (
                  // @ts-ignore
                  <FieldErrorMessage message={errors.ongoingBenefit?.documents?.message} />
                )}
              </Box>
            </FormInfoBlock>
          </Stack>
        )}
      </BorderedBlock>
      <BenefitFormBlock
        radioFieldName="isUserReceivedOneOffBenefits"
        benefitType="oneOffBenefits"
        benefitListType={SubmissionBenefitListType.OneOffBenefits}
      />
      <BenefitFormBlock
        radioFieldName="isUserReceivedBackPaymentBenefits"
        benefitType="backPaymentBenefits"
        benefitListType={SubmissionBenefitListType.BackPaymentBenefits}
      />
      <BenefitFormBlock
        radioFieldName="isUserReceivedHealthDamages"
        benefitType="healthDamages"
        benefitListType={SubmissionBenefitListType.HealthDamage}
      />
      <BenefitFormBlock
        radioFieldName="isUserReceivedMotherChildFoundations"
        benefitType="motherChildFoundations"
        multiple={false}
      />
      <BenefitFormBlock
        radioFieldName="isUserReceivedBackPaymentWagePensionBenefits"
        benefitType="backPaymentWagePensionBenefits"
        multiple={false}
      />
      <BenefitFormBlock
        radioFieldName="isUserReceivedAdditionalBenefits"
        benefitType="additionalBenefits"
        benefitListType={SubmissionBenefitListType.AdditionalBenefits}
      />
    </Stack>
  );
};

export default OngoingServices;
