import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Volume({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>volume</title>
      <defs>
        <path
          d="M4.64921894,4.0018773 L9.37530495,0.221008492 C10.0300683,-0.302802184 11,0.163371093 11,1.0018773 L11,15.0018773 C11,15.8403835 10.0300683,16.3065568 9.37530495,15.7827461 L4.64921894,12.0018773 L1,12.0018773 C0.44771525,12.0018773 0,11.5541621 0,11.0018773 L0,5.0018773 C0,4.44959255 0.44771525,4.0018773 1,4.0018773 L4.64921894,4.0018773 Z M9,3.08250215 L5.62469505,5.78274611 C5.447382,5.92459655 5.2270715,6.0018773 5,6.0018773 L2,6.0018773 L2,10.0018773 L5,10.0018773 C5.2270715,10.0018773 5.447382,10.0791581 5.62469505,10.2210085 L9,12.9212525 L9,3.08250215 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1000, -86)">
          <g id="volume" transform="translate(1007, 89.9981)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-7, -3.9981)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
