import { ViewportBreakpoint } from '@constants';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SignUpFormSectionBoxProps extends BoxProps {
  showBorder?: boolean;
}

export const SignUpFormSectionBox = styled(Box)<SignUpFormSectionBoxProps>(({ theme, showBorder }) => ({
  borderBottom: showBorder ? `1px solid ${theme.palette.grey[500]}` : 'none',
  maxWidth: '777px',
  width: '100%',

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    '& > div > div': {
      flexDirection: 'column',
    },
    '& > div > div > div': {
      maxWidth: '100%',
    },
  },
}));
