import { Menu, MenuProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)<MenuProps & { width?: number }>(({ theme, width }) => ({
  '& .MuiMenu-paper': {
    width,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e4e4e4',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    boxSizing: 'border-box',
    padding: '4px',
  },
  '& .MuiList-root': {
    padding: '0',
  },
  '& .MuiMenuItem-root': {
    borderRadius: '4px',
    padding: '8px 12px',
    '& .MuiTypography-root': {
      color: theme.palette.grey[100],
      fontWeight: 500,
    },
    '& rect': {
      fill: theme.palette.grey[100],
    },
    '& .MuiListItemText-root': {
      flex: 'none',
    },
    '&:hover': {
      '& .MuiTypography-root': {
        color: theme.palette.secondary.dark,
      },
      '& rect': {
        fill: theme.palette.secondary.dark,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));
