import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Minimize({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>minimize</title>
      <defs>
        <path
          d="M5,1 L5,4 C5,4.55228475 4.55228475,5 4,5 L1,5 C0.44771525,5 0,5.44771525 0,6 C0,6.55228475 0.44771525,7 1,7 L4,7 C5.65685425,7 7,5.65685425 7,4 L7,1 C7,0.44771525 6.55228475,0 6,0 C5.44771525,0 5,0.44771525 5,1 Z M19,5 L16,5 C15.4477153,5 15,4.55228475 15,4 L15,1 C15,0.44771525 14.5522847,0 14,0 C13.4477153,0 13,0.44771525 13,1 L13,4 C13,5.65685425 14.3431458,7 16,7 L19,7 C19.5522847,7 20,6.55228475 20,6 C20,5.44771525 19.5522847,5 19,5 Z M15,19 L15,16 C15,15.4477153 15.4477153,15 16,15 L19,15 C19.5522847,15 20,14.5522847 20,14 C20,13.4477153 19.5522847,13 19,13 L16,13 C14.3431458,13 13,14.3431458 13,16 L13,19 C13,19.5522847 13.4477153,20 14,20 C14.5522847,20 15,19.5522847 15,19 Z M1,15 L4,15 C4.55228475,15 5,15.4477153 5,16 L5,19 C5,19.5522847 5.44771525,20 6,20 C6.55228475,20 7,19.5522847 7,19 L7,16 C7,14.3431458 5.65685425,13 4,13 L1,13 C0.44771525,13 0,13.4477153 0,14 C0,14.5522847 0.44771525,15 1,15 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1073, -321)">
          <g id="minimize" transform="translate(1075, 323)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
