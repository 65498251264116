import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Type({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>type</title>
      <defs>
        <path
          d="M6,18 C5.44771525,18 5,17.5522847 5,17 C5,16.4477153 5.44771525,16 6,16 L8,16 L8,2 L2,2 L2,4 C2,4.55228475 1.55228475,5 1,5 C0.44771525,5 0,4.55228475 0,4 L0,1 C0,0.44771525 0.44771525,0 1,0 L17,0 C17.5522847,0 18,0.44771525 18,1 L18,4 C18,4.55228475 17.5522847,5 17,5 C16.4477153,5 16,4.55228475 16,4 L16,2 L10,2 L10,16 L12,16 C12.5128358,16 12.9355072,16.3860402 12.9932723,16.8833789 L13,17 C13,17.5522847 12.5522847,18 12,18 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-847, -84)">
          <g id="type" transform="translate(850, 87)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -3)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
