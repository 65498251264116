import { LocalStorageKey } from '@constants';
import React from 'react';
import { Navigate } from 'react-router-dom';

const withEligibilityCheck =
  (Component: React.FC) =>
  ({ ...props }) => {
    const eligibilityCheck = JSON.parse(localStorage.getItem(LocalStorageKey.EligibilityCheck) || '{}');
    const isEmptyEligibilityCheck = Object.keys(eligibilityCheck).length === 0;

    if (isEmptyEligibilityCheck) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };

export default withEligibilityCheck;
