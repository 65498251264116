import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ResetPasswordForm from '../forms/ResetPasswordForm';
import { FormBox, Wrapper } from '../styles';

const ResetPasswordStep: React.FC = () => {
  return (
    <Wrapper container>
      <Grid item>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h2">
            <FormattedMessage id="resetPassword.title" />
          </Typography>
        </Box>
        <FormBox>
          <ResetPasswordForm />
        </FormBox>
      </Grid>
    </Wrapper>
  );
};

export default ResetPasswordStep;
