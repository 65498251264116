import { Button, InputController } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { FormProvider, Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import useResetPassword from '../../../shared/hooks/useResetPassword';
import { RestorePasswordContext } from '../RestorePassword';

interface IResetPasswordForm {
  password: string;
  repeatPassword: string;
}

const ResetPasswordForm: React.FC = () => {
  const { customerId } = useContext(RestorePasswordContext);
  const { formatMessage } = useIntl();

  const resetPassword = useResetPassword();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .matches(
        /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{8,12})|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,})$/,
        formatMessage({ id: 'signup.password.requirements.title' }),
      ),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'signup.password.required.title' }))
      .oneOf([yup.ref('password'), ''], formatMessage({ id: 'signup.password.match.title' })),
  });

  const methods = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema) as unknown as Resolver<IResetPasswordForm, ResolverOptions<IResetPasswordForm>>,
  });
  const { control, handleSubmit } = methods;

  const handleFormSubmit = (data: IResetPasswordForm) => {
    resetPassword.mutate({ id: customerId, password: data.password });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <InputController
              fullWidth
              name="password"
              password
              control={control}
              placeholder={formatMessage({ id: 'resetPassword.password.placeholder' })}
              label={formatMessage({ id: 'resetPassword.password.label' })}
            />
            <InputController
              fullWidth
              password
              name="repeatPassword"
              control={control}
              placeholder={formatMessage({ id: 'resetPassword.repeatPassword.placeholder' })}
              label={formatMessage({ id: 'resetPassword.repeatPassword.label' })}
            />
          </Box>
          <Button fullWidth type="submit" variant="contained">
            <FormattedMessage id="resetPassword.submit" />
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
