export enum ActionType {
  SignUp = 'signUp',
  RestorePassword = 'restorePassword',
}

export interface IVerificationCodeDto {
  codeValue: number;
  params: {
    email: string | undefined;
  };
}

export interface IReSendVerificationCodeDto {
  email: string;
  actionType: ActionType;
}
