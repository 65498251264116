import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import Drawer from '@components/Drawer';
import { ViewportBreakpoint } from '@constants';
import { useViewportSize } from '@hooks';

interface IInfoDrawerProps {
  drawerTopOffset: number;
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}

const InfoDrawer: FC<IInfoDrawerProps> = ({ drawerTopOffset, open, onClose, title, description }) => {
  const viewportWidth = useViewportSize();
  const isMobile = viewportWidth <= ViewportBreakpoint.Mobile;
  return (
    <Drawer
      top={drawerTopOffset > 0 && !isMobile ? `${drawerTopOffset}px` : '0px'}
      anchor="right"
      open={open}
      onClose={onClose}
      width={isMobile ? '100vw' : '420px'}
    >
      <Stack direction="column">
        <Typography variant="h3" fontSize={16} fontWeight={500}>
          {title}
        </Typography>

        <Typography fontSize={14} variant="body2" margin="12px 0 0">
          {description}
        </Typography>
      </Stack>
    </Drawer>
  );
};

export default InfoDrawer;
