import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Scissors({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>scissors</title>
      <defs>
        <path
          d="M4,0 C6.209139,0 8,1.790861 8,4 C8,4.74140044 7.79829277,5.43568963 7.44676165,6.03098422 L10,8.585 L17.2928932,1.29289322 C17.6533772,0.932409257 18.2206082,0.904679722 18.6128994,1.20970461 L18.7071068,1.29289322 C19.0976311,1.68341751 19.0976311,2.31658249 18.7071068,2.70710678 L7.44676165,13.9690158 C7.79829277,14.5643104 8,15.2585996 8,16 C8,18.209139 6.209139,20 4,20 C1.790861,20 0,18.209139 0,16 C0,13.790861 1.790861,12 4,12 C4.74140044,12 5.43568963,12.2017072 6.03098422,12.5532383 L8.585,10 L6.03098422,7.44676165 C5.43568963,7.79829277 4.74140044,8 4,8 C1.790861,8 0,6.209139 0,4 C0,1.790861 1.790861,0 4,0 Z M11.7622536,11.7735334 C12.1524243,11.3826559 12.785589,11.3820829 13.1764666,11.7722536 L18.7064666,17.2922536 C19.0973441,17.6824243 19.0979171,18.315589 18.7077464,18.7064666 C18.3175757,19.0973441 17.684411,19.0979171 17.2935334,18.7077464 L11.7635334,13.1877464 C11.3726559,12.7975757 11.3720829,12.164411 11.7622536,11.7735334 Z M4,14 C2.8954305,14 2,14.8954305 2,16 C2,17.1045695 2.8954305,18 4,18 C5.1045695,18 6,17.1045695 6,16 C6,15.4575599 5.78405191,14.9655572 5.43343595,14.605272 L5.41289322,14.5871068 C5.40625272,14.5804663 5.39972515,14.5737556 5.39331048,14.5669772 C5.03444282,14.2159481 4.5424401,14 4,14 Z M4,2 C2.8954305,2 2,2.8954305 2,4 C2,5.1045695 2.8954305,6 4,6 C4.5424401,6 5.03444282,5.78405191 5.39472795,5.43343595 L5.41289322,5.41289322 C5.41953371,5.40625272 5.42624436,5.39972515 5.43302278,5.39331048 C5.78405191,5.03444282 6,4.5424401 6,4 C6,2.8954305 5.1045695,2 4,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1427, -392)">
          <g id="scissors" transform="translate(1429, 394)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
