import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Save({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>save</title>
      <defs>
        <path
          d="M14,0 C14.2652165,0 14.5195704,0.10535684 14.7071068,0.292893219 L19.7071068,5.29289322 C19.8946432,5.4804296 20,5.73478351 20,6 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 L14,0 Z M13.5857864,2 L6,2 L6,5 L13,5 C13.5522847,5 14,5.44771525 14,6 C14,6.55228475 13.5522847,7 13,7 L5,7 C4.44771525,7 4,6.55228475 4,6 L4,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.5522847 2.44771525,18 3,18 L4,18 L4,11 C4,10.4871642 4.38604019,10.0644928 4.88337887,10.0067277 L5,10 L15,10 C15.5522847,10 16,10.4477153 16,11 L16,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,6.41421356 L13.5857864,2 Z M14,12 L6,12 L6,18 L14,18 L14,12 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1355, -599)">
          <g id="save" transform="translate(1357, 601)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
