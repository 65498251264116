import { authApi } from '@api';
import { ChangeCustomerPasswordDto } from '@app-types/customer';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useChangePassword = (
  options?: UseMutationOptions<unknown, unknown, { id: string; passwords: ChangeCustomerPasswordDto }>,
) => {
  return useMutation({
    ...options,
    mutationFn: authApi.changePassword,
  });
};

export default useChangePassword;
