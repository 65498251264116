import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function UserMinus({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>user-minus</title>
      <defs>
        <path
          d="M12,12 C14.7614237,12 17,14.2385763 17,17 L17,19 C17,19.5522847 16.5522847,20 16,20 C15.4477153,20 15,19.5522847 15,19 L15,17 C15,15.3431458 13.6568542,14 12,14 L5,14 C3.34314575,14 2,15.3431458 2,17 L2,19 C2,19.5522847 1.55228475,20 1,20 C0.44771525,20 0,19.5522847 0,19 L0,17 C0,14.2385763 2.23857625,12 5,12 Z M8.5,0 C11.2614237,0 13.5,2.23857625 13.5,5 C13.5,7.76142375 11.2614237,10 8.5,10 C5.73857625,10 3.5,7.76142375 3.5,5 C3.5,2.23857625 5.73857625,0 8.5,0 Z M23,8 C23.5522847,8 24,8.44771525 24,9 C24,9.55228475 23.5522847,10 23,10 L17,10 C16.4477153,10 16,9.55228475 16,9 C16,8.44771525 16.4477153,8 17,8 Z M8.5,2 C6.84314575,2 5.5,3.34314575 5.5,5 C5.5,6.65685425 6.84314575,8 8.5,8 C10.1568542,8 11.5,6.65685425 11.5,5 C11.5,3.34314575 10.1568542,2 8.5,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1288, -84)">
          <g id="user-minus" transform="translate(1288, 86)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
