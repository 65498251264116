import { SubmissionStatus } from '@app-types';
import { Button } from '@components';
import { StepperContext } from '@components/Stepper';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAppDispatch } from '@store';
import { getCurrentSubmission, selectCurrentSubmission } from '@store/submission/slice';
import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useChangeSubmissionStatusByCustomer from '../../../../shared/hooks/useChangeSubmissionStatusByCustomer';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import Date from '../../Date/Date';
import { Wrapper } from '../styles';

interface ISubmissionAuditProps {
  status: string | undefined;
  createdAt: string | undefined;
  toggleVisibility?: () => void;
  submissionId: string | undefined;
}

const SubmissionAudit: FC<ISubmissionAuditProps> = ({ status, createdAt, toggleVisibility, submissionId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!submissionId) {
      dispatch(getCurrentSubmission());
    }
  }, [submissionId]);

  const currentSubmission = useSelector(selectCurrentSubmission);

  const { goToNextStep } = useContext(StepperContext);
  const { mutateAsync: changeSubmissionStatusByCustomer } = useChangeSubmissionStatusByCustomer(
    submissionId || currentSubmission?.id,
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  let firstParagraph = '';
  let isSubmissionResult: boolean;

  switch (status) {
    case SubmissionStatus.Update:
      firstParagraph = 'submissionCreation.applicationReview.submissionAudit.firstParagraph.updateStatus';
      isSubmissionResult = false;
      break;
    case SubmissionStatus.Completed:
    case SubmissionStatus.Archived:
      firstParagraph = 'submissionCreation.applicationReview.submissionAudit.firstParagraph.gotResult';
      isSubmissionResult = true;
      break;
    default:
      firstParagraph = 'submissionCreation.applicationReview.submissionAudit.firstParagraph';
      isSubmissionResult = false;
      break;
  }

  const makeWithdrawnSubmissionStatus = async () => {
    try {
      const res = await changeSubmissionStatusByCustomer({ status: SubmissionStatus.Withdrawn });

      if (res.status === SubmissionStatus.Withdrawn) {
        goToNextStep();
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <Container sx={{ pt: '24px' }}>
      <Wrapper container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Date createdAt={createdAt} />
          <Typography variant="h2" sx={{ fontSize: '32px', mt: '8px' }}>
            <FormattedMessage id="submissionCreation.applicationReview.submissionAudit.title" />
          </Typography>
          <Box sx={{ maxWidth: '490px' }}>
            <Typography sx={{ mt: '16px' }}>
              <FormattedMessage id={firstParagraph} />
            </Typography>
            {!isSubmissionResult && (
              <Typography sx={{ mt: '32px' }}>
                <FormattedMessage id="submissionCreation.applicationReview.submissionAudit.secondParagraph" />
              </Typography>
            )}
          </Box>
          {isSubmissionResult ? (
            <Button sx={{ mt: '32px' }} onClick={toggleVisibility}>
              <FormattedMessage id="submissionCreation.applicationReview.button.completedOrDeclined" />
            </Button>
          ) : (
            <Button variant="outlined" sx={{ mt: '32px' }} onClick={handleOpenConfirmModal}>
              <FormattedMessage id="submissionCreation.applicationReview.button" />
            </Button>
          )}
          {openConfirmModal && (
            <ConfirmModal
              open={openConfirmModal}
              titleId="submissionCreation.confirmModal.withdrawSubmission.title"
              handleClose={handleCloseConfirmModal}
              updateClick={() => makeWithdrawnSubmissionStatus()}
            />
          )}
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default SubmissionAudit;
