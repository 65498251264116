import { useIsMobile } from '@hooks';
import { ButtonProps, Button as MaterialButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Color } from '@themes';

export const StyledButton = styled(MaterialButton)<ButtonProps>(({ theme, color, variant }) => {
  let backgroundColor = '';
  let hoverBackgroundColor = '';
  let pressedBackgroundColor = '';
  let textColor = '';
  let border = '';
  const isMobile = useIsMobile();

  switch (color) {
    case 'primary':
      backgroundColor = theme.palette.primary.main;
      hoverBackgroundColor = theme.palette.primary.dark;
      pressedBackgroundColor = theme.palette.background.default;
      textColor = theme.palette.secondary.main;
      break;
    case 'secondary':
      backgroundColor = theme.palette.secondary.main;
      hoverBackgroundColor = theme.palette.primary.light;
      pressedBackgroundColor = Color.VeryLightBlue;
      textColor = theme.palette.background.default;
      break;
    case 'error':
      backgroundColor = theme.palette.error.main;
      hoverBackgroundColor = theme.palette.error.dark;
      pressedBackgroundColor = Color.VeryDarkRed;
      textColor = theme.palette.secondary.main;
      break;
    default:
      break;
  }

  if (variant === 'outlined') {
    backgroundColor = `${theme.palette.grey[600]}`;
    hoverBackgroundColor = `${theme.palette.grey[600]}`;
    pressedBackgroundColor = `${theme.palette.grey[500]}`;
    textColor = `${theme.palette.grey[200]}`;
    border = `1px solid ${theme.palette.grey[500]}`;
  }

  return {
    padding: '14px 32px',
    borderRadius: '4px',
    textTransform: 'none',
    backgroundColor,
    boxShadow: 'none',
    minWidth: '136px',
    height: isMobile ? '64px' : '48px',
    color: textColor,
    border,

    '&:hover': {
      backgroundColor: hoverBackgroundColor,
      boxShadow: 'none',
      border: variant === 'outlined' ? `1px solid ${theme.palette.grey[400]}` : 'none',
      color: variant === 'outlined' ? theme.palette.grey[100] : textColor,
    },

    '&:active': {
      backgroundColor: pressedBackgroundColor,
      boxShadow: 'none',
      border: variant === 'outlined' ? `1px solid ${theme.palette.grey[400]}` : 'none',
      color: variant === 'outlined' ? theme.palette.grey[100] : textColor,
    },

    '& .MuiButton-startIcon': {
      marginRight: '14px',
      color: textColor,
    },
  };
});
