import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function XSquare({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>x-square</title>
      <defs>
        <path
          d="M3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.5522847 2.44771525,18 3,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,3 C18,2.44771525 17.5522847,2 17,2 L3,2 Z M17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 L17,0 Z M6.29289322,6.29289322 C5.90236893,6.68341751 5.90236893,7.31658249 6.29289322,7.70710678 L8.58578644,10 L6.29289322,12.2928932 C5.90236893,12.6834175 5.90236893,13.3165825 6.29289322,13.7071068 C6.68341751,14.0976311 7.31658249,14.0976311 7.70710678,13.7071068 L10,11.4142136 L12.2928932,13.7071068 C12.6834175,14.0976311 13.3165825,14.0976311 13.7071068,13.7071068 C14.0976311,13.3165825 14.0976311,12.6834175 13.7071068,12.2928932 L11.4142136,10 L13.7071068,7.70710678 C14.0976311,7.31658249 14.0976311,6.68341751 13.7071068,6.29289322 C13.3165825,5.90236893 12.6834175,5.90236893 12.2928932,6.29289322 L10,8.58578644 L7.70710678,6.29289322 C7.31658249,5.90236893 6.68341751,5.90236893 6.29289322,6.29289322 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1223, -84)">
          <g id="x-square" transform="translate(1225, 86)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
