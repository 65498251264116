import { AdditionalDemand, SubmissionStatus } from '@app-types';
import { StepperContext } from '@components/Stepper';
import { useIsMobile } from '@hooks';
import { AlertTriangleIcon, CheckSquareIcon } from '@icons';
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  FieldArrayWithId,
  FormProvider,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  WatchObserver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useChangeSubmissionStatusByCustomer from '../../../../shared/hooks/useChangeSubmissionStatusByCustomer';
import useSendRequestedDocuments from '../../../../shared/hooks/useSendRequestedDocuments';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import Date from '../../Date/Date';
import { convertRequestedDocumentsFormValuesToDto } from '../../dto';
import SubmissionAudit from '../SubmissionAudit/SubmissionAudit';
import { StyledIconButton, Wrapper } from '../styles';
import MissingDocument from './MissingDocument';
import { SelectFileBox, StyledButton, TitlesBox } from './styles';

export interface IDocumentsRequestedValues {
  uploadedInfo: Document[];
}

interface IDocumentsRequested {
  id: string;
  section: string;
  customerFiles: FileList[];
  comment?: string;
}

interface IDocumentsRequestedProps {
  createdAt: string | undefined;
  additionalDemands: AdditionalDemand[] | undefined;
  submissionId: string | undefined;
  status: string | undefined;
  messageToRequestedDocuments: string | undefined;
}

export interface Document {
  id: string;
  section: string;
  documentName: string;
  type: string;
  additionalMessage?: string;
  declarationToApplicant?: string;
  customerFiles?: FileList[] | any;
  comment?: string;
}

interface ISubmissionFormContext {
  getUploadedInfoValue: (index: number, key: keyof IDocumentsRequested) => any;
  uploadedInfoFields: FieldArrayWithId[];
  appendUploadedInfo: UseFieldArrayAppend<IDocumentsRequestedValues, 'uploadedInfo'>;
  removeUploadedInfo: UseFieldArrayRemove;
}

export const SubmissionFormContext = createContext<ISubmissionFormContext>({
  getUploadedInfoValue: () => {},
  uploadedInfoFields: [],
  appendUploadedInfo: () => {},
  removeUploadedInfo: () => {},
});

const DocumentsRequested: FC<IDocumentsRequestedProps> = ({
  createdAt,
  additionalDemands,
  submissionId,
  status,
  messageToRequestedDocuments,
}) => {
  const isMobile = useIsMobile();
  const [documentsArray, setDocumentsArray] = useState<Document[] | undefined>([]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  useEffect(() => {
    if (additionalDemands) {
      setDocumentsArray(
        additionalDemands.reduce((acc: Document[], curr: AdditionalDemand) => {
          const documents = curr.documents.map((doc: Document) => ({
            id: curr.id,
            section: curr.section,
            documentName: doc.documentName,
            documentType: doc.type,
            declarationToApplicant: doc.declarationToApplicant,
          }));
          acc.push(...documents);
          return acc;
        }, []),
      );
    }
  }, []);

  const theme = useTheme();
  const initialValues: { uploadedInfo: Document[] } = {
    uploadedInfo:
      additionalDemands?.reduce((acc: Document[], curr: AdditionalDemand) => {
        const documents = curr.documents.map(() => ({
          id: curr.id,
          section: curr.section,
          customerFiles: [],
          comment: '',
        }));
        acc.push(...documents);
        return acc;
      }, []) || [],
  };

  const methods = useForm<IDocumentsRequestedValues>({ defaultValues: initialValues });

  const { mutateAsync: sendRequestedDocuments } = useSendRequestedDocuments(submissionId);
  const { mutateAsync: changeSubmissionStatusByCustomer } = useChangeSubmissionStatusByCustomer(submissionId);

  const { control, watch, handleSubmit } = methods;
  const { goToNextStep } = useContext(StepperContext);

  const getUploadedInfoValue = (index: number, key: keyof IDocumentsRequested) => {
    return watch(`uploadedInfo[${index}].${key}` as unknown as WatchObserver<IDocumentsRequestedValues>);
  };

  const {
    fields: uploadedInfoFields,
    append: appendUploadedInfo,
    remove: removeUploadedInfo,
  } = useFieldArray({
    control,
    name: 'uploadedInfo',
  });

  const [updatedStatus, setUpdatedStatus] = useState(SubmissionStatus.DocumentRequested);

  const handleFormSubmit = async (data: IDocumentsRequestedValues) => {
    const isCustomerFilesUploaded = data.uploadedInfo.every(
      (info) => info?.customerFiles && info?.customerFiles?.length > 0,
    );

    if (!isCustomerFilesUploaded) {
      console.error('Not all customer files are uploaded.');
      return;
    }

    if (data.uploadedInfo) {
      try {
        const dto = await convertRequestedDocumentsFormValuesToDto(data.uploadedInfo);
        const res = await sendRequestedDocuments(dto);

        if (res.status === SubmissionStatus.Update) {
          setUpdatedStatus(res.status);
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const contextValue = useMemo(
    () => ({ getUploadedInfoValue, uploadedInfoFields, appendUploadedInfo, removeUploadedInfo }),
    [uploadedInfoFields],
  );

  const makeWithdrawnSubmissionStatus = async () => {
    try {
      const res = await changeSubmissionStatusByCustomer({ status: SubmissionStatus.Withdrawn });

      if (res.status === SubmissionStatus.Withdrawn) {
        goToNextStep();
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return updatedStatus === SubmissionStatus.Update ? (
    <SubmissionAudit status={status} createdAt={createdAt} submissionId={submissionId} />
  ) : (
    <Container sx={{ pt: '40px', mx: isMobile ? 0 : 'auto', px: isMobile ? 0 : 'auto' }}>
      <Wrapper container sx={{ maxWidth: '940px', width: '100%', margin: isMobile ? 0 : '0 auto' }}>
        <Grid item xs={12}>
          <TitlesBox>
            <AlertTriangleIcon width="56px" height="56px" color={theme.palette.primary.main} />
            <Box sx={{ display: isMobile ? 'block' : 'grid', maxWidth: '100%' }}>
              <Date createdAt={createdAt} />
              <Typography variant="h2" sx={{ mt: '8px' }}>
                <FormattedMessage id="submissionCreation.applicationReview.documentsRequested.title" />
              </Typography>
              <Typography sx={{ mt: '8px' }}>
                {messageToRequestedDocuments && messageToRequestedDocuments.length > 0 ? (
                  messageToRequestedDocuments
                ) : (
                  <FormattedMessage id="submissionCreation.applicationReview.documentsRequested.description" />
                )}
              </Typography>
            </Box>
          </TitlesBox>
          <Box sx={{ mt: '32px' }}>
            <Typography variant="h3" sx={{ mb: '32px', mx: isMobile ? 0 : '80px' }}>
              <FormattedMessage id="submissionCreation.applicationReview.documentsRequested.missingDocuments" />
            </Typography>
            <SubmissionFormContext.Provider value={contextValue}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Box sx={{ mx: isMobile ? 0 : '24px', display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Stack direction="column" gap={2} sx={{ minWidth: isMobile ? '310px' : 'auto' }}>
                      {documentsArray &&
                        documentsArray.map((document: Document, docIndex: number) => (
                          <MissingDocument
                            key={docIndex}
                            index={docIndex}
                            title={document.documentName}
                            description={document.declarationToApplicant}
                            sectionName={document.section}
                          />
                        ))}
                    </Stack>
                  </Box>
                  <SelectFileBox>
                    <StyledButton variant="outlined" onClick={handleOpenConfirmModal}>
                      <FormattedMessage id="submissionCreation.applicationReview.button" />
                    </StyledButton>
                    <StyledIconButton makeButtonActive type="submit">
                      <CheckSquareIcon color={theme.palette.secondary.main} />
                      <FormattedMessage id="submissionCreation.applicationReview.documentsRequested.submitDocuments" />
                    </StyledIconButton>
                  </SelectFileBox>
                </form>
              </FormProvider>
            </SubmissionFormContext.Provider>
          </Box>
          {openConfirmModal && (
            <ConfirmModal
              open={openConfirmModal}
              titleId="submissionCreation.confirmModal.withdrawSubmission.title"
              handleClose={handleCloseConfirmModal}
              updateClick={() => makeWithdrawnSubmissionStatus()}
            />
          )}
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default DocumentsRequested;
