import { customersApi } from '@api';
import { ChangeCustomerDto } from '@app-types/customer';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useChangeCustomerInfo = (
  options?: UseMutationOptions<unknown, unknown, { id: string; customer: ChangeCustomerDto }>,
) => {
  return useMutation({
    ...options,
    mutationFn: customersApi.changeCustomerInfo,
  });
};

export default useChangeCustomerInfo;
