import { ServerBase64 } from '@app-types';
import convertFileToBase64 from './convertFileToBase64';

const convertFileToServerBase64 = async (file: File): Promise<ServerBase64> => {
  const base64 = await convertFileToBase64(file);

  return {
    name: file.name,
    base64,
  };
};

export default convertFileToServerBase64;
