import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon } from '@icons';
import { ButtonProps, useTheme } from '@mui/material';
import React, { MouseEvent, ReactNode, forwardRef, useEffect, useState } from 'react';
import { StyledIconButton } from './styles';

interface IIconButtonProps {
  id?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
  open?: boolean;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
  makeButtonActive?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export interface IStyledProps {
  textColor?: string;
  backgroundColor?: string;
  border?: string;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
}

// @ts-ignore
const IconButton: React.FC<IIconButtonProps & ButtonProps> = forwardRef(
  ({ id, onClick, className, children, open, showLeftIcon, showRightIcon, makeButtonActive, type, ...restProps }, ref) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleMouseDown = () => {
      setIsActive(true);
    };

    const handleMouseUp = () => {
      setIsActive(false);
    };

    let iconColor;
    let backgroundColor;
    let textColor;
    let border;
    let borderBottomLeftRadius;
    let borderBottomRightRadius;

    if (isActive || makeButtonActive) {
      iconColor = theme.palette.secondary.main;
      backgroundColor = theme.palette.background.default;
      textColor = theme.palette.secondary.main;
      border = 'none';

      if (open) {
        borderBottomLeftRadius = 0;
        borderBottomRightRadius = 0;
      }
    } else if (isHovered) {
      iconColor = theme.palette.secondary.main;
    } else {
      iconColor = theme.palette.primary.main;
    }

    useEffect(() => {
      setIsActive(open || false);
    }, [open]);

    return (
      <StyledIconButton
        className={className}
        id={id}
        onClick={onClick}
        color="primary"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        backgroundColor={backgroundColor}
        textColor={textColor}
        border={border}
        borderBottomLeftRadius={borderBottomLeftRadius}
        borderBottomRightRadius={borderBottomRightRadius}
        type={type}
        ref={ref}
        {...restProps}
      >
        {showLeftIcon && <PlusCircleIcon color={iconColor} />}
        {children}
        {showRightIcon &&
          (open ? <ChevronUpIcon color={theme.palette.secondary.main} /> : <ChevronDownIcon color={iconColor} />)}
      </StyledIconButton>
    );
  },
);

export default IconButton;
