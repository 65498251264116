import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Home({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>home</title>
      <defs>
        <path
          d="M3,22 C1.34314575,22 0,20.6568542 0,19 L0,8 C0,7.69140957 0.142472844,7.40010398 0.386059386,7.21064778 L9.38605939,0.210647783 C9.74716987,-0.0702159275 10.2528301,-0.0702159275 10.6139406,0.210647783 L19.6139406,7.21064778 C19.8575272,7.40010398 20,7.69140957 20,8 L20,19 C20,20.6568542 18.6568542,22 17,22 L3,22 Z M10,2.26686158 L2,8.48908381 L2,19 C2,19.5522847 2.44771525,20 3,20 L6,20 L6,11 C6,10.4871642 6.38604019,10.0644928 6.88337887,10.0067277 L7,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,8.48908381 L10,2.26686158 Z M12,12 L8,12 L8,20 L12,20 L12,12 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-995, -598)">
          <g id="home" transform="translate(997, 599)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
