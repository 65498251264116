import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function MessageCircle({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>message-circle</title>
      <defs>
        <path
          d="M2.58286793,17.4189243 L6.38550134,16.1513798 C6.6384472,16.0670646 6.91459052,16.0872548 7.15258246,16.2074651 C8.19011132,16.7315234 9.33675615,17.0030971 10.5013422,17.0000632 C13.3415547,16.9989642 15.9374826,15.3935877 17.2091311,12.8492098 C17.7331893,11.8116809 18.0047631,10.6650361 18.0017325,9.5026704 L18.0032475,9.05514856 C17.7932256,5.24827893 14.7535133,2.20856668 11.0017291,2.00003465 L10.4991218,2.00003465 C9.33675615,1.99702914 8.19011132,2.26860292 7.14878785,2.79456774 C4.60820452,4.06430968 3.00282805,6.66023758 3.0017257,9.5026704 C2.9986951,10.6650361 3.27026888,11.8116809 3.79432714,12.8492098 C3.91453742,13.0872017 3.93472769,13.363345 3.8504124,13.6162909 L2.58286793,17.4189243 Z M20.0017576,9.49879623 C20.0053688,10.9757073 19.6602057,12.4326122 18.9962337,13.7471219 C17.3878939,16.9651941 14.0997186,18.998671 10.5043364,19.0000597 C9.16926521,19.0035406 7.85051152,18.7219957 6.63567774,18.1761728 L1.31795687,19.9487464 C0.536198839,20.2093324 -0.207540205,19.4655934 0.0530458055,18.6838354 L1.82561905,13.3661157 C1.27979641,12.1513007 0.998251292,10.8327406 1.00172918,9.49967619 C1.00312127,5.90207368 3.03659813,2.61389834 6.25087574,1.00746511 C7.56957773,0.341385645 9.02696643,-0.00378536211 10.5017291,3.13101109e-05 L11.0568145,0.00158149621 C15.8815637,0.267758981 19.7340333,4.12022851 20.0017576,9.00006314 L20.0017576,9.49879623 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-918, -253)">
          <g id="message-circle" transform="translate(920, 255)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
