import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ChevronsDown({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chevrons-down</title>
      <defs>
        <path
          d="M1.70710678,7.29289322 C1.31658249,6.90236893 0.683417511,6.90236893 0.292893219,7.29289322 C-0.0976310729,7.68341751 -0.0976310729,8.31658249 0.292893219,8.70710678 L5.29289322,13.7071068 C5.68341751,14.0976311 6.31658249,14.0976311 6.70710678,13.7071068 L11.7071068,8.70710678 C12.0976311,8.31658249 12.0976311,7.68341751 11.7071068,7.29289322 C11.3165825,6.90236893 10.6834175,6.90236893 10.2928932,7.29289322 L6,11.5857864 L1.70710678,7.29289322 Z M1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L5.29289322,6.70710678 C5.68341751,7.09763107 6.31658249,7.09763107 6.70710678,6.70710678 L11.7071068,1.70710678 C12.0976311,1.31658249 12.0976311,0.683417511 11.7071068,0.292893219 C11.3165825,-0.0976310729 10.6834175,-0.0976310729 10.2928932,0.292893219 L6,4.58578644 L1.70710678,0.292893219 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1227, -324)">
          <g id="chevrons-down" transform="translate(1233, 329)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-6, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
