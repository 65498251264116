import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Share2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>share-2</title>
      <defs>
        <path
          d="M16,0 C18.209139,0 20,1.790861 20,4 C20,6.209139 18.209139,8 16,8 C14.8888376,8 13.8834967,7.54692417 13.1586408,6.81543601 L7.85031287,9.91221401 C7.94782938,10.2580744 8,10.6229447 8,11 C8,11.3766935 7.94792947,11.7412252 7.8505935,12.0867902 L13.1616199,15.1815608 C13.88625,14.4518359 14.890359,14 16,14 C18.209139,14 20,15.790861 20,18 C20,20.209139 18.209139,22 16,22 C13.790861,22 12,20.209139 12,18 C12,17.6214972 12.0525719,17.2552733 12.1508122,16.9082318 L6.84135921,13.815436 C6.11650333,14.5469242 5.11116243,15 4,15 C1.790861,15 0,13.209139 0,11 C0,8.790861 1.790861,7 4,7 C5.11116243,7 6.11650333,7.45307583 6.84135921,8.18456399 L12.1496871,5.08778599 C12.0521706,4.74192557 12,4.37705534 12,4 C12,1.790861 13.790861,0 16,0 Z M16,16 C15.3045793,16 14.6920565,16.3549287 14.3336983,16.8935195 L14.2840082,16.9934777 C14.2641466,17.0275619 14.2425799,17.060035 14.2194793,17.0908521 L14.185885,17.1568505 C14.0666023,17.4130633 14,17.6987538 14,18 C14,19.1045695 14.8954305,20 16,20 C17.1045695,20 18,19.1045695 18,18 C18,16.8954305 17.1045695,16 16,16 Z M4,9 C2.8954305,9 2,9.8954305 2,11 C2,12.1045695 2.8954305,13 4,13 C4.72904906,13 5.36698889,12.609915 5.71648645,12.0270779 L5.72599178,12.0065223 L5.75014799,11.9687351 C5.90934691,11.6817347 6,11.3514539 6,11 C6,10.6456415 5.90784232,10.3128076 5.74618687,10.0241581 L5.72631298,9.9940285 C5.72309376,9.98851216 5.71993332,9.98297772 5.71683144,9.9774259 C5.36989309,9.39186321 4.73070882,9 4,9 Z M16,2 C14.8954305,2 14,2.8954305 14,4 C14,4.35145393 14.0906531,4.6817347 14.249852,4.96873506 L14.273687,5.0059715 C14.2769062,5.01148784 14.2800667,5.01702228 14.2831686,5.0225741 C14.6301069,5.60813679 15.2692912,6 16,6 C17.1045695,6 18,5.1045695 18,4 C18,2.8954305 17.1045695,2 16,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-918, -668)">
          <g id="share-2" transform="translate(920, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
