import { Button } from '@components';
import { useIsMobile } from '@hooks';
import { XIcon } from '@icons';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import SocketApi from '../../../../shared/api/socket';
import SelectUploadMethod from './SelectUploadMethod';
import { SelectMethodsBox } from './styles';

interface IUploadFilesProps {
  open: boolean;
  handleClose: () => void;
  formFieldName: string;
  customerId: string;
}

const UploadFiles: FC<IUploadFilesProps> = ({ open, handleClose, formFieldName, customerId }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleFileUpload = () => {
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          background: theme.palette.secondary.main,
          width: '100%',
          height: '100%',
          overflowY: isMobile ? 'scroll' : 'visible',
        }}
      >
        <Box sx={{ pt: '40px', pr: '40px', display: 'flex', justifyContent: 'end' }}>
          <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <XIcon />
          </Box>
        </Box>
        <Box sx={{ maxWidth: '935px', margin: '0 auto', textAlign: 'center' }}>
          <Typography variant="h1" sx={{ px: '20px' }}>
            <FormattedMessage id="submissionCreation.uploadDocuments.title" />
          </Typography>
          <Typography sx={{ mt: '8px', px: '20px' }}>
            <FormattedMessage id="submissionCreation.uploadDocuments.description" />
          </Typography>
          <SelectMethodsBox>
            <SelectUploadMethod
              open={open}
              formFieldName={formFieldName}
              method={{ scan: true }}
              customerId={customerId}
              handleClose={handleClose}
            />
            <Box
              sx={{
                display: isMobile ? 'none' : 'block',
                borderRight: `1px solid ${theme.palette.grey[500]}`,
                py: '8px',
                height: '369px',
              }}
            ></Box>
            <SelectUploadMethod
              open={open}
              onFileUpload={handleFileUpload}
              formFieldName={formFieldName}
              method={{ load: true }}
              handleClose={handleClose}
            />
          </SelectMethodsBox>
          <Button variant="outlined" onClick={handleClose} sx={{ mt: '32px', mb: isMobile ? '32px' : 0 }}>
            <FormattedMessage id="submissionCreation.cancel.button.title" />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadFiles;
