import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function VolumeX({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>volume-x</title>
      <defs>
        <path
          d="M11,1.0018773 L11,15.0018773 C11,15.8403835 10.0300683,16.3065568 9.37530495,15.7827461 L4.64921894,12.0018773 L1,12.0018773 C0.44771525,12.0018773 0,11.5541621 0,11.0018773 L0,5.0018773 C0,4.44959255 0.44771525,4.0018773 1,4.0018773 L4.64921894,4.0018773 L9.37530495,0.221008492 C10.0300683,-0.302802184 11,0.163371093 11,1.0018773 Z M9,3.08250215 L5.62469505,5.78274611 C5.447382,5.92459655 5.2270715,6.0018773 5,6.0018773 L2,6.0018773 L2,10.0018773 L5,10.0018773 C5.2270715,10.0018773 5.447382,10.0791581 5.62469505,10.2210085 L9,12.9212525 L9,3.08250215 Z M15.2928932,4.29477052 C15.6834175,3.90424623 16.3165825,3.90424623 16.7071068,4.29477052 L19,6.587 L21.2928932,4.29477052 C21.6533772,3.93428656 22.2206082,3.90655702 22.6128994,4.21158191 L22.7071068,4.29477052 C23.0976311,4.68529481 23.0976311,5.31845979 22.7071068,5.70898408 L20.414,8.001 L22.7071068,10.2947705 C23.0675907,10.6552545 23.0953203,11.2224855 22.7902954,11.6147767 L22.7071068,11.7089841 C22.3165825,12.0995084 21.6834175,12.0995084 21.2928932,11.7089841 L19,9.416 L16.7071068,11.7089841 C16.3466228,12.069468 15.7793918,12.0971976 15.3871006,11.7921727 L15.2928932,11.7089841 C14.9023689,11.3184598 14.9023689,10.6852948 15.2928932,10.2947705 L17.585,8.001 L15.2928932,5.70898408 C14.9324093,5.34850012 14.9046797,4.78126906 15.2097046,4.38897786 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-464, -86)">
          <g id="volume-x" transform="translate(465, 90)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -4)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
