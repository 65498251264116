import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ThumbsDown({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>thumbs-down</title>
      <defs>
        <path
          d="M7.68253967,15.0003703 L3.03386341,15.0003176 C2.15373585,15.0102846 1.31363364,14.6332378 0.73607564,13.9690461 C0.158517642,13.3048544 -0.0982206266,12.4205346 0.0340919176,11.5488197 L1.41384398,2.55044559 C1.63815225,1.07133143 2.91791387,-0.0164659471 4.40253967,0.000188664513 L15.6825397,0.000188664513 C16.2348244,0.000188664513 16.6825397,0.448096948 16.6825397,1.0003817 L16.6825397,12.0003817 C16.6825397,12.1403 16.6531774,12.2786612 16.5963512,12.4065202 L12.5963512,21.4065202 C12.4358499,21.7676482 12.0777284,22.0003817 11.6825397,22.0003817 C9.47340067,22.0003817 7.68253967,20.2095207 7.68253967,18.0003817 L7.68253967,15.0003703 Z M4.39123536,2.00025297 C3.89259199,1.99468062 3.46600478,2.35727975 3.39098743,2.85194369 L2.01121594,11.8504458 C1.96718793,12.1405182 2.05276735,12.4352914 2.24528668,12.6566886 C2.43780602,12.8780859 2.71784009,13.0037681 3.02253967,13.0003817 L8.68253967,13.0003817 C9.23482442,13.0003817 9.68253967,13.4480969 9.68253967,14.0003817 L9.68253967,18.0003817 C9.68253967,18.8929467 10.2672294,19.6489501 11.0744919,19.9062748 L14.6825397,11.7881672 L14.6825397,2.00025297 L4.39123536,2.00025297 Z M16.6825397,11.0004399 L18.3702221,11.000538 C19.0151611,11.0119439 19.569227,10.5528602 19.6825397,9.92361306 L19.6825397,3.07715279 C19.5692133,2.4479045 19.0146453,1.98882401 18.3525397,2.0003817 L16.6825397,2.0003817 L16.6825397,11.0004399 Z M15.6825397,0.000381697643 L18.3348572,0.000538044622 C20.0134852,-0.0291488743 21.4484721,1.20259954 21.6735152,2.86633891 L21.6825397,3.0003817 L21.6825397,10.0003817 L21.6735152,10.1344245 C21.4484721,11.7981639 20.0134852,13.0299123 18.3525397,13.0003817 L15.6825397,13.0003817 C15.1302549,13.0003817 14.6825397,12.5526664 14.6825397,12.0003817 L14.6825397,1.0003817 C14.6825397,0.448096948 15.1302549,0.000381697643 15.6825397,0.000381697643 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-165, -140)">
          <g id="thumbs-down" transform="translate(166.3175, 140.9996)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1.3175, -0.9996)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
