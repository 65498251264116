import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function BatteryCharging({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>battery-charging</title>
      <defs>
        <path
          d="M6.19,0.000106273572 C6.74228475,0.000106273572 7.19,0.447821524 7.19,1.00010627 C7.19,1.55239102 6.74228475,2.00010627 6.19,2.00010627 L3,2.00010627 C2.44771525,2.00010627 2,2.44782152 2,3.00010627 L2,11.0001063 C2,11.552391 2.44771525,12.0001063 3,12.0001063 L5,12.0001063 C5.55228475,12.0001063 6,12.4478215 6,13.0001063 C6,13.552391 5.55228475,14.0001063 5,14.0001063 L3,14.0001063 C1.34314575,14.0001063 0,12.6569605 0,11.0001063 L0,3.00010627 C0,1.34325202 1.34314575,0.000106273572 3,0.000106273572 L6.19,0.000106273572 Z M17,0.000106273572 C18.6568542,0.000106273572 20,1.34325202 20,3.00010627 L20,11.0001063 C20,12.6569605 18.6568542,14.0001063 17,14.0001063 L13.81,14.0001063 C13.2577153,14.0001063 12.81,13.552391 12.81,13.0001063 C12.81,12.4478215 13.2577153,12.0001063 13.81,12.0001063 L17,12.0001063 C17.5522847,12.0001063 18,11.552391 18,11.0001063 L18,3.00010627 C18,2.44782152 17.5522847,2.00010627 17,2.00010627 L15,2.00010627 C14.4477153,2.00010627 14,1.55239102 14,1.00010627 C14,0.447821524 14.4477153,0.000106273572 15,0.000106273572 Z M11.5547002,0.168055979 C12.0142289,0.474408438 12.1384028,1.09527778 11.8320503,1.55480647 L8.86851709,6.00010627 L13,6.00010627 C13.7986954,6.00010627 14.2750868,6.89025174 13.8320503,7.55480647 L9.83205029,13.5548065 C9.52569784,14.0143352 8.90482849,14.138509 8.4452998,13.8321566 C7.98577112,13.5258041 7.86159725,12.9049348 8.16794971,12.4454061 L11.1314829,8.00010627 L7,8.00010627 C6.20130462,8.00010627 5.72491322,7.10996081 6.16794971,6.44540608 L10.1679497,0.445406077 C10.4743022,-0.0141226113 11.0951715,-0.13829648 11.5547002,0.168055979 Z M24,8.00010627 L24,6.00010627 C24,5.44782152 23.5522847,5.00010627 23,5.00010627 C22.4477153,5.00010627 22,5.44782152 22,6.00010627 L22,8.00010627 C22,8.55239102 22.4477153,9.00010627 23,9.00010627 C23.5522847,9.00010627 24,8.55239102 24,8.00010627 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1720, -530)">
          <g id="battery-charging" transform="translate(1720, 535)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(0, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
