import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon, InfoIcon } from '@icons';
import { Box, IconButton, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import fourthTipImage from '@assets/fourthTipImage.png';
import secondTipImage from '@assets/secondTipImage.png';
import thirdTipImage from '@assets/thirdTipImage.png';
import SignUpCarouselSlide from '../SignUpCarouselSlide';
import { PaginationButton, ProceedAppButton, ProceedAppButtonBox, StyledImg } from './styles';

const SignUpCarouselTips: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const slides = [
    {
      title: 'signup.carousel.firstSlide.title',
      image: <InfoIcon
        color={theme.palette.primary.dark}
        style={{width: '100px', height: '100px', marginTop: '50px', marginBottom: '50px'}}
      />,
      topDescription: 'signup.carousel.firstSlide.topDescription',
      bottomDescription: 'signup.carousel.firstSlide.bottomDescription',
    },
    {
      title: 'signup.carousel.secondSlide.title',
      image: <StyledImg src={secondTipImage} alt="Tip image" />,
      topDescription: 'signup.carousel.secondSlide.topDescription',
      bottomDescription: 'signup.carousel.secondSlide.bottomDescription',
    },
    {
      title: 'signup.carousel.thirdSlide.title',
      image: <StyledImg src={thirdTipImage} alt="Tip image" />,
      topDescription: 'signup.carousel.thirdSlide.topDescription',
      bottomDescription: 'signup.carousel.thirdSlide.bottomDescription',
    },
    {
      title: 'signup.carousel.fourthSlide.title',
      image: <StyledImg src={fourthTipImage} alt="Tip image" style={{width: '130px', marginTop: '50px', marginBottom: '50px'}} />,
      topDescription: 'signup.carousel.fourthSlide.topDescription',
      bottomDescription: 'signup.carousel.fourthSlide.bottomDescription',
    },
    {
      title: 'signup.carousel.fifthSlide.title',
      image: <CheckCircleIcon
        color={theme.palette.primary.dark}
        style={{width: '100px', height: '100px', marginTop: '50px', marginBottom: '50px'}}
      />,
      topDescription: 'signup.carousel.fifthSlide.topDescription',
    },
  ];

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton className="arrow-left" sx={{ '&.swiper-button-disabled': { visibility: 'hidden' } }}>
            <ArrowLeftIcon />
          </IconButton>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true }}
            navigation={{
              nextEl: '.arrow-right',
              prevEl: '.arrow-left',
            }}
            modules={[Pagination, Navigation]}
            style={{ maxWidth: '600px', display: 'flex', alignItems: 'center', textAlign: 'center' }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <SignUpCarouselSlide
                  number={index + 1}
                  image={slide.image}
                  title={formatMessage({ id: slide.title })}
                  topDescription={formatMessage({ id: slide.topDescription })}
                  bottomDescription={slide.bottomDescription && formatMessage({ id: slide.bottomDescription })}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <IconButton className="arrow-right" sx={{ '&.swiper-button-disabled': { visibility: 'hidden' } }}>
            <ArrowRightIcon />
          </IconButton>
        </Box>
        <PaginationButton component="div" className="swiper-pagination" />
      </Box>
      <ProceedAppButtonBox>
        <ProceedAppButton color="primary" onClick={() => navigate('/submission-creation')}>
          <FormattedMessage id="signup.carousel.proceedApp.button.title" />
        </ProceedAppButton>
      </ProceedAppButtonBox>
    </Box>
  );
};

export default SignUpCarouselTips;
