import { ActionType } from '@app-types/verificationCode';
import { StepperContext } from '@components/Stepper';
import React, { useContext } from 'react';
import SendCodeContent from '../../SignUpSendCode/components/SendCodeContent';
import { RestorePasswordContext } from '../RestorePassword';

const VerifyCodeStep: React.FC = () => {
  const { email, setCustomerId } = useContext(RestorePasswordContext);
  const { goToNextStep } = useContext(StepperContext);

  const onSubmit = (id: string) => {
    setCustomerId(id);
    goToNextStep();
  };

  return (
    <SendCodeContent
      titleId="forgotPassword.verifyCode.title"
      subtitleId="forgotPassword.verifyCode.subtitle"
      email={email}
      actionType={ActionType.RestorePassword}
      onSubmit={onSubmit}
    />
  );
};

export default VerifyCodeStep;
