import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Aperture({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>aperture</title>
      <defs>
        <path
          d="M11,0 C11.3967433,0 11.7885809,0.0210040174 12.1744614,0.0619607369 C12.2196627,0.0636978634 12.2649039,0.069217594 12.3098672,0.0779128216 C16.2275452,0.542025134 19.513889,3.06580602 21.0565874,6.53620323 C21.0731423,6.56852533 21.0885693,6.60233161 21.1021091,6.63708158 C21.6794582,7.97508709 22,9.45011027 22,11 C22,13.417943 21.2198552,15.6536734 19.8975466,17.4692103 C19.8696617,17.5158531 19.8369557,17.5599205 19.8004725,17.6013127 C17.7933028,20.2721337 14.5984338,22 11,22 C10.6032567,22 10.2114191,21.978996 9.82553858,21.9380393 C9.78033734,21.9363021 9.73509608,21.9307824 9.69013277,21.9220872 C5.77057077,21.4577513 2.48295033,18.9317661 0.941188128,15.4587893 C0.920960231,15.4196597 0.903067766,15.3786304 0.88794967,15.3362829 C0.316394071,14.0057611 0,12.5398294 0,11 C0,8.582057 0.780144799,6.34632658 2.1024534,4.53078974 C2.13033829,4.48414692 2.16304434,4.44007946 2.19952752,4.39868729 C4.20669717,1.72786632 7.40156618,0 11,0 Z M15.619,12.999 L11.5888725,19.9810428 C14.088904,19.8195726 16.3108983,18.6373293 17.8423483,16.84682 L15.619,12.999 Z M11.577,16 L3.51635911,16.00121 C4.84024688,17.9783047 6.92683299,19.4015721 9.35514199,19.8500367 L11.577,16 Z M19.4880298,8.00041586 L15.042,8 L19.073373,14.9820556 C19.6666263,13.7815633 20,12.4297134 20,11 C20,9.94824913 19.8195907,8.93863511 19.4880298,8.00041586 Z M14.465,10.999 L12.733,8 L9.266,8 L7.534,10.999 L9.267,14 L12.732,14 L14.465,10.999 Z M2,11 C2,12.0521223 2.18053678,13.0620779 2.51232144,14.000578 L6.958,14 L2.92662703,7.01794438 C2.33337365,8.21843672 2,9.57028656 2,11 Z M4.15765169,5.15318001 L6.38,9 L10.4111275,2.01895718 C7.91109598,2.1804274 5.68910172,3.36267069 4.15765169,5.15318001 Z M12.644858,2.14996326 L10.421,6 L18.4843066,5.99978429 C17.1604526,4.0221725 15.073574,2.5985031 12.644858,2.14996326 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-239, -668)">
          <g id="aperture" transform="translate(240, 669)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
