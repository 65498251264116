import eventBus, { EventBusCallback } from '../eventBus';

type SavingStatus = 'init' | 'pending' | 'loaded' | 'error' | 'show' | 'hide';

const eventName = 'changeSavingSubmitionStatus';

const subscribe = (callback: EventBusCallback) => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (status?: SavingStatus) => {
  return eventBus.broadcast(eventName, status);
};

export default {
  eventName,
  subscribe,
  broadcast,
};
