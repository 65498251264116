import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Rewind({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>rewind</title>
      <defs>
        <path
          d="M9,12.9572122 L9,3.0464909 L2.62882204,8.00185154 L9,12.9572122 Z M9.38605939,15.7912038 L0.386059386,8.79120375 C-0.128686462,8.39084587 -0.128686462,7.6128572 0.386059386,7.21249932 L9.38605939,0.212499318 C10.0429174,-0.298390243 11,0.16970336 11,1.00185154 L11,15.0018515 C11,15.8339997 10.0429174,16.3020933 9.38605939,15.7912038 Z M20,12.9572122 L20,3.0464909 L13.628822,8.00185154 L20,12.9572122 Z M20.3860594,15.7912038 L11.3860594,8.79120375 C10.8713135,8.39084587 10.8713135,7.6128572 11.3860594,7.21249932 L20.3860594,0.212499318 C21.0429174,-0.298390243 22,0.16970336 22,1.00185154 L22,15.0018515 C22,15.8339997 21.0429174,16.3020933 20.3860594,15.7912038 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-387, -671)">
          <g id="rewind" transform="translate(388, 674.9981)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -3.9981)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
