import { FormatDateOptions } from 'react-intl';

export enum ViewportBreakpoint {
  Desktop = 1280,
  Tablet = 1024,
  Mobile = 768,
}

export enum LocalStorageKey {
  EligibilityCheck = 'eligibility-check',
  ZipCode = 'zip-code',
}

export enum SessionStorageKey {
  Email = 'email',
}

export enum Currency {
  Euro = 'EUR',
}

export const DATE_WITH_TIME_OPTIONS: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const DATE_OPTIONS: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
