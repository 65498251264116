import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Trash({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>trash</title>
      <defs>
        <path
          d="M12,0 C13.6568542,0 15,1.34314575 15,3 L15,4 L19,4 C19.5128358,4 19.9355072,4.38604019 19.9932723,4.88337887 L20,5 C20,5.55228475 19.5522847,6 19,6 L18,6 L18,19 C18,20.5976809 16.75108,21.9036609 15.1762728,21.9949073 L15,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,6 L1,6 C0.487164161,6 0.0644928393,5.61395981 0.00672773133,5.11662113 L0,5 C0,4.44771525 0.44771525,4 1,4 L5,4 L5,3 C5,1.40231912 6.24891996,0.0963391206 7.82372721,0.00509269341 L8,0 Z M16,6 L4,6 L4,19 C4,19.5128358 4.38604019,19.9355072 4.88337887,19.9932723 L5,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,6 Z M12,2 L8,2 C7.44771525,2 7,2.44771525 7,3 L7,4 L13,4 L13,3 C13,2.48716416 12.6139598,2.06449284 12.1166211,2.00672773 L12,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1722, -83)">
          <g id="trash" transform="translate(1724, 84)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
