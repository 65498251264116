const isApiUrlProvided =
  !!process.env.REACT_APP_API_PORT && !!process.env.REACT_APP_API_HOSTNAME && !!process.env.REACT_APP_API_PROTOCOL;

export default {
  api: {
    prefix: process.env.REACT_APP_API_PREFIX || 'api',
    hostname: process.env.REACT_APP_API_HOSTNAME || 'localhost',
    protocol: process.env.REACT_APP_API_PROTOCOL || 'http',
    port: process.env.REACT_APP_API_PORT || '8080',
    url: isApiUrlProvided
      ? `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}`
      : '',
  },
};
