import { ChangeCustomerDto } from '@app-types/customer';

export const convertSettingsMainInfoFormValuesToDto = (formValues: ChangeCustomerDto): ChangeCustomerDto => {
  return {
    email: formValues.email,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    dateOfBirth: null,
    street: formValues.street,
    houseNumber: formValues.houseNumber,
    zipCode: formValues.zipCode,
    city: formValues.city,
    phone: formValues.phone,
    bank: formValues.bank,
    iban: formValues.iban,
  };
};
