import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Check({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>check</title>
      <defs>
        <path
          d="M1.70710678,6.29289322 C1.31658249,5.90236893 0.683417511,5.90236893 0.292893219,6.29289322 C-0.0976310729,6.68341751 -0.0976310729,7.31658249 0.292893219,7.70710678 L5.29289322,12.7071068 C5.68341751,13.0976311 6.31658249,13.0976311 6.70710678,12.7071068 L17.7071068,1.70710678 C18.0976311,1.31658249 18.0976311,0.683417511 17.7071068,0.292893219 C17.3165825,-0.0976310729 16.6834175,-0.0976310729 16.2928932,0.292893219 L6,10.5857864 L1.70710678,6.29289322 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1224, -464)">
          <g id="check" transform="translate(1227, 470)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -6)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
