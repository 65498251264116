import { verificationCodesApi } from '@api';
import { IReSendVerificationCodeDto } from '@app-types/verificationCode';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useReSendVerificationCode = (options?: UseMutationOptions<unknown, unknown, IReSendVerificationCodeDto>) => {
  return useMutation({
    ...options,
    mutationFn: verificationCodesApi.reSend,
  });
};

export default useReSendVerificationCode;
