import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Link({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>link</title>
      <defs>
        <path
          d="M4.69475268,8.68658175 C5.92551372,7.4553744 7.63034577,6.81942224 9.36677284,6.94378174 C11.1031999,7.06814124 12.7000061,7.94055024 13.7427527,9.33458175 C14.0735594,9.77683207 13.9832165,10.4035186 13.5409662,10.7343254 C13.0987159,11.0651321 12.4720293,10.9747892 12.1412226,10.5325389 C11.4460582,9.60318458 10.3815208,9.02157857 9.2239027,8.93867224 C8.06628465,8.85576591 6.92972996,9.27973401 6.10909442,10.1006671 L3.12127585,13.0882721 C1.60538701,14.6577877 1.62706635,17.1526112 3.17000154,18.6955464 C4.71293674,20.2384816 7.2077603,20.260161 8.76488086,18.7564535 L10.4748809,17.0464535 C10.8654052,16.6559293 11.4985701,16.6559293 11.8890944,17.0464535 C12.2796187,17.4369778 12.2796187,18.0701428 11.8890944,18.4606671 L10.1666994,20.1828485 C7.81242611,22.4566818 4.07019077,22.4241628 1.75578798,20.10976 C-0.558614809,17.7953572 -0.591133822,14.0531219 1.69488086,11.6864535 Z M11.7172758,1.68427212 C14.0715492,-0.589561133 17.8137845,-0.557042119 20.1281873,1.75736067 C22.4425901,4.07176346 22.4751091,7.8139988 20.1890944,10.1806671 L17.1892226,13.1805389 C15.9584616,14.4117463 14.2536295,15.0476984 12.5172024,14.9233389 C10.7807754,14.7989794 9.18396918,13.9265704 8.1412226,12.5325389 C7.81041586,12.0902886 7.90075873,11.463602 8.34300906,11.1327953 C8.78525938,10.8019885 9.41194595,10.8923314 9.74275268,11.3345817 C10.4379171,12.2639361 11.5024545,12.8455421 12.6600726,12.9284484 C13.8176906,13.0113548 14.9542453,12.5873866 15.7748809,11.7664535 L18.7626994,8.77884854 C20.2785883,7.20933299 20.2569089,4.71450943 18.7139737,3.17157423 C17.1710385,1.62863904 14.676215,1.6069597 13.1170299,3.11272563 L11.3970299,4.82272563 C11.0053687,5.21210972 10.3722064,5.21026376 9.98282234,4.81860258 C9.59343826,4.4269414 9.59528421,3.79377911 9.98694539,3.40439503 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-307, -320)">
          <g id="link" transform="translate(308.058, 321.0664)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1.058, -1.0664)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
