import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Grid({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>grid</title>
      <defs>
        <path
          d="M2,2 L2,7 L7,7 L7,2 L2,2 Z M19,11 C19.5522847,11 20,11.4477153 20,12 L20,19 C20,19.5522847 19.5522847,20 19,20 L12,20 C11.4477153,20 11,19.5522847 11,19 L11,12 C11,11.4477153 11.4477153,11 12,11 L19,11 Z M8,11 C8.55228475,11 9,11.4477153 9,12 L9,19 C9,19.5522847 8.55228475,20 8,20 L1,20 C0.44771525,20 0,19.5522847 0,19 L0,12 C0,11.4477153 0.44771525,11 1,11 L8,11 Z M18,13 L13,13 L13,18 L18,18 L18,13 Z M7,13 L2,13 L2,18 L7,18 L7,13 Z M8,0 C8.55228475,0 9,0.44771525 9,1 L9,8 C9,8.55228475 8.55228475,9 8,9 L1,9 C0.44771525,9 0,8.55228475 0,8 L0,1 C0,0.44771525 0.44771525,0 1,0 L8,0 Z M19,0 C19.5522847,0 20,0.44771525 20,1 L20,8 C20,8.55228475 19.5522847,9 19,9 L12,9 C11.4477153,9 11,8.55228475 11,8 L11,1 C11,0.44771525 11.4477153,0 12,0 L19,0 Z M18,2 L13,2 L13,7 L18,7 L18,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1427, -669)">
          <g id="grid" transform="translate(1429, 671)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
