import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ChevronUp({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chevron-up</title>
      <defs>
        <path
          d="M7,2.41421356 L12.2928932,7.70710678 C12.6834175,8.09763107 13.3165825,8.09763107 13.7071068,7.70710678 C14.0976311,7.31658249 14.0976311,6.68341751 13.7071068,6.29289322 L7.70710678,0.292893219 C7.31658249,-0.0976310729 6.68341751,-0.0976310729 6.29289322,0.292893219 L0.292893219,6.29289322 C-0.0976310729,6.68341751 -0.0976310729,7.31658249 0.292893219,7.70710678 C0.683417511,8.09763107 1.31658249,8.09763107 1.70710678,7.70710678 L7,2.41421356 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1509, -201)">
          <g id="chevron-up" transform="translate(1514, 209)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -8)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
