import { ChangeCustomerDto } from '@app-types/customer';
import axios from './axios';

const changeCustomerInfo = async ({ id, customer }: { id: string | undefined; customer: ChangeCustomerDto }) => {
  const response = await axios.patch(`/customers/change-customer-info/${id}`, customer);

  return response.data;
};

const deleteCustomer = (id: string | undefined) => axios.delete(`/customers/${id}`);

export default {
  changeCustomerInfo,
  deleteCustomer
};
