import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Chrome({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chrome</title>
      <defs>
        <path
          d="M11,0 C15.4879145,0 19.3480954,2.68764716 21.0588119,6.54121067 C21.0619153,6.54726667 21.0651713,6.55372617 21.0683588,6.56022533 L21.1088714,6.65573503 C21.6823467,7.98841046 22,9.45710927 22,11 C22,17.0751322 17.0751322,22 11,22 C10.6032567,22 10.2114191,21.978996 9.82553858,21.9380393 C9.7801945,21.9362766 9.73521166,21.9307872 9.69050231,21.9221585 C4.23245437,21.2752484 0,16.6318956 0,11 C0,8.58071145 0.781013316,6.34383834 2.10466143,4.52775918 C2.13289614,4.48049253 2.16596793,4.43635814 2.20284747,4.39493984 C4.20988298,1.72614383 7.40336024,0 11,0 Z M19.4880298,8.00041586 L15.0002202,7.99980567 C15.6279975,8.83549949 16,9.87430683 16,11 C16,11.9721597 15.7225524,12.8795181 15.2425192,13.6472129 L11.5888725,19.9810428 C16.2849519,19.6777359 20,15.7727159 20,11 C20,9.94824913 19.8195907,8.93863511 19.4880298,8.00041586 Z M2,11 C2,15.4084158 5.16955557,19.0765422 9.35413895,19.8498514 L11.5956379,15.9648897 C11.4003221,15.9880742 11.2015538,16 11,16 C9.08113347,16 7.41473035,14.9190756 6.57647414,13.3329103 L2.92712257,7.01694178 C2.3335593,8.21768402 2,9.56988854 2,11 Z M11,8 C9.34314575,8 8,9.34314575 8,11 C8,11.4991495 8.12190339,11.9698269 8.33756273,12.3838848 L8.4057496,12.4995226 C8.41258381,12.5113447 8.41914907,12.5232485 8.42544727,12.5352269 C8.94607457,13.4127655 9.9044593,14 11,14 C12.0590737,14 12.9899704,13.4512091 13.5238305,12.622487 L13.5937781,12.5003405 C13.6036336,12.4832548 13.6139171,12.4665699 13.6246073,12.4502916 C13.86436,12.021364 14,11.5265222 14,11 C14,9.34314575 12.6568542,8 11,8 Z M11,2 C8.26180021,2 5.80920599,3.22282284 4.15851465,5.15217123 L6.40163528,9.03330822 C7.16549595,7.24963283 8.93681428,6 11,6 L18.4843066,5.99978429 C16.8697643,3.58793383 14.1203231,2 11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1222, -391)">
          <g id="chrome" transform="translate(1223, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
