import { Button } from '@components';
import { useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Warning from '../Warning';

interface IBusinessAccountWarningStepProps {
  onBack: () => void;
}

const BusinessAccountWarningStep: React.FC<IBusinessAccountWarningStepProps> = ({ onBack }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Warning>
      <Typography variant="overline" color="error.main" sx={{ marginBottom: theme.spacing(0.5) }}>
        <FormattedMessage id="eligibilityCheck.step.7.overline" />
      </Typography>
      <Typography variant="h2" sx={{ marginBottom: theme.spacing(2) }}>
        <FormattedMessage id="eligibilityCheck.step.7.title" />
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: theme.spacing(2), whiteSpace: 'pre-wrap' }}>
        <FormattedMessage id="eligibilityCheck.step.7.description" />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Button variant="outlined" onClick={onBack}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button variant="contained" onClick={() => navigate('/login')} color="error">
          <FormattedMessage id="common.leave" />
        </Button>
      </Stack>
    </Warning>
  );
};

export default BusinessAccountWarningStep;
