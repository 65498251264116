import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(() => ({
  marginLeft: '-7px',
  marginRight: '31px',

  '.MuiFormControlLabel-label': {
    fontSize: '16px',
    zIndex: 1,
  },
}));
