import { StepperLayout } from '@components';
import { withoutAuth } from '@hoc';
import React, { useMemo, useState } from 'react';

import ForgotPasswordStep from './steps/ForgotPasswordStep';
import ResetPasswordStep from './steps/ResetPasswordStep';
import VerifyCodeStep from './steps/VerifyCodeStep';

export interface IRestorePasswordContext {
  customerId: string;
  email: string;
  setCustomerId: (id: string) => void;
  setEmail: (email: string) => void;
}

export const RestorePasswordContext = React.createContext<IRestorePasswordContext>({
  customerId: '',
  email: '',
  setCustomerId: () => {},
  setEmail: () => {},
});

const RestorePassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');

  const contextValue = useMemo(() => ({ customerId, email, setCustomerId, setEmail }), [customerId, email]);

  return (
    <RestorePasswordContext.Provider value={contextValue}>
      <StepperLayout
        disableLogout
        hideSteps
        steps={[
          { id: '1', title: 'empty', content: <ForgotPasswordStep /> },
          { id: '2', title: 'empty', content: <VerifyCodeStep /> },
          { id: '3', title: 'empty', content: <ResetPasswordStep /> },
        ]}
        className="Mui-default-padding"
      />
    </RestorePasswordContext.Provider>
  );
};

export default withoutAuth(RestorePassword);
