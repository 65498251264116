import { AxiosResponse } from 'axios';
import axios from './axios';

interface IValidateResponse {
  valid: boolean;
  iban: string;
  bankData: {
    bankCode: string;
    name: string;
    zip: string;
    city: string;
    bic: string;
  };
}

const validate = (iban: string): Promise<AxiosResponse<IValidateResponse>> =>
  axios.get(`https://openiban.com/validate/${iban}?getBIC=true`);

export default { validate };
