import { Box, Grid, IconButton as MuiIconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { IFile } from '@app-types';
import {
  CheckboxController,
  DatePickerController,
  FieldErrorMessage,
  IconButton,
  InputController,
  RadioGroupController,
} from '@components';
import { StepperContext } from '@components/Stepper';
import UploadedFile from '@components/UploadedFile';
import { ViewportBreakpoint } from '@constants';
import { useViewportSize } from '@hooks';
import { InfoIcon, Trash2Icon } from '@icons';
import { convertFileListToArray } from '@utils';
import { MAX_CHILD_COUNT } from '../../const';
import { SubmissionRadioValue } from '../../types';
import { SubmissionFormContext } from '../SubmissionForm';
import UploadFileButton from '../UploadFileButton';
import { BorderedBlock, FormInfoBlock } from '../styles';

interface IMaintenanceObligationsProps {}

const MaintenanceObligations: FC<IMaintenanceObligationsProps> = () => {
  const theme = useTheme();

  const { openInfoDrawer } = useContext(StepperContext);

  const { getChildrenValue, appendChild, childrenFields, removeChild } = useContext(SubmissionFormContext);

  const { formatMessage } = useIntl();

  const { control, watch, setValue } = useFormContext();

  const marriageCertificateFiles = watch('marriageCertificateFiles') as FileList;
  const uploadedMarriageCertificateFiles = watch('uploadedMarriageCertificateFiles') as IFile[];
  const hasSpouse = watch('hasSpouse');
  const isThereSpouseHousehold = watch('isThereSpouseHousehold');

  const viewportWidth = useViewportSize();
  const isMobile = viewportWidth <= ViewportBreakpoint.Mobile;

  const getMarriageCertificates = () => {
    return convertFileListToArray(marriageCertificateFiles);
  };

  const handleMarriageCertificateFileDelete = (index: number) => {
    return () => {
      const fileList = getMarriageCertificates();

      if (!fileList) {
        return;
      }

      fileList.splice(index, 1);
      setValue('marriageCertificateFiles', fileList);
    };
  };

  const handleUploadedMarriageCertificateFileDelete = (index: number) => {
    return () => {
      const fileList = getMarriageCertificates();

      if (!fileList) {
        return;
      }

      fileList.splice(index, 1);
      setValue('uploadedMarriageCertificateFiles', fileList);
    };
  };

  const getUploadedChildDocuments = (fieldIndex: number) => {
    const childDocuments = getChildrenValue(fieldIndex, 'uploadedChildDocuments') as FileList;

    return convertFileListToArray(childDocuments);
  };

  const handleUploadedChildDocumentFileDelete = (childIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getUploadedChildDocuments(childIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);
      setValue(`children[${childIndex}].uploadedChildDocuments`, fileList);
    };
  };

  const getChildDocuments = (fieldIndex: number) => {
    const childDocuments = getChildrenValue(fieldIndex, 'childDocuments') as IFile[];

    return childDocuments;
  };

  const handleChildDocumentFileDelete = (childIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getChildDocuments(childIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);
      setValue(`children[${childIndex}].childDocuments`, fileList);
    };
  };

  const handleChildBenefitGoesIntoPAccount = (e: React.ChangeEvent<HTMLInputElement>, fieldIndex: number) => {
    setValue(`children[${fieldIndex}].isThereCreditedToPAccount`, e.target.checked);
    setValue(`children[${fieldIndex}].isBenefits`, e.target.checked);
  };

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Stack width="100%">
      <BorderedBlock
        display="flex"
        justifyContent="space-between"
        direction="row"
        mt="39px"
        pb="19px"
        gap={1}
        alignItems="center"
      >
        <Typography variant="h2">
          <FormattedMessage id="submissionCreation.maintenanceObligations.title" />
        </Typography>
        <MuiIconButton
          size="small"
          onClick={() =>
            openInfoDrawer(
              formatMessage({ id: 'submissionCreation.drawer.title' }),
              formatMessage({ id: 'submissionCreation.maintenanceObligations.drawer.description' }),
            )
          }
        >
          <InfoIcon color={theme.palette.primary.dark} />
        </MuiIconButton>
      </BorderedBlock>

      <BorderedBlock pt="27px" pb="30px">
        <RadioGroupController
          row
          name="hasSpouse"
          control={control}
          label={formatMessage({ id: 'submissionCreation.maintenanceObligations.hasSpouse' })}
          options={[
            { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
            { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
          ]}
          onIconClick={() =>
            openInfoDrawer(
              formatMessage({ id: 'submissionCreation.drawer.title' }),
              formatMessage({ id: 'submissionCreation.maintenanceObligations.hasSpouse.drawer.description' }),
            )
          }
          showIcon
        />
      </BorderedBlock>

      {hasSpouse === SubmissionRadioValue.Yes && (
        <>
          <BorderedBlock pt="27px" pb="30px">
            <RadioGroupController
              row
              name="isThereSpouseHousehold"
              control={control}
              label={formatMessage({ id: 'submissionCreation.maintenanceObligations.isThereSpouseHousehold' })}
              options={[
                { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
                { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
              ]}
              onIconClick={() =>
                openInfoDrawer(
                  formatMessage({ id: 'submissionCreation.drawer.title' }),
                  formatMessage({
                    id: 'submissionCreation.maintenanceObligations.isThereSpouseHousehold.drawer.description',
                  }),
                )
              }
              showIcon
            />
          </BorderedBlock>

          <BorderedBlock pt="27px" pb="30px">
            {isThereSpouseHousehold === SubmissionRadioValue.No && (
              <RadioGroupController
                row
                name="isThereSpouseMaintenance"
                control={control}
                label={formatMessage({ id: 'submissionCreation.maintenanceObligations.isThereSpouseMaintenance' })}
                options={[
                  { value: SubmissionRadioValue.Yes, label: formatMessage({ id: 'common.yes' }) },
                  { value: SubmissionRadioValue.No, label: formatMessage({ id: 'common.no' }) },
                ]}
                onIconClick={() =>
                  openInfoDrawer(
                    formatMessage({ id: 'submissionCreation.drawer.title' }),
                    formatMessage({
                      id: 'submissionCreation.maintenanceObligations.isThereSpouseMaintenance.drawer.description',
                    }),
                  )
                }
                showIcon
              />
            )}

            <FormInfoBlock mt="24px">
              <Typography fontSize="16px" fontWeight={600} mb={2} position="relative">
                <FormattedMessage id="submissionCreation.maintenanceObligations.spouseInfo" />
              </Typography>
              <Grid container spacing={isMobile ? 1 : 4}>
                <Grid item xs={12} md={4}>
                  <InputController
                    name="spouseFirstName"
                    control={control}
                    label={formatMessage({ id: 'common.user.firstName' })}
                    placeholder={formatMessage({ id: 'common.input.placeholder.firstName' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <InputController
                    name="spouseLastName"
                    control={control}
                    label={formatMessage({ id: 'common.user.lastName' })}
                    placeholder={formatMessage({ id: 'common.input.placeholder.lastName' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePickerController
                    fullWidth
                    name="spouseBirthDate"
                    control={control}
                    label={formatMessage({ id: 'common.user.birthDate' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item container xs={12} columnSpacing={4}>
                  <Grid item xs={12}>
                    <Stack direction="row" mb={1} gap={1} alignItems="center">
                      <Typography fontSize="16px" sx={{ zIndex: 1 }}>
                        <FormattedMessage
                          id="submissionCreation.maintenanceObligations.uploadFile"
                          values={{
                            message: (
                              <Box fontWeight={600} display="inline" component="span">
                                <FormattedMessage id="submissionCreation.maintenanceObligations.marriageCertificate" />
                              </Box>
                            ),
                          }}
                        />
                      </Typography>
                      <MuiIconButton
                        size="small"
                        onClick={() =>
                          openInfoDrawer(
                            formatMessage({ id: 'submissionCreation.drawer.title' }),
                            formatMessage({
                              id: 'submissionCreation.maintenanceObligations.uploadFile.drawer.description',
                            }),
                          )
                        }
                      >
                        <InfoIcon color={theme.palette.primary.dark} />
                      </MuiIconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadFileButton fieldName="uploadedMarriageCertificateFiles" />
                    <Box mt={1}>
                      {errors.marriageCertificateFiles && (
                        <FieldErrorMessage message={errors.marriageCertificateFiles.message} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} pt={1}>
                    <Stack direction="column" gap={1}>
                      {getMarriageCertificates()?.map((file, index) => (
                        <UploadedFile
                          onDeleteFile={handleMarriageCertificateFileDelete(index)}
                          key={file.name}
                          fileName={file.name}
                        />
                      ))}
                      {uploadedMarriageCertificateFiles?.map((file, index) => (
                        <UploadedFile
                          onDeleteFile={handleUploadedMarriageCertificateFileDelete(index)}
                          key={file.name}
                          fileName={file.name || ''}
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </FormInfoBlock>
          </BorderedBlock>
        </>
      )}

      <Stack>
        <Typography variant="h4" mt="30px" mb="10px">
          <FormattedMessage id="submissionCreation.maintenanceObligations.childrenInfo" />
        </Typography>

        <Stack direction="column" gap={4}>
          {childrenFields.map(({ id }, fieldIndex) => (
            <FormInfoBlock key={id} mt="24px">
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight={600} mb={2} position="relative">
                  {fieldIndex < 20 ? (
                    <>
                      <FormattedMessage id={`submissionCreation.maintenanceObligations.childNumber.${fieldIndex + 1}`} />
                      &nbsp;
                      <FormattedMessage id="submissionCreation.maintenanceObligations.child" />
                    </>
                  ) : (
                    <FormattedMessage
                      id="submissionCreation.maintenanceObligations.childNumber"
                      values={{ number: fieldIndex + 1 }}
                    />
                  )}
                </Typography>
                <MuiIconButton onClick={() => removeChild(fieldIndex)}>
                  <Trash2Icon style={{ height: 24, width: 24 }} color={theme.palette.error.main} />
                </MuiIconButton>
              </Stack>

              <Grid container spacing={isMobile ? 1 : 4}>
                <Grid item xs={12} md={4}>
                  <InputController
                    name={`children[${fieldIndex}].firstName`}
                    control={control}
                    label={formatMessage({ id: 'common.user.firstName' })}
                    placeholder={formatMessage({ id: 'common.input.placeholder.firstName' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <InputController
                    name={`children[${fieldIndex}].lastName`}
                    control={control}
                    label={formatMessage({ id: 'common.user.lastName' })}
                    placeholder={formatMessage({ id: 'common.input.placeholder.lastName' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePickerController
                    fullWidth
                    name={`children[${fieldIndex}].birthDate`}
                    control={control}
                    label={formatMessage({ id: 'common.user.birthDate' })}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4} alignContent="center">
                  <CheckboxController
                    name={`children[${fieldIndex}].isThereHousehold`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.maintenanceObligations.isThereHousehold' })}
                    backgroundMode="white"
                    disabled={getChildrenValue(fieldIndex, 'isThereMaintenance')}
                  />
                </Grid>
                <Grid item xs={12} md={4} alignContent="center">
                  <CheckboxController
                    name={`children[${fieldIndex}].isThereMaintenance`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.maintenanceObligations.isThereMaintenance' })}
                    backgroundMode="white"
                    disabled={getChildrenValue(fieldIndex, 'isThereHousehold')}
                  />
                </Grid>
                <Grid item xs={12} md={4} alignContent="center">
                  <CheckboxController
                    name={`children[${fieldIndex}].isThereCreditedToPAccount`}
                    control={control}
                    label={formatMessage({ id: 'submissionCreation.maintenanceObligations.isThereCreditedToPAccount' })}
                    onChange={(e) => handleChildBenefitGoesIntoPAccount(e, fieldIndex)}
                    backgroundMode="white"
                  />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="column" mb={1}>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography fontSize="16px" sx={{ zIndex: 1 }}>
                          <FormattedMessage id="submissionCreation.maintenanceObligations.uploadFollowingDocuments" />
                        </Typography>
                        <MuiIconButton
                          size="small"
                          onClick={() =>
                            openInfoDrawer(
                              formatMessage({ id: 'submissionCreation.drawer.title' }),
                              formatMessage({
                                id: 'submissionCreation.maintenanceObligations.uploadFollowingDocuments.drawer.description',
                              }),
                            )
                          }
                        >
                          <InfoIcon color={theme.palette.primary.dark} />
                        </MuiIconButton>
                      </Stack>
                      <Typography fontSize="16px" component="span" fontWeight={600} sx={{ zIndex: 1 }}>
                        <FormattedMessage id="submissionCreation.maintenanceObligations.birthCertificate" />
                      </Typography>
                      {getChildrenValue(fieldIndex, 'isThereMaintenance') && (
                        <Typography fontSize="16px" component="span" fontWeight={600} sx={{ zIndex: 1 }}>
                          <FormattedMessage id="submissionCreation.maintenanceObligations.paymentReceipt" />
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <UploadFileButton fieldName={`children[${fieldIndex}].uploadedChildDocuments`} />
                    <Box mt={1}>
                      {/* @ts-ignore */}
                      {errors && errors.children?.[fieldIndex]?.childDocuments && (
                        // @ts-ignore
                        <FieldErrorMessage message={errors.children?.[fieldIndex]?.childDocuments?.message} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack direction="column" gap={1} mt={1}>
                      {getChildDocuments(fieldIndex)?.map((file, fileIndex) => (
                        <UploadedFile
                          key={file.name}
                          fileName={file.name || ''}
                          onDeleteFile={handleChildDocumentFileDelete(fieldIndex, fileIndex)}
                        />
                      ))}
                      {getUploadedChildDocuments(fieldIndex)?.map((file, fileIndex) => (
                        <UploadedFile
                          key={file.name}
                          fileName={file.name}
                          onDeleteFile={handleUploadedChildDocumentFileDelete(fieldIndex, fileIndex)}
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </FormInfoBlock>
          ))}
        </Stack>
        {childrenFields?.length < MAX_CHILD_COUNT && (
          <BorderedBlock pl="2%" pr="2%" pb="30px" mt={2}>
            <IconButton onClick={() => appendChild({})} showLeftIcon>
              <FormattedMessage id="submissionCreation.maintenanceObligations.addChild" />
            </IconButton>
          </BorderedBlock>
        )}
      </Stack>
    </Stack>
  );
};

export default MaintenanceObligations;
