import { ViewportBreakpoint } from '@constants';
import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import SubmissionFormFooter from './SubmissionFormFooter';

export const Form = styled('form')(() => ({
  width: '100%',
}));

export const BorderedBlock = styled(Stack)<StackProps>(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey['500']}`,
}));

export const FormInfoBlock = styled(Stack)<StackProps>(({ theme }) => ({
  padding: '34px 56px 40px 56px',
  marginRight: '2%',
  marginLeft: '2%',

  borderRadius: '8px',
  backgroundColor: theme.palette.grey['600'],

  [`@media (max-width: ${ViewportBreakpoint.Mobile}px)`]: {
    position: 'relative',
    margin: 0,
    padding: '32px 0',
    borderRadius: 0,

    '&:before': {
      content: '""',
      position: 'absolute',
      left: -22,
      top: 0,
      width: '100vw',
      height: '100%',
      backgroundColor: theme.palette.grey['600'],
    },
  },
}));

export const StyledSubmissionFormFooter = styled(SubmissionFormFooter)(() => ({
  marginTop: '96px',
}));
