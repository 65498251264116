import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Clipboard({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>clipboard</title>
      <defs>
        <path
          d="M12,0 C13.1045695,0 14,0.8954305 14,2 L15,2 C16.6568542,2 18,3.34314575 18,5 L18,19 C18,20.6568542 16.6568542,22 15,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,5 C0,3.34314575 1.34314575,2 3,2 L4,2 C4,0.8954305 4.8954305,0 6,0 L12,0 Z M4,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,19 C2,19.5522847 2.44771525,20 3,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 C14,5.1045695 13.1045695,6 12,6 L6,6 C4.8954305,6 4,5.1045695 4,4 Z M12,2 L6,2 L6,4 L12,4 L12,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1428, -194)">
          <g id="clipboard" transform="translate(1431, 195)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
