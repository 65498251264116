import { ActionType } from '@app-types/verificationCode';
import { Button } from '@components';
import { CheckmarkIcon, XCircleIcon } from '@icons';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useReSendVerificationCode from '../../../../shared/hooks/useReSendVerificationCode';
import useVerificationCodes from '../../../../shared/hooks/useVerificationCodes';
import CodeInputs from '../CodeInputs';
import {
  CodeInputsBox,
  CodeInputsWithCheckMarkWrapper,
  SendEmailText,
  StyledGrid,
  StyledSignUpAlert,
  Subtitle,
  Title,
} from './styles';

enum ErrorType {
  Invalid = 'signup.sendCode.invalid',
  TryAgain = 'signup.sendCode.tryAgain',
  IncorrectInputEnded = 'signup.sendCode.attempts.incorrectInput.ended',
  NewCodeRequest = 'signup.sendCode.newCode.request',
  LimitReachedPerHour = 'signup.sendCode.limit.reached.perHour',
}

type ISendCodeContent = {
  titleId: string;
  subtitleId?: string;
  email: string;
  actionType: ActionType;
  onSubmit: (id: string) => void;
};

const SendCodeContent: React.FC<ISendCodeContent> = ({ titleId, subtitleId, email, actionType, onSubmit }) => {
  const theme = useTheme();
  const [showNextStepButton, setShowNextStepButton] = useState(false);
  const [errorIds, setErrorIds] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [code, setCode] = useState('');

  const checkVerificationCode = useVerificationCodes({
    codeValue: parseFloat(code),
    params: { email },
  });

  const reSendVerificationCode = useReSendVerificationCode({
    onSuccess: (data: any) => {
      if (data.request.status === 429) {
        setShowError(true);
        setErrorIds([ErrorType.LimitReachedPerHour]);
        return;
      }
      setShowError(false);
    },
  });

  const onSendAgain = async () => {
    const res = await reSendVerificationCode.mutateAsync({ email, actionType });

    if (!res.data) {
      return;
    }
    setOpenAlert(true);
  };

  useEffect(() => {
    if (code.length !== 4 || reSendVerificationCode.isPending) {
      setShowNextStepButton(false);
      setShowError(false);

      return;
    }

    if (checkVerificationCode.data?.request.status === 404) {
      setShowNextStepButton(false);
      setShowError(true);
      setErrorIds([ErrorType.Invalid, ErrorType.TryAgain]);
      return;
    }

    if (checkVerificationCode.data?.request.status === 429) {
      setShowNextStepButton(false);
      setShowError(true);
      setErrorIds([ErrorType.IncorrectInputEnded, ErrorType.NewCodeRequest]);
      return;
    }

    if (checkVerificationCode.data?.request.status === 200) {
      setShowNextStepButton(true);
      setShowError(false);
    }
  }, [code, checkVerificationCode.data?.request.status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenAlert(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [openAlert]);

  return (
    <>
      {openAlert && <StyledSignUpAlert openAlert={openAlert} setOpenAlert={setOpenAlert} email={email} />}
      <Container
        maxWidth="lg"
        sx={{
          '&': {
            padding: actionType === ActionType.SignUp ? '0 24px' : 'none',
          },
        }}
      >
        <StyledGrid container>
          <Grid item xs={12} sm={8} md={6}>
            <Box textAlign="center">
              <Title>
                <FormattedMessage id={titleId} />
              </Title>
              {subtitleId && (
                <Subtitle>
                  <FormattedMessage id={subtitleId} />
                </Subtitle>
              )}
              <CodeInputsWithCheckMarkWrapper>
                <CodeInputsBox>
                  <CodeInputs onChange={setCode} value={code} length={4} />
                </CodeInputsBox>
                {showNextStepButton && (
                  <Box>
                    <CheckmarkIcon color={theme.palette.primary.main} />
                  </Box>
                )}
                {showError && (
                  <Box>
                    <XCircleIcon color={theme.palette.error.main} width="40px" height="40px" />
                  </Box>
                )}
              </CodeInputsWithCheckMarkWrapper>
              {showError && (
                <Box>
                  <Typography color={theme.palette.error.main}>
                    <FormattedMessage id={errorIds[0]} />
                  </Typography>
                  {errorIds[1] && (
                    <Typography>
                      <FormattedMessage id={errorIds[1]} />
                    </Typography>
                  )}
                </Box>
              )}
              {showNextStepButton ? (
                <Button
                  sx={{ mt: '24px' }}
                  color="primary"
                  onClick={() => onSubmit(checkVerificationCode.data?.data.customer.id)}
                >
                  <FormattedMessage id="signup.sendCode.proceedNextStep.button.title" />
                </Button>
              ) : (
                <Button sx={{ mt: '24px' }} color="primary" variant="outlined">
                  <FormattedMessage id="signup.sendCode.button.title" />
                </Button>
              )}
              <Box sx={{ mt: '40px' }}>
                <Typography sx={{ fontWeight: 500 }}>
                  <FormattedMessage id="signup.sendCode.didNotRecieveEmail" />
                </Typography>
                <Typography>
                  <FormattedMessage id="signup.sendCode.checkSpamFolder" />
                </Typography>
                <Typography>
                  <FormattedMessage id="signup.sendCode.email" />
                  <SendEmailText onClick={onSendAgain}>
                    <FormattedMessage id="signup.sendCode.sendAgain" />
                  </SendEmailText>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </StyledGrid>
      </Container>
    </>
  );
};

export default SendCodeContent;
