import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function CameraOff({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>camera-off</title>
      <defs>
        <path
          d="M0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L23.7071068,22.2928932 C24.0976311,22.6834175 24.0976311,23.3165825 23.7071068,23.7071068 C23.3165825,24.0976311 22.6834175,24.0976311 22.2928932,23.7071068 L20.585,22 L3,22 C1.40231912,22 0.0963391206,20.75108 0.00509269341,19.1762728 L0,19 L0,8 C0,6.34314575 1.34314575,5 3,5 L3.585,5 L0.292893219,1.70710678 C-0.0675907428,1.34662282 -0.0953202783,0.779391764 0.209704612,0.387100557 Z M5.585,7 L3,7 C2.44771525,7 2,7.44771525 2,8 L2,19 C2,19.5522847 2.44771525,20 3,20 L18.585,20 L15.3230235,16.7385182 C14.2189859,17.7317822 12.7123843,18.1950192 11.2126999,17.9621354 L10.9853635,17.9214576 C9.0164907,17.5222899 7.47771009,15.9835093 7.07854238,14.0146365 C6.75949891,12.4409722 7.21800495,10.8368269 8.26148178,9.67697647 L5.585,7 Z M15,2 C15.3343532,2 15.6465845,2.16710114 15.8320503,2.4452998 L17.5351838,5 L21,5 C22.6568542,5 24,6.34314575 24,8 L24,17.34 C24,17.8922847 23.5522847,18.34 23,18.34 C22.4477153,18.34 22,17.8922847 22,17.34 L22,8 C22,7.44771525 21.5522847,7 21,7 L17,7 C16.6656468,7 16.3534155,6.83289886 16.1679497,6.5547002 L14.4648162,4 L9,4 C8.44771525,4 8,3.55228475 8,3 C8,2.44771525 8.44771525,2 9,2 Z M9.67964288,11.0938523 C9.10241184,11.782544 8.85439948,12.7083664 9.0386644,13.617243 C9.27816503,14.7985666 10.2014334,15.721835 11.382757,15.9613356 C12.2916336,16.1456005 13.217456,15.8975882 13.9061477,15.3203571 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1288, -458)">
          <g id="camera-off" transform="translate(1288, 458)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <rect id="Rectangle" x={0} y={0} width={24} height={24} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
