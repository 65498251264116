import { eligibilityApi } from '@api';
import { Button, Input } from '@components';
import { StepperContext } from '@components/Stepper';
import { LocalStorageKey } from '@constants';
import { useIsMobile } from '@hooks';
import { Stack, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NotIncludedInResponsibleForZIPsStep from './NotIncludedInResponsibleForZIPsStep';
import WrongRegionStep from './WrongRegionStep';

interface IZipCheckStepProps {
  onSubmit: () => void;
}

const ZipCheckStep: React.FC<IZipCheckStepProps> = ({ onSubmit }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const zipEligibilityCheckMutation = useMutation({
    mutationKey: ['check-zip-eligibility'],
    mutationFn: eligibilityApi.checkZipCodeEligibility,
  });
  const { formatMessage } = useIntl();
  const { goToNextStep } = useContext(StepperContext);

  const [zip, setZip] = useState('');
  const [isWrongRegion, setIsWrongRegion] = useState(false);
  const [isNotIncludedInResponsibleForZIPs, setNotIncludedInResponsibleForZIPs] = useState(false);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== undefined || evt.target.value !== null) {
      setZip(evt.target.value);
    }
  };

  const handleCheck = async () => {
    const { data } = await zipEligibilityCheckMutation.mutateAsync(zip);

    if (data?.isEligibleByLocalities && data?.isEligibleByResponsibleForZIPs) {
      localStorage.setItem(LocalStorageKey.ZipCode, zip);
      goToNextStep();
      onSubmit();
    } else if (!data?.isEligibleByLocalities) {
      setIsWrongRegion(true);
    } else {
      setNotIncludedInResponsibleForZIPs(true);
    }
  };

  if (isWrongRegion) {
    return <WrongRegionStep onBack={() => setIsWrongRegion(false)} />;
  }

  if (isNotIncludedInResponsibleForZIPs) {
    return <NotIncludedInResponsibleForZIPsStep onBack={() => setNotIncludedInResponsibleForZIPs(false)} />;
  }

  return (
    <Stack alignItems="center" gap={4}>
      <Typography variant="h2" textAlign="center" sx={{ marginTop: theme.spacing(isMobile ? 19 : 37) }}>
        <FormattedMessage id="eligibilityCheck.step.4.title" />
      </Typography>
      <Stack direction="row" gap={isMobile ? 2 : 3}>
        <Input
          placeholder={formatMessage({ id: 'eligibilityCheck.step.4.input.placeholder' })}
          onChange={handleChange}
          value={zip}
          number
        />
        <Button variant="contained" onClick={handleCheck}>
          <FormattedMessage id="common.check" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default ZipCheckStep;
