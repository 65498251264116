import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function PlusSquare({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>plus-square</title>
      <defs>
        <path
          d="M17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.6568542 18.6568542,20 17,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,3 C0,1.34314575 1.34314575,0 3,0 L17,0 Z M17,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,17 C2,17.5522847 2.44771525,18 3,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,3 C18,2.44771525 17.5522847,2 17,2 Z M10,5 C10.5522847,5 11,5.44771525 11,6 L11,9 L14,9 C14.5128358,9 14.9355072,9.38604019 14.9932723,9.88337887 L15,10 C15,10.5522847 14.5522847,11 14,11 L11,11 L11,14 C11,14.5128358 10.6139598,14.9355072 10.1166211,14.9932723 L10,15 C9.44771525,15 9,14.5522847 9,14 L9,11 L6,11 C5.48716416,11 5.06449284,10.6139598 5.00672773,10.1166211 L5,10 C5,9.44771525 5.44771525,9 6,9 L9,9 L9,6 C9,5.48716416 9.38604019,5.06449284 9.88337887,5.00672773 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1427, -599)">
          <g id="plus-square" transform="translate(1429, 601)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -2)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
