import { CreateSubmissionDto, UpdateSubmissionDto } from '@app-types';
import axios from './axios';

const createSubmissionInFillingProcess = async (data: CreateSubmissionDto) => {
  const response = await axios.post('/submissions-in-filling-process', data);

  return response.data;
};

const updateSubmissionInFillingProcess = async (id: string, data: UpdateSubmissionDto) => {
  const response = await axios.patch(`/submissions-in-filling-process/${id}`, data);

  return response.data;
};

const getCurrentSubmissionInFillingProcess = () => axios.get('/submissions-in-filling-process/current/my');

export default {
  createSubmissionInFillingProcess,
  updateSubmissionInFillingProcess,
  getCurrentSubmissionInFillingProcess,
};
