import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Droplet({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>droplet</title>
      <defs>
        <path
          d="M4.05216977,7.36733849 C2.04923244,9.36896339 1.44950109,12.3801481 2.53269917,14.9964321 C3.61589725,17.6127162 6.16864151,19.3187079 9.00029477,19.3187079 C11.831948,19.3187079 14.3846923,17.6127162 15.4678904,14.9964321 C16.5510885,12.3801481 15.9513571,9.36896339 13.948188,7.36710678 L8.99592019,2.41483898 L4.05216977,7.36733849 Z M9.70240155,0.292893219 L15.3621698,5.95266151 C17.9373749,8.52617924 18.7084581,12.3977024 17.3157748,15.7614962 C15.9230916,19.1252899 12.6409918,21.3187079 9.00029477,21.3187079 C5.35959773,21.3187079 2.07749796,19.1252899 0.684814709,15.7614962 C-0.707868537,12.3977024 0.0632146338,8.52617924 2.63756306,5.9535187 L8.28756306,0.293518701 C8.67798588,-0.0975951254 9.31163308,-0.0978752523 9.70240155,0.292893219 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-167, -319)">
          <g id="droplet" transform="translate(170.0047, 320.69)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3.0047, -1.69)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
