import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Anchor({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>anchor</title>
      <defs>
        <path
          d="M11,0 C13.209139,0 15,1.790861 15,4 C15,5.86356525 13.7256022,7.42947895 12.0007613,7.87378648 L12.0005275,19.9450129 C16.1718958,19.4836432 19.4839418,16.1714408 19.9450712,12 L18,12 C17.4477153,12 17,11.5522847 17,11 C17,10.4477153 17.4477153,10 18,10 L21,10 C21.5522847,10 22,10.4477153 22,11 C22,17.0751322 17.0751322,22 11,22 L10.7115925,21.9962926 C4.76976358,21.8433652 0,16.9787016 0,11 C0,10.4477153 0.44771525,10 1,10 L4,10 C4.55228475,10 5,10.4477153 5,11 C5,11.5522847 4.55228475,12 4,12 L2.05492878,12 C2.51609495,16.1717736 5.8286327,19.4841717 10.000471,19.9451233 L10.0002435,7.87404517 C8.27489272,7.43008706 7,5.86392711 7,4 C7,1.790861 8.790861,0 11,0 Z M11,2 C9.8954305,2 9,2.8954305 9,4 C9,5.1045695 9.8954305,6 11,6 C12.1045695,6 13,5.1045695 13,4 C13,2.8954305 12.1045695,2 11,2 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-387, -526)">
          <g id="anchor" transform="translate(388, 527)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
