import { Button } from '@components';
import { ChevronDownIcon, ChevronUpIcon, UserIcon } from '@icons';
import { ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { selectCustomer } from '@store/customer/selectors';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StyledMenu } from './styles';

interface MenuButtonProps {
  menuOptions: {
    label: string;
    icon?: JSX.Element;
    onClick: () => void;
  }[];
}

const MenuButton: FC<MenuButtonProps> = ({ menuOptions }) => {
  const account = useSelector(selectCustomer);
  const theme = useTheme();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        ref={buttonRef}
        startIcon={<UserIcon style={{ width: '20px', height: '20px' }} color={theme.palette.grey['200']} />}
        endIcon={
          open ? (
            <ChevronUpIcon color={theme.palette.grey['200']} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
          ) : (
            <ChevronDownIcon
              color={theme.palette.grey['200']}
              style={{ width: '16px', height: '16px', marginLeft: '10px' }}
            />
          )
        }
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          borderRadius: open ? '4px 4px 0 0' : '4px',
          backgroundColor: 'white',
        }}
      >
        {account?.firstName || ''} {account?.lastName || ''}
      </Button>
      <StyledMenu
        id="basic-menu"
        width={buttonRef?.current?.offsetWidth || 200}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.label} onClick={option.onClick}>
            <ListItemText>{option.label}</ListItemText>
            {!!option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default MenuButton;
