import { Button } from '@components';
import { XIcon } from '@icons';
import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalContent, StyledModal } from './styles';

interface IConfirmModalProps {
  open: boolean;
  titleId: string;
  handleClose: () => void;
  updateClick?: () => void;
}

const ConfirmModal: FC<IConfirmModalProps> = ({ open, titleId, handleClose, updateClick }) => {
  return (
    <div>
      <StyledModal
        aria-labelledby="submission-modal-title"
        aria-describedby="submission-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalContent sx={{ maxWidth: 860, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              padding: '24px 20px 0 24px',
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: '0 24px 24px 24px', textAlign: 'center' }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              <FormattedMessage id={titleId} />
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', mt: 3 }}>
              <Button variant="contained" onClick={updateClick}>
                <FormattedMessage id="submissionCreation.confirmModal.yes.button" />
              </Button>
              <Button onClick={handleClose} size="large" type="button" variant="outlined" disableElevation>
                <FormattedMessage id="submissionCreation.confirmModal.no.button" />
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </StyledModal>
    </div>
  );
};

export default ConfirmModal;
