import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function AlignCenter({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>align-center</title>
      <defs>
        <path
          d="M16,4 L4,4 C3.44771525,4 3,4.44771525 3,5 C3,5.55228475 3.44771525,6 4,6 L16,6 C16.5522847,6 17,5.55228475 17,5 C17,4.44771525 16.5522847,4 16,4 Z M19,0 L1,0 C0.44771525,0 0,0.44771525 0,1 C0,1.55228475 0.44771525,2 1,2 L19,2 C19.5522847,2 20,1.55228475 20,1 C20,0.44771525 19.5522847,0 19,0 Z M19,8 L1,8 C0.44771525,8 0,8.44771525 0,9 C0,9.55228475 0.44771525,10 1,10 L19,10 C19.5522847,10 20,9.55228475 20,9 C20,8.44771525 19.5522847,8 19,8 Z M16,12 L4,12 C3.44771525,12 3,12.4477153 3,13 C3,13.5522847 3.44771525,14 4,14 L16,14 C16.5522847,14 17,13.5522847 17,13 C17,12.4477153 16.5522847,12 16,12 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-166, -529)">
          <g id="align-center" transform="translate(168, 534)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="🎨Color" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -5)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
