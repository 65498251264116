import { Button, FileInputController, InputController, UploadedFile } from '@components';
import { useIsMobile } from '@hooks';
import { FileIcon } from '@icons';
import { Box, Typography, useTheme } from '@mui/material';
import { selectCustomer } from '@store/customer/selectors';
import { convertFileListToArray } from '@utils';
import { FC, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFiles from '../UploadFiles/UploadFiles';
import { SubmissionFormContext } from './DocumentsRequested';

interface IMissingDocumentProps {
  title: string;
  description?: string | undefined;
  index: number;
  sectionName: string;
}

const MissingDocument: FC<IMissingDocumentProps> = ({ title, description, index, sectionName }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();
  const { control, setValue } = useFormContext();
  const [isOpenedUploadFilesModal, setIsOpenedUploadFilesModal] = useState(false);
  const customer = useSelector(selectCustomer);

  const { getUploadedInfoValue } = useContext(SubmissionFormContext);

  const getAdditionalDocuments = (fieldIndex: number) => {
    const supplementDocuments = getUploadedInfoValue(fieldIndex, 'customerFiles') as FileList;

    return convertFileListToArray(supplementDocuments);
  };

  const handleAdditionalDocumentsFileDelete = (childIndex: number, fileIndex: number) => {
    return () => {
      const fileList = getAdditionalDocuments(childIndex);

      if (!fileList) {
        return;
      }

      fileList.splice(fileIndex, 1);
      setValue(`uploadedInfo[${childIndex}].customerFiles`, fileList);
    };
  };

  let childSection;
  let childNumber;
  const isChild = sectionName.includes('submissionDetails.children.requestedDocuments.title');

  if (isChild) {
    const endIndex = sectionName.indexOf('title') + 'title'.length;

    childSection = sectionName.substring(0, endIndex).trim();
    childNumber = sectionName.substring(endIndex).trim();
  }

  const handleClose = () => {
    setIsOpenedUploadFilesModal(false);
  };

  return (
    <Box
      sx={{
        background: theme.palette.grey[600],
        padding: '32px 56px',
        borderRadius: '8px',
      }}
    >
      <Typography variant="h4" sx={{ fontSize: '20px' }}>
        {`${title} (${formatMessage({ id: isChild ? childSection : sectionName })}${isChild ? `${childNumber}` : ''})`}
      </Typography>
      <Typography sx={{ mt: '8px' }}>{description}</Typography>
      <Box sx={{ mt: '24px' }}>
        <InputController
          fullWidth
          name={`uploadedInfo[${index}].comment`}
          control={control}
          placeholder={formatMessage({
            id: 'submissionCreation.applicationReview.documentsRequested.comment.placeholder',
          })}
          label={formatMessage({ id: 'submissionCreation.applicationReview.documentsRequested.comment.label' })}
          multiline
          rows={2}
        />
      </Box>
      <Box sx={{ mt: '32px' }}>
        {getAdditionalDocuments(index).length > 0 ? (
          getAdditionalDocuments(index)?.map((file, fileIndex) => (
            <UploadedFile
              key={file.name}
              onDeleteFile={handleAdditionalDocumentsFileDelete(index, fileIndex)}
              fileName={file.name}
            />
          ))
        ) : (
          <Box>
            {isMobile ? (
              <FileInputController name={`uploadedInfo[${index}].customerFiles`} control={control} />
            ) : (
              <Button
                startIcon={<FileIcon color={theme.palette.secondary.main} />}
                component="label"
                variant="contained"
                onClick={() => setIsOpenedUploadFilesModal(true)}
              >
                <FormattedMessage id="submissionCreation.applicationReview.documentsRequested.addDocuments" />
              </Button>
            )}
          </Box>
        )}
      </Box>
      {!isMobile && (
        <UploadFiles
          open={isOpenedUploadFilesModal}
          handleClose={handleClose}
          formFieldName={`uploadedInfo[${index}].customerFiles`}
          customerId={customer?.id || ''}
        />
      )}
    </Box>
  );
};

export default MissingDocument;
