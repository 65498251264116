import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Volume1({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>volume-1</title>
      <defs>
        <path
          d="M11,1.0018773 L11,15.0018773 C11,15.8403835 10.0300683,16.3065568 9.37530495,15.7827461 L4.64921894,12.0018773 L1,12.0018773 C0.44771525,12.0018773 0,11.5541621 0,11.0018773 L0,5.0018773 C0,4.44959255 0.44771525,4.0018773 1,4.0018773 L4.64921894,4.0018773 L9.37530495,0.221008492 C10.0300683,-0.302802184 11,0.163371093 11,1.0018773 Z M9,3.08250215 L5.62469505,5.78274611 C5.447382,5.92459655 5.2270715,6.0018773 5,6.0018773 L2,6.0018773 L2,10.0018773 L5,10.0018773 C5.2270715,10.0018773 5.447382,10.0791581 5.62469505,10.2210085 L9,12.9212525 L9,3.08250215 Z M13.833,3.75466375 C14.2235833,3.36419844 14.8567482,3.36429404 15.2472135,3.7548773 C17.5895052,6.09787641 17.5895052,9.89587819 15.2472135,12.2388773 C14.8567482,12.6294606 14.2235833,12.6295562 13.833,12.2390908 C13.4424167,11.8486255 13.4423211,11.2154606 13.8327865,10.8248773 C15.3943142,9.2628779 15.3943142,6.73087671 13.8327865,5.1688773 C13.4423211,4.77829404 13.4424167,4.14512907 13.833,3.75466375 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-309, -143)">
          <g id="volume-1" transform="translate(312, 146.9981)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-3, -3.9981)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
