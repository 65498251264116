import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Checkmark({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M7.845 20.488a1.667 1.667 0 1 0-2.357 2.357l8.333 8.334c.651.65 1.707.65 2.358 0l18.333-18.334a1.667 1.667 0 1 0-2.357-2.357L15 27.643l-7.155-7.155z"
          id={id}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`mask-${id}`} fill="#fff">
          <use xlinkHref={`#${id}`} />
        </mask>
        <use fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
        <g mask={`url(#mask-${id})`} fill={color}>
          <path d="M0 0h40v40H0z" />
        </g>
      </g>
    </svg>
  );
}
