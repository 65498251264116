import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Bell({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>bell</title>
      <defs>
        <path
          d="M17,13 L17,8 C17,4.68629145 14.3137085,2 11,2 C7.68629154,2 5.00000005,4.68629145 5,8 L5,13 C5,13.7285742 4.80521143,14.4116532 4.46487122,15 L17.5351288,15 C17.1947886,14.4116532 17,13.7285742 17,13 Z M21,17 L1,17 C-0.333333333,17 -0.333333333,15 1,15 C2.1045695,15 3,14.1045694 3,13 L3,8 C3,3.58172193 6.58172205,0 11,0 C15.418278,0 19,3.58172193 19,8 L19,13 C19,14.1045694 19.8954305,15 21,15 C22.3333333,15 22.3333333,17 21,17 Z M13.595,20.5017718 C13.0583034,21.4269783 12.0696029,21.9964562 11,21.9964562 C9.93039709,21.9964562 8.94169664,21.4269783 8.405,20.5017718 C8.01827917,19.8351072 8.49928951,18.9999999 9.27,18.9999999 L12.73,18.9999999 C13.5007105,18.9999999 13.9817208,19.8351072 13.595,20.5017718 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1721, -459)">
          <g id="bell" transform="translate(1722, 460)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
