const deleteFalsyValuesExceptFalse = (object: any) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] || object[key] === false) {
      // @ts-ignore
      newObject[key] = object[key];
    }
  });
  return newObject;
};

export default deleteFalsyValuesExceptFalse;
