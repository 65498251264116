import { useConnectSocket, useIsMobile } from '@hooks';
import { CheckCircleIcon, FileIcon, XCircleIcon } from '@icons';
import { Stack, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SocketApi from '../../shared/api/socket';
import { StyledButton, StyledInput, StyledPageLayout } from './styles';

const MobileUpload: React.FC = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  useConnectSocket();

  const [isFilesLoadedSuccessfully, setIsFilesLoadedSuccessfully] = useState(false);
  const [isFilesUploadFailed, setIsFilesUploadFailed] = useState(false);

  const { userAgent } = navigator;
  const isAndroid = /android/i.test(userAgent);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    const timestampFromUrl = parseInt(urlParams.get('timestamp') || '0', 10);
    const currentTime = new Date().getTime();

    const remainingTime = 30 * 60 * 1000 - (currentTime - timestampFromUrl);

    if (tokenFromUrl) {
      localStorage.setItem('accessToken', tokenFromUrl);
    }

    if (remainingTime <= 0) {
      setIsFilesUploadFailed(true);
      setIsFilesLoadedSuccessfully(false);
    } else {
      const timeoutId = setTimeout(() => {
        setIsFilesUploadFailed(true);
        setIsFilesLoadedSuccessfully(false);
      }, remainingTime);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const androidFileInputRef = useRef<HTMLInputElement>(null);
  const androidMZFileInputRef = useRef<HTMLInputElement>(null);
  const androidChromeFileInputRef = useRef<HTMLInputElement>(null);
  const androidEdgeFileInputRef = useRef<HTMLInputElement>(null);

  const handleCaptureMultiple = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files) return;

    const urlParams = new URLSearchParams(window.location.search);
    const formFieldName = urlParams.get('formFieldName');
    const decodedFieldName = decodeURIComponent(formFieldName || '');

    const filePromises = Array.from(files).map(
      (file) =>
        new Promise<{ name: string; data: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({ name: file.name, data: reader.result as string });
            } else {
              reject(new Error('Failed to read file as base64'));
            }
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        }),
    );

    Promise.all(filePromises)
      .then((fileDataArray) => {
        SocketApi.socket?.emit('server-path', { formFieldName: decodedFieldName, files: fileDataArray });
        setIsFilesLoadedSuccessfully(true);
        setIsFilesUploadFailed(false);
      })
      .catch((error) => {
        setIsFilesLoadedSuccessfully(false);
        setIsFilesUploadFailed(true);
        console.error('Error reading files:', error);
      });
  };

  const handleOpenGallery = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      const event = new MouseEvent('click');
      ref.current.dispatchEvent(event);
    }
  };

  const getAndroidButton = () => {
    const browser = userAgent.toLowerCase();

    if (browser.includes('edg')) {
      return (
        <>
          <StyledInput ref={androidEdgeFileInputRef} type="file" multiple onChange={handleCaptureMultiple} />
          <StyledButton type="button" onClick={() => handleOpenGallery(androidEdgeFileInputRef)}>
            <FileIcon color={theme.palette.primary.main} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              <FormattedMessage id="mobileUpload.selectLoading.files" />
            </Typography>
          </StyledButton>
        </>
      );
    }

    if (browser.includes('chrome')) {
      return (
        <>
          <StyledInput ref={androidChromeFileInputRef} type="file" accept="images/*" onChange={handleCaptureMultiple} />
          <StyledButton type="button" onClick={() => handleOpenGallery(androidChromeFileInputRef)}>
            <FileIcon color={theme.palette.primary.main} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              <FormattedMessage id="mobileUpload.selectLoading.files" />
            </Typography>
          </StyledButton>
        </>
      );
    }

    if (browser.includes('firefox')) {
      return (
        <>
          <StyledInput ref={androidMZFileInputRef} type="file" accept="image/*" onChange={handleCaptureMultiple} />
          <StyledButton type="button" onClick={() => handleOpenGallery(androidMZFileInputRef)}>
            <FileIcon color={theme.palette.primary.main} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              <FormattedMessage id="mobileUpload.selectLoading.files" />
            </Typography>
          </StyledButton>
        </>
      );
    }

    return (
      <>
        <StyledInput ref={androidFileInputRef} type="file" onChange={handleCaptureMultiple} />
        <StyledButton type="button" onClick={() => handleOpenGallery(androidFileInputRef)}>
          <FileIcon width="32px" height="32px" color={theme.palette.primary.main} />
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
            <FormattedMessage id="mobileUpload.selectLoading.files" />
          </Typography>
        </StyledButton>
      </>
    );
  };

  return (
    <StyledPageLayout disableLogout isFullScreen bodyHeight={`calc(100% - ${isMobile ? '74px' : '88px'})`}>
      {!isFilesLoadedSuccessfully && !isFilesUploadFailed && (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Typography variant="h2" sx={{ fontSize: '32px' }}>
            <FormattedMessage id="mobileUpload.selectLoading.title" />
          </Typography>
          <Stack mt="32px">
            {isAndroid ? (
              getAndroidButton()
            ) : (
              <>
                <StyledInput
                  ref={fileInputRef}
                  type="file"
                  accept=".pdf, .png, .jpg, .jpeg, application/pdf"
                  multiple
                  onChange={handleCaptureMultiple}
                />
                <StyledButton type="button" onClick={() => handleOpenGallery(fileInputRef)}>
                  <FileIcon width="32px" height="32px" color={theme.palette.primary.main} />
                  <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                    <FormattedMessage id="mobileUpload.selectLoading.files" />
                  </Typography>
                </StyledButton>
              </>
            )}
          </Stack>
        </Stack>
      )}
      {isFilesLoadedSuccessfully && (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CheckCircleIcon width="56px" height="56px" color={theme.palette.primary.main} />
          <Typography mt="24px" variant="h2" sx={{ fontSize: '32px', textAlign: 'center' }}>
            <FormattedMessage id="mobileUpload.success.title" />
          </Typography>
        </Stack>
      )}

      {isFilesUploadFailed && (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <XCircleIcon width="56px" height="56px" color={theme.palette.error.main} />
          <Typography mt="24px" variant="h2" sx={{ fontSize: '32px', textAlign: 'center' }}>
            <FormattedMessage id="mobileUpload.failed.title" />
          </Typography>
          <Typography mt="16px" sx={{ fontSize: '16px', textAlign: 'center' }}>
            <FormattedMessage id="mobileUpload.failed.description" />
          </Typography>
        </Stack>
      )}
    </StyledPageLayout>
  );
};

export default MobileUpload;
