import { FormControlLabel, FormControlLabelProps, FormLabel, FormLabelProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface IStyledRadioFormControlLabelProps extends FormControlLabelProps {
  error?: boolean;
}

export const StyledTypography = styled(Typography)<FormLabelProps>(() => ({
  fontSize: '20px',
  fontWeight: 500,
}));

export const StyledFormLabel = styled(FormLabel)<FormLabelProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 12,
  width: '100%',
}));

export const StyledRadioFormControlLabel = styled(FormControlLabel)<IStyledRadioFormControlLabelProps>(() => ({
  marginLeft: '-7px',
  marginRight: '31px',

  '.MuiFormControlLabel-label': {
    fontSize: '16px',
  },
}));
