import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function CloudOff({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="25px"
      height="24px"
      viewBox="0 0 25 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cloud-off</title>
      <defs>
        <path
          d="M9.90775973,3.00387855 C9.35761705,2.95528357 8.87224427,3.36186832 8.82364929,3.912011 C8.7750543,4.46215369 9.18163905,4.94752647 9.73178174,4.99612145 C12.6945831,5.25783042 15.1684835,7.36327753 15.9005317,10.2461214 C16.0131297,10.6895381 16.4122813,11 16.8697707,11 L18.1297707,11 C19.4733481,10.998408 20.7270318,11.6702806 21.4690552,12.7889494 C22.2110787,13.9076182 22.3424903,15.3239044 21.8189562,16.5599993 C21.6035647,17.0685511 21.8412182,17.6554231 22.34977,17.8708146 C22.8583219,18.086206 23.4451939,17.8485525 23.6605853,17.3400007 C24.4458865,15.4858583 24.2487691,13.361429 23.1357339,11.6834259 C22.0226987,10.0054227 20.1421732,8.99761371 18.1285853,9.0000007 L17.6122492,9.00000041 C16.4550531,5.68443895 13.4537495,3.31710149 9.90775973,3.00387855 Z M0.422663951,0.292893219 C0.813188243,-0.0976310729 1.44635322,-0.0976310729 1.83687751,0.292893219 L23.8368775,22.2928932 C24.2274018,22.6834175 24.2274018,23.3165825 23.8368775,23.7071068 C23.4463532,24.0976311 22.8131882,24.0976311 22.422664,23.7071068 L19.5435011,20.8296346 C19.081732,20.9420663 18.6073054,20.9994387 18.1297707,21 L9.14588249,20.9998702 C5.01497008,21.0664351 1.36928221,18.3119524 0.304762191,14.3200023 C-0.646187123,10.7539424 0.680861452,7.01864694 3.55226512,4.83707802 L0.422663951,1.70710678 C0.0321396597,1.31658249 0.0321396597,0.683417511 0.422663951,0.292893219 Z M4.98305321,6.26859311 C2.59822782,7.9381666 1.47373224,10.9415526 2.23723207,13.804677 C3.06519209,16.909527 5.90072709,19.0519025 9.12977073,19 L17.715,19 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-766, -251)">
          <g id="cloud-off" transform="translate(766, 251)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <rect id="Rectangle" x={0} y={0} width={24} height={24} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
