import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { submissionsInFillingProcessApi } from '@api';
import { CreateSubmissionDto } from '@app-types';

const useCreateSubmissionInFillingProcess = (options?: UseMutationOptions<unknown, unknown, CreateSubmissionDto>) => {
  return useMutation({
    ...options,
    mutationFn: submissionsInFillingProcessApi.createSubmissionInFillingProcess,
  });
};

export default useCreateSubmissionInFillingProcess;
