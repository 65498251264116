import { authApi } from '@api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useForgotPassword = (options?: UseMutationOptions<unknown, unknown, { email: string }>) => {
  return useMutation({
    ...options,
    mutationFn: authApi.forgotPassword,
  });
};

export default useForgotPassword;
