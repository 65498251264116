import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Edit3({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>edit-3</title>
      <defs>
        <path
          d="M12,2.41421356 L2,12.4142136 L2,15 L4.58578644,15 L14.5857864,5 L12,2.41421356 Z M12.7071068,0.292893219 L16.7071068,4.29289322 C17.0976311,4.68341751 17.0976311,5.31658249 16.7071068,5.70710678 L5.70710678,16.7071068 C5.5195704,16.8946432 5.26521649,17 5,17 L1,17 C0.44771525,17 0,16.5522847 0,16 L0,12 C0,11.7347835 0.10535684,11.4804296 0.292893219,11.2928932 L11.2928932,0.292893219 C11.6834175,-0.0976310729 12.3165825,-0.0976310729 12.7071068,0.292893219 Z M1,22 L19,22 C19.5522847,22 20,21.5522847 20,21 C20,20.4477153 19.5522847,20 19,20 L1,20 C0.44771525,20 0,20.4477153 0,21 C0,21.5522847 0.44771525,22 1,22 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-388, -391)">
          <g id="edit-3" transform="translate(390, 392)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-2, -1)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
