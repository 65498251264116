import { Button, InputController } from '@components';
import { SessionStorageKey } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLogin } from '@hooks';
import { Box, Typography, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Resolver, ResolverOptions, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface ILoginFormValues {
  email: string;
  password: string;
}

const Form: React.FC = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(formatMessage({ id: 'signup.email.valid.title' }))
      .required(formatMessage({ id: 'signup.email.required.title' })),
    password: yup.string().required(formatMessage({ id: 'signup.password.required.title' })),
  });

  const { control, handleSubmit } = useForm<ILoginFormValues>({
    resolver: yupResolver(schema) as unknown as Resolver<ILoginFormValues, ResolverOptions<ILoginFormValues>>,
  });

  const { mutateAsync: login } = useLogin();

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    sessionStorage.setItem(SessionStorageKey.Email, e.target.value);
  };

  const handleFormSubmit = async (data: ILoginFormValues) => {
    setErrorMessage(null);

    const res = await login(data);

    if (res instanceof AxiosError) {
      if (res.response?.status === 401 || res.response?.status === 403) {
        return setErrorMessage(formatMessage({ id: `login.serverError[${res.response.data.message}]` }));
      }
      return setErrorMessage(formatMessage({ id: 'login.box.form.error.common' }));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h3" sx={{ marginBottom: theme.spacing(4) }}>
        <FormattedMessage id="login.box.title" />
      </Typography>
      <Box sx={{ marginBottom: theme.spacing(3) }}>
        <InputController
          fullWidth
          name="email"
          control={control}
          placeholder={formatMessage({ id: 'login.box.form.email.placeholder' })}
          label={formatMessage({ id: 'login.box.form.email.label' })}
          onBlur={handleEmailBlur}
        />
      </Box>
      <Box sx={{ marginBottom: theme.spacing(4) }}>
        <InputController
          fullWidth
          name="password"
          password
          control={control}
          placeholder={formatMessage({ id: 'login.box.form.password.placeholder' })}
          label={formatMessage({ id: 'login.box.form.password.label' })}
        />
      </Box>
      <Button variant="contained" type="submit" fullWidth sx={{ marginBottom: theme.spacing(2) }}>
        <FormattedMessage id="login.box.form.submit" />
      </Button>
      {!!errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Typography variant="body2" color="grey.200" sx={{ '& a': { color: theme.palette.grey['200'] } }}>
        {/* dangerouslySetInnerHTML is used here in order to highlight with bold font weight some parts of the text due to the design */}
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'login.box.form.forgotPassword' }) }} />
      </Typography>
    </form>
  );
};

export default Form;
