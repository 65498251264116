import { Alert, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSignUpAlertCollapse = styled(Collapse)(() => ({
  display: 'block',
  position: 'fixed',
  top: '120px',
  width: '100%',
  left: 0,
}));

export const StyledSignUpAlert = styled(Alert)(({ theme }) => ({
  background: theme.palette.background.default,
  color: theme.palette.secondary.main,
  borderRadius: '4px',
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
  padding: '20px 16px 20px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '428px',

  '.MuiAlert-action': {
    padding: 0,
    margin: 0,
    width: '24px',
    height: '24px',
  },

  '.MuiAlert-icon': {
    display: 'none',
  },

  '.MuiAlert-message': {
    padding: 0,
    fontSize: '16px',
    lineHeight: 1.5,
  },

  '.MuiButtonBase-root': {
    padding: 0,
  },
}));
