import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function Volume2({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>volume-2</title>
      <defs>
        <path
          d="M17.363,0.292786454 C17.7535833,-0.0976788643 18.3867482,-0.0975832564 18.7772135,0.293 C23.0714149,4.58849837 23.0714149,11.5515016 18.7772135,15.847 C18.3867482,16.2375833 17.7535833,16.2376789 17.363,15.8472135 C16.9724167,15.4567482 16.9723211,14.8235833 17.3627865,14.433 C20.8762239,10.9185013 20.8762239,5.22149866 17.3627865,1.707 C16.9723211,1.31641674 16.9724167,0.683251772 17.363,0.292786454 Z M11,1.07 L11,15.07 C11,15.9085062 10.0300683,16.3746795 9.37530495,15.8508688 L4.64921894,12.07 L1,12.07 C0.44771525,12.07 0,11.6222847 0,11.07 L0,5.07 C0,4.51771525 0.44771525,4.07 1,4.07 L4.64921894,4.07 L9.37530495,0.289131191 C10.0300683,-0.234679485 11,0.231493792 11,1.07 Z M9,3.15062485 L5.62469505,5.85086881 C5.447382,5.99271925 5.2270715,6.07 5,6.07 L2,6.07 L2,10.07 L5,10.07 C5.2270715,10.07 5.447382,10.1472808 5.62469505,10.2891312 L9,12.9893752 L9,3.15062485 Z M13.833,3.82278645 C14.2235833,3.43232114 14.8567482,3.43241674 15.2472135,3.823 C17.5895052,6.16599911 17.5895052,9.96400089 15.2472135,12.307 C14.8567482,12.6975833 14.2235833,12.6976789 13.833,12.3072135 C13.4424167,11.9167482 13.4423211,11.2835833 13.8327865,10.893 C15.3943142,9.33100059 15.3943142,6.79899941 13.8327865,5.237 C13.4423211,4.84641674 13.4424167,4.21325177 13.833,3.82278645 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1572, -142)">
          <g id="volume-2" transform="translate(1573, 146)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-1, -4)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
