import { Checkbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '&.MuiCheckbox-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const DefaultIcon = styled('div')<{ isHovering?: boolean; backgroundMode?: 'white' | 'gray' }>(
  ({ theme, isHovering, backgroundMode }) => ({
    width: '16px',
    height: '16px',

    border: `1px solid ${isHovering ? theme.palette.primary.dark : theme.palette.grey['400']}`,
    borderRadius: '2px',
    backgroundColor: backgroundMode === 'gray' ? theme.palette.grey['600'] : theme.palette.secondary.main,

    boxSizing: 'border-box',
  }),
);

export const CheckedIconContainer = styled('div')(({ theme }) => ({
  width: '16px',
  height: '16px',

  borderRadius: '2px',
  backgroundColor: theme.palette.primary.main,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  boxSizing: 'border-box',
}));
