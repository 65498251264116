import { nanoid } from '@reduxjs/toolkit';

const id = nanoid();

export default function ChevronsRight({ color = '#000000', ...props }: Record<string, unknown> & { color?: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>chevrons-right</title>
      <defs>
        <path
          d="M7.29289322,0.292893219 C7.68341751,-0.0976310729 8.31658249,-0.0976310729 8.70710678,0.292893219 L13.7071068,5.29289322 C14.0976311,5.68341751 14.0976311,6.31658249 13.7071068,6.70710678 L8.70710678,11.7071068 C8.31658249,12.0976311 7.68341751,12.0976311 7.29289322,11.7071068 C6.90236893,11.3165825 6.90236893,10.6834175 7.29289322,10.2928932 L11.5857864,6 L7.29289322,1.70710678 C6.90236893,1.31658249 6.90236893,0.683417511 7.29289322,0.292893219 Z M0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L6.70710678,5.29289322 C7.09763107,5.68341751 7.09763107,6.31658249 6.70710678,6.70710678 L1.70710678,11.7071068 C1.31658249,12.0976311 0.683417511,12.0976311 0.292893219,11.7071068 C-0.0976310729,11.3165825 -0.0976310729,10.6834175 0.292893219,10.2928932 L4.58578644,6 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 Z"
          id={id}
        />
      </defs>
      <g id="🕹-Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Feather" transform="translate(-1076, -464)">
          <g id="chevrons-right" transform="translate(1081, 470)">
            <mask id={`mask-${id}`} fill="white">
              <use xlinkHref={`#${id}`} />
            </mask>
            <use id="Combined-Shape" fill={color} fillRule="nonzero" xlinkHref={`#${id}`} />
            <g id="COLOR/-black" mask={`url(#mask-${id})`} fill={color}>
              <g transform="translate(-5, -6)" id="Rectangle">
                <rect x={0} y={0} width={24} height={24} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
